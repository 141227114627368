import 'intersection-observer' // Polyfill for v50 and below
import { lazyload } from '../utilities/DOM_events'
import * as device from '../device'
import axios from 'axios'

import {
  removeAllMsNewsBlocks,
  getBlockAttributes,
  setRequestUrl,
  renderItems
} from './ms_news_utilites'

const filterFeeds = (items, usedMsNewsItems) => {
  return items.filter(item => {
    if (usedMsNewsItems[item.id]) return false

    usedMsNewsItems[item.id] = true
    return true
  })
}

const initMsNews = () => {
  const msNewsBlockContainers = Array.from(document.querySelectorAll('[data-mp-ms-news-block]'))
  // if no ms new block, return
  if (msNewsBlockContainers.length === 0) {
    return
  }
  const tracking_ocid = (window as any).mp_globals?.ms_news_config?.tracking_ocid

  if (!tracking_ocid) {
    removeAllMsNewsBlocks()
  }

  const categoryCounts = {}
  const usedMsNewsItems = {}

  // For each block.
  // We can get blocks on the page (so it's ordered) and then verify they have a corresponding config,
  // otherwise destroy.
  for (const msNewsBlockContainer of msNewsBlockContainers) {
    // get block attributes
    const blockAttributes = getBlockAttributes(msNewsBlockContainer)
    const { category, count, blockId, personalize } = blockAttributes
    const offset = categoryCounts[category]
    if (categoryCounts[category]) {
      categoryCounts[category] += parseInt(count)
    } else {
      categoryCounts[category] = parseInt(count)
    }

    const requestContent = async () => {
      try {
        if (personalize && !device.mpid()) throw new Error('personaliz block needs an mpid')

        const requestUrl = setRequestUrl(tracking_ocid, count, category, offset, personalize)

        const response = await axios.get(requestUrl)
        const activityId = response.headers['ddd-activityid'].split(',')[0]
        const items = response?.data?.value?.[0]?.subCards

        if (!items || !items.length) {
          throw new Error('MSN returned no items')
        }

        const filteredItems = await filterFeeds(items, usedMsNewsItems)

        if (!filteredItems || filteredItems.length === 0) {
          throw new Error('MSN: no unused msn items left')
        }

        renderItems(filteredItems, msNewsBlockContainer, blockId, personalize, activityId)
        msNewsBlockContainer.classList.remove('skeleton')
        msNewsBlockContainer.dispatchEvent(new Event('msNewsLoad'))
      } catch (err) {
        console.error(err)
        // Request error. There are no retries. Destroy block.
        msNewsBlockContainer.remove()
      }
    }

    lazyload(msNewsBlockContainer, requestContent, { rootMargin: '100px', threshold: 0 })
  }
}

export default initMsNews
