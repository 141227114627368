import * as device from '../device'
import { isTwaMode } from '../bridge_interface/bridge_interface'
import { getMpFeature } from '../utilities'

const appVersion = device.majorAppVersion()

const setV74Links = () => {
  if (appVersion !== 74) return

  const linkArr = Array.from(
    document.querySelectorAll<HTMLAnchorElement>('[data-banner-twa-settings-icon]')
  )

  linkArr.forEach(element => {
    switch (device.appPartner()) {
      case 'cricket': {
        element.href = 'https://dailymoments.page.link/ScoopSettings'
        break
      }
      case 'blu': {
        element.href = 'https://blumoment.page.link/BluSettings'
        break
      }
      case 'pwg': {
        element.href = 'https://pwgmoment.page.link/PWGNPSettings'
        break
      }
      case 'boost': {
        element.href = 'https://dailymoments.page.link/FastNewsSettings'
        break
      }
      case 'metropcs': {
        element.href = 'https://dailymoments.page.link/MZTMOSettings'
        break
      }
      case 'firstly': {
        element.href = 'https://dailymoments.page.link/FirstlySettings'
        break
      }
      case 'mobileposse': {
        element.href = 'https://dailymoments.page.link/MZMPSettings'
        break
      }
    }
  })

  if (appVersion < 75) {
  }
}

const setV75Links = () => {
  if (appVersion < 75) return

  const linkArr = Array.from(
    document.querySelectorAll<HTMLAnchorElement>('[data-banner-twa-settings-icon]')
  )

  linkArr.forEach(element => {
    if (device.appPartner() === 'mobileposse' && appVersion >= 75) {
      element.href = 'https://dailymoments.page.link/MZMPSettings'
    }
  })
}

const renderBanner = () => {
  const banner: HTMLElement = document.querySelector('[data-banner-twa]') as HTMLElement
  const noSettings: HTMLElement = document.querySelector(
    '[data-banner-twa-version="below74"]'
  ) as HTMLElement
  const withSettings: HTMLElement = document.querySelector(
    '[data-banner-twa-version="above74"]'
  ) as HTMLElement

  banner.style.display = 'flex'

  if (appVersion >= 74) {
    noSettings.style.display = 'none'
    withSettings.style.display = 'flex'
  } else {
    noSettings.style.display = 'flex'
    withSettings.style.display = 'none'
  }
}

const hideBanner = () => {
  const banner: HTMLElement = document.querySelector('[data-banner-twa]') as HTMLElement
  const noSettings: HTMLElement = document.querySelector(
    '[data-banner-twa-version="below74"]'
  ) as HTMLElement
  const withSettings: HTMLElement = document.querySelector(
    '[data-banner-twa-version="above74"]'
  ) as HTMLElement

  banner.style.display = 'none'
  noSettings.style.display = 'none'
  withSettings.style.display = 'none'
}

const initBanner = () => {
  const banner: HTMLElement = document.querySelector('[data-banner-twa]') as HTMLElement

  if (!banner) return

  if (isTwaMode() && getMpFeature('twa_banner', true)) {
    renderBanner()
  } else {
    hideBanner()
  }

  setV74Links()
  setV75Links()
}

export default initBanner
