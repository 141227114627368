import { getAssetDomainByMPGlobals } from 'lib/deployment'
import captureMessage from '../../../../sentry/captureMessage'
import template from '../../shared/templates/feed_item'
import { setTimeDifference } from '../../timestamp/utils'
import { cropImage } from '../../utilities/imageManipulation'

const w = window as any
const language = document.documentElement.lang

export const validateUrl = urlToValidate => {
  try {
    new URL(urlToValidate)
    return urlToValidate
  } catch (e) {
    return ''
  }
}

export const checkLinksValidity = currentPost => {
  const isLinkValid = validateUrl(currentPost.url)
  const isSourceLinkValid = validateUrl(currentPost.sourceLink)

  if (!isLinkValid && isSourceLinkValid) {
    currentPost.url = currentPost.sourceLink
  }

  if (isLinkValid && !isSourceLinkValid) {
    currentPost.sourceLink = currentPost.url
  }

  if (!isLinkValid && !isSourceLinkValid) {
    captureMessage(`Fotoscapes ${currentPost.uid}: No Valid Links`)

    currentPost.url = ''
    currentPost.sourceLink = ''
  }
}

export const formatUrl = (type, currentPost) => {
  // updates the url of the currentPost to the sourceLink value of the API response if link_destination has been set to "referral" within the block.
  const base = w.location.href.substring(0, w.location.href.lastIndexOf('/'))

  switch (type) {
    case 'referral':
      currentPost.url = currentPost.sourceLink
      break
    case 'full_page_article':
      currentPost.url = `${base}/article.html?fotoscape_id=${currentPost.uid}`
      break
    case 'stub':
      currentPost.url = `${base}/springboard.html?fotoscape_id=${currentPost.uid}`
      break
  }
}

export const formatDestination = (
  currentPost,
  block_link_destination,
  platform_link_destination
) => {
  if (block_link_destination) {
    formatUrl(block_link_destination, currentPost)
  } else if (platform_link_destination && platform_link_destination[currentPost.lbtype]) {
    formatUrl(platform_link_destination[currentPost.lbtype], currentPost)
  } else if (currentPost.lbtype == 'springboard') {
    currentPost.url = currentPost.sourceLink
  }
}

/**
 * Scrub weird n dash parsing.
 */
export const scrubDescription = (s: string) => {
  return s
    .replace(/\^\[(.+?)\|.+?\^]$/, '') // Remove trailing links.
    .replace(/\^\[(.+?)\|.+?\^]/g, '$1') // Replace inline links with plain text.
    .replace(/\s+$/s, '') // Remove trailing whitespace.
    .replace(/\^—/g, '—')
}

export const getContextFromJson = postSettings => {
  const {
    title,
    image: thumbnailUrl,
    url: link,
    publisher: publisherName,
    logo,
    darkLogo,
    layout,
    summary: description,
    index,
    pub_time,
    category,
    uid
  } = postSettings

  const publisherLogo = cropImage(logo, getAssetDomainByMPGlobals(), {
    size: 'clamped',
    protocol: 'https:'
  })
  const publisherDarkLogo = cropImage(darkLogo, getAssetDomainByMPGlobals(), {
    size: 'clamped',
    protocol: 'https:'
  })

  const context: any = {
    thumbnailUrl,
    publisherName,
    publisherLogo,
    publisherDarkLogo,
    title,
    contentType: 'gallery',
    contentTypeText: 'browse',
    link,
    layout,
    description: scrubDescription(description),
    index,
    pub_time: setTimeDifference(pub_time, language),
    category,
    uid
  }

  return context
}

export const renderPost = postSettings => {
  return template(getContextFromJson(postSettings))
}

export const isTrendingCarousel = layout => layout?.includes('trending-carousel')
export const isInstantPlayGames = layout => layout?.includes('instant-play-games')

export const wrappedLayouts = ['edge-list', 'edge-tile', 'trending-carousel', 'instant-play-games']
export const hasSectionWrapper = layout =>
  wrappedLayouts.filter(wrappedLayout => layout.includes(wrappedLayout)).length

export const getArticlElement = (layout, articleElement) => {
  return hasSectionWrapper(layout) ? articleElement.querySelector('article') : articleElement
}
