let lastClickedArticle: Element;

export const setBlockAnimation = (element: Element) => {
  element.addEventListener('click', () => {
    if(lastClickedArticle) {
      lastClickedArticle.classList.remove('block-item--active')
    }

    element.classList.add('block-item--active')
    lastClickedArticle = element;

    window.onfocus = function() {
      if(lastClickedArticle) {
        lastClickedArticle.classList.remove('block-item--active')
      }

      window.onfocus = null
    }
  })
}
