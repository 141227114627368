/**
 * Captures an exception to Sentry in the content frontend.
 */
const captureException = (exception: Error, message = 'NEPTUNE ERROR') => {
  const w = window as any

  console.error(`${message}: %O`, exception)

  if (w.Sentry) {
    w.Sentry.captureException(exception)
  }
}

export default captureException
