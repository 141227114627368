import i18n from '../../../../../utilities/translation'
import { appendInternalTrackingSearchParams } from '../../../utilities/analytics'
import { FeedItemContext } from 'content/src/lib/feed/interfaces'
import { createAttributes } from '../utils'
import { header, footer, language, media } from '../feed_item'

const podcastLargeTemplate = (feedItem: FeedItemContext) => {
  const {
    link,
    title,
    duration,
    publisherLogo,
    publisherDarkLogo,
    publisherName,
    isVideoLink,
    thumbnailUrl,
    isSponsored,
    contentType,
    contentTypeText,
    ms_news,
    contentApiId,
    pub_time,
    layout
  } = feedItem

  return `
    <article ${createAttributes(feedItem)}>
      <a data-mp-cct
        class='block-item__body'
        href='${appendInternalTrackingSearchParams(link)}'>
      
        <div class="block-item__musicbars"></div>
      
        ${media(thumbnailUrl, isVideoLink, duration, layout)}

        ${header(
          isSponsored,
          publisherDarkLogo,
          publisherLogo,
          publisherName,
          isVideoLink,
          ms_news,
          layout
        )}
        <h1 class="block-item__title ${language}">
          <span class="block-item__timestamp"></span>
          <span class="block-item__headline">${title}</span>
          <span class="block-item__subtitle ${language}"></span>
        </h1>
        <a class="block-item__playbutton">${i18n('Play', language)}</a>
      </a>
 
      ${footer(
        title,
        link,
        isSponsored,
        contentTypeText,
        contentType,
        language,
        layout,
        publisherDarkLogo,
        publisherLogo,
        publisherName,
        isVideoLink,
        ms_news,
        contentApiId,
        pub_time
      )}
    </article>
  `
}

export default podcastLargeTemplate
