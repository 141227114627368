import { media, header } from '../../feed_item'
import { createAttributes } from '../../utils'
import { appendInternalTrackingSearchParams } from '../../../../utilities/analytics'
const language = document.documentElement.lang

const feedItemTemplate = feedItem => {
  const {
    link,
    title,
    publisherLogo,
    publisherDarkLogo,
    publisherName,
    isVideoLink,
    thumbnailUrl,
    duration,
    isSponsored,
    layout,
    pub_time,
    ms_news
  } = feedItem

  return `
    <section class="swiper-slide">
      <article
        ${createAttributes(feedItem)}>
        <a class="block-item__body" href="${appendInternalTrackingSearchParams(link)}" data-mp-cct >
            <div class="block-item__body-content">
            ${header(
              isSponsored,
              publisherDarkLogo,
              publisherLogo,
              publisherName,
              isVideoLink,
              ms_news
            )}
            ${title ? `<h1 class="block-item__title ${language}">${title}</h1>` : ''}
            ${pub_time ? `<span class="block-item__timestamp">${pub_time}</span>` : ``}
            </div>
            ${media(thumbnailUrl, isVideoLink, duration, layout)}
        </a>
    </article>
  </section>`
}

export default feedItemTemplate
