import axios from 'axios'
import FotoscapesURL from '../fotoscape/FotoscapesURL'
import { NewPersonalize } from '../fotoscape/helpers/personalize'
import { fetchFotoscapesDaily } from '../fotoscape/fotoscape'
import initActionButtons from '../action_buttons/action_buttons'
import * as modal from '../utilities/modal/modal'
import { GA4FotoscapeGallery } from '../ga4_support/blocks/GA4FotoscapeGallery'
import fotoscapeGalleryWrapper from '../shared/templates/layouts/fotoscape_gallery/fotoscape_gallery_wrapper'
import fotoscapeGalleryItem from '../shared/templates/layouts/fotoscape_gallery/fotoscape_gallery_item'
import captureException from '../../../sentry/captureException'

interface categorizedBlocksInterface {
  [key: string]: any[]
}

const getLookbook = async uid => {
  const url = `${FotoscapesURL.getBaseURL()}/wp/v1/lookbook/${uid}?ckey=${FotoscapesURL.getKey()}&tf=html`

  const response: any = await axios.get(url)
  const { data } = response

  return data.lookbook.looks
}

const contentInit = async () => {
  try {
    const config = (window as any).mp_globals.fotoscape_config
    if (config == undefined) {
      return
    }

    let { blocks } = config
    blocks = blocks.filter(block => block.block_id.includes('fotoscape_gallery'))

    const categorizedBlocks: categorizedBlocksInterface = {}

    for (const block of blocks) {
      const { category } = block
      if (!categorizedBlocks[category]) {
        categorizedBlocks[category] = []
      }
      categorizedBlocks[category].push(block)
    }

    // noinspection ES6MissingAwait
    Object.entries(categorizedBlocks).forEach(async ([category, blocks]) => {
      const data = await fetchFotoscapesDaily(category)
      let { items } = data
      const instance = NewPersonalize()

      for (const block of blocks) {
        const blockElement = document.getElementById(block.block_id)
        const blockContainerElement = document.querySelector(`#${block.block_id} .block__main`)

        if (!blockContainerElement || !(blockContainerElement instanceof HTMLElement)) {
          continue
        }

        GA4FotoscapeGallery.wireImpressionBlock(blockElement)

        let galleryImages

        const settings = {
          count: 1
        }
        const appropriateItems = items.filter(post => post.numImages > 2 && post.link)
        const lookbookItem = instance.choose(appropriateItems, settings, {})[0]

        if (!lookbookItem) return

        const looks = await getLookbook(lookbookItem.uid)
        if (looks.length) {
          galleryImages = looks.map(look => instance.findImage(look.images, 2220, 1296))
        }

        if (!galleryImages.length) return

        const language = document.documentElement.lang

        blockContainerElement.innerHTML = fotoscapeGalleryWrapper({
          publisherLogo: lookbookItem.brandLogoDark,
          publisherDarkLogo: lookbookItem.brandLogo,
          publisherName: lookbookItem.owner,
          url: lookbookItem.link,
          items: galleryImages.length,
          title: instance.chooseText(lookbookItem.title, language)
        })

        GA4FotoscapeGallery.wireLinkClick(blockContainerElement)

        // remove post from all items to avoid duplication
        items = items.filter(post => post.uid != lookbookItem.uid)

        galleryImages.forEach((image, index) => {
          const currentPostElementString = fotoscapeGalleryItem(image.url)

          const dummyElement = document.createElement('div')
          dummyElement.innerHTML = currentPostElementString

          const imageElement = dummyElement.querySelector('.swiper-slide') as HTMLElement
          blockContainerElement.querySelector('.swiper-wrapper').appendChild(imageElement)

          GA4FotoscapeGallery.wireImpressionImage(blockElement, imageElement, index)
        })

        blockContainerElement.parentElement.dispatchEvent(new Event('fotoscapesGalleryLoad'))
        blockContainerElement.parentElement.classList.remove('skeleton')

        modal.init({ containerSelector: `#${block.block_id}` })
        initActionButtons(`#${block.block_id}`)
      }
    })
  } catch (e) {
    console.error('Fotoscape gallery init error ', e)
    captureException(e, 'Fotoscape gallery init error')
  }
}

export default contentInit
