import { NeptuneAttribute, NeptuneAttributeKey } from '../../../lib/NeptuneAttribute'
import { lazyload } from '../../utilities/DOM_events'
import { BlockType, DataLayerInteractionType } from '../interfaces'
import GA4Handler from '../GA4Handler'

/**
 * These handle blocks that are generated from handlebars templates.
 */
class GA4Block {
  static setupCarousel(blockElement: HTMLElement) {
    const links = Array.from(blockElement.querySelectorAll('a'))

    for (const linkIndex in links) {
      const link = links[linkIndex] as HTMLElement
      const theme = blockElement.getAttribute('data-mp-section-nav-bar-theme')
      const action = link.getAttribute('data-hash-id')

      link.addEventListener('click', e => {
        GA4Handler.clickHandleEvent(e)
      })

      // Setup impression handling.
      lazyload(
        link,
        () => {
          const dataLayerEvent = GA4Handler.createImpressionObject(link, {
            carousel_position: linkIndex,
            element_action: action,
            theme: theme
          })

          GA4Handler.pushObject('GA4Block.setupCarousel', dataLayerEvent)
        },
        {
          threshold: 0.7 // This might need to be tweaked for carousel.
        }
      )
    }
  }

  static setupDiscoverBar(blockElement: HTMLElement, blockPosition: string) {
    const linksDiscoverBar = Array.from(blockElement.querySelectorAll('a.navigation__item'))

    // @todo Change to
    for (const linkIndex in linksDiscoverBar) {
      // Click handling.
      const link = linksDiscoverBar[linkIndex] as HTMLElement
      link.addEventListener('click', e => {
        GA4Handler.clickHandleEvent(e)
      })

      GA4Handler.impressionHandleEvent(link, {
        block_position: blockPosition,
        carousel_position: linkIndex,
        interaction_type: DataLayerInteractionType.internal
      })
    }
  }

  static setupGeneric(blockType: string, blockElement: HTMLElement) {
    const optional: any = {}

    switch (blockType) {
      case BlockType.house_deal_block:
        optional.title = blockElement.querySelector('article').getAttribute('data-mp-share-title')
        break
      case BlockType.portal_footer_block:
        optional.element_action = 'dnsmi_or_manage_policy'
        break
      case BlockType.promo_card_block:
        optional.element_action = 'my_account'
        break
      case BlockType.header_block_alert:
        optional.title = (blockElement.querySelector('.header__title > h2') as HTMLElement).innerText
        optional.element_action = 'header_block_alert'
    }

    ;(() => {
      const links = Array.from(blockElement.querySelectorAll('a'))

      for (const linkIndex in links) {
        const link = links[linkIndex]

        switch (blockType) {
          // These have empty links which allow the selector to work, but don't have click events.
          case BlockType.horoscope_details_block:
          case BlockType.horoscope_details_group_block:
            break
          default:
            link.addEventListener('click', e => {
              GA4Handler.clickHandleEvent(e, optional)
            })
        }

        const dataLayerEvent = GA4Handler.createImpressionObject(link, optional)

        lazyload(
          link,
          () => {
            GA4Handler.pushObject('GA4Handler.setupGeneric', dataLayerEvent)
          },
          {
            threshold: 0.7 // This might need to be tweaked for carousel.
          }
        )
      }
    })()
  }
}

export default GA4Block
