import { FeedItemContext } from '../../../../../../lib/feed/interfaces'
import { appendInternalTrackingSearchParams } from '../../../utilities/analytics'
import { style, header, footer, language, app_theme } from '../feed_item'
import {
  isVisionYouTheme,
  isMomentTheme,
  removeSpecialCharacters,
  createAttributes
} from '../utils'

const smallPhotocardTemplate = (feedItem: FeedItemContext) => {
  const {
    link,
    title,
    publisherLogo,
    publisherDarkLogo,
    publisherName,
    isVideoLink,
    thumbnailUrl,
    isSponsored,
    contentType,
    contentTypeText,
    description,
    ms_news,
    contentApiId,
    pub_time,
    layout
  } = feedItem

  return `
  <article
    ${createAttributes(feedItem)}>
    <a class="block-item__body"
       href="${appendInternalTrackingSearchParams(link)}"
       style="${style(layout, thumbnailUrl)}"
       data-mp-cct>

      ${
        isVisionYouTheme(app_theme) || isMomentTheme(app_theme)
          ? `<div class="block-item__media">
          <img loading="lazy" class="block-item__thumbnail" src="${thumbnailUrl}" alt="" />
          </div>
          <div class="block-item__blur"></div>
          `
          : ''
      }
      ${header(
        isSponsored,
        publisherDarkLogo,
        publisherLogo,
        publisherName,
        isVideoLink,
        ms_news,
        layout
      )}

      <h1 class="block-item__title ${language}">
            <span class="block-item__headline">${title}</span>
            <span class="block-item__subtitle ${language}"></span>
          </h1>

          ${
            description
              ? `<p class="block-item__description ${language}">${removeSpecialCharacters(
                  description
                )}</p>`
              : ''
          }
    </a>

    ${footer(
      title,
      link,
      isSponsored,
      contentTypeText,
      contentType,
      language,
      layout,
      publisherDarkLogo,
      publisherLogo,
      publisherName,
      isVideoLink,
      ms_news,
      contentApiId,
      pub_time
    )}

  </article>
  `
}

export default smallPhotocardTemplate
