/**
 * Captures a message to Sentry in the content frontend.
 */
const captureMessage = (exception: string, message = 'NEPTUNE ERROR') => {
  const w = window as any

  console.error(`${message}: %o`, exception)

  if (w.Sentry) {
    w.Sentry.captureMessage(exception)
  }
}

export default captureMessage
