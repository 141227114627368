import { getMode } from '../bridge_interface/bridge_interface'

const initModeFilter = () => {
  const templates: any[] = Array.from(document.querySelectorAll('template[data-filter-modes]'))
  const mode = getMode()

  try {
    for (const elem of templates) {
      const modeFilters = elem.dataset.filterModes.split(',')

      if (!mode && modeFilters.includes('unknown')) continue
      if (modeFilters.includes(mode)) continue

      const content = elem.content.cloneNode(true)
      elem.parentNode.insertBefore(content, elem)
    }
  } catch (e) {
    console.error('error filtering ad', e)
  }
}

export default initModeFilter
