class FotoscapesURL {
  static getBaseDomain(): string {
    if (typeof window === 'undefined') {
      return 'fotoscapes.com'
    }

    const params = new URLSearchParams(window?.location?.search)
    if (params.has('fotoscapes_staging')) {
      return 'staging.fotoscapes.com'
    }

    return 'fotoscapes.com'
  }

  static getBaseURL(): string {
    if (typeof window === 'undefined') {
      return 'https://fotoscapes.com'
    }

    const params = new URLSearchParams(window?.location?.search)
    if (params.has('fotoscapes_staging')) {
      return 'https://staging.fotoscapes.com'
    }

    return 'https://fotoscapes.com'
  }

  static getKey(): string {
    if (typeof window === 'undefined') {
      return 'fb529d256155b9c6'
    }

    const params = new URLSearchParams(window?.location?.search)
    if (params.has('fotoscapes_staging')) {
      return '733053ec7939379e'
      // return 'fb529d256155b9c6'
    }

    // 733053ec7939379e
    return 'fb529d256155b9c6'
  }

  static getMediaDomain(): string {
    if (typeof window === 'undefined') {
      return 'media.fotoscapes.com'
    }

    const params = new URLSearchParams(window?.location?.search)
    if (params.has('fotoscapes_staging')) {
      return 'media.staging.fotoscapes.com'
    }

    return 'media.fotoscapes.com'
  }

  static getMediaURL(): string {
    if (typeof window === 'undefined') {
      return 'https://media.fotoscapes.com'
    }

    const params = new URLSearchParams(window?.location?.search)
    if (params.has('fotoscapes_staging')) {
      return 'https://media.staging.fotoscapes.com'
    }

    return 'https://media.fotoscapes.com'
  }
}

export default FotoscapesURL
