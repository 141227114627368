import captureMessage from '../../../sentry/captureMessage'
import { fetchFotoscapesDaily } from '../fotoscape/fotoscape'
import { formatDestination, checkLinksValidity } from '../fotoscape/helpers/utils'
import { FotoscapeDailyResponseArticle } from '../fotoscape/interfaces/FotoscapeDailyArticle'
import { appendInternalTrackingSearchParams } from '../utilities/analytics'
import { neptuneDebug } from '../../../utilities'
import captureException from '../../../sentry/captureException'
import { NewPersonalize } from '../fotoscape/helpers/personalize'
import { GA4TickerFotoscape } from '../ga4_support/blocks/GA4TickerFotoscape'

interface IFsData {
  [key: string]: FotoscapeDailyResponseArticle[]
}
const fsData: IFsData = {}

let platformLinkDestination = document.documentElement.getAttribute('data-mp-fotoscape-link-destinaton')

try {
  if (platformLinkDestination) {
    platformLinkDestination = JSON.parse(platformLinkDestination)
  }
} catch (e) {
  console.error('Cannot parse platform link destination ', e)
}

const setUI = (template, data) => {
  const language = document.documentElement.lang
  const instance = NewPersonalize()
  const itemData = data
  itemData.url = itemData.link || ''

  const title = instance.chooseText(data.title, language)
  const image = instance.findImage(data.previews, 120, 120).link

  const content = template.content.firstElementChild.cloneNode(true)
  const blockLinkDestination = template.dataset.mpFotoscapeTickerLinkDestination
  const layout = JSON.parse(template.dataset.mpFotoscapeTickerLayout)

  const { type, font_size, font_alignment = '', background_color = '' } = layout

  checkLinksValidity(itemData)
  formatDestination(itemData, blockLinkDestination, platformLinkDestination)

  content.querySelector('[data-mp-fotoscape-ticker-text]').textContent = title
  content.querySelector('[data-mp-fotoscape-ticker-image]').src = image
  content.querySelector('[data-mp-fotoscape-ticker-link]').href = appendInternalTrackingSearchParams(itemData.url)

  const tickerContainer = content.querySelector('[data-mp-fotoscape-ticker-container]')

  if (type === 'color') {
    tickerContainer.style['background-color'] = background_color
    tickerContainer.classList.add(type, font_size)
  } else {
    tickerContainer.classList.add(type, font_size, font_alignment)
  }

  return content
}

const checkDataValidity = (itemsArray: FotoscapeDailyResponseArticle[]): FotoscapeDailyResponseArticle[] => {
  try {
    return itemsArray.filter(item => {
      if (!item.link && !item.sourceLink) {
        captureMessage(`Ticker Block Fotoscape Item ${item.uid} Is Invalid: No Links`)
        return false
      }
      return true
    })
  } catch (e) {
    console.error('Error Filtering Ticker Fotoscape Items ', e)
    return []
  }
}

const displayWidget = (template, index) => {
  try {
    const category = template.dataset.mpFotoscapeTickerCategory
    const data = fsData[category].shift()
    const content = setUI(template, data)

    const child = template.parentNode.insertBefore(content, template)

    GA4TickerFotoscape.wireImpressionImage(child, index)
    GA4TickerFotoscape.wireLinkClick(child.querySelector('a'))
  } catch (e) {
    neptuneDebug(e)
    captureException(e)
  }
}

const getTickerBlocks = (): HTMLElement[] => {
  return Array.from(document.querySelectorAll('section.block--ticker'))
}

const getTemplatesForTickerBlock = (block): HTMLElement[] => {
  return Array.from(block.querySelectorAll('template[data-ticker-fotoscape-template]'))
}

const initFotoscapeTicker = async () => {
  const blocks = getTickerBlocks()

  if (!blocks || blocks.length === 0) {
    return
  }

  for (const block of blocks) {
    const tempArr = getTemplatesForTickerBlock(block)

    if (!tempArr || tempArr.length === 0) {
      continue
    }

    await Promise.all(
      tempArr.map(async (template: any) => {
        const category = template.dataset.mpFotoscapeTickerCategory
        const data = await fetchFotoscapesDaily(category)
        fsData[category] = checkDataValidity(data.items)
      })
    )

    tempArr.map((template, index) => {
      const category = template.dataset.mpFotoscapeTickerCategory

      if (!fsData[category] || fsData[category].length === 0) {
        return
      }

      displayWidget(template, index)
    })
  }
}

export default initFotoscapeTicker
