export const signData = {
  es: {
    aquarius: { dates: 'en. 20 - febr. 18', testExpression: /^Acuario/i },
    pisces: { dates: 'febr. 19 - mzo. 20', testExpression: /^Piscis/i },
    aries: { dates: 'mzo. 21 - abr. 19', testExpression: /^aries/i },
    taurus: { dates: 'abr. 20 - mayo 20', testExpression: /^tauro/i },
    gemini: { dates: 'mayo 21 - jun. 20', testExpression: /^géminis/i },
    cancer: { dates: 'jun. 21 - jul. 22', testExpression: /^Cáncer/i },
    leo: { dates: 'jul. 23 - ag. 22', testExpression: /^Leo/i },
    virgo: { dates: 'ag. 23 - set. 22', testExpression: /^Virgo/i },
    libra: { dates: 'set. 23 - oct. 22', testExpression: /^Libra/i },
    scorpio: { dates: 'oct. 23 - nov. 21', testExpression: /^Escorpi/i },
    sagittarius: { dates: 'nov. 22 - dic. 21', testExpression: /^Sagitario/i },
    capricorn: { dates: 'dic. 22 - en. 19', testExpression: /^Capricornio/i }
  },
  en: {
    aquarius: { dates: 'Jan 20 - Feb 18', testExpression: /^Aquarius/i },
    pisces: { dates: 'Feb 19 - Mar 20', testExpression: /^Pisces/i },
    aries: { dates: 'Mar 21 - Apr 19', testExpression: /^Aries/i },
    taurus: { dates: 'Apr 20 - May 20', testExpression: /^Taurus/i },
    gemini: { dates: 'May 21 - Jun 20', testExpression: /^Gemini/i },
    cancer: { dates: 'Jun 21 - Jul 22', testExpression: /^Cancer/i },
    leo: { dates: 'Jul 23 - Aug 22', testExpression: /^Leo/i },
    virgo: { dates: 'Aug 23 - Sep 22', testExpression: /^Virgo/i },
    libra: { dates: 'Sep 23 - Oct 22', testExpression: /^Libra/i },
    scorpio: { dates: 'Oct 23 - Nov 21', testExpression: /^Scorpio/i },
    sagittarius: { dates: 'Nov 22 - Dec 21', testExpression: /^Sagittarius/i },
    capricorn: { dates: 'Dec 22 - Jan 19', testExpression: /^Capricorn/i }
  },
  'en-at': {
    aquarius: { dates: 'Jan 20 - Feb 18', testExpression: /^Aquarius/i },
    pisces: { dates: 'Feb 19 - Mar 20', testExpression: /^Pisces/i },
    aries: { dates: 'Mar 21 - Apr 19', testExpression: /^Aries/i },
    taurus: { dates: 'Apr 20 - May 20', testExpression: /^Taurus/i },
    gemini: { dates: 'May 21 - Jun 20', testExpression: /^Gemini/i },
    cancer: { dates: 'Jun 21 - Jul 22', testExpression: /^Cancer/i },
    leo: { dates: 'Jul 23 - Aug 22', testExpression: /^Leo/i },
    virgo: { dates: 'Aug 23 - Sep 22', testExpression: /^Virgo/i },
    libra: { dates: 'Sep 23 - Oct 22', testExpression: /^Libra/i },
    scorpio: { dates: 'Oct 23 - Nov 21', testExpression: /^Scorpio/i },
    sagittarius: { dates: 'Nov 22 - Dec 21', testExpression: /^Sagittarius/i },
    capricorn: { dates: 'Dec 22 - Jan 19', testExpression: /^Capricorn/i }
  },
  hi: {
    aquarius: { dates: 'Jan 20 - Feb 18', testExpression: /^Aquarius/i },
    pisces: { dates: 'Feb 19 - Mar 20', testExpression: /^Pisces/i },
    aries: { dates: 'Mar 21 - Apr 19', testExpression: /^Aries/i },
    taurus: { dates: 'Apr 20 - May 20', testExpression: /^Taurus/i },
    gemini: { dates: 'May 21 - Jun 20', testExpression: /^Gemini/i },
    cancer: { dates: 'Jun 21 - Jul 22', testExpression: /^Cancer/i },
    leo: { dates: 'Jul 23 - Aug 22', testExpression: /^Leo/i },
    virgo: { dates: 'Aug 23 - Sep 22', testExpression: /^Virgo/i },
    libra: { dates: 'Sep 23 - Oct 22', testExpression: /^Libra/i },
    scorpio: { dates: 'Oct 23 - Nov 21', testExpression: /^Scorpio/i },
    sagittarius: { dates: 'Nov 22 - Dec 21', testExpression: /^Sagittarius/i },
    capricorn: { dates: 'Dec 22 - Jan 19', testExpression: /^Capricorn/i }
  },
  pt: {
    aquarius: { dates: '20 Jan - 18 Fev', testExpression: /^Aquário/i },
    pisces: { dates: '19 Fev - 20 Mar', testExpression: /^Peixes/i },
    aries: { dates: '21 Mar - 19 Abr', testExpression: /^Áries/i },
    taurus: { dates: '20 Abr - 20 Maio', testExpression: /^Touro/i },
    gemini: { dates: '21 Maio - 20 Jun', testExpression: /^Gêmeos/i },
    cancer: { dates: '21 Jun - 22 Jul', testExpression: /^Câncer/i },
    leo: { dates: '23 Jul - 22 Ago', testExpression: /^Leão/i },
    virgo: { dates: '23 Ago - 22 Set', testExpression: /^Virgem/i },
    libra: { dates: '23 Set - 22 Out', testExpression: /^Libra/i },
    scorpio: { dates: '23 Out - 21 Nov', testExpression: /^Escorpião/i },
    sagittarius: { dates: '22 Nov - 21 Dez', testExpression: /^Sagitário/i },
    capricorn: { dates: '22 Dez - 19 Jan', testExpression: /^Capricórnio/i }
  },
  'en-in': {
    aquarius: { dates: '20 Jan - 18 Fev', testExpression: /^Aquário/i },
    pisces: { dates: '19 Fev - 20 Mar', testExpression: /^Peixes/i },
    aries: { dates: '21 Mar - 19 Abr', testExpression: /^Áries/i },
    taurus: { dates: '20 Abr - 20 Maio', testExpression: /^Touro/i },
    gemini: { dates: '21 Maio - 20 Jun', testExpression: /^Gêmeos/i },
    cancer: { dates: '21 Jun - 22 Jul', testExpression: /^Câncer/i },
    leo: { dates: '23 Jul - 22 Ago', testExpression: /^Leão/i },
    virgo: { dates: '23 Ago - 22 Set', testExpression: /^Virgem/i },
    libra: { dates: '23 Set - 22 Out', testExpression: /^Libra/i },
    scorpio: { dates: '23 Out - 21 Nov', testExpression: /^Escorpião/i },
    sagittarius: { dates: '22 Nov - 21 Dez', testExpression: /^Sagitário/i },
    capricorn: { dates: '22 Dez - 19 Jan', testExpression: /^Capricórnio/i }
  },
  'es-mx': {
    aquarius: { dates: 'en. 20 - febr. 18', testExpression: /^Acuario/i },
    pisces: { dates: 'febr. 19 - mzo. 20', testExpression: /^Piscis/i },
    aries: { dates: 'mzo. 21 - abr. 19', testExpression: /^aries/i },
    taurus: { dates: 'abr. 20 - mayo 20', testExpression: /^tauro/i },
    gemini: { dates: 'mayo 21 - jun. 20', testExpression: /^géminis/i },
    cancer: { dates: 'jun. 21 - jul. 22', testExpression: /^Cáncer/i },
    leo: { dates: 'jul. 23 - ag. 22', testExpression: /^Leo/i },
    virgo: { dates: 'ag. 23 - set. 22', testExpression: /^Virgo/i },
    libra: { dates: 'set. 23 - oct. 22', testExpression: /^Libra/i },
    scorpio: { dates: 'oct. 23 - nov. 21', testExpression: /^Escorpi/i },
    sagittarius: { dates: 'nov. 22 - dic. 21', testExpression: /^Sagitario/i },
    capricorn: { dates: 'dic. 22 - en. 19', testExpression: /^Capricornio/i }
  },
  'fr-ca': {
    aquarius: { dates: 'Jan 20 - Feb 18', testExpression: /^Verseau/i },
    pisces: { dates: 'Feb 19 - Mar 20', testExpression: /^Poissons/i },
    aries: { dates: 'Mar 21 - Apr 19', testExpression: /^Bélier/i },
    taurus: { dates: 'Apr 20 - May 20', testExpression: /^Taureau/i },
    gemini: { dates: 'May 21 - Jun 20', testExpression: /^Gémeaux/i },
    cancer: { dates: 'Jun 21 - Jul 22', testExpression: /^Cancer/i },
    leo: { dates: 'Jul 23 - Aug 22', testExpression: /^Lion/i },
    virgo: { dates: 'Aug 23 - Sep 22', testExpression: /^Vierge/i },
    libra: { dates: 'Sep 23 - Oct 22', testExpression: /^Balance/i },
    scorpio: { dates: 'Oct 23 - Nov 21', testExpression: /^Scorpion/i },
    sagittarius: { dates: 'Nov 22 - Dec 21', testExpression: /^Sagittaire/i },
    capricorn: { dates: 'Dec 22 - Jan 19', testExpression: /^Capricorne/i }
  },
  'en-ph': {
    aquarius: { dates: 'Jan 20 - Feb 18', testExpression: /^Aquarius/i },
    pisces: { dates: 'Feb 19 - Mar 20', testExpression: /^Pisces/i },
    aries: { dates: 'Mar 21 - Apr 19', testExpression: /^Aries/i },
    taurus: { dates: 'Apr 20 - May 20', testExpression: /^Taurus/i },
    gemini: { dates: 'May 21 - Jun 20', testExpression: /^Gemini/i },
    cancer: { dates: 'Jun 21 - Jul 22', testExpression: /^Cancer/i },
    leo: { dates: 'Jul 23 - Aug 22', testExpression: /^Leo/i },
    virgo: { dates: 'Aug 23 - Sep 22', testExpression: /^Virgo/i },
    libra: { dates: 'Sep 23 - Oct 22', testExpression: /^Libra/i },
    scorpio: { dates: 'Oct 23 - Nov 21', testExpression: /^Scorpio/i },
    sagittarius: { dates: 'Nov 22 - Dec 21', testExpression: /^Sagittarius/i },
    capricorn: { dates: 'Dec 22 - Jan 19', testExpression: /^Capricorn/i }
  },
  it: {
    aquarius: { dates: 'en. 20 - febr. 18', testExpression: /^Acquario/i },
    pisces: { dates: 'febr. 19 - mzo. 20', testExpression: /^Pesci/i },
    aries: { dates: 'mzo. 21 - abr. 19', testExpression: /^Ariete/i },
    taurus: { dates: 'abr. 20 - mayo 20', testExpression: /^Toro/i },
    gemini: { dates: 'mayo 21 - jun. 20', testExpression: /^Gemelli/i },
    cancer: { dates: 'jun. 21 - jul. 22', testExpression: /^Cancro/i },
    leo: { dates: 'jul. 23 - ag. 22', testExpression: /^Leone/i },
    virgo: { dates: 'ag. 23 - set. 22', testExpression: /^Vergine/i },
    libra: { dates: 'set. 23 - oct. 22', testExpression: /^Bilancia/i },
    scorpio: { dates: 'oct. 23 - nov. 21', testExpression: /^Scorpione/i },
    sagittarius: { dates: 'nov. 22 - dic. 21', testExpression: /^Sagittario/i },
    capricorn: { dates: 'dic. 22 - en. 19', testExpression: /^Capricorno/i }
  },
  'de-at': {
    aquarius: { dates: 'Jan 20 - Feb 18', testExpression: /^Wassermann/i },
    pisces: { dates: 'Feb 19 - Mar 20', testExpression: /^Fische/i },
    aries: { dates: 'Mar 21 - Apr 19', testExpression: /^Widder/i },
    taurus: { dates: 'Apr 20 - May 20', testExpression: /^Stier/i },
    gemini: { dates: 'May 21 - Jun 20', testExpression: /^Zwillinge/i },
    cancer: { dates: 'Jun 21 - Jul 22', testExpression: /^Krebs/i },
    leo: { dates: 'Jul 23 - Aug 22', testExpression: /^Löwe/i },
    virgo: { dates: 'Aug 23 - Sep 22', testExpression: /^Jungfrau/i },
    libra: { dates: 'Sep 23 - Oct 22', testExpression: /^Waage/i },
    scorpio: { dates: 'Oct 23 - Nov 21', testExpression: /^Skorpion/i },
    sagittarius: { dates: 'Nov 22 - Dec 21', testExpression: /^Schütze/i },
    capricorn: { dates: 'Dec 22 - Jan 19', testExpression: /^Steinbock/i }
  },
  'en-ae': {
    aquarius: { dates: 'Jan 20 - Feb 18', testExpression: /^Aquarius/i },
    pisces: { dates: 'Feb 19 - Mar 20', testExpression: /^Pisces/i },
    aries: { dates: 'Mar 21 - Apr 19', testExpression: /^Aries/i },
    taurus: { dates: 'Apr 20 - May 20', testExpression: /^Taurus/i },
    gemini: { dates: 'May 21 - Jun 20', testExpression: /^Gemini/i },
    cancer: { dates: 'Jun 21 - Jul 22', testExpression: /^Cancer/i },
    leo: { dates: 'Jul 23 - Aug 22', testExpression: /^Leo/i },
    virgo: { dates: 'Aug 23 - Sep 22', testExpression: /^Virgo/i },
    libra: { dates: 'Sep 23 - Oct 22', testExpression: /^Libra/i },
    scorpio: { dates: 'Oct 23 - Nov 21', testExpression: /^Scorpio/i },
    sagittarius: { dates: 'Nov 22 - Dec 21', testExpression: /^Sagittarius/i },
    capricorn: { dates: 'Dec 22 - Jan 19', testExpression: /^Capricorn/i }
  }
}

const horoscopeBlockContextAdapter = async (block, page) => {
  const { settings } = block
  const { sign, type, leadsToChildPage } = settings
  let { language } = page
  if (language === 'en-ae') language = 'en'

  return {
    leadsToChildPage,
    partialName: 'horoscope_block',
    horoscopeType: type,
    horoscopeSign: sign,
    horoscopeDates: signData[language][sign].dates,
    link: `horoscope-${sign}.html`
  }
}

export default horoscopeBlockContextAdapter
