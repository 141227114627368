import { signData } from 'lib/context/context_adapters/block_context_adapters/horoscope_block_context_adapter'
import captureException from '../../../sentry/captureException'
import { NeptuneAttribute, NeptuneAttributeKey } from '../../lib/NeptuneAttribute'
import { fetchFotoscapesDaily } from '../fotoscape/fotoscape'
import initHoroscopeCarousel from '../horoscope_carousel'
import carouselItemTemplate from '../horoscope_carousel/template'
import { IFetchedHoroscopes, IFotoscapeHoroscopeResponse } from './interfaces'
import { setBlockAnimation } from '../../../utilities/animation'

const fetchedHoroscopes: IFetchedHoroscopes = {
  general: {},
  love: {},
  money: {},
  health: {}
} as IFetchedHoroscopes

const horoscopeBlocksIdentifiers = [
  'block--horoscope',
  'block--horoscope_details',
  'block--horoscope-top',
  'horoscope-group',
  'block--horoscope-carousel'
]
const app_theme = document.documentElement.getAttribute('data-mp-app-theme')
let language = document.documentElement.lang.split('-')[0];

const removeSkeleton = (skeletonEl: Element) => {
  const onTransitionEnd = () => {
    skeletonEl.remove();
    skeletonEl.removeEventListener("transitionend", onTransitionEnd)
  }
  skeletonEl.addEventListener("transitionend", onTransitionEnd);
  skeletonEl.classList.add('skeleton-wrapper--hidden');
}

const getBlockArticles = (identifier: string, block: HTMLElement): HTMLElement[] => {
  if (identifier === 'block--horoscope-carousel') {
    return Array.from(block.querySelectorAll('.block__main')) as HTMLElement[]
  }

  return Array.from(block.querySelectorAll('.block-item')) as HTMLElement[]
}

const initHoroscopeGridAnimation = () => {
  document.querySelectorAll('.block--horoscope-grid').forEach(gridEl => {
    gridEl.querySelectorAll('.block-item').forEach(gridItemEl => {
      setBlockAnimation(gridItemEl)
    });
  })
}

const horoscopesInit = async () => {
  for (const identifier of horoscopeBlocksIdentifiers) {
    const blocksOnPage: HTMLElement[] = Array.from(document.querySelectorAll(`.${identifier}`))

    if (!blocksOnPage.length) {
      continue
    }

    for (const block of blocksOnPage) {
      const blockArticles = getBlockArticles(identifier, block)

      if (!blockArticles.length) {
        continue
      }

      for (const article of blockArticles) {
        const horoscopeType = article.getAttribute('data-mp-horoscope-type')
        const horoscopeSign = article.getAttribute('data-mp-horoscope-sign')

        if (horoscopeType) {
          if (!Object.keys(fetchedHoroscopes[horoscopeType]).length) {
            try {
              let fetchedData: IFotoscapeHoroscopeResponse = await fetchFotoscapesDaily(
                `horoscopes-${horoscopeType}`
              )
              if (!fetchedData?.items?.length) {
                fetchedData = await fetchFotoscapesDaily(`horoscopes-${horoscopeType}`, '1:1', 'en')
                language = 'en'
              }

              const { items } = fetchedData
              if (items.length) {
                items.forEach(fetchedItem => {
                  let signKey
                  Object.keys(signData[language]).forEach(key => {
                    const testExpression = signData[language][key].testExpression
                    const isMatchingSign = testExpression.test(fetchedItem?.horoscope?.sign)

                    if (isMatchingSign) {
                      signKey = key
                    }
                  })
                  if (signKey) {
                    const horoscopeTitle = fetchedItem.horoscope.sign || signKey.toUppercase()
                    const horoscopeContent = fetchedItem.horoscope.horoscopetext[language]
                      ? fetchedItem.horoscope.horoscopetext[language]
                      : fetchedItem.horoscope.horoscopetext['en']
                    const { dates } = signData[language][signKey]

                    fetchedHoroscopes[horoscopeType] = {
                      ...fetchedHoroscopes[horoscopeType],
                      [signKey]: { title: horoscopeTitle, content: horoscopeContent, dates }
                    }
                  }
                })
              }
            } catch (e) {
              captureException(e, 'horoscopesInit: cannot fetch horoscope data ')
            }
          }
        } else {
          throw new Error('horoscopesInit: no horoscope type provided')
        }

        if (identifier === 'block--horoscope-carousel') {
          let resultTemplate = ''
          const leadsToChildPage = article.getAttribute('data-mp-leads-to-child-page')

          const keyList = Object.keys(fetchedHoroscopes[horoscopeType])
          if (keyList.length) {
            keyList.sort()
              .forEach(sign => {
                const { title, content, dates } = fetchedHoroscopes[horoscopeType][sign]

                console.log('contentContainer: content: ', content)

                if (content) {
                  resultTemplate += carouselItemTemplate(
                    sign,
                    horoscopeType,
                    content,
                    leadsToChildPage,
                    title,
                    dates
                  )
                }
              })
          } else {
            block.remove();
          }

          let contentContainer = blockArticles[0]
          if (app_theme === 'moment') {
            contentContainer = blockArticles[0].querySelector('.swiper-wrapper')
          }
          contentContainer.innerHTML = resultTemplate;
          const skeletonEl = block.querySelector('.skeleton-wrapper');

          if(skeletonEl) {
            removeSkeleton(skeletonEl);
          }

          const itemList = block.querySelectorAll('.block-item__body')
          itemList.forEach(item => {
            setBlockAnimation(item)
          })
        } else {
          const horoscope = fetchedHoroscopes[horoscopeType][horoscopeSign]

          if (horoscope?.content) {
            // NOTE: horoscope_top_block uses handlebars template.
            const contentContainer = article.querySelector('.block-item__body') as HTMLElement
            if(article.querySelector('.block-item__inner')?.hasAttribute('href')) {
              setBlockAnimation(article)
            }

            if (contentContainer) {
              NeptuneAttribute.set(
                article,
                NeptuneAttributeKey.shareHoroscopeText,
                horoscope.content
              );
              contentContainer.innerHTML = horoscope.content;

              const skeletonEl = article.querySelector('.skeleton-wrapper');
              if(skeletonEl) {
                removeSkeleton(skeletonEl);
              }
            }
          } else {
            block.remove()
          }
        }
      }
    }
  }

  // Calling this multiple times causes pagination bullets to not work if multiple carousels are on a page.
  initHoroscopeCarousel()
  initHoroscopeGridAnimation()
}
export default horoscopesInit;
