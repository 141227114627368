import i18n from '../../../../utilities/translation'
const language = document.documentElement.lang

const shuffle = array => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }

  return array
}

const feedbackOptions = [
  "The content isn't interesting to me",
  'I already get this content somewhere else',
  'Too many pop-ups and/or notifications',
  "It interrupts me when I'm trying to do something else",
  'The content is too repetitive'
]

const buttons = shuffle(feedbackOptions)

const formScreen = ({
  text,
  button_one_text,
  button_two_text,
  button_one_action,
  button_two_action,
  feedback_title,
  show_close,
  screen
}: any) => `
  <div class="feedback-form__body">
    ${
      text
        ? `<div class="feedback-form__header" role="dialog" aria-modal="true" aria-labelledby="prompt-title"> 
             <div class="feedback-form__icon">
               <img src=${(window as any).mp_globals.env.app_icon} alt="app icon">
             </div>
           <div tabindex="0" class="feedback-form__title ${language}">
           ${text}
           </div>
           <div class="feedback-form__close">
             <button data-prompt-close data-gtm-event-type="click-element" data-gtm-event-category="app rating" data-gtm-event-action="close" data-gtm-event-label="close" data-gtm-element-action="close" data-gtm-element-detail="close" aria-label="Close prompt">
               ✕
             </button>
           </div>
         </div>`
        : ''
    }
    ${
      feedback_title
        ? `
        <form id="feedback-form__radio-buttons" class="feedback-form__radio-buttons">
          <fieldset>
            <div class="feedback-form__radio-button-flex-container">
              <div class="feedback-form__radio-button-container ${language}">
                <label for="feedbackChoice1">${i18n(buttons[0], language)}</label>
                <input type="radio" id="feedbackChoice1" name="user_selection" value="${i18n(
                  buttons[0],
                  language
                )}">
              </div>
              <div class="feedback-form__radio-button-container ${language}">
                <label for="feedbackChoice2">${i18n(buttons[1], language)}</label>
                <input type="radio" id="feedbackChoice2" name="user_selection" value="${i18n(
                  buttons[1],
                  language
                )}">
              </div>
              <div class="feedback-form__radio-button-container ${language}">
                <label for="feedbackChoice3">${i18n(buttons[2], language)}</label>
                <input type="radio" id="feedbackChoice3" name="user_selection" value="${i18n(
                  buttons[2],
                  language
                )}">
              </div>
              <div class="feedback-form__radio-button-container ${language}">
                <label for="feedbackChoice4">${i18n(buttons[3], language)}</label>
                <input type="radio" id="feedbackChoice4" name="user_selection" value="${i18n(
                  buttons[3],
                  language
                )}">
              </div>
              <div class="feedback-form__radio-button-container ${language}">
                <label for="feedbackChoice5">${i18n(buttons[4], language)}</label>
                <input type="radio" id="feedbackChoice5" name="user_selection" value="${i18n(
                  buttons[4],
                  language
                )}">
              </div>
            </div>
          </fieldset>
        </form>
      `
        : ''
    }
  </div>
  ${
    button_one_text || button_two_text
      ? `
        <div class="feedback-form__actions ${screen}">
          ${
            button_one_text
              ? `<button
                  data-gtm-event-type="click-element"
                   data-gtm-event-category="App Rating" 
                   data-gtm-event-action="${button_one_action}"
                   data-gtm-event-label="${button_one_text}"  
                   data-gtm-element-action="${button_one_action}" 
                   data-gtm-element-detail="${button_one_text}"
                   id="mp_feedback_form__button_1" 
                   class="button button-secondary--big ${language}">
                  
                   ${button_one_text}
                </button>`
              : ''
          }
          ${
            button_two_text
              ? `<button
                   data-gtm-event-type="click-element"
                   data-gtm-event-category="App Rating" 
                   data-gtm-event-action="${button_two_action}" 
                   data-gtm-event-label="${button_two_text}"  
                   data-gtm-element-action="${button_two_action}" 
                   data-gtm-element-detail="${button_two_text}"
                   id="mp_feedback_form__button_2" 
                   class="button button-primary--small ${language}">
                   
                   ${button_two_text}
                </button>`
              : ''
          }        
        </div>
        `
      : ''
  }
  ${
    show_close
      ? `<button id="mp_feedback_form__button_close" class="feedback-form__close ${language}" aria-label="${i18n(
          'close',
          language
        )}">x</button>`
      : ''
  }
`

export default formScreen
