import { neptuneDebug } from 'content/src/assets/utilities'

const blockTypesToSetUp = ['.block--large-photocard']

let blocksLeftOnPage

const initBlockType = (id, eventName = '') => {
  try {
    const currentTypeBlocks = []

    blocksLeftOnPage = blocksLeftOnPage.filter(block => {
      if (block?.id) {
        if (!block.id.includes(id)) return true
      } else {
        if (!block.classList.value.includes(id)) return true
      }
      currentTypeBlocks.push(block)
      return false
    })

    if (currentTypeBlocks.length) {
      if (eventName === 'msNewsLoad' || eventName === 'fotoscapesLoad') {
        currentTypeBlocks.forEach(block => {
          block.addEventListener(eventName, () => {
            const ctaBlock = block.querySelector('.block__main')
            const ctaFlag = ctaBlock.getAttribute('data-mp-cta')
            if (ctaFlag === 'false' || !!ctaFlag === false) {
              const ctaButton = ctaBlock.querySelector('.cr-button')
              ctaButton?.classList.add('remove-cta')
            }
          })
        })

        return
      }
    }

    if (!blocksLeftOnPage.length) return
  } catch (e) {
    console.error(`Error removing CTA button from ${id}`, e)
  }
}

const initCallToAction = () => {
  const app_theme = document.documentElement.getAttribute('data-mp-app-theme')

  if (!(app_theme === 'moment')) return

  try {
    blockTypesToSetUp.forEach(blockType => {
      const blocksOnPage: HTMLElement[] = Array.from(document.querySelectorAll(blockType))
      if (!blocksOnPage.length) return

      blocksLeftOnPage = blocksOnPage

      initBlockType('ms_news', 'msNewsLoad')
      initBlockType('fotoscape_block', 'fotoscapesLoad')
    })
  } catch (e) {
    neptuneDebug('error in initCallToAction: ', e)
  }
}

export default initCallToAction
