import { getAssetDomainByMPGlobals } from 'lib/deployment'
import feedItemTemplate from '../shared/templates/feed_item'
import initActionButtons from '../action_buttons/action_buttons'
import trendingCarouselWrapper from '../shared/templates/layouts/trending_carousel/trending_carousel_wrapper'
import slideshowWrapper from '../shared/templates/layouts/slideshow_template'
import * as modal from '../utilities/modal/modal'
import { cropImage } from '../utilities/imageManipulation'
import * as device from '../device'
import axios from 'axios'
import { setTimeDifference } from '../timestamp/utils'
import GA4Handler from '../ga4_support/GA4Handler'

const API_KEY = 'TbxpMGkkkzC6JNNDFD96Qr3c7qMs965Xe79oGROO16'
const LOCALES = {
  en: 'en-us',
  es: 'es-us',
  pt: 'pt-br',
  hi: 'hi-in',
  it: 'it',
  'en-in': 'en-in',
  'en-it': 'en-it',
  'en-tn': 'en-tn',
  'en-at': 'en-at',
  'en-ae': 'en-ae',
  'de-at': 'de-at',
  'es-mx': 'es-mx',
  'fr-ca': 'fr-ca',
  'en-ph': 'en-ph'
}

const language = document.documentElement.lang
const locale = LOCALES[language] ?? 'en-us'

const setContainerInitialHTML = (container, layout, itemsNumber) => {
  if (layout.includes('slideshow')) {
    container.innerHTML = slideshowWrapper(itemsNumber)
  } else if (layout.includes('trending-carousel')) {
    container.innerHTML = trendingCarouselWrapper()
  } else {
    container.innerHTML = ''
  }
}

const insertItem = (container, itemToInsert, layout) => {
  const selectorMap = {
    slideshow: '.slides',
    'trending-carousel': '.swiper-wrapper'
  }

  const layoutSelectorMatch = () => {
    for (const key in selectorMap) {
      if (layout.includes(key)) return key
    }
    return false
  }

  const matchFound = layoutSelectorMatch()
  if (matchFound) {
    if (matchFound.includes('slideshow')) {
      itemToInsert = `<div class="slider-slide">${itemToInsert}</div>`
    }

    const wrapper = container.querySelector(selectorMap[matchFound]) as HTMLElement
    wrapper.insertAdjacentHTML('beforeend', itemToInsert)
  } else {
    container.insertAdjacentHTML('beforeend', itemToInsert)
  }
}

const renderItem = (rawItem, appTheme) => {
  const { title, provider, url: link, images, index, layout, abstract, publishedDateTime } = rawItem
  const { logoUrl: publisherLogo, name: publisherName } = provider
  const image = images?.[0].url
  if (!image) {
    console.debug('Missing required image for feed item: ' + rawItem?.id)
    console.debug(rawItem)
    return ''
  }
  const thumbnailUrl = cropImage(image, getAssetDomainByMPGlobals(), {
    size: appTheme === 'moment' ? 'photocard' : 'large'
  })
  const context: any = {
    thumbnailUrl,
    publisherName,
    publisherLogo,
    title,
    link,
    contentType: 'text',
    contentTypeText: 'read',
    index,
    layout,
    ms_news: true,
    description: abstract,
    pub_time: setTimeDifference(publishedDateTime, language)
  }

  return feedItemTemplate(context)
}

const renderItems = (items, blockContainer, blockId, personalize, activityId) => {
  const innerBlockContainer = blockContainer.querySelector('.block__main')
  const layout = innerBlockContainer.parentElement.getAttribute('data-mp-block-layout')
  const appTheme = document.documentElement.getAttribute('data-mp-app-theme')
  const itemsNumber = items.length

  setContainerInitialHTML(innerBlockContainer, layout, itemsNumber)

  for (const [index, rawItem] of items.entries()) {
    rawItem.index = index + 1
    rawItem.layout = layout

    const renderedItemString = renderItem(rawItem, appTheme)
    insertItem(innerBlockContainer, renderedItemString, layout)

    const article = Array.from(innerBlockContainer.children).pop() as HTMLElement

    // GA4 click handling.
    const link = article?.querySelector('a')
    if (link) {
      link.addEventListener('click', e => {
        GA4Handler.clickHandleEvent(e)
      })
      GA4Handler.impressionHandleEvent(link as HTMLElement)
    }

    if (personalize) addPersonalizationHandlers(article, rawItem, activityId)
  }

  modal.init({ containerSelector: `#${blockId}` })
  initActionButtons(`#${blockId}`)
}

const removeAllMsNewsBlocks = () => {
  Array.from(document.querySelectorAll('[data-mp-ms-news-block]')).forEach(element =>
    element.remove()
  )
  const errorMsg = 'Missing necessary ocid for MS News partner attribution.'
  console.error(errorMsg)

  if ((window as any).Sentry) {
    ;(window as any).Sentry.captureMessage(errorMsg)
  }
  return
}

const getBlockAttributes = blockContainer => {
  const unencodedCategoryStr = blockContainer.getAttribute('data-mp-ms-news-block-category')
  return {
    unencodedCategory: unencodedCategoryStr,
    category: encodeURIComponent(unencodedCategoryStr),
    count: blockContainer.getAttribute('data-mp-ms-news-block-count'),
    blockId: blockContainer.getAttribute('id'),
    personalize: blockContainer.getAttribute('data-mp-ms-news-block-personalize')
  }
}

const setRequestUrl = (tracking_ocid, count, category, offset, personalize) => {
  // NOTE: Portals don't have mpids and alternatives for user persistence should be considered.
  const API_ENDPOINT_ROOT = `https://api.msn.com/news/feed?apikey=${API_KEY}&ocid=${tracking_ocid}&market=${locale}&$top=${count}`
  let url = `${API_ENDPOINT_ROOT}`

  if (category) {
    url = `${url}&query=${category}`
  }

  if (device.mpid() && personalize) {
    url = `${url}&user=${device.mpid()}`
  }

  // If this is not the first request for the category, we need to skip previously requested records
  if (offset) {
    url = `${url}&$skip=${offset}`
  }
  return url
}

const recordClick = async (id, activityId) => {
  const tracking_ocid = (window as any).mp_globals?.ms_news_config?.tracking_ocid
  const endpoint = ` https://api.msn.com/feedback/api/EventsProxy`

  try {
    await axios.post(endpoint, {
      ClientId: tracking_ocid,
      Market: locale,
      Event: 'Click',
      Product: 'browser',
      Events: [
        {
          EventType: 'Click',
          UserId: device.mpid(),
          ActivityId: activityId,
          TargetId: id,
          TargetType: 'CmsArticle',
          EventTime: new Date().toISOString()
        }
      ]
    })
  } catch (e) {
    console.debug('ms news personalization error', e)
  }
}

const addPersonalizationHandlers = (article, rawItem, activityId) => {
  const linkElement = article.querySelector('a')
  const { id } = rawItem

  linkElement.dataset.href = linkElement.href

  let isClicked = false
  linkElement.addEventListener('click', async e => {
    if (!isClicked) {
      isClicked = true
      e.preventDefault()
      e.stopPropagation()
      e.stopImmediatePropagation()

      await recordClick(id, activityId)

      linkElement.href = linkElement.dataset.href
      linkElement.click()
    }
  })
}

export { removeAllMsNewsBlocks, getBlockAttributes, setRequestUrl, renderItems }
