import i18n from 'content/src/assets/utilities/translation'
import { FeedItemContext } from '../../../../../../lib/feed/interfaces'
import { appendInternalTrackingSearchParams } from '../../../utilities/analytics'
import { header, footer, language, media, actions } from '../feed_item'
import { createAttributes, removeSpecialCharacters } from '../utils'

export const app_theme = document.documentElement.getAttribute('data-mp-app-theme')

const edgeDescriptionTemplate = (feedItem: FeedItemContext) => {
  const {
    link,
    title,
    publisherLogo,
    publisherDarkLogo,
    publisherName,
    isVideoLink,
    thumbnailUrl,
    isSponsored,
    contentType,
    contentTypeText,
    description,
    ms_news,
    contentApiId,
    pub_time,
    layout,
    duration
  } = feedItem

  return `
  <article
  ${createAttributes(feedItem)}>
  <a class="block-item__body"
     href="${appendInternalTrackingSearchParams(link)}"
     data-mp-cct>
    ${media(thumbnailUrl, isVideoLink, duration, layout)}

    ${
      app_theme === 'moment'
        ? ''
        : header(
            isSponsored,
            publisherDarkLogo,
            publisherLogo,
            publisherName,
            isVideoLink,
            ms_news,
            layout
          )
    }

       
    ${
      app_theme === 'moment'
        ? ` 
          <div class="block-item__publisher">
            <div class="block-item__body-logo ${language}">
              <div class="block-item__body-logo-light">
              ${
                ms_news && !isSponsored && publisherLogo && publisherName
                  ? `<img loading="lazy" class="block-item__publisher-logo" src="${publisherLogo}" alt="${publisherName} logo" />
                  <small class="block-item__publisher-name block-item__publisher-name-ms-news ${language} ">${publisherName}</small>`
                  : `${
                      !isSponsored && publisherLogo && publisherName
                        ? `<img loading="lazy" class="block-item__publisher-logo" src="${publisherLogo}" alt="${publisherName} logo" />`
                        : `
                      <small class="block-item__publisher-name ${language}">
                        ${publisherName}
                      </small>`
                    }`
              }
                ${isVideoLink ? `<small>${i18n('Go to Video', language)}</small>` : ''}
              </div>
              <div class="block-item__body-logo-dark ${language}">
              ${
                ms_news && !isSponsored && publisherLogo && publisherName
                  ? `<img loading="lazy" class="block-item__publisher-logo" src="${publisherLogo}" alt="${publisherName} logo" />
                  <small class="block-item__publisher-name block-item__publisher-name-ms-news ${language} ">${publisherName}</small>`
                  : `${
                      !isSponsored && publisherDarkLogo && publisherName
                        ? `<img loading="lazy" class="block-item__publisher-logo" src="${publisherDarkLogo}" alt="${publisherName} logo" />`
                        : `
                      <small class="block-item__publisher-name ${language}">
                        ${publisherName}
                      </small>
                      `
                    }`
              }
                ${
                  isVideoLink
                    ? `<small class="${language}">${i18n('Go to Video', language)}</small>`
                    : ''
                }
              </div>
            </div>
          
          ${actions(layout, title, link, contentApiId)}
        </div>`
        : ''
    }

    <h1 class="block-item__title ${language}">
        <span class="block-item__headline">${title}</span>
        <span class="block-item__subtitle ${language}"></span>
    </h1>

    ${
      description
        ? `<p class="block-item__description ${language}">${removeSpecialCharacters(
            description
          )}</p>`
        : ''
    }

   </a>
   
    ${
      app_theme === 'moment'
        ? ''
        : footer(
            title,
            link,
            isSponsored,
            contentTypeText,
            contentType,
            language,
            layout,
            publisherDarkLogo,
            publisherLogo,
            publisherName,
            isVideoLink,
            ms_news,
            contentApiId,
            pub_time
          )
    }
    </article>
  `
}

export default edgeDescriptionTemplate
