import { neptuneWarn } from '../../../../utilities'
import { DataLayerInteractionType } from '../interfaces'

export class GA4Link {
  url: {
    pathname: string
    host: string
    search: string
    hash: string
  }
  interactionType: DataLayerInteractionType
}

/**
 * Creates a URL object for a link.  Constructs an absolute URL when the link is relative.
 *
 * Note: URL.canParse does not work in Jest.
 */
const getLinkUrl = (target: HTMLElement): URL => {
  const href = target.getAttribute('href')

  try {
    return new URL(href)
  } catch (e) {
    return new URL(href, window.location.origin)
  }
}

const getInteractionType = (windowUrl: URL, linkUrl: URL): DataLayerInteractionType => {
  if (windowUrl.hostname !== linkUrl.hostname) {
    return DataLayerInteractionType.external
  }

  return DataLayerInteractionType.internal
}

const ga4ParseUrl = (target: HTMLElement): GA4Link => {
  const link = new GA4Link()

  if (target.tagName !== 'A') {
    neptuneWarn('ga4ParseUrl: target is not a link: ', target)
    return link
  }

  const href = target.getAttribute('href')

  if (!href) {
    neptuneWarn('ga4ParseUrl: target.href is empty: ', target)
    return link
  }

  const windowUrl = new URL(window.location.href)
  const linkUrl = getLinkUrl(target)

  link.url = {
    pathname: linkUrl.pathname,
    host: linkUrl.host,
    search: linkUrl.search,
    hash: linkUrl.hash
  }
  link.interactionType = getInteractionType(windowUrl, linkUrl)

  return link
}

export default ga4ParseUrl
