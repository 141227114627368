/**
 * Used for switching config / logic against browser userAgent.
 */
const userAgentMatch = (userAgent: string, matchList: string[]): boolean => {
  for (const match of matchList) {
    console.log('MATCH: %o', match)
    if (userAgent.toLowerCase().includes(match.toLowerCase())) {
      return true
    }
  }

  return false
}

export default userAgentMatch
