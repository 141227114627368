import { getAssetDomainByMPGlobals } from '../../../../lib/deployment'

export default [
  {
    date: '2022',
    title: '30 for 30',
    subtitle: 'A Streetball Mixedtape',
    image: `https://${getAssetDomainByMPGlobals()}/image/upload/v1661204129/app/Podcast/30on30.webp`,
    play_link: `https://www.podtrac.com/pts/redirect.mp3/traffic.megaphone.fm/ESP9355474649.mp3?updated=1653596078`,
    outbound_link: `https://dcs.megaphone.fm/ESP9355474649.mp3?key=3c3112f7e5227c8e64e094ab00b24f66`
  },
  {
    date: '2022',
    title: '30 for 30',
    subtitle: 'Cursed and Blessed',
    image: `https://${getAssetDomainByMPGlobals()}/image/upload/v1661204129/app/Podcast/30on30.webp`,
    play_link: `https://dcs.megaphone.fm/ESP7416473752.mp3?key=e004f69b1caba98dc7166ffbaa7f3774`,
    outbound_link: `https://dcs.megaphone.fm/ESP7416473752.mp3?key=e004f69b1caba98dc7166ffbaa7f3774`
  },
  {
    date: '2022',
    title: '30 for 30',
    subtitle: 'The Loophole',
    image: `https://${getAssetDomainByMPGlobals()}/image/upload/v1661204129/app/Podcast/30on30.webp`,
    play_link: `https://dcs.megaphone.fm/ESP7497799485.mp3?key=3af84c1dd299216c0edd6c4572eb286d`,
    outbound_link: `https://dcs.megaphone.fm/ESP7497799485.mp3?key=3af84c1dd299216c0edd6c4572eb286d`
  },
  {
    date: '2022',
    title: '30 for 30',
    subtitle: 'Back Pass',
    image: `https://${getAssetDomainByMPGlobals()}/image/upload/v1661204129/app/Podcast/30on30.webp`,
    play_link: `https://dcs.megaphone.fm/ESP9788744626.mp3?key=ba110a0a15a3de4e6576ba98e5c1b51d`,
    outbound_link: `https://dcs.megaphone.fm/ESP9788744626.mp3?key=ba110a0a15a3de4e6576ba98e5c1b51d`
  },
  {
    date: '2022',
    title: '30 for 30',
    subtitle: 'The Fall of Condor',
    image: `https://${getAssetDomainByMPGlobals()}/image/upload/v1661204129/app/Podcast/30on30.webp`,
    play_link: `https://dcs.megaphone.fm/ESP3338827508.mp3?key=ae229c153f039c78868dc8b763d4295d`,
    outbound_link: `https://dcs.megaphone.fm/ESP3338827508.mp3?key=ae229c153f039c78868dc8b763d4295d`
  },
  {
    date: '2022',
    title: '30 for 30',
    subtitle: 'The Fall of Condor',
    image: `https://${getAssetDomainByMPGlobals()}/image/upload/v1661204129/app/Podcast/30on30.webp`,
    play_link: `https://dcs.megaphone.fm/ESP3338827508.mp3?key=5cda5e636c56b7f336347a03716d095e`,
    outbound_link: `https://dcs.megaphone.fm/ESP3338827508.mp3?key=5cda5e636c56b7f336347a03716d095e`
  },

  {
    date: '2022',
    title: '30 for 30',
    subtitle: 'The Figher Inside',
    image: `https://${getAssetDomainByMPGlobals()}/image/upload/v1661204129/app/Podcast/30on30.webp`,
    play_link: `https://dcs.megaphone.fm/ESP5700133549.mp3?key=091ef8fed803556456ea558f991b6660`,
    outbound_link: `https://dcs.megaphone.fm/ESP5700133549.mp3?key=091ef8fed803556456ea558f991b6660`
  },
  {
    date: '2022',
    title: '30 for 30',
    subtitle: 'On the Ice',
    image: `https://${getAssetDomainByMPGlobals()}/image/upload/v1661204129/app/Podcast/30on30.webp`,
    play_link: `https://dcs.megaphone.fm/ESP6270143135.mp3?key=85ff014781a4cfa6ab9556a3afc7b8ef`,
    outbound_link: `https://dcs.megaphone.fm/ESP6270143135.mp3?key=85ff014781a4cfa6ab9556a3afc7b8ef`
  },
  {
    date: '2022',
    title: '30 for 30',
    subtitle: 'The Longest Game',
    image: `https://${getAssetDomainByMPGlobals()}/image/upload/v1661204129/app/Podcast/30on30.webp`,
    play_link: `https://dcs.megaphone.fm/ESP6270143135.mp3?key=85ff014781a4cfa6ab9556a3afc7b8ef`,
    outbound_link: `https://dcs.megaphone.fm/ESP6270143135.mp3?key=85ff014781a4cfa6ab9556a3afc7b8ef`
  },
  {
    date: '2022',
    title: '30 for 30',
    subtitle: 'On the Ice',
    image: `https://${getAssetDomainByMPGlobals()}/image/upload/v1661204129/app/Podcast/30on30.webp`,
    play_link: `https://www.podtrac.com/pts/redirect.mp3/traffic.megaphone.fm/ESP6270143135.mp3?updated=1592408696`,
    outbound_link: `https://www.espn.com/radio/play/_/id/19959694`
  },
  {
    date: '2022',
    title: '30 for 30',
    subtitle: 'Juiced',
    image: `https://${getAssetDomainByMPGlobals()}/image/upload/v1661204129/app/Podcast/30on30.webp`,
    play_link: `https://dcs.megaphone.fm/ESP9259007990.mp3?key=82ea763d016e26e30b6cfee25761b3eb`,
    outbound_link: `https://dcs.megaphone.fm/ESP9259007990.mp3?key=82ea763d016e26e30b6cfee25761b3eb`
  },
  {
    date: '2022',
    title: '30 for 30',
    subtitle: 'Madden Game',
    image: `https://${getAssetDomainByMPGlobals()}/image/upload/v1661204129/app/Podcast/30on30.webp`,
    play_link: `https://dcs.megaphone.fm/ESP1174717865.mp3?key=c2dc145abfb37ea17ce9a0c567237c90`,
    outbound_link: `https://dcs.megaphone.fm/ESP1174717865.mp3?key=c2dc145abfb37ea17ce9a0c567237c90`
  }
]
