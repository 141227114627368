/**
 * @file
 *
 * Personalized Fotoscapes.
 *
 * This library supports the personlization class and logic that exists in Cashew currently.
 *
 * Effectively, an initial call is made to softbox's api based on the current date and some 50 results
 * are returned. the personlize class instance does it magic and returns a sorted list tailored to the
 * individual user based on whatever heuristics have been created (I haven't dived in yet BB 10/2019).
 *
 * The templating for the feed items differ enough that DCM treated them with separate components. Future
 * stories might make it possible to merge this into our standard feed item.
 *
 * Note: In Cashew, the API call is made client-side (and for each block, so as many as 4 times a page).
 * Since the call is user agnostic and only conditional on the date, we moved this call to the
 * context adapter for fotoscapes, leaving this library only responsible for communicating with local
 * storage, sorting the values already on the globals object, and templating the results.
 */
import axios from 'axios'
import captureException from '../../../sentry/captureException'
import captureMessage from '../../../sentry/captureMessage'
import { NeptuneAttribute, NeptuneAttributeKey } from '../../lib/NeptuneAttribute'
import GA4Handler from '../ga4_support/GA4Handler'
import FotoscapesURL from './FotoscapesURL'
import { NewPersonalize } from './helpers/personalize'
import initActionButtons from '../action_buttons/action_buttons'
import * as modal from '../utilities/modal/modal'
import trendingCarouselWrapper from '../shared/templates/layouts/trending_carousel/trending_carousel_wrapper'
import instantPlayGamesWrapper from '../shared/templates/layouts/instant_play_games/instant_play_games_wrapper'
import openFullPageArticle from '../full_page_article'
import { BlockType } from '../ga4_support/interfaces'
import { getAspectRatioData } from './helpers/aspect_ratio_map'
import {
  checkLinksValidity,
  formatDestination,
  getArticlElement,
  hasSectionWrapper,
  isInstantPlayGames,
  isTrendingCarousel,
  renderPost
} from './helpers/utils'
import {
  FotoscapeDailyResponseArticle,
  FotoscapeDailyFormattedArticle,
  FotoscapeDailyResponse,
  FotoscapeImageAspectRatio
} from './interfaces/FotoscapeDailyArticle'
import slideshowWrapper from '../shared/templates/layouts/slideshow_template'
import { setBlockAnimation } from '../../../../assets/utilities/animation'

const md5 = require('md5')
const language = document.documentElement.lang
const w = window as any

export const fetchFotoscapesDaily = async (category, previewAspect = '1:1', language = '') => {
  if (!language) {
    language = document.documentElement.lang.split('-')[0]
  }

  let url = `${FotoscapesURL.getBaseURL()}/wp/v1/daily?ckey=${FotoscapesURL.getKey()}&mp_lang=${language}`

  if (category !== 'default') {
    url += `&sched=${category}`
  }

  if (previewAspect) {
    url += `&previewAspect=${previewAspect}`
  }

  const response: any = await axios.get(url)
  const { data } = response
  return data
}

const setBlockStyles = (blockContainerElement: Element) => {
  const itemList = blockContainerElement.querySelectorAll('.block-item__body');

  itemList?.forEach(item => {
    const { bottom } = item.getBoundingClientRect();
    const descriptionEl = item.querySelector('.block-item__description');

    if(descriptionEl) {
      const { bottom: descriptionBottom } = descriptionEl.getBoundingClientRect();

      if((bottom - descriptionBottom) > 16) {
        (descriptionEl as HTMLElement).style.webkitLineClamp = '4';
      }

      if((bottom - descriptionBottom) > 40) {
        (descriptionEl as HTMLElement).style.webkitLineClamp = '5';
      }
    }
  });
}

const fotoscapesInit = async () => {
  // If a config doesn't exist, we can assume there are no fotoscape elements on a page.

  const config = w.mp_globals.fotoscape_config
  if (config == undefined) {
    return
  }

  let { blocks } = config
  const { platform_link_destination } = config
  blocks = blocks.filter(block => !block.block_id.includes('fotoscape_gallery'))

  // We want to subdivide the personalized blocks into category lists.
  // Each category group will make an API call (in the future we hope softbox will support a single call
  // with multiple categories).
  // Each category will attend to its own coordinating logic.
  // NOTE: Adding a default or uncategorized category type could also be done ahead of time in the Data Processor.
  const categorizedBlocks = {
    default: {}
  }
  for (const block of blocks) {
    const { layout, category } = block

    if (category) {
      if (!categorizedBlocks[category]) {
        categorizedBlocks[category] = {}
      }

      if (!categorizedBlocks[category]?.[layout]) {
        categorizedBlocks[category][layout] = []
      }
      categorizedBlocks[category][layout].push(block)
    } else {
      if (!categorizedBlocks.default[layout]) {
        categorizedBlocks.default[layout] = []
      }
      categorizedBlocks.default[layout].push(block)
    }
  }

  for (const category of Object.keys(categorizedBlocks)) {
    // TODO: Ask Rod about getting rid of the hack Steve talks about below.
    // Note: The following is lifted from mycontent.js in DCM (like many formatting
    // functions here). It has been done client-side, but because the request is user
    // agnostic, as long as this context adapter runs close to pageview time, we can save ourselves
    // the trip client-side.
    // ---- STEVE GEYER'S NOTES: -----
    // Parameterize the URL by expanding the URI
    // template {date} to the appropriate value.
    //
    // HACK ALERT: This subroutine has two barely justifiable hacks in
    // it. This first is our acquiring the date by getting the ISO8601
    // string and then slice off the date from the front of this
    // string. The second hack is to use string replace() to expand the
    // {date} string with the actual date.  Both of these hacks can be
    // fixed with external libraries, but for now I decided that fewer
    // dependencies was a better tradeoff. The ISO8601 hack is sad and is
    // necessary because Javascript has a weak Date library. It should
    // have a standard and parameterizable date formatter like other
    // modern languages. The URI template hack only makes sense because we
    // have one simple and consistent parameter to expand. If we start
    // using more URI template expansions we should replace this with the
    // uri-template library.
    const blocksPerLayout = categorizedBlocks[category]

    for (const blockPerLayout of Object.entries(blocksPerLayout)) {
      const [layout, blocks] = blockPerLayout as any

      const imageAspectRatioData: FotoscapeImageAspectRatio = getAspectRatioData(layout)
      const data = await fetchFotoscapesDaily(category, imageAspectRatioData.ratio)
      const { items: rawPosts, interests: defaultInterests }: FotoscapeDailyResponse = data
      // It breaks my heart a little that they called the class instance instance.
      const instance = NewPersonalize()

      const platform = document.documentElement.getAttribute('data-mp-platform');
      const theme = document.documentElement.getAttribute('data-mp-app-theme');

      const posts: FotoscapeDailyFormattedArticle[] = rawPosts
        .map((post: FotoscapeDailyResponseArticle) => {
          const {
            // This code is just realiasing everything.
            brandLogoDark: logo,
            brandLogo: darkLogo,
            link: url,
            owner: publisher,
            numImages: num_images,
            // After an issue in prod with this value not being set correctly, we're going to put in a safeguard on
            // our side as well to ensure it ends up as an empty array at least.
            interests: rawInterests,
            // The ken burns effect data would seem to be useless for our purposes.
            // kb,
            // This is a bit wacky. The real images key is used in the lookbk I guess, so what we call
            // images here is really preview images. (I'm trying to port as is for now, so maintaining the approach
            // as much as I can).
            previews: images,
            sourceLink,
            summary,
            title,
            uid,
            publishOn,
            // TODO: Does this value exist anymore? I don't see it on the return object
            promote,
            boost,
            lbtype
          } = post

          if (!url && !sourceLink) {
            captureMessage(`Fotoscapes ${uid} Post Is Invalid: No Links`)
            return null
          }

          // This is a port and accords to the edge/large image size.
          const PREVIEW_MIN_WIDTH =
            imageAspectRatioData.width || (platform === 'moment' ? 370 : 450)
          const PREVIEW_MIN_HEIGHT =
            imageAspectRatioData.height || (platform === 'moment' ? 390 : 200)
          const image = instance.findImage(images, PREVIEW_MIN_WIDTH, PREVIEW_MIN_HEIGHT)

          const interests = rawInterests ?? []
          const info = defaultInterests[interests[0]] || { name: { en: '' } }
          const interest = info.name.en

          const formattedPost: FotoscapeDailyFormattedArticle = {
            source: 'Lookbk',
            media_type: 'gallery',
            title: instance.chooseText(title, language),
            summary: instance.chooseText(summary, language),
            image: image.link,
            interests,
            interest,
            url,
            sourceLink,
            uid,
            publisher,
            promote,
            boost,
            logo,
            darkLogo,
            num_images,
            instance,
            category,
            pub_time: publishOn,
            lbtype
          }

          return formattedPost
        })
        .filter(post => post)

      const settings = {
        count: rawPosts.length
      }

      // Take the 50 raw posts and 'personalize' their ordering.
      const orderedPosts = instance.choose(posts, settings, defaultInterests)

      // For each block container on the page of the given category.
      // (DCM handled all blocks independently and blind, leading to duplicate content on a page)
      // We now have a list that's been obstensibly sorted from best to last. And we can just
      // work through it as we go down the page filling in blocks based on their count.
      for (const index in blocks as any) {
        const block = blocks[index]
        const { block_id } = block
        const blockContainerElement = document.querySelector(
          `#${block_id} .block__main`
        ) as HTMLElement

        if (!blockContainerElement) continue
        if (!(blockContainerElement instanceof HTMLElement)) continue

        const blockLayout = blockContainerElement.parentElement.getAttribute('data-mp-layout')
        const blockIndex = blockContainerElement.parentElement.getAttribute('data-mp-block-index')

        const rowIndex = Array.from(
          blockContainerElement.parentElement.parentElement.children
        ).indexOf(blockContainerElement.parentElement)

        // Clear out the loading skeletons
        blockContainerElement.innerHTML = ''

        const layout = blockContainerElement.parentElement.getAttribute('data-mp-block-layout')
        const loadFullArticle = blockContainerElement.parentElement.getAttribute(
          'data-mp-load-full-article'
        )

        // Leaving it as is for now, don't want to make things too complicated
        // I believe the whole file should be refactored, so let's do it as a next step
        if (isTrendingCarousel(layout)) {
          blockContainerElement.innerHTML = trendingCarouselWrapper()
        } else if (layout.includes('slideshow')) {
          blockContainerElement.innerHTML = slideshowWrapper(block.count)
        } else if (isInstantPlayGames(layout)) {
          blockContainerElement.innerHTML = instantPlayGamesWrapper()
        }

        const { count: blockCount, link_destination: block_link_destination } = block

        let currentPostCount = 0

        // The category engage is only returning three posts at the moment.
        for (const currentPost of orderedPosts) {
          // All the fotoscape blocks are initialized at the same time asynchronously, which I
          // believe cases a race condition when using an array of post IDs to track what's already been used.
          if (document.querySelector(`[data-mp-uid="${currentPost.uid}"]`)) {
            continue
          }

          // Block count limit reached.
          if (currentPostCount >= blockCount) {
            break
          }

          currentPost.layout = layout
          currentPost.index = currentPostCount + 1

          checkLinksValidity(currentPost)
          formatDestination(currentPost, block_link_destination, platform_link_destination)

          const currentPostElementString = renderPost(currentPost)

          // A dummy element allows us to add event listeners to the DOM one post at a time.
          const dummyElement = document.createElement('div')
          dummyElement.innerHTML = currentPostElementString

          const articleElement = hasSectionWrapper(layout)
            ? dummyElement.querySelector('section')
            : dummyElement.querySelector('article')

          // In order to guarantee the execution of updates to personalization weights before navigating
          // away from the page while also not preventing other listeners from normal operations, we're
          // going to disable the link and add an event listener to reenable it after updating weights.
          const linkElement = hasSectionWrapper(layout)
            ? articleElement.querySelector('article').querySelector('a')
            : articleElement.querySelector('a')
          // While we could simply store this value in javascript, there may be some merit to keeping
          // it visible/accessible on the DOM.
          linkElement.dataset.href = linkElement.href
          // If we remove the link, screenreaders start reading the internal elements individually.
          // So, we disable through a flag long enough to update.
          let isClicked = false
          let isFullPageAttempted = false

          setBlockAnimation(articleElement)

          linkElement.addEventListener('click', async e => {
            if (
              !isClicked &&
              !isFullPageAttempted &&
              loadFullArticle === 'true' &&
              block_link_destination !== 'referral'
            ) {
              try {
                isFullPageAttempted = true

                await openFullPageArticle(
                  'fotoscape',
                  md5(linkElement.dataset.href),
                  linkElement.dataset.href
                )

                return
              } catch (e) {
                captureException(e, 'Could not load full page article')
              }
            }

            if (!isClicked) {
              isClicked = true
              e.preventDefault()
              e.stopPropagation()
              e.stopImmediatePropagation()
              // Updates weights in localStorage
              instance.click(currentPost.uid, currentPost.interests)
              linkElement.href = linkElement.dataset.href
              linkElement.click()
            }
          })

          if (isTrendingCarousel(layout) || isInstantPlayGames(layout)) {
            blockContainerElement.querySelector('.swiper-wrapper').appendChild(articleElement)
          } else if (layout === 'slideshow') {
            const slide = document.createElement('div')
            slide.classList.add('slider-slide')
            slide.appendChild(articleElement)
            blockContainerElement.querySelector('.slides').appendChild(slide)
          } else {
            blockContainerElement.appendChild(articleElement)
          }

          // GA4 setup.
          const link = getArticlElement(layout, articleElement).querySelector('a')
          const optional = blockLayout.includes('carousel')
            ? {
                carousel_count: blockCount,
                carousel_position: NeptuneAttribute.get(
                  articleElement,
                  NeptuneAttributeKey.gtmScrollCount
                )
              }
            : {}

          // Setup click handling.
          link.addEventListener('click', (browserEvent: Event) => {
            GA4Handler.clickHandleEvent(browserEvent, optional)
          })

          GA4Handler.impressionHandleEvent(articleElement, optional, 0.7, link)

          currentPostCount++
        }

        setTimeout(() => {
          if(layout === 'carousel' && theme === 'moment') {
            setBlockStyles(blockContainerElement);
          }
        })

        blockContainerElement.parentElement.dispatchEvent(new Event('fotoscapesLoad'))
        blockContainerElement.parentElement.classList.remove('skeleton')

        modal.init({ containerSelector: `#${block_id}` })
        initActionButtons(`#${block_id}`)
      }
    }
  }
}

export default fotoscapesInit
