import { isInViewport } from './DOM_events'

export const refreshVisibleAds = () => {
  ;(window as any).googletag = (window as any).googletag || {}
  ;(window as any).googletag.cmd = (window as any).googletag.cmd || []

  const gptadslots = (window as any).gptadslots
  if (!gptadslots) {
    console.debug('ADS: Cannot refresh ads, no valid gpt ad slots found.')
    return
  }

  const adSlotsMap = Object.entries(gptadslots)
  adSlotsMap.forEach(async ([elementId, adSlot]) => {
    // Find slot block element
    const adBlockContainer = document.querySelector(`#${elementId}`)
    const isVisible = await isInViewport(adBlockContainer)
    if (isVisible) {
      ;(window as any).googletag.cmd.push(function () {
        console.debug(`ADS: Refreshing ${elementId}`)
        ;(window as any).googletag.pubads().refresh([adSlot])
      })
    }
  })
}
