import { appendInternalTrackingSearchParams } from '../../../utilities/analytics'
import { FeedItemContext } from 'content/src/lib/feed/interfaces'
import { createAttributes } from '../utils'
import { header, footer, language, media } from '../feed_item'

const podcastSmallTemplate = (feedItem: FeedItemContext) => {
  const {
    link,
    title,
    duration,
    publisherLogo,
    publisherDarkLogo,
    publisherName,
    isVideoLink,
    thumbnailUrl,
    isSponsored,
    contentType,
    contentTypeText,
    ms_news,
    contentApiId,
    pub_time,
    layout
  } = feedItem

  return `
    <article
      ${createAttributes(feedItem)}>
      <a class="block-item__body"
        href="${appendInternalTrackingSearchParams(link)}"
        data-mp-cct>
   
        ${media(thumbnailUrl, isVideoLink, duration, layout)}

        ${header(
          isSponsored,
          publisherDarkLogo,
          publisherLogo,
          publisherName,
          isVideoLink,
          ms_news,
          layout
        )}

        <h1 class="block-item__title ${language}">
          <span class="block-item__headline">${title}</span>
          <span class="block-item__subtitle ${language}"></span>
        </h1>
      </a>

      ${footer(
        title,
        link,
        isSponsored,
        contentTypeText,
        contentType,
        language,
        layout,
        publisherDarkLogo,
        publisherLogo,
        publisherName,
        isVideoLink,
        ms_news,
        contentApiId,
        pub_time
      )}
    </article>
  `
}

export default podcastSmallTemplate
