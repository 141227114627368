export const setUpTimeWillBeAllowedIn = (activeState, updateState) => {
  const { formWasShownTimeArray } = activeState

  if (formWasShownTimeArray.length === 3) {
    const threeDaysAgoFromNow = Date.now() - 3 * 24 * 60 * 60 * 1000
    if (formWasShownTimeArray[0] > threeDaysAgoFromNow) {
      // was shown 3 times for the last 3 days
      updateState({ timeWillBeAllowedIn: Date.now() + 30 * 24 * 60 * 60 * 1000 })
    }
  }

  if (activeState.timeWillBeAllowedIn && activeState.timeWillBeAllowedIn < Date.now()) {
    updateState({ timeWillBeAllowedIn: null })
  }
}

export const updateFormWasShownTimeArray = (activeState, updateState) => {
  let wasShownTimeArray = activeState.formWasShownTimeArray

  const totalTimesShown = wasShownTimeArray.length
  if (totalTimesShown > 2) {
    wasShownTimeArray = wasShownTimeArray.slice(totalTimesShown - 2, totalTimesShown)
  }

  wasShownTimeArray.push(Date.now())
  updateState({ formWasShownTimeArray: wasShownTimeArray })
}
