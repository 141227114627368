const close = block => {
  block.classList.add('fadeOut')

  setTimeout(() => {
    block.remove()
  }, 400)
}

const initHeaderAlert = () => {
  const block: HTMLTemplateElement = document.querySelector('[data-block--header-alert]')

  if (!block) return

  const closeBtn: HTMLElement = block.querySelector('[data-prompt-close]')

  closeBtn.onclick = () => {
    close(block)
  }
}

export default initHeaderAlert
