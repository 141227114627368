import i18n from '../../../../utilities/translation'

export const template = ({ title, link1, link2, buttonText, declineButtonText }) =>
  `
    <p class="cookie-dialog__body">
      ${title}
      ${link1 ? `<a href="${link1.url}">${link1.text}</a>` : ``}
      ${link2 ? ` ${i18n('and')} <a href="${link2.url}">${link2.text}</a>` : ``}
    </p>

    
    <div class="cookie-dialog__button-container">
      <button id="cookie-dialog__button--accept" class="cookie-dialog__button cookie-dialog__button--accept">
        ${buttonText}
      </button>

      ${
        declineButtonText
          ? `<button id="cookie-dialog__button--reject" class="cookie-dialog__button cookie-dialog__button--reject">
              ${declineButtonText}
            </button>`
          : ``
      }
    </div>
  `
