/**
 * @todo We need to separate browser helpers from node helpers. --hrivera
 */

import URLSearchParams from '@ungap/url-search-params' // Polyfill for < v49

/**
 *
 * @param array
 * @returns array
 */
export const shuffleArray = (array: any[]): any[] => {
  const result = array.slice()

  for (let i = result.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[result[i], result[j]] = [result[j], result[i]]
  }

  return result
}

// Turn a flat array of file paths into a structured object representing a directory
// tree.
export const buildFolderTree = pages => {
  const generateNestedPaths = (path, children = [], [currentLevel, ...subLevels]: any) => {
    let targetedDir = children.find(child => child.name === currentLevel)
    if (!targetedDir) {
      targetedDir = {
        name: currentLevel,
        path,
        children: []
      }
      children.push(targetedDir)
    }
    if (subLevels.length) {
      generateNestedPaths(path, targetedDir.children, subLevels)
    }
    return children
  }
  const tree = { children: [] }
  pages
    .map(page => [page, page.split('/')])
    .reduce((acc, curr) => {
      return generateNestedPaths(curr[0], acc, curr[1])
    }, tree.children)
  return tree
}

export const ga4Debug = (message, dataLayerObject) => {
  const w = window as any
  const params = new URLSearchParams(w?.location?.search)

  if (params.get('ga4_debug') === '1' || (typeof process !== 'undefined' && process?.env?.GA4_DEBUG)) {
    console.debug(
      '%cNEPTUNE GA4',
      'display: inline-block; color: #fff; background: #a00; font-weight: bold; padding: 1px 8px; border-radius: 8px;',
      message,
      JSON.stringify(
        Object.keys(dataLayerObject)
          .sort()
          .reduce((acc, key) => {
            acc[key] = dataLayerObject[key]
            return acc
          }, {}),
        /*
         */
        // dataLayerObject,
        null,
        2
      )
    )
  }
}

export const neptuneDebug = (...args) => {
  const w = window as any
  const params = new URLSearchParams(w?.location?.search)

  if (
    params.has('neptune_debug') ||
    (typeof process !== 'undefined' && process.env.NEPTUNE_DEBUG) ||
    w.location.href.includes('localhost')
  ) {
    console.debug(
      '%cNEPTUNE',
      'display: inline-block; color: #fff; background: #00a; font-weight: bold; padding: 1px 8px; border-radius: 8px;',
      ...args
    )
  }
}

export const neptuneDebugCloudinary = (...args) => {
  const w = window as any
  const params = new URLSearchParams(w?.location?.search)

  if (params.has('neptune_debug_cloudinary') || (typeof process !== 'undefined' && process.env.NEPTUNE_DEBUG)) {
    console.debug(
      '%cNEPTUNE CLOUDINARY',
      'display: inline-block; color: #fff; background: #00a; font-weight: bold; padding: 1px 8px; border-radius: 8px;',
      ...args
    )
  }
}

export const neptuneWarn = (...args) => {
  const w = window as any
  const params = new URLSearchParams(w?.location?.search)

  if (
    params.has('neptune_debug') ||
    (typeof process !== 'undefined' && process.env.NEPTUNE_DEBUG) ||
    w.location.href.includes('localhost')
  ) {
    console.warn(
      '%cNEPTUNE',
      'display: inline-block; color: #fff; background: #aa0; font-weight: bold; padding: 1px 8px; border-radius: 8px;',
      ...args
    )
  }
}

// How to initialize slider:
// 1. Create hbs file (just copy content/src/raw/templates/partials/slideshow.hbs and
// add your wrapper outside the "slider-container" by layout or block etc.)
// 2. {{#each items}} items are what we are sliding
// 3. Create init func in content/src/assets/common/scripts/ like .../scripts/slideshow/slideshow.ts
// + export it .../content_page/initializers.ts
// 4. Apply styles (Attention: progress lines are white, so can be invisible on white background)
// 5. Extend with functionality you need
export class Slider {
  slider: HTMLElement
  slideIndex: number
  slides: HTMLElement[]
  dots: HTMLElement[]
  activeSlide: HTMLElement
  next: HTMLElement
  prev: HTMLElement

  constructor(sliderContainer) {
    this.slider = sliderContainer
    this.slides = Array.from(this.slider.getElementsByClassName('slider-slide')) as HTMLElement[]
    this.activeSlide = this.slides[1]
    this.next = this.slider.querySelector('.next')
    this.prev = this.slider.querySelector('.prev')
    this.slideIndex = 1
  }

  showSlides(n) {
    const { slides } = this
    let i

    this.dots = Array.from(this.slider.getElementsByClassName('dot')) as HTMLElement[]

    const dotWidth = 100 / this.dots.length + '%'
    this.dots.forEach(el => {
      el.style.width = dotWidth
    })

    if (n > slides.length) {
      this.slideIndex = 1
    }
    if (n < 1) {
      this.slideIndex = slides.length
    }
    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = 'none'
      slides[i].classList.remove('activeSlide')
    }
    for (i = 0; i < this.dots.length; i++) {
      this.dots[i].className = this.dots[i].className.replace(' active', '')
      this.dots[i].style.setProperty('--width', '0')
    }

    this.activeSlide = slides[this.slideIndex - 1]
    this.activeSlide.style.display = 'block'
    this.activeSlide.classList.add('activeSlide')

    for (i = 0; i < this.slideIndex; i++) {
      if (i === this.slideIndex - 1) {
        this.dots[i].className += ' active'
      } else {
        this.dots[i].style.setProperty('--width', '100%')
      }
    }
  }

  plusSlides() {
    this.showSlides((this.slideIndex += 1))
  }
  minusSlides() {
    this.showSlides((this.slideIndex -= 1))
  }

  init() {
    this.showSlides(this.slideIndex)
    this.next.addEventListener('click', this.plusSlides.bind(this))
    this.prev.addEventListener('click', this.minusSlides.bind(this))
  }
}

export const hoursToMilliseconds = hours => 1000 * 60 * 60 * hours

export const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window['opera']
  if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)) {
    return 'iOS'
  } else if (userAgent.match(/Android/i)) {
    return 'Android'
  } else {
    return 'unknown'
  }
}

export const convertToUrl = link => {
  if (!link) return ''
  let url

  try {
    url = new URL(link)
  } catch (e) {
    url = link
  }
  return url
}
