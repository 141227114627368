import Swiper from 'swiper'
import { Navigation } from 'swiper/modules'

import { initPerBlockType } from '../utilities/DOM_events'
import contentInit from './fotoscape_gallery'
import { handleTouchEvents } from '../../../utilities/touchEvent'

Swiper.use([Navigation])

const initSwiper = block => {
  const swiper = block.querySelector('.swiper')

  new Swiper(swiper, {
    slidesPerView: 1.5,
    spaceBetween: 15,
    loop: true,
    autoplay: {
      delay: 3000
    },
    effect: 'fade',
    centeredSlides: true,
    slidesOffsetBefore: 0,
    grabCursor: true,
    speed: 1000
  })
}

const initTrendingCarousel = () => {
  contentInit()

  const blocksOnPage = Array.from(document.querySelectorAll('.block--fotoscape-gallery'))

  if (!blocksOnPage) return
  const initCarousel = initPerBlockType(blocksOnPage, initSwiper)

  initCarousel('fotoscape_gallery_block', 'fotoscapesGalleryLoad')

  blocksOnPage.forEach(block => {
    block.addEventListener('fotoscapesGalleryLoad', () => {
      handleTouchEvents(`#${block.getAttribute('id')} .fotoscape-gallery__swiper`)
    })
  })
}
export default initTrendingCarousel
