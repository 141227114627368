import GA4Handler from '../ga4_support/GA4Handler'
import { shuffle } from '../utilities/index'
import { debounce } from 'lodash'
import { DataLayerVideoClick, BlockType } from '../ga4_support/interfaces'

import CONTENT_ITEMS from './content_data'

const players = {}
const renderedVideos = []

const triggerGTMEvent = event => {
  const link = new URL(event.target.getVideoUrl())
  const domain = link.hostname
  const path = link.pathname

  const data: DataLayerVideoClick = {
    event: 'video',
    element: 'youtube_video',
    element_action: 'play',
    block_type: BlockType.youtube_video,
    domain: domain,
    path: path
  }

  GA4Handler.pushObject('YouTube::triggerGTMEvent', data)
}

const setUpYoutubeScript = () => {
  const tag = document.createElement('script')

  tag.src = 'https://www.youtube.com/iframe_api'
  const firstScriptTag = document.getElementsByTagName('script')[0]
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
}

const setUpYoutubePlayer = () => {
  ;(window as any).onYouTubeIframeAPIReady = () => {
    renderedVideos.forEach(item => {
      players[`youtube-video-${item}`] = new window['YT'].Player(`youtube-video-${item}`, {
        height: '320',
        width: '200',
        videoId: item,
        playerVars: {
          controls: 0
        },
        events: {
          onStateChange: (window as any).onPlayerStateChange,
          onReady: (window as any).checkOnPlayerReady
        }
      })
      players[`youtube-video-${item}`]['isPlaying'] = false
      players[`youtube-video-${item}`]['customId'] = `youtube-video-${item}`
    })
  }

  let done = false
  ;(window as any).checkOnPlayerReady = event => {
    if (!event.target.videoTitle) {
      document.getElementById(event.target.customId).remove()
      players[event.target.customId].destroy()
      delete players[event.target.customId]
    }
  }
  ;(window as any).onPlayerStateChange = event => {
    const id = event.target['customId']

    if (event.data == window['YT'].PlayerState.PLAYING && !done) {
      players[id]['isPlaying'] = true
      ;(window as any).stopAllOtherVideos(id)
      done = true
      triggerGTMEvent(event)
      return
    }

    if (players[id]) players[id]['isPlaying'] = false
    done = false
  }
  ;(window as any).stopVideo = event => {
    const id = event.target['customId']
    players[id].stopVideo()
    if (players[id]) players[id]['isPlaying'] = false
  }
  ;(window as any).pauseVideo = event => {
    const id = event.target['customId']
    players[id].pauseVideo()
    if (players[id]) players[id]['isPlaying'] = false
  }
  ;(window as any).stopAllOtherVideos = id => {
    Object.keys(players).forEach(key => {
      if (key != id) {
        players[key].pauseVideo()
      }
    })
  }
}

const renderVideos = (template, blockItems) => {
  let itemsContent = ''
  blockItems.forEach(item => {
    itemsContent += `<div class="block-item" id="youtube-video-${item}"></div>`
  })

  const content = template.content.cloneNode(true)

  content.querySelector('[data-mp-youtube-video-target]').innerHTML = itemsContent
  if (template.parentNode) {
    template.parentNode.insertBefore(content, template)
  }
}

const pauseNotInViewportVideo = () => {
  const playingElements = []

  for (const key in players) {
    if (players[key]['isPlaying']) {
      playingElements.push(key)
    }
  }

  playingElements.forEach(id => {
    const el = document.getElementById(id)
    if (notInTheViewport(el)) {
      players[id].pauseVideo()
    }
  })
}

const notInTheViewport = el => {
  const rect = el.getBoundingClientRect()
  return (
    rect.x < 0 ||
    rect.x + rect.width < 0 ||
    rect.y + rect.height < 0 ||
    rect.x > window.innerWidth ||
    rect.y > window.innerHeight
  )
}

const initYouTubeVideo = () => {
  const containers: HTMLElement[] = Array.from(
    document.querySelectorAll('[data-youtube-video-container]')
  )

  if (!containers.length) return

  setUpYoutubeScript()

  let items = shuffle(CONTENT_ITEMS)

  containers.forEach(container => {
    if (!items.length) return

    const template: any = container.querySelector('template[data-youtube-video-template]')
    if (!template) return

    const count = Number(template.dataset.mpYoutubeCount)
    const blockItems = items.slice(0, count)

    renderedVideos.push(...blockItems)
    items = items.slice(count, items.length)

    renderVideos(template, blockItems)

    if (container.classList.contains('block--carousel')) {
      container.querySelector('.block__main').addEventListener(
        'scroll',
        debounce(function () {
          pauseNotInViewportVideo()
        }, 300)
      )
    }
  })

  setUpYoutubePlayer()

  document.addEventListener(
    'scroll',
    debounce(function () {
      pauseNotInViewportVideo()
    }, 300)
  )
}

export default initYouTubeVideo
