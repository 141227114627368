import GA4Handler from '../GA4Handler'
import {
  BlockType,
  DataLayerInteractionType,
  DataLayerNonLinkClick,
  DataLayerNonLinkClickElementAction
} from '../interfaces'

const ga4EmojiFeedbackClickHandler = (section, type, action, detail) => {
  const blockIndex = section?.getAttribute('data-mp-block-index')

  const data: DataLayerNonLinkClick = {
    block_position: blockIndex,
    block_type: BlockType.emoji_feedback_block,
    block_layout: null,
    content_type: type,
    element: BlockType.emoji_feedback_block,
    element_action: action,
    element_detail: detail,
    event: DataLayerNonLinkClickElementAction.impression_click,
    interaction_type: DataLayerInteractionType.internal
  }

  GA4Handler.pushObject('ga4EmojiFeedbackClickHandler', data)
}

export default ga4EmojiFeedbackClickHandler
