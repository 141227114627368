import * as device from '../device'
import { openAppInPlayStore } from '../bridge_interface/bridge_interface'
import { setFocusToFirstFocusableElement, constrainTabFocusToModal } from '../utilities/modal'
import ga4UpgradePromptClickHandler from 'content/src/assets/common/scripts/ga4_support/blocks/upgrade_prompt'

const isUpgradeDeclined = () => {
  const declineDate = localStorage.getItem('upgrade_declined')

  if (!declineDate) return false

  const declineValue = Number.parseInt(declineDate)
  const currentValue = new Date().valueOf()

  const diff = currentValue - declineValue
  const diffInHours = diff / 1000 / 60 / 60

  if (diffInHours > 24) return false

  return true
}

const isUpgradeAvailable = () => {
  const deviceVersion = device.majorAppVersion()
  const availableVersion = device.availableMajorAppVersion()

  if (!deviceVersion || !availableVersion) return false
  return deviceVersion < availableVersion
}

const closePrompt = (accept = false) => {
  const prompt = document.querySelector('section[data-upgrade-prompt-content]')
  const date = new Date().valueOf().toString()
  localStorage.setItem('upgrade_declined', date)

  if (!accept) {
    ga4UpgradePromptClickHandler(
      prompt,
      'editorial',
      'no',
      `device.majorAppVersion: ${device.majorAppVersion()}, device.availableMajorAppVersion: ${device.availableMajorAppVersion()}`
    )
  }

  prompt.remove()
}

const addClosePromptHandlers = () => {
  const prompt = document.querySelector('section[data-upgrade-prompt-content]')
  const close: HTMLElement = document.querySelector('button[data-prompt-close]')
  const decline: HTMLElement = document.querySelector('button[data-prompt-decline]')
  const confirm: HTMLAnchorElement = document.querySelector('a[data-prompt-confirm]')
  const platformLink = confirm?.dataset?.playstoreLink
  const link = platformLink || device.playstoreLink()
  const elems = [close, decline]

  confirm.onclick = () => {
    closePrompt(true)
    ga4UpgradePromptClickHandler(
      prompt,
      'editorial',
      'yes',
      `device.majorAppVersion: ${device.majorAppVersion()}, device.availableMajorAppVersion: ${device.availableMajorAppVersion()}`
    )
    openAppInPlayStore(link)
  }

  elems.map(elem => {
    elem.onclick = () => {
      closePrompt(false)
    }
  })
}

const addFocusHandlers = () => {
  const prompt: HTMLElement = document.querySelector('section[data-upgrade-prompt-content]')
  setFocusToFirstFocusableElement(prompt)

  prompt.onkeydown = event => constrainTabFocusToModal(prompt, event)
}

const addHandlers = () => {
  addClosePromptHandlers()
  addFocusHandlers()
}

const displayPrompt = template => {
  const content = template.content.cloneNode(true)
  template.parentNode.insertBefore(content, template)

  addHandlers()
}

const initUpgradePrompt = () => {
  const template: HTMLTemplateElement = document.querySelector(
    'template[data-upgrade-prompt-template]'
  )

  if (!template) return

  const anchor: any = template.content.querySelector('a[data-prompt-confirm]')
  const platformLink = anchor?.dataset?.playstoreLink

  if (!device.playstoreLink() && !platformLink) return
  if (isUpgradeAvailable() && !isUpgradeDeclined()) {
    displayPrompt(template)
  }
}

export default initUpgradePrompt
