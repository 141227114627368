import URLSearchParams from '@ungap/url-search-params' // Polyfill for < v49
import * as device from '../device'
import GA4Handler from '../ga4_support/GA4Handler'
import FotoscapesURL from '../fotoscape/FotoscapesURL'

// Domains included in partnership with Static Media (CM-4904).
export const zSourceDomains = [
  'Looper.com',
  'NickiSwift.com',
  'Grunge.com',
  'TheList.com',
  'Mashed.com',
  'SVG.com',
  'HealthDigest.com',
  'HouseDigest.com',
  'TastingTable.com',
  'SlashFilm.com',
  'SlashGear.com',
  'WrestlingInc.com',
  'Glam.com',
  'TheDailyMeal.com',
  'Explore.com'
]

export const aWeatherDomains = ['Accuweather.com']
// All outbound links are required to append several utm parameters for
// reporting.
// This gets called on page load to handle any elements generated statically, and then
// again every time a client library uses the feed item template to dynamically render
// content.
export const appendInternalTrackingSearchParams = link => {
  const platform = document.documentElement.getAttribute('data-mp-platform')
  const queryParams: any = {
    utm_source: `mp-${platform}`
  }

  const isArkadiumLink = link.includes('arkadiumarena.com')
  if (isArkadiumLink) {
    queryParams['arkpromo'] = `mp-${platform}`
  }

  if (
    zSourceDomains.some(domain => {
      return link.toLowerCase().includes(domain.toLowerCase())
    })
  ) {
    queryParams['zsource'] = 'dt_app'
  }

  if (
    aWeatherDomains.some(domain => {
      return link.toLowerCase().includes(domain.toLowerCase())
    })
  ) {
    queryParams['partner'] = 'web_dtrsscoop_adc'
  }

  // Some parameters will come from the scheduler.
  const activePageParams = new URLSearchParams((window as any).location.search)
  // Some will be passed through to external links.
  // Some will need to be realiased for some reason.
  // eg time_slot -> mp_time-slot (for the benefit of fotoscapes gpt targeting setup)
  // In Cashew, some of these parameters would be passed to all partners, but until that
  // reasserts itself as a requirement, we'll be constraining the additional properties
  // just to fotoscapes, an internal property of our own that we know uses them.
  // NOTE: The following test for whether a link is fotoscapes is the same currently used by
  // Cashew, for parity.
  const isFotoscapesLink = link.includes('myfirstly.com') || link.includes(FotoscapesURL.getBaseDomain())
  if (isFotoscapesLink) {
    // The following is a subset of the properties Fotoscapes looks for to pass to GPT.
    // The full list (at the time of writing) is at:
    // https://github.com/mobileposse/lightbox/blob/d813bb63ac52738c0df051fb7faee820704b91b3/webplayer/modules/player/assets/player.js#L178
    const timeSlot = activePageParams.get('time_slot')
    if (timeSlot) {
      queryParams['mp_time-slot'] = timeSlot
    }
    const mpid = localStorage.getItem('mp_uuID')
    if (mpid) {
      queryParams['mp_mpid'] = mpid
    }
    const aaid = localStorage.getItem('mp_androidAID')
    if (aaid) {
      queryParams['mp_aaid'] = aaid
    }
  }

  const queryParamString = Object.entries(queryParams)
    .map(([key, value]: any) => `${key}=${encodeURIComponent(value)}`)
    .join('&')
  // If there's already some querystring params we want to append, otherwise create querystring.
  const utmParamsPrependChar = link.indexOf('?') > 0 ? '&' : '?'

  return link + utmParamsPrependChar + queryParamString
}

export const dataLayerPush = (event: any) => {
  ;(window as any).dataLayer = (window as any).dataLayer || []
  ;(window as any).dataLayer.push(event)
}

export const passIdentifiersToDatalayer = () => {
  ;(window as any).dataLayer = (window as any).dataLayer || []

  const userId = localStorage.getItem('int_mp_uuID')
  if (userId) {
    GA4Handler.pushObject('passIdentifiersToDatalayer', { userId })
  }
  const appVersion = localStorage.getItem('int_mp_mpAppVersion')
  if (appVersion) {
    GA4Handler.pushObject('passIdentifiersToDatalayer', { appVersion })
  }
  const mode = localStorage.getItem('int_mp_mode')
  if (mode) {
    GA4Handler.pushObject('passIdentifiersToDatalayer', { mode })
  }
  const aaid = localStorage.getItem('int_mp_androidAID')
  if (aaid) {
    GA4Handler.pushObject('passIdentifiersToDatalayer', { aaid })
  }

  const partner = getPartner()
  if (partner) {
    GA4Handler.pushObject('passIdentifiersToDatalayer', { partner })
  }
  // The following is a way to determine the platform
  // We found a pattern in the version code followed as such:
  // code < 1000 = tobago
  // code < 10000 = pistachio
  // code > 10000 = neptune
  // Exceptions:
  // code === 5004 = tobago
  const versionCode = parseInt(localStorage.getItem('int_mp_appVersionCode'), 10)
  if (versionCode) {
    let platform
    if (versionCode === 5004 || versionCode < 1000) {
      platform = 'tobago'
    } else if (versionCode < 10_000) {
      platform = 'pistachio'
    } else {
      platform = 'neptune'
    }
    dataLayerPush({ platform })
  }
}

const getPartner = () => {
  const pagePartner = document.documentElement.dataset.mpPlatform

  if (pagePartner !== 'metropcs') return pagePartner

  const appId = device.getAppId()

  if (!appId) return pagePartner
  if (appId.includes('metrozone')) return 'metropcs'

  return 'mobileposse'
}

export const setDeviceGTMDataAttributes = () => {
  const mode = localStorage.getItem('int_mp_mode')
  const mpid = localStorage.getItem('int_mp_uuID')
  const app_version = localStorage.getItem('int_mp_mpAppVersion')
  const partner = getPartner()

  const gtmData = { mode, mpid, app_version, partner }

  for (const [key, value] of Object.entries(gtmData)) {
    if (!value) continue

    document.documentElement.setAttribute(`data-gtm-${key}`, value)
  }
}
