// Cricket wants to show a warning to users that their site uses cookies.
// This is not GDPR compliant FYI, just a carrier request. Implementing as a kludge
// because we will need to implement a real GDPR solution sooner than later.
// Certain aspects of the cashew implementation will be ported as-is to avoid
// the headache of arguing for the improvement deltas. Including making it a fixed overlay
// that is totally ignorable (and also messing with screen navigation.)
import { template } from './templates/cookie_notice_dialog'
import * as platforms from './templates'
import './cookie_dialog.scss'

const CASHEW_COOKIE_KEY = 'hideFeedbackPrompt520896'
const platform = document.documentElement.dataset.mpPlatform

const showDialog = (acceptHandler, rejectHandler, gdpr = false) => {
  const pageContainer = document.querySelector('.general-container')
  const dialogContainer = document.createElement('div')
  dialogContainer.classList.add('cookie-dialog__container')

  if (gdpr) dialogContainer.classList.add('gdpr')

  const language = document.documentElement.lang
  const configuration = platforms[platform][language]

  dialogContainer.innerHTML = template(configuration)
  pageContainer.insertAdjacentElement('afterbegin', dialogContainer)

  const transitionEndKey = pageContainer.getElementsByClassName.hasOwnProperty('transitionend')
    ? 'transitionend'
    : 'webkitTransitionEnd'

  const closeDialog = () => {
    dialogContainer.addEventListener(transitionEndKey, () => {
      dialogContainer.remove()
    })
    dialogContainer.classList.add('cookie-dialog__animateout')
  }

  const acceptButton = dialogContainer.querySelector('#cookie-dialog__button--accept')
  acceptButton.addEventListener('click', () => {
    closeDialog()
    acceptHandler()
  })

  const rejectButton = dialogContainer.querySelector('#cookie-dialog__button--reject')
  rejectButton?.addEventListener('click', () => {
    closeDialog()
    rejectHandler()
  })
}

const showCookieNotice = () => {
  const cookieNoticeFlag = (window as any).mp_globals.features.cookie_notice_dialog
  if (!cookieNoticeFlag) return

  const mercury_ls_key = `local_int_${platform}_cookie_alert_acceptance`
  const hasAccepted = localStorage.getItem(mercury_ls_key) === 'true'

  if (!hasAccepted) {
    const hasAcceptedOnCashew = document.cookie.indexOf(CASHEW_COOKIE_KEY) !== -1
    if (hasAcceptedOnCashew) {
      localStorage.setItem(mercury_ls_key, 'true')
      return
    }

    const accept = () => {
      localStorage.setItem(mercury_ls_key, 'true')
    }

    showDialog(accept, null)
  }
}

const clearCookies = () => {
  document.cookie.split(';').forEach(function (c) {
    document.cookie = c
      .replace(/^ +/, '')
      .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/')
  })
}

const showGDPRNotice = (forceOpen = false) => {
  const gdprNoticeFlag = (window as any).mp_globals?.features?.gdpr_cookie_notice_dialog
  const key = `gdpr_${platform}`
  const selection = JSON.parse(localStorage.getItem(key))
  const hasSelected = selection?.date

  if (!gdprNoticeFlag) return
  if (hasSelected && !forceOpen) return

  const accept = () => {
    localStorage.setItem(key, JSON.stringify({ accepted: true, date: new Date() }))
    location.reload()
  }

  const reject = () => {
    localStorage.setItem(key, JSON.stringify({ accepted: false, date: new Date() }))
    clearCookies()
    location.reload()
  }

  showDialog(accept, reject, true)
}

const addSettingsHandler = () => {
  const footer = document.querySelector('[data-mp-block-type="portal_footer"]')
  if (!footer) return

  const links = footer.getElementsByClassName('portal-footer__link')
  const settings = Array.from(links).find((link: any) => link.href.includes('#cookie-settings'))
  settings.addEventListener('click', () => showGDPRNotice(true))
}

const initCookieNotice = () => {
  showCookieNotice()
  showGDPRNotice()
  addSettingsHandler()
}

export default initCookieNotice
