import dateSetup, { formatDate } from '../portal_header/utilities'

const platformsNoLogo = ['firstly', 'moment']

const initDailyTopHeader = () => {
  try {
    // there should be just 1 daily top block on a page
    const blockDailyTop = document.querySelector('.block--daily-top') as HTMLElement
    const blockDailyTopLogo = document.querySelector('.subheader__logo')
    const platform = document.documentElement.getAttribute('data-mp-platform')

    if (!blockDailyTop) return

    const logoContainer = blockDailyTop.querySelector('.subheader__logo')

    const language = document.documentElement.lang
    if (!language) return

    const localLocale = dateSetup(language)

    let displayFormat = formatDate(language, localLocale)

    const backgroundImageCheck = window
      .getComputedStyle(blockDailyTopLogo)
      .getPropertyValue('background-image')

    if (platformsNoLogo.includes(platform) || backgroundImageCheck === 'none') {
      logoContainer.innerHTML = displayFormat
      logoContainer.classList.add('text-logo')
    }
  } catch (e) {
    console.error('Daily top header init error: ', e)
  }
}

export default initDailyTopHeader
