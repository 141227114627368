import * as constants from '../../bridge_interface/constants'
import captureException from '../../../../sentry/captureException'
import { settings } from '../../weather_widget/settings/config'
import axios from 'axios'

const { blockConfig } = settings

const w = window as any
const env = w.mp_globals && w.mp_globals.env && w.mp_globals.env.environment

const apiLocationUrl =
  env === 'live' ? blockConfig.api.location.live : blockConfig.api.location.sandbox
const apiWeatherUrl =
  env === 'live' ? blockConfig.api.weatherCurrent.live : blockConfig.api.weatherCurrent.sandbox

const validateLocation = location => {
  return (
    !!location.location.latitude &&
    !!location.location.longitude &&
    // !!location.location.state.length && Washington DC does not return a state code.
    !!location.location.zipcode.length &&
    !!location.source.slugName.length &&
    !!location.source.currentLink.length &&
    !!location.source.hourlyLink.length &&
    !!location.source.tenDayLink.length &&
    !!location.source.sourceLink.length
  )
}

const getApiWeather = async geolocation => {
  const url = `${apiWeatherUrl}latitude=${geolocation.latitude}&longitude=${geolocation.longitude}`

  try {
    return await axios
      .get(url, { transformResponse: d => d })
      .then(response => JSON.parse(response.data))
  } catch (e) {
    captureException(e, 'Weather API Call Failed')

    return {
      weather: {
        temperatureMax: undefined,
        temperatureMin: undefined,
        temperature: undefined,
        temperatureFeelsLike: undefined,
        windSpeed: undefined,
        relativeHumidity: undefined,
        iconCode: undefined
      }
    }
  }
}

const validateWeather = weather => {
  return (
    !!weather &&
    !!weather.weather &&
    (weather.weather.temperature === 0 || !!weather.weather.temperature) &&
    (weather.weather.temperatureMin === 0 || !!weather.weather.temperatureMin) &&
    (weather.weather.temperatureMax === 0 || !!weather.weather.temperatureMax) &&
    (weather.weather.temperatureFeelsLike === 0 || !!weather.weather.temperatureFeelsLike) &&
    (weather.weather.windSpeed === 0 || !!weather.weather.windSpeed) &&
    (weather.weather.relativeHumidity === 0 || !!weather.weather.relativeHumidity)
  )
}

const getApiLocation = async geolocation => {
  const url = `${apiLocationUrl}latitude=${geolocation.latitude}&longitude=${geolocation.longitude}`

  try {
    return await axios
      .get(url, { transformResponse: d => d })
      .then(response => JSON.parse(response.data))
  } catch (e) {
    if (e instanceof SyntaxError) {
      captureException(e, `Weather::getApiLocation: could not parse API JSON for URL ${url}`)
    } else {
      captureException(e, `Weather::getApiLocation: unspecified error for URL ${url}`)
    }

    throw e
  }
}

const getCurrentPosition = () => {
  const lat = localStorage.getItem(constants.LS_BRIDGE_LAT)
  const lng = localStorage.getItem(constants.LS_BRIDGE_LNG)

  // These are rounded to improve cache-ability.
  const latitude = Math.round(parseFloat(lat) * 100) / 100
  const longitude = Math.round(parseFloat(lng) * 100) / 100

  return {
    latitude,
    longitude
  }
}

export const getCurrentWeather = async () => {
  const geolocation = getCurrentPosition()

  try {
    const location = await getApiLocation(geolocation)
    const weather = await getApiWeather(geolocation)

    if (validateLocation(location) && validateWeather(weather)) {
      return {
        weatherData: weather.weather,
        locationData: location.location,
        loading: false,
        source: location.source
      }
    }

    return {}
  } catch (e) {
    captureException(e)
    return {}
  }
}
