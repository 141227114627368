import { FeedItemContext } from '../../../../../../lib/feed/interfaces'
import { appendInternalTrackingSearchParams } from '../../../utilities/analytics'
import { style, header, footer, language, app_theme, media } from '../feed_item'
import { isMomentTheme, removeSpecialCharacters, formatCategory, createAttributes } from '../utils'

const smallPhotocardFlatTemplate = (feedItem: FeedItemContext) => {
  const {
    link,
    title,
    publisherLogo,
    publisherDarkLogo,
    publisherName,
    isVideoLink,
    thumbnailUrl,
    duration,
    isSponsored,
    contentType,
    contentTypeText,
    description,
    ms_news,
    contentApiId,
    pub_time,
    category,
    layout
  } = feedItem

  return `
  <article
    ${createAttributes(feedItem)}>
    <a class="block-item__body"
       href="${appendInternalTrackingSearchParams(link)}"
       style="${style(layout, thumbnailUrl)}"
       data-mp-cct>

      ${media(thumbnailUrl, isVideoLink, duration, layout)}
      ${header(
        isSponsored,
        publisherDarkLogo,
        publisherLogo,
        publisherName,
        isVideoLink,
        ms_news,
        layout
      )}
        <h1 class="block-item__title ${language}">
            <span class="block-item__headline">${title}</span>
            <span class="block-item__subtitle ${language}"></span>
          </h1>

          ${
            description
              ? `<p class="block-item__description ${language}">${removeSpecialCharacters(
                  description
                )}</p>`
              : ''
          }

      ${
        isMomentTheme(app_theme)
          ? `<p class="block-item__category ${language}">${
              category !== 'default' && category !== undefined ? formatCategory(category) : ''
            }</p>`
          : ''
      }

        </div>
    </a>

    ${footer(
      title,
      link,
      isSponsored,
      contentTypeText,
      contentType,
      language,
      layout,
      publisherDarkLogo,
      publisherLogo,
      publisherName,
      isVideoLink,
      ms_news,
      contentApiId,
      pub_time
    )}

    </article>
  `
}

export default smallPhotocardFlatTemplate
