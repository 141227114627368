import ColorThief from 'colorthief'
import GA4Handler from '../ga4_support/GA4Handler'
import { shuffle } from '../utilities'
import { drawStars } from '../utilities/drawStars'
import app_download_En from './app_download_en'
import app_download_Es from './app_download_es'

const CONTENT_ITEMS = {
  en: app_download_En,
  es: app_download_Es
}

const replaceElements = (indexOfA, indexOfB, array) => {
  return ([array[indexOfA], array[indexOfB]] = [array[indexOfB], array[indexOfA]])
}

export const checkForCarousel = (appArray, appBlock) => {
  const appCarousel = appBlock.querySelector('[data-app-download-carousel]')

  // check if carousel is required
  if (!appCarousel) return

  // check if we have a particular item(app) to be on carousel
  if (appCarousel.id) {
    const carouselElement = appArray.filter(el => el.title === appCarousel.id)
    const carouselElementIndex = appArray.indexOf(carouselElement[0])

    // if so we make it the first item in the array
    if (carouselElementIndex) {
      replaceElements(0, carouselElementIndex, appArray)
    }
  }

  // if not we just take first item of already existing mixed array without any changes
  addCarousel(appArray[0], appCarousel)
}

const addCarousel = (appItem, appCarousel) => {
  appItem.carouselImages.forEach(url => {
    const link = document.createElement('a')
    link.href = appItem.url
    link.className = 'app-download-carousel__image-container'

    const image = document.createElement('img')
    image.src = url
    image.className = 'app-download-carousel__image'

    link.appendChild(image)

    link.addEventListener('click', e => {
      GA4Handler.clickHandleEvent(e, { element_detail: appItem.title, title: appItem.title }) // , BlockType.app_download_block, appItem.title, appItem.url)
    })

    appCarousel.appendChild(link)
  })
}

const getApp = (first, index, appContentArray) => {
  if (first && index === 0) {
    return appContentArray.find(el => el.title === first)
  }

  return appContentArray[index]
}

const fillAppElements = async (appItems, appContentArray) => {
  appItems.map((el, index) => {
    const allowApi = el.dataset.mpAllowApi
    const appImage: HTMLElement = el.querySelector('[data-app-download-image]')

    if (el.parentElement.className.includes('edge')) {
      setBackgroundColor(el, appImage)
    }

    if (allowApi) return

    const first = el.dataset.appDownloadItemFirst
    const app = getApp(first, index, appContentArray)
    const appStars = drawStars(app.starCount)
    const appContentLink: HTMLElement = el.querySelector('[data-app-download-content-link]')

    appContentLink.setAttribute('href', app.url)
    appContentLink.setAttribute('data-gtm-event-label', app.title)

    appContentLink.addEventListener('click', e => {
      GA4Handler.clickHandleEvent(e, { element_detail: app.title, title: app.title })
    })

    appImage.setAttribute('src', app.image)
    appImage.setAttribute('alt', app.title)

    const appTitle: HTMLElement = el.querySelector('[data-app-download-title]')
    appTitle.innerHTML = app.title

    const appDownloadNumber: HTMLElement = el.querySelector('[data-app-download-number]')
    if (appDownloadNumber) appDownloadNumber.innerHTML = app.downloadNumber

    const appStarCount: HTMLElement = el.querySelector('[data-app-download-star-count]')

    if (appStarCount) appStarCount.innerHTML += appStars

    const appStarVoteNumber: HTMLElement = el.querySelector('[data-app-download-star-vote-number]')
    if (appStarVoteNumber) appStarVoteNumber.innerHTML = app.starVoteNumber

    const appDescription: HTMLElement = el.querySelector('[data-app-download-description]')
    if (appDescription) appDescription.innerHTML = app.description
  })
}

const setBackgroundColor = async (el, appImage) => {
  const mode = document.documentElement.dataset.colorScheme

  const colorThief = new ColorThief()
  appImage.crossOrigin = 'Anonymous'

  appImage.addEventListener('load', function () {
    const color = colorThief.getColor(appImage)
    const rgb = `rgb(${color.join()})`

    if (mode === 'dark') {
      el.style = `background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), ${rgb};`
    } else {
      el.style = `background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), ${rgb};`
    }
  })
}

const initAppDownloadBlock = async () => {
  try {
    const language = document.documentElement.lang

    const appBlocks = Array.from(document.querySelectorAll('[data-app-download-block]'))

    appBlocks.forEach(appBlock => {
      const appContentArray = shuffle(CONTENT_ITEMS[language])
      const appItems = Array.from(appBlock.querySelectorAll('[data-app-download-item]'))

      GA4Handler.impressionHandleEvent(
        appBlock as HTMLElement,
        {
          title: null
        },
        0.4
      )

      checkForCarousel(appContentArray, appBlock)

      fillAppElements(appItems, appContentArray)
    })
  } catch (e) {
    console.error('initAppDownloadBlock error: ' + e)
  }
  return ''
}

export default initAppDownloadBlock
