import { getHoursDifference } from '../utilities'
import i18n from '../../../utilities/translation'

const formatTimeDifference = (timeDifference, language) => {
  if (timeDifference < 0) return i18n('popular', language)
  if (timeDifference < 1) return `${Math.floor(timeDifference * 60)}min ${i18n('ago', language)}`
  if (timeDifference < 4) return `${Math.floor(timeDifference)}h ${i18n('ago', language)}`
  if (timeDifference < 24) return i18n('today', language)
  if (timeDifference < 48) return i18n('yesterday', language)

  return i18n('popular', language)
}

export const setTimeDifference = (prevDate, language) => {
  const prevDateFormatCheck = new Date(prevDate).getTime()
  const hourDifference = getHoursDifference(
    prevDateFormatCheck ? prevDateFormatCheck : prevDate,
    new Date()
  )

  if (!hourDifference) return i18n('popular', language)

  return formatTimeDifference(hourDifference, language)
}
