import i18n from '../../../../utilities/translation'
const language = document.documentElement.lang

const isBookmarkEnabled = (window as any).mp_globals?.features?.bookmarks

const actionButtonsTemplate = (theme = 'dark') => `
<div class="block-item__actions">
  <button
    class="button button__icon button__icon--share ${theme === 'dark' ? 'svg--dark' : 'svg--light'}"
    type="button"
    aria-label="${i18n('share', language)}"
    data-mp-button-share
    data-gtm-id="share"
  >
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-gtm-id="share">
      <path data-gtm-id="share" class="svg--fillable" d="M16.5 15.7333C15.8667 15.7333 15.3 15.9833 14.8667 16.375L8.925 12.9167C8.96667 12.725 9 12.5333 9 12.3333C9 12.1333 8.96667 11.9417 8.925 11.75L14.8 8.325C15.25 8.74167 15.8417 9 16.5 9C17.8833 9 19 7.88333 19 6.5C19 5.11667 17.8833 4 16.5 4C15.1167 4 14 5.11667 14 6.5C14 6.7 14.0333 6.89167 14.075 7.08333L8.2 10.5083C7.75 10.0917 7.15833 9.83333 6.5 9.83333C5.11667 9.83333 4 10.95 4 12.3333C4 13.7167 5.11667 14.8333 6.5 14.8333C7.15833 14.8333 7.75 14.575 8.2 14.1583L14.1333 17.625C14.0917 17.8 14.0667 17.9833 14.0667 18.1667C14.0667 19.5083 15.1583 20.6 16.5 20.6C17.8417 20.6 18.9333 19.5083 18.9333 18.1667C18.9333 16.825 17.8417 15.7333 16.5 15.7333ZM16.5 5.66667C16.9583 5.66667 17.3333 6.04167 17.3333 6.5C17.3333 6.95833 16.9583 7.33333 16.5 7.33333C16.0417 7.33333 15.6667 6.95833 15.6667 6.5C15.6667 6.04167 16.0417 5.66667 16.5 5.66667ZM6.5 13.1667C6.04167 13.1667 5.66667 12.7917 5.66667 12.3333C5.66667 11.875 6.04167 11.5 6.5 11.5C6.95833 11.5 7.33333 11.875 7.33333 12.3333C7.33333 12.7917 6.95833 13.1667 6.5 13.1667ZM16.5 19.0167C16.0417 19.0167 15.6667 18.6417 15.6667 18.1833C15.6667 17.725 16.0417 17.35 16.5 17.35C16.9583 17.35 17.3333 17.725 17.3333 18.1833C17.3333 18.6417 16.9583 19.0167 16.5 19.0167Z" fill="#949494"/>
    </svg>
  </button>

  <button
    class="button button__icon button__icon--feedback ${
      isBookmarkEnabled ? 'button__icon--feedback-bookmark' : ''
    } ${theme === 'dark' ? 'svg--dark' : 'svg--light'}"
    type="button"
    aria-label="${i18n('feedback', language)}"
    data-modal-custom-trigger-target-feedback="modal--feedback"
    data-gtm-id="feedback"
  >
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-gtm-id="feedback">
      <path data-gtm-id="feedback" class="svg--fillable" d="M10.0318 18.5944C9.92977 19.4306 9.21981 20.0592 8.37743 20.0592H7.33333C6.41286 20.0592 5.66667 19.313 5.66667 18.3925V15.0592C4.74619 15.0592 4 14.313 4 13.3925V9.22584C4 8.30536 4.74619 7.55917 5.66667 7.55917H10.6667L19.5271 4.05852C19.9552 3.88941 20.4393 4.09931 20.6084 4.52735C20.6469 4.62484 20.6667 4.72873 20.6667 4.83356V18.6042C20.6667 19.0645 20.2936 19.4376 19.8333 19.4376C19.7067 19.4376 19.5817 19.4087 19.4679 19.3532L10.6667 15.0592L10.463 15.059L10.0318 18.5944ZM7.33333 18.3925H8.37743L8.78423 15.0592H7.33333V18.3925ZM11.0516 13.3925L19 17.2704V6.05881L10.984 9.22584H5.66667V13.3925H11.0516Z" fill="#949494"/>
    </svg>
  </button>

  ${
    isBookmarkEnabled
      ? `<button
    type='button'
    class="button button__icon button__icon--bookmark svg--{{theme}} ${
      theme === 'dark' ? 'svg--dark' : 'svg--light'
    }"
    aria-label="${i18n('bookmark', language)}"
    data-mp-button-bookmark
    data-mp-bookmarked="false"
    data-gtm-event-type="click-element"
    data-gtm-event-category=""
    data-gtm-event-action=""
    data-gtm-event-label="" >

    <svg
      data-gtm-event-type="click-element"
      data-gtm-event-category="unsave"
      data-gtm-event-action=""
      data-gtm-event-label=""
      class="filled" width="24" height="17" viewBox="0 0 12 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        data-gtm-event-type="click-element"
        data-gtm-event-category="unsave"
        data-gtm-event-action=""
        data-gtm-event-label=""
        d="M10 0H1.66667C0.75 0 0 0.833333 0 1.85185V16.6667L5.83333 13.8889L11.6667 16.6667V1.85185C11.6667 0.833333 10.9167 0 10 0Z" fill="#949494"/>
    </svg>

    <svg
      data-gtm-event-type="click-element"
      data-gtm-event-category="save"
      data-gtm-event-action=""
      data-gtm-event-label=""
      class="empty" width="24" height="17" viewBox="0 0 12 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        data-gtm-event-type="click-element"
        data-gtm-event-category="save"
        data-gtm-event-action=""
        data-gtm-event-label=""
        d="M10 0H1.66667C0.75 0 0 0.833333 0 1.85185V16.6667L5.83333 13.8889L11.6667 16.6667V1.85185C11.6667 0.833333 10.9167 0 10 0ZM10 14.1667L5.83333 12.0705L1.66667 14.1667V1.66667H10V14.1667Z" fill="#949494"/>
    </svg>
  </button>`
      : ``
  }
</div>
`

export default actionButtonsTemplate
