import { appendInternalTrackingSearchParams } from '../../../utilities/analytics'
import { setTimeDifference } from '../../../timestamp/utils'
import { header, footer, actions, media, app_theme } from '../feed_item'
import { createAttributes, isMomentTheme, removeSpecialCharacters } from '../utils'
import i18n from 'content/src/assets/utilities/translation'

const language = document.documentElement.lang

// apricot requires 2 titles
const apricotVisionYouTemplate = ({
  link,
  title,
  publisherLogo,
  publisherDarkLogo,
  publisherName,
  thumbnailUrl,
  isSponsored,
  contentType,
  contentTypeText,
  description,
  pub_time,
  isVideoLink,
  ms_news,
  contentApiId,
  duration
}) => `
    <a class="block-item__body" href="${appendInternalTrackingSearchParams(link)}"
        data-gtm-event-category="content_reveal_external_click" 
        data-gtm-event-type="click-element"
        data-gtm-event-action="${link}"
        data-gtm-event-label="${link}"
        data-mp-cct>

        ${media(thumbnailUrl, isVideoLink, duration, 'stub')}

        ${header(
          isSponsored,
          publisherDarkLogo,
          publisherLogo,
          publisherName,
          isVideoLink,
          ms_news
        )}

        <h1 class="block-item__stub-title">${title}</h1>
        <h1 class="block-item__title">${title}</h1>

        ${
          description
            ? `<p class="block-item__description ${language}">${removeSpecialCharacters(
                description
              )}</p>`
            : ''
        }

        ${footer(
          title,
          link,
          isSponsored,
          contentTypeText,
          contentType,
          language,
          'stub',
          publisherDarkLogo,
          publisherLogo,
          publisherName,
          isVideoLink,
          ms_news,
          contentApiId,
          pub_time
        )}
        <div class="block-item__button-container">
            <a href="${appendInternalTrackingSearchParams(
              link
            )}" data-mp-cct data-mp-external-content-link class="block-item__button-link" 
            ${description ? 'style="min-width: calc(100% + 8px);' : ''}">
                <button data-gtm-event-type="click-element" data-gtm-event-label="Continue reading" class="button button-secondary--big">
                <span>continue reading</span>
                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="10.5" cy="10.5" r="10.5" transform="rotate(90 10.5 10.5)" class="block-item__button-svg" fill="#222222"/>
                    <path class="block-item__button-arrow"
                    d="M11.9699 10.5L7.31904 5.75176C7.11167 5.54016 7.11379 5.20161 7.32327 4.99001L8.15484 4.15843C8.36856 3.94684 8.71135 3.94684 8.92294 4.16055L14.8413 10.117C14.9471 10.2228 15 10.3603 15 10.5C15 10.6397 14.9471 10.7772 14.8413 10.883L8.92294 16.8394C8.71135 17.0532 8.36856 17.0532 8.15484 16.8416L7.32327 16.01C7.11379 15.7984 7.11167 15.4598 7.31904 15.2482L11.9699 10.5Z"
                    fill="white" />
                </svg></button>
            </a>
        </div>

`

const momentTemplate = (
  {
    credit,
    title,
    pub_time,
    description,
    link,
    thumbnailUrl,
    isSponsored,
    publisherDarkLogo,
    publisherLogo,
    publisherName,
    isVideoLink,
    ms_news,
    contentApiId,
    body
  },
  optional: any = {}
) =>
  `<div class="block-item__header" style="--bg-image: url(${thumbnailUrl})">
        ${optional.show_back_button ? '<button class="back"></button>' : ''}
        ${header(
          isSponsored,
          publisherDarkLogo,
          publisherLogo,
          publisherName,
          isVideoLink,
          ms_news
        )}
        <a href="${appendInternalTrackingSearchParams(link)}"         
          data-gtm-event-category="content_reveal_external_click" 
          data-gtm-event-type="click-element"
          data-gtm-event-action="${link}"
          data-gtm-event-label="${link}"
          data-mp-cct 
          class="block-item__title ${language}"> 
            ${title}
        </a>
        <div class="block-item__header-footer">
            ${credit ? `<span class="credit">${credit}</span>` : ''}
            ${
              pub_time
                ? `<span class="block-item__timestamp">${
                    !!new Date(pub_time) ? setTimeDifference(pub_time, language) : pub_time
                  }</span>`
                : ''
            }
        ${actions('stub', title, link, contentApiId)}
        </div>
    </div>
    <a href="${appendInternalTrackingSearchParams(link)}"         
    data-gtm-event-category="content_reveal_external_click" 
    data-gtm-event-type="click-element"
    data-gtm-event-action="${link}"
    data-gtm-event-label="${link}"
    data-mp-cct class="block-item__body">
      <div class="block-item__description-wrapper">
      ${
        body
          ? `<div class="full_body ${language}">
              ${body}
            </div>`
          : ''
      }
      ${
        description && !body
          ? `<div class="block-item__description ${language}">
              ${removeSpecialCharacters(description)}
            </div>`
          : ''
      }
            <div class="block-item__engagement-subtitle">
              <button class="button button--primary-small">${i18n('Read More', language)}</button>
            </div>
      </div>
    </a>`

const stubTemplate = (feedItem, optional: any = {}) => {
  return `
      <article
      ${createAttributes(feedItem)}>
     ${
       isMomentTheme(app_theme) || optional.force_moment_template
         ? momentTemplate(feedItem, optional)
         : apricotVisionYouTemplate(feedItem)
     }
    </article>`
}

export default stubTemplate
