import GA4Handler from '../GA4Handler'
import {
  BlockType,
  DataLayerInteractionType,
  DataLayerNonLinkClick,
  DataLayerNonLinkClickElementAction
} from '../interfaces'

const ga4UpgradePromptClickHandler = (section, type, action, detail) => {
  const blockIndex = section?.getAttribute('data-mp-block-index')

  const dataLayerObject: DataLayerNonLinkClick = {
    event: DataLayerNonLinkClickElementAction.impression_click,
    content_type: type,
    block_position: blockIndex,
    block_type: BlockType.upgrade_prompt,
    element: BlockType.upgrade_prompt,
    element_action: action,
    element_detail: detail,
    interaction_type: DataLayerInteractionType.internal
  }

  GA4Handler.pushObject('ga4UpgradePromptClickHandler', dataLayerObject)
}

export default ga4UpgradePromptClickHandler
