import Swiper from 'swiper'
import { Autoplay, Pagination } from 'swiper/modules'

import { neptuneDebug } from '../../../utilities'
import initVisualBriefFotoscape from './fotoscape'

Swiper.use([Pagination, Autoplay])

const applyAdvertisementStyle = () => {
  const html: HTMLElement = document.documentElement
  html.style.height = '100%'

  const body: HTMLElement = document.querySelector('body')
  body.style.height = '100%'

  const general: HTMLElement = document.querySelector('.general-container')
  general.style.height = '100%'

  const main: HTMLElement = document.querySelector('.main-container')
  main.style.height = '100%'

  const block: HTMLElement = document.querySelector('.block--ad')

  block.style.position = 'absolute'
  block.style.bottom = '0px'
  block.style.marginLeft = 'auto'
  block.style.zIndex = '10'
  block.style.width = '100%'
  block.style.paddingBottom = '0'
}

const initVisualBrief = async () => {
  const block: HTMLElement = document.querySelector('.block--visual-brief')

  if (!block) {
    return
  }

  const swiper: HTMLElement = block.querySelector('.swiper')

  if (!swiper) {
    neptuneDebug('initVisualBrief swiper not found')
    return
  }

  const pagination: HTMLElement = block.querySelector('.ticker-pagination')
  const autoplayEnabled = swiper.dataset.mpSwiperAutoplayEnabled === 'true'
  const autoplayDelay = parseInt(swiper.dataset.mpSwiperAutoplayDelay) * 1000 || 5000

  const config: any = {
    loop: false,
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1,
        spaceBetween: 0
      },
      360: {
        slidesPerView: 1,
        spaceBetween: 0
      },
      370: {
        slidesPerView: 1,
        spaceBetween: 0
      },
      380: {
        slidesPerView: 1,
        spaceBetween: 0
      },
      400: {
        slidesPerView: 1,
        spaceBetween: 0
      },
      415: {
        slidesPerView: 1,
        spaceBetween: 0
      }
    },
    pagination: {
      el: pagination,
      clickable: false
    }
  }

  if (autoplayEnabled) {
    config.autoplay = {
      delay: autoplayDelay
    }
  }

  const swipe = new Swiper(swiper, config)

  await initVisualBriefFotoscape()
  // await initEndOfContent()

  applyAdvertisementStyle()

  // hack to make this loop work with dynamoc content
  // https://github.com/nolimits4web/swiper/issues/2629
  // swipe.loopDestroy()
  // swipe.loopCreate()
}

export default initVisualBrief
