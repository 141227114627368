import URLSearchParams from '@ungap/url-search-params' // Polyfill for < v49

import { neptuneDebug } from '../../../utilities'

export interface schedule {
  element: HTMLTemplateElement
  startDay: string
  startTime: string
  endDay: string
  endTime: string
  isDefault: string
}

// const scheduleBlockInit = (date = new Date()) => {
const scheduleBlockInit = (date = null) => {
  if (date === null) {
    date = new Date()
  }

  const w = window as any
  const params = new URLSearchParams(w?.location?.search)
  if (params.has('today')) {
    neptuneDebug(`date override: ${params.get('today')}`)
    date = new Date(params.get('today'))
  }

  const schedules = generateSetup()

  neptuneDebug('date? %o', date)
  neptuneDebug('schedules complete: %o', schedules)

  for (const schedule of Object.values(schedules) as any) {
    let defaultBlock = {} as schedule
    let foundCustom = false

    for (const s of Object.values(schedule) as any) {
      if (s.isDefault === 'true') {
        // Don't process default block.
        defaultBlock = s
        continue
      }

      // Clone custom into the document if a matching scheduled template was found.
      if (processScheduledTemplate(date, s)) {
        const clone = s.element.content.cloneNode(true) as HTMLElement
        s.element.parentNode.insertBefore(clone, s.element)
        foundCustom = true
      }
    }

    // Clone the default into the document if no custom scheduled matched.
    if (!foundCustom) {
      const clone = defaultBlock.element.content.cloneNode(true) as HTMLElement
      defaultBlock.element.parentNode.insertBefore(clone, defaultBlock.element)
    }
  }
}

export const dayToNumber = (day: string) => {
  switch (day.toLowerCase()) {
    case 'sunday':
      return 0
    case 'monday':
      return 1
    case 'tuesday':
      return 2
    case 'wednesday':
      return 3
    case 'thursday':
      return 4
    case 'friday':
      return 5
    case 'saturday':
      return 6
    default:
      throw new Error(`day '${day}' not valid`)
  }
}

export const generateSetup = () => {
  const setup = {}
  const templates = Array.from(document.querySelectorAll('template[data-schedule-id]'))

  // Create list of blocks.
  for (const t of templates) {
    const id = t.getAttribute('data-schedule-id')
    setup[id] = setup[id] || []

    setup[id].push({
      element: t,
      startDay: t.getAttribute('data-schedule-start-day'),
      startTime: t.getAttribute('data-schedule-start-time'),
      endDay: t.getAttribute('data-schedule-end-day'),
      endTime: t.getAttribute('data-schedule-end-time'),
      isDefault: t.getAttribute('data-schedule-default')
    } as schedule)
  }

  return setup
}

export const processScheduledTemplate = (date, s) => {
  const startDay = dayToNumber(s.startDay)
  const startTime = parseInt(s.startTime)
  const endDay = dayToNumber(s.endDay)
  const endTime = parseInt(s.endTime)

  const day = date.getDay()
  const time = date.getHours() * 100 + date.getMinutes()

  neptuneDebug(`day: ${day} >= startDay: ${startDay} && day: ${day} <= endDay: ${endDay}`)
  neptuneDebug(`FIRST: ${day} == ${startDay} && ${time} >= ${startTime}`)
  neptuneDebug(`SECOND: ${day} > ${startDay} && ${day} < ${endDay}`)
  neptuneDebug(`THIRD: ${day} == ${endDay} && ${time} >= ${startTime} && ${time} <= ${endTime}`)

  // This works from sunday to saturday.
  if (day >= startDay && day <= endDay) {
    if (day == startDay && time >= startTime) {
      neptuneDebug('HIT: first branch')
      return true
      // customBlock = s
    } else if (day > startDay && day < endDay) {
      neptuneDebug('HIT: second branch')
      return true
    } else if (day == endDay && time >= startTime && time <= endTime) {
      neptuneDebug('HIT: third branch')
      return true
    }
  }

  return false
}

export default scheduleBlockInit
