import i18n from '../../../../../../utilities/translation'
const language = document.documentElement.lang

const feedTemplate = () => `
  <div class="swiper--trending-carousel" >
    <div class='block-item__actions'>
      <button id="personalize" data-mp-three-dots-button class="button button__icon button__icon--personalize svg--dark" type="button"
      data-mp-share-title="" data-mp-share-link="" data-mp-content-api-id=""
      data-gtm-id="three_dots" aria-label="${i18n('Three Dots', language)}"
      data-modal-custom-trigger-target-personalization='modal--personalize' data-gtm-event-type="click-element"
      data-gtm-event-category="Three Dots" data-gtm-event-action="" data-gtm-event-label="">
      <div
        class="icon-three-dots icon-three-dots-swiper"
        data-gtm-id="three_dots"
        data-gtm-event-type="click-element"
        data-gtm-event-category="Three Dots"
        data-gtm-event-label=""></div>
      </button>
    </div>
    
    <div class="swiper-wrapper"></div>

    <div class="swiper-pagination"></div>
  </div>`

export default feedTemplate
