import axios from 'axios'
import captureMessage from '../../../sentry/captureMessage'
import feedItemTemplate from '../shared/templates/feed_item'
import initActionButtons from '../action_buttons/action_buttons'
import * as modal from '../utilities/modal/modal'
import { fetchLicensedFotoscapeArticleJson, generateLicensedFotoscapeContext } from '../fotoscape/full_page_article'
import { LicensedFotoscapeArticle } from '../fotoscape/licensed_fotoscape_article'
import { formatDestination, checkLinksValidity } from '../fotoscape/helpers/utils'

const platformLinkDestination = document.documentElement.getAttribute('data-mp-fotoscape-link-destinaton')

const initFeedItem = async () => {
  try {
    const searchParams = new URLSearchParams(location.search)
    const feedItemId = searchParams.get('feed_item')
    const fotoscapeId = searchParams.get('fotoscape_id')

    if (!feedItemId && !fotoscapeId) {
      return
    }

    let feedHTML = ''

    const blockWithLayout = document.querySelector('[data-feed-item-block-external]')

    if (!blockWithLayout) {
      return
    }

    const blockLinkDestination = blockWithLayout.getAttribute('data-mp-fotoscape-link-destinaton')
    const layout = blockWithLayout?.getAttribute('data-mp-block-layout')
    const block = document.querySelector('[data-feed-item-block]')
    const { feed_item_api } = (window as any).mp_globals.env

    if (!block) return

    if (feedItemId) {
      const response = await axios.get(`${feed_item_api}/${feedItemId}`)
      feedHTML = feedItemTemplate({ ...response.data, layout })
    } else {
      if (fotoscapeId) {
        const fetchedArticle = await fetchLicensedFotoscapeArticleJson(fotoscapeId)
        const language = document.documentElement.getAttribute('lang')
        const article = await LicensedFotoscapeArticle.generateLicensedFotoscapeArticleLookbook(
          language,
          fetchedArticle
        )

        if (!article.url && !article.sourceLink) {
          captureMessage(`Feed Item Fotoscape ${article.uid} Is Invalid: No Links`)
        }

        checkLinksValidity(article)
        formatDestination(article, blockLinkDestination, platformLinkDestination)
        const context = generateLicensedFotoscapeContext(article)

        feedHTML = feedItemTemplate({ ...context, layout })
      }
    }

    block.innerHTML = feedHTML
    modal.init({ containerSelector: `[data-feed-item-block]` })
    initActionButtons(`[data-feed-item-block]`)
  } catch (e) {
    console.error('cannot get shared article', e.message)
    ;(window as any).Sentry.captureMessage(`cannot get feed item: ${e.message}`)
  }
}

export default initFeedItem
