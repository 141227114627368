/**
 * @todo Move this file as it's not limited to portals. --hrivera
 */
import moment from 'moment'

export const formatDate = (language, localLocale) => {
  switch (language) {
    case 'es':
      return `${localLocale.format('D')} de ${localLocale.format('MMM')}`
    case 'de-at':
      return `${localLocale.format('D')}. ${localLocale.format('MMMM')}`
    default:
      return `${localLocale.format('ddd')}, ${localLocale.format('MMM')} ${localLocale.format('D')}`
  }
}

const isEnglish = ['en-us', 'en-in', 'en-at', 'en-it', 'en-tn', 'en-ph']
const languageMap = {
  'de-at': 'de',
  'fr-ca': 'fr',
  'es-mx': 'es'
}

const mapLanguage = language => {
  if (languageMap[language]) return languageMap[language]
  if (isEnglish.includes(language)) return 'en'

  return language
}

const init = language => {
  const localLocale = moment()

  localLocale.locale(mapLanguage(language))

  return localLocale
}

export default init
