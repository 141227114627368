import { handleClick } from './utils'
import { setEventListenersActionButtons } from '../../utilities/DOM_events'

const initLikeDislikePersonalizeButtons = () => {
  const modalContainer = document.getElementById('modal--personalize') as HTMLElement

  if (!modalContainer) return
  const likeButton = modalContainer.querySelector('[data-mp-button-like]') as HTMLElement
  const dislikeButton = modalContainer.querySelector('[data-mp-button-dislike]') as HTMLElement

  if (!likeButton || !dislikeButton) return

  setEventListenersActionButtons(likeButton, handleClick, 'like')
  setEventListenersActionButtons(dislikeButton, handleClick, 'dislike')
}

export default initLikeDislikePersonalizeButtons
