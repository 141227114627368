import { FeedItemContext } from 'content/src/lib/feed/interfaces'
import defaultTemplate from './default'
import edgeDescriptionTemplate from './edge_description'

const edgeTileTemplate = (feedItem: FeedItemContext) => {
  const isTheFirstArticle = feedItem.index <= 1
  const template = isTheFirstArticle ? edgeDescriptionTemplate(feedItem) : defaultTemplate(feedItem)
  const layoutName = isTheFirstArticle ? 'block--edge-description' : 'block--tile'

  return `
    <section class="block ${layoutName}">
    ${template}
    </section>
    `
}

export default edgeTileTemplate
