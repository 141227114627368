import URLSearchParams from '@ungap/url-search-params'
import { GA4UserAgent } from '../../../../types/useragent'
import { ga4Debug } from '../../../utilities'
import GA4Handler from './GA4Handler'
import { BlockType, DataLayerContentSourceType, isSponsored } from './interfaces'
import { androidAID, appVersion, mpid } from '../device'
import { getMode } from '../bridge_interface/bridge_interface'
import {
  LS_BRIDGE_APP_VERSION_MP_INTERNAL,
  LS_BRIDGE_MODE_INTERNAL,
  LS_BRIDGE_UUID_INTERNAL
} from '../bridge_interface/constants'
import GA4Block from './blocks/GA4Block'
import { getPartner, getPlatform } from './utils'

/**
 * Loop through blocks and wire up supported types.
 *
 * Note: fotoscapes has to be wired directly during rendering, see fotoscape/fotoscapes.ts
 */
const initDataLayer = async () => {
  const blocks = Array.from(document.querySelectorAll('.block'))

  blocks.forEach(blockElement => {
    const element = blockElement as HTMLElement
    const blockType = element?.getAttribute('data-mp-block-type')
    const blockPosition = element?.getAttribute('data-mp-block-index')

    switch (blockType) {
      case BlockType.discover_bar:
        GA4Block.setupDiscoverBar(element, blockPosition)
        break

      case BlockType.google_search_bar:
        const form = element.querySelector('form')

        // @evan How do we handle the form submission?
        form.addEventListener('submit', () => {
          const dataLayerEvent = {
            event: 'click_element',
            block_position: blockPosition,
            content_type: 'search'
          }

          GA4Handler.pushObject('initDataLayer:google_search_bar form submission', dataLayerEvent)
        })
        break

      case BlockType.horoscope_carousel_block:
      case BlockType.horoscope_discover_bar:
      case BlockType.portal_navigation_bar:
      case BlockType.section_navigation_bar:
        GA4Block.setupCarousel(element)
        break

      // These are generic links with some optional customization.
      case BlockType.header_block_alert:
      case BlockType.horoscope_block:
      case BlockType.horoscope_details_block:
      case BlockType.horoscope_details_group_block:
      case BlockType.horoscope_group_block:
      case BlockType.house_deal_block:
      case BlockType.image_only_block:
      case BlockType.portal_footer_block:
      case BlockType.promo_card_block:
        GA4Block.setupGeneric(blockType, element)
        break
    }
  })
}

/**
 * This gets called in universal setup -- it has to get called higher up in the stack.
 */
export const ga4HandleInitConsent = () => {
  const root = document.querySelector('html')
  const event = {
    event: 'gtm.init_consent',
    partner: root.getAttribute('data-gtm-partner'),
    mode: getMode() || 'webview',
    app_version: appVersion(),
    architecture: 'neptune',
    aaid: androidAID(),
    mpid: mpid(),
    user_agent: navigator.userAgent
  }

  GA4Handler.pushObject('ga4HandleInitConsent', event)
}

export const ga4HandleNeptunePageView = (extra: GA4UserAgent = null) => {
  const event: any = {
    aaid: androidAID() || null,
    appVersion: localStorage.getItem(LS_BRIDGE_APP_VERSION_MP_INTERNAL) || null,
    event: 'neptune_page_view',
    mode: localStorage.getItem(LS_BRIDGE_MODE_INTERNAL) || null,
    mpid: mpid() || null,
    partner: getPartner() || null,
    platform: getPlatform() || null,
    userId: localStorage.getItem(LS_BRIDGE_UUID_INTERNAL) || null,
    user_agent: navigator.userAgent,
    user_agent_browser: extra?.browser || null,
    user_agent_browser_version: extra?.browserVersion || null,
    user_agent_mobile: extra?.mobile !== undefined ? extra.mobile : null,
    user_agent_model: extra?.model || null,
    user_agent_platform: extra?.platform || null,
    user_agent_platform_version: extra?.platformVersion || null
  }

  GA4Handler.pushObject('ga4HandleNeptunePageView', event)
}

/**
 * This is used to prevent link navigation while debugging.  It's enabled when
 * ga4_cancel_navigation is set in the querystring.
 */
export const ga4DebugCancelLinkNavigation = (e: Event) => {
  const w = window as any
  const params = new URLSearchParams(w?.location?.search)

  if (params.has('ga4_cancel_navigation')) {
    ga4Debug('GA4 ga4DebugCancelLinkNavigation', {})
    e.cancelBubble = true
    e.preventDefault()
  }
}

export const getContentType = (blockType: BlockType): DataLayerContentSourceType => {
  return isSponsored(blockType) ? DataLayerContentSourceType.sponsored : DataLayerContentSourceType.editorial
}

export default initDataLayer
