import { getGdprPlatforms } from 'lib/process'

const getGDPRDomainFromMPGlobals = (platform: string) => {
  switch (window?.mp_globals?.env?.environment) {
    case 'live':
      return `${platform}.myfirstly.com`
    default:
      return `${platform}.sandbox.mobileposse.com`
  }
}

/**
 * Returns the domain that should be used for loading assets.
 */
export const getAssetDomainByMPGlobals = () => {
  const platform = window?.mp_globals?.env?.platform

  if (platform && getGdprPlatforms().includes(platform as any)) {
    return getGDPRDomainFromMPGlobals(platform)
  } else {
    if (window?.mp_globals?.env?.environment === 'live') {
      return 'neptune.mobileposse.com'
    }

    return 'sandbox.mobileposse.com'
  }
}
