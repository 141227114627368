import { FotoscapeImageAspectRatio } from '../interfaces/FotoscapeDailyArticle'

export const getAspectRatioData = (layout: string): FotoscapeImageAspectRatio => {
  switch (layout) {
    case 'large-photocard':
    case 'small-photocard-flat':
    case 'small-photocard':
      return { ratio: '1:1', width: 360, height: 360 }
    case 'tile':
    case 'quiz-tile':
    case 'games-tile':
      return { ratio: '9:4', width: 270, height: 160 }
    case 'edge':
    case 'trending-carousel':
    case 'daily-top':
    case 'daily-top-minimal':
      return { ratio: '9:4', width: 450, height: 200 }

    case 'list-medium':
    case 'list-small':
    case 'list-small-sponsored':
      return { ratio: '1:1', width: 120, height: 120 }
    default:
      return { ratio: '1:1', width: 360, height: 360 }
  }
}
