import { getAssetDomainByMPGlobals } from 'lib/deployment'
import { FeedItemContext } from 'lib/feed/interfaces'
import { appendInternalTrackingSearchParams } from '../../../utilities/analytics'
import { header, footer, language, media, bodyLogo } from '../feed_item'
import { createAttributes } from '../utils'
import { cropImage } from '../../../utilities/imageManipulation'

const quizCarouselTemplate = (feedItem: FeedItemContext) => {
  const {
    link,
    title,
    duration,
    publisherLogo: publisherLogoImg,
    publisherDarkLogo: publisherLogoImgDark,
    publisherName,
    isVideoLink,
    thumbnailUrl,
    isSponsored,
    contentType,
    contentTypeText,
    index,
    ms_news,
    contentApiId,
    pub_time,
    layout
  } = feedItem
  const publisherLogo =
    publisherLogoImg &&
    cropImage(publisherLogoImg, getAssetDomainByMPGlobals(), {
      size: 'no-whitespace',
      protocol: 'https:'
    })
  const publisherDarkLogo =
    publisherLogoImgDark &&
    cropImage(publisherLogoImgDark, getAssetDomainByMPGlobals(), {
      size: 'no-whitespace',
      protocol: 'https:'
    })

  return `
  <article
  ${createAttributes(feedItem)}
  data-gtm-scroll-count=${index}>
  <a class="block-item__body"
     href="${appendInternalTrackingSearchParams(link)}"
     data-mp-cct>
    ${media(thumbnailUrl, isVideoLink, duration, layout)}
  
    ${header(
      isSponsored,
      publisherDarkLogo,
      publisherLogo,
      publisherName,
      isVideoLink,
      ms_news,
      layout
    )}
  
    <h1 class="block-item__title ${language}">
        <span class="block-item__headline">${title}</span>
        <span class="block-item__subtitle ${language}"></span>
    </h1>

    ${bodyLogo(ms_news, isSponsored, publisherLogo, publisherName, isVideoLink, publisherDarkLogo)}
    </a>

    ${footer(
      title,
      link,
      isSponsored,
      contentTypeText,
      contentType,
      language,
      layout,
      publisherDarkLogo,
      publisherLogo,
      publisherName,
      isVideoLink,
      ms_news,
      contentApiId,
      pub_time
    )}
    </article>
  `
}

export default quizCarouselTemplate
