import i18n from 'content/src/assets/utilities/translation'
import { actions } from '../shared/templates/feed_item'
import { getAssetDomainByMPGlobals } from 'lib/deployment'

const app_theme = document.documentElement.getAttribute('data-mp-app-theme')
const language = document.documentElement.lang

const momentTemplate = (horoscopeSign, horoscopeType, horoscopeContent, leadsToChildPage, name) => {
  return ` <li class="block-item swiper-slide"
    data-mp-content-type="horoscope_carousel_item"
    data-mp-horoscope-sign="${horoscopeSign}"
    data-mp-horoscope-type="${horoscopeType}"
    data-mp-share-link="horoscope-${horoscopeSign}.html"
    data-mp-share-horoscope-sign="${i18n(horoscopeSign, language)}"
    data-mp-share-horoscope-type="${i18n(horoscopeType, language)}"
    data-mp-share-horoscope-text="${horoscopeContent}"
    data-gtm-article-type="editorial"
    data-gtm-widget-id="horoscope" data-gtm-event-type="click"
    data-gtm-share-link="horoscope-${horoscopeSign}.html"
    data-gtm-share-horoscope-sign="${i18n(horoscopeSign, language)}"
    data-gtm-share-horoscope-type="${i18n(horoscopeType, language)}"
    data-gtm-share-horoscope-text="${horoscopeContent}">

    <a href="${
      leadsToChildPage
        ? `horoscope-${horoscopeSign}.html?isChild=true`
        : `horoscope-${horoscopeSign}.html`
    }">
    <div class="block-item__body">
    <div class="left">
        <div class="horoscope-name">
        ${name}
        </div>
        <div class="horoscope-text">
        ${horoscopeContent}
        </div>
    </div>
    <div
        class="right icon icon--${horoscopeType}-dark icon--${horoscopeSign}-dark background-color--${horoscopeSign}"></div>
    </div>
    </a>
    </li>`
}

const defaultTemplate = (
  horoscopeSign,
  horoscopeType,
  horoscopeContent,
  leadsToChildPage,
  name,
  dates
) => {
  const horoscopeLink = leadsToChildPage
    ? `horoscope-${horoscopeSign}.html?isChild=true`
    : `horoscope-${horoscopeSign}.html`

  return `<li class="block-item"
    data-mp-content-type="horoscope_carousel_item"
    data-mp-share-link="horoscope-${horoscopeSign}.html"
    data-mp-share-horoscope-sign="${i18n(horoscopeSign, language)}"
    data-mp-share-horoscope-type="${i18n(horoscopeType, language)}"
    data-mp-share-horoscope-text="${horoscopeContent}"
    data-gtm-widget-id="horoscope" data-gtm-event-type="click"
    data-gtm-share-link="horoscope-${horoscopeSign}.html"
    data-gtm-share-horoscope-sign="${i18n(horoscopeSign, language)}"
    data-gtm-share-horoscope-type="${i18n(horoscopeType, language)}"
    data-gtm-share-horoscope-text="${horoscopeContent}">

    <a href="${horoscopeLink}">
      <div class="block-item__header">
        <div class="horoscope-icon icon icon--${horoscopeSign}"></div>
        <div class="block-item__header-text">
            <div class="title">${name}</div>
            <div class="subtitle">${dates}</div>
        </div>
      </div>
      <div class="block-item__body horoscope-text">${horoscopeContent}</div>
    </a>

    <footer class="block-item__footer">
    ${actions('horoscope_carousel_block', horoscopeSign, horoscopeLink, '')}
    </footer>
    </li>`
}

const carouselItemTemplate = (
  horoscopeSign,
  horoscopeType,
  horoscopeContent,
  leadsToChildPage,
  name,
  dates
) => {
  if (app_theme === 'moment') {
    return momentTemplate(horoscopeSign, horoscopeType, horoscopeContent, leadsToChildPage, name)
  }
  return defaultTemplate(
    horoscopeSign,
    horoscopeType,
    horoscopeContent,
    leadsToChildPage,
    name,
    dates
  )
}

export default carouselItemTemplate
