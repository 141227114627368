export enum NeptuneAttributeKey {
  // Internal attributes.
  blockIndex = 'mpBlockIndex',
  blockLayout = 'mpBlockLayout',
  blockType = 'mpBlockType',
  publisherName = 'mpPublisherName',
  horoscopeSign = 'mpHoroscopeSign',
  horoscopeType = 'mpHoroscopeType',
  shareHoroscopeSign = 'mpShareHoroscopeSign',
  shareHoroscopeText = 'mpShareHoroscopeText',
  shareTitle = 'mpShareTitle',

  // GTM attributes.
  gtmExperimentId = 'gtmExperimentId',
  gtmExperimentType = 'gtmExperimentType',
  gtmScrollCount = 'gtmScrollCount'
}

export class NeptuneAttribute {
  static set(element: HTMLElement, key: NeptuneAttributeKey, value: string) {
    element.dataset[key] = value
  }

  static get(element: HTMLElement, key: NeptuneAttributeKey) {
    return element.dataset[key]
  }

  static toAttributeName(key: NeptuneAttributeKey) {
    const k = key.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase()

    return `data-${k}`
  }
}
