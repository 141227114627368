import { ga4Debug } from '../../../utilities'
import { NeptuneAttribute, NeptuneAttributeKey } from '../../lib/NeptuneAttribute'
import { lazyload } from '../utilities/DOM_events'
import { GA4ElementValues } from './GA4ElementValues'
import ga4ParseUrl, { GA4Link } from './generic/ga4ParseUrl'
import { ga4DebugCancelLinkNavigation } from './index'
import {
  BlockType,
  DataLayerExternalClickEvent,
  DataLayerImpressionEvent,
  DataLayerEventType,
  DataLayerNonLinkClickElementAction,
  getBlockInteractionType,
  getSponsoredType,
  DataLayerBaseEvent,
  DataLayerOptionalEvent
} from './interfaces'

class GA4Handler {
  static clickCreateObject(target?: HTMLElement, optional = {}) {
    const values = new GA4ElementValues(target)
    const ga4Link: GA4Link = ga4ParseUrl(target)

    const dataLayerEvent: DataLayerExternalClickEvent = {
      block_layout: values.blockLayout || null,
      block_position: values.blockIndex || null,
      block_type: values.blockType as BlockType,
      content_type: getSponsoredType(values.blockType),
      domain: ga4Link.url.host,
      element: values.blockType,
      event: DataLayerEventType.impression_click,
      interaction_type: ga4Link.interactionType,
      path: `${ga4Link.url.pathname}${ga4Link.url.search}${ga4Link.url.hash}`,
      publisher: values.publisher || null,
      title: values.title || target.innerText || null,

      ...optional
    }

    if (values.experimentID) {
      dataLayerEvent.experiment_group = values.experimentType
      dataLayerEvent.experiment_id = values.experimentID
      dataLayerEvent.experiment_type = values.experimentType
    }

    dataLayerEvent['creator'] = 'clickCreateObject'

    return dataLayerEvent
  }

  static clickHandleEvent(e: Event, optional = {}, target?: HTMLElement) {
    target = target || (e.currentTarget as HTMLElement)

    ga4DebugCancelLinkNavigation(e)

    const dataLayerObject = GA4Handler.clickCreateObject(target, optional)

    switch (dataLayerObject.block_type) {
      case BlockType.discover_bar:
        delete dataLayerObject['publisher']
        break
      case BlockType.portal_footer_block:
        delete dataLayerObject.title
        break
      case BlockType.horoscope_block:
      case BlockType.horoscope_carousel_block:
      case BlockType.horoscope_details_block:
      case BlockType.horoscope_details_group_block:
      case BlockType.horoscope_group_block:
        dataLayerObject.title = NeptuneAttribute.get(
          target.parentElement,
          NeptuneAttributeKey.shareHoroscopeSign
        ).replace(/\n.*$/gs, '')
        break
      case BlockType.horoscope_discover_bar:
        dataLayerObject.title = NeptuneAttribute.get(target, NeptuneAttributeKey.shareHoroscopeSign)
        break
      default:
        break
    }

    if (!dataLayerObject['publisher']) {
      dataLayerObject['publisher'] = null
    }

    if (dataLayerObject['title']) {
      dataLayerObject['title'] = dataLayerObject['title'].replace(/\n/gs, '')
      dataLayerObject['title'] = dataLayerObject['title'].replace(/[^a-zA-Z0-9 ',.]/gs, ' ')
      dataLayerObject['title'] = dataLayerObject['title'].trim()
    } else {
      dataLayerObject['title'] = null
    }

    if (!dataLayerObject['element_action']) {
      dataLayerObject['element_action'] = DataLayerNonLinkClickElementAction.impression_click
    }

    if (!dataLayerObject['element_detail']) {
      dataLayerObject['element_detail'] = null
    } else {
      dataLayerObject['element_detail'] = dataLayerObject['element_detail'].replace(/[^a-zA-Z0-9 ',.]/gs, ' ')
    }

    this.pushObject('GA4Handler.clickHandleEvent', dataLayerObject)
  }

  static createGenericEvent<T extends DataLayerBaseEvent>(
    target?: HTMLElement,
    optional: DataLayerOptionalEvent = {},
    link: HTMLElement = null
  ): T {
    const ga4Link = link ? ga4ParseUrl(link) : new GA4Link()
    const values = new GA4ElementValues(target)

    const dataLayerObject = {
      block_layout: values.blockLayout,
      block_position: values.blockIndex,
      block_type: values.blockType as BlockType,
      content_type: getSponsoredType(values.blockType),
      element: values.blockType,
      event: DataLayerEventType.impression_view,
      interaction_type: link ? ga4Link.interactionType : getBlockInteractionType(values.blockType),
      ...optional
    }

    return dataLayerObject as T
  }

  static createImpressionObject(target?: HTMLElement, optional = {}, link: HTMLElement = null) {
    const dataLayerObject = this.createGenericEvent<DataLayerImpressionEvent>(target, optional, link)
    const values = new GA4ElementValues(target)

    dataLayerObject.carousel_position = null

    // Add custom attributes for experiments.
    if (values.experimentID) {
      dataLayerObject.experiment_group = values.experimentType
      dataLayerObject.experiment_id = values.experimentID
      dataLayerObject.experiment_type = values.experimentType
    }

    if (values.publisher) {
      dataLayerObject.publisher = values.publisher
    }

    if (!dataLayerObject.element_action) {
      dataLayerObject.element_action = DataLayerEventType.impression_view
    }

    if (!dataLayerObject.domain) {
      dataLayerObject.domain = null
    }

    if (!dataLayerObject.path) {
      dataLayerObject.path = null
    }

    if (!dataLayerObject.publisher) {
      dataLayerObject.publisher = null
    }

    switch (values.blockType) {
      case BlockType.emoji_feedback_block:
        dataLayerObject.title = (values.section.querySelector('.block--emoji-feedback__title') as HTMLElement).innerText
        break
      case BlockType.horoscope_block:
      case BlockType.horoscope_carousel_block:
      case BlockType.horoscope_details_block:
      case BlockType.horoscope_details_group_block:
      case BlockType.horoscope_group_block:
        dataLayerObject.title = NeptuneAttribute.get(
          target.parentElement,
          NeptuneAttributeKey.shareHoroscopeSign
        ).replace(/\n.*$/gs, '')
        break
      case BlockType.horoscope_discover_bar:
        dataLayerObject.title = NeptuneAttribute.get(target, NeptuneAttributeKey.shareHoroscopeSign)
        break
      case BlockType.portal_footer_block:
        dataLayerObject.title = null
        break
      case BlockType.quotes_widget_block:
        dataLayerObject.title = values.section.querySelector('blockquote').innerText
        break
      case BlockType.words_widget_block:
        dataLayerObject.title = values.section.querySelector('h1').innerText
        break
    }

    if (dataLayerObject.title) {
      dataLayerObject.title = dataLayerObject.title.replace(/[^a-zA-Z0-9 ',.]/gs, ' ')
      dataLayerObject.title = dataLayerObject.title.trim()
    } else {
      dataLayerObject.title = null
    }

    if (!dataLayerObject.element_detail) {
      dataLayerObject.element_detail = null
    } else {
      dataLayerObject.element_detail = dataLayerObject.element_detail.replace(/[^a-zA-Z0-9 ',.]/gs, ' ')
    }

    return dataLayerObject
  }

  static impressionHandleEvent(target: HTMLElement, optional = {}, threshold = 0.7, link: HTMLElement = null) {
    lazyload(
      target,
      () => {
        const dataLayerObject = this.createImpressionObject(
          target,
          {
            ...optional,
            creator: 'impressionHandleEvent'
          },
          link
        )

        this.pushObject('GA4Handler.impressionHandleEvent', dataLayerObject)
      },
      {
        threshold: threshold // This might need to be tweaked for carousel.
      }
    )
  }

  static pushObject(functionName: string, dataLayerObject: any) {
    const o = Object.keys(dataLayerObject)
      .sort()
      .reduce((acc, key) => {
        acc[key] = dataLayerObject[key]
        return acc
      }, {})

    ga4Debug(`GA4 ${functionName}: ${dataLayerObject.block_type || 'no-block'} pushing ${dataLayerObject.event}: `, o)

    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(o)
  }
}

export default GA4Handler
