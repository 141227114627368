import { dataLayerPush } from '../utilities/analytics'

const groupExperimentsById = () => {
  const allExperiments = {}
  // templates is set to [] if not experiment found
  const templates = Array.from(document.querySelectorAll('template[data-experiment-id]'))

  for (const elem of templates) {
    const experimentId = elem.attributes['data-experiment-id'].value
    allExperiments[experimentId] = allExperiments[experimentId] || []
    allExperiments[experimentId].push(elem)
  }

  // returns {} if no experiment found
  return allExperiments
}

const experimentByType = (experiments, type) =>
  experiments.find(experiment => experiment.attributes['data-experiment-type'].value === type)

const getVariantPercent = experiment =>
  parseInt(experiment.attributes['data-experiment-variant-percent'].value, 10)

const trackExperiment = (experimentId: string, group: 'control' | 'variant') => {
  dataLayerPush({ event: 'experiment', experimentId, group })
}

const setExperimentDataAttributes = (content: any, id: string, selectedExperimentType: string) => {
  // See https://github.com/mobileposse/neptune/issues/3914
  Array.from(content.children).forEach((child: any) => {
    child.setAttribute('data-gtm-experiment-id', id)
    child.setAttribute('data-gtm-experiment-type', selectedExperimentType)
  })
}

const initExperiment = () => {
  const allExperiments = groupExperimentsById()

  for (const [id, experiments] of Object.entries(allExperiments)) {
    const group = Math.random() * 100

    const variantPercent = getVariantPercent(experiments[0])

    const selectedExperimentType = group <= variantPercent ? 'variant' : 'control'
    const selectedExperiment = experimentByType(experiments, selectedExperimentType)

    trackExperiment(id, selectedExperimentType)

    const content = selectedExperiment.content.cloneNode(true)

    setExperimentDataAttributes(content, id, selectedExperimentType)

    selectedExperiment.parentNode.insertBefore(content, selectedExperiment)
  }

  // Certain blocks (jokes, memes) require async loading of content, experiment support must be initialized first.
  const experimentLoadedEvent = new CustomEvent('experiment:initialized')
  document.dispatchEvent(experimentLoadedEvent)
  window.experimentsLoaded = true
}

export default initExperiment
