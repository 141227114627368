import Swiper from 'swiper'
import { Autoplay, Pagination } from 'swiper/modules'

import initWeather from '../ticker/weather'
import initFotoscape from '../ticker/fotoscape'
import { GA4Ticker } from '../ga4_support/blocks/GA4Ticker'
import { setBlockAnimation } from '../../../utilities/animation'

Swiper.use([Pagination, Autoplay])

const initTicker = async () => {
  const swiper: HTMLElement = document.querySelector('.block--ticker .swiper')

  if (!swiper) {
    return
  }

  const pagination: HTMLElement = document.querySelector('.ticker-pagination')
  const hasAuto = swiper.className.includes('autoplay-enabled')

  const config: any = {
    loop: true,
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1.1,
        spaceBetween: 12
      },
      360: {
        slidesPerView: 1.2,
        spaceBetween: 12
      },
      370: {
        slidesPerView: 1.25,
        spaceBetween: 12
      },
      380: {
        slidesPerView: 1.3,
        spaceBetween: 12
      },
      400: {
        slidesPerView: 1.35,
        spaceBetween: 12
      },
      415: {
        slidesPerView: 1.4,
        spaceBetween: 12
      }
    },
    pagination: {
      el: pagination,
      clickable: true
    }
  }

  if (hasAuto) {
    config.autoplay = {
      delay: 3000
    }
    config.speed = 1000
  }

  await initWeather()
  await initFotoscape()

  const blocks = document.querySelectorAll('section.block--ticker')

  Array.from(blocks).forEach((block: any) => {
    block.classList.remove('skeleton')
    GA4Ticker.wireImpressionBlock(block)
  })

  // hack to make this loop work with dynamoc content
  // https://github.com/nolimits4web/swiper/issues/2629
  const swipe = new Swiper(swiper, config)
  swipe.loopDestroy()
  swipe.loopCreate()

  let newSwiper

  swiper.addEventListener('keydown', e => {
    if (!swiper.getAttribute('tab-clicked')) {
      swiper.setAttribute('tab-clicked', 'true')
      swipe.destroy()

      newSwiper = new Swiper(swiper, { ...config, loop: false, autoplay: false })
    }

    if (e.shiftKey) {
      newSwiper.slidePrev(100, false)
    } else {
      newSwiper.slideNext(100, false)
    }
  })

  document.querySelectorAll('.block--ticker').forEach(tickerBlock => {
      const tickerItemList = tickerBlock.querySelectorAll('.ticker-block-item')
      if(tickerItemList.length) {
        tickerItemList.forEach(tickerItemBlock => {
          setBlockAnimation(tickerItemBlock)
        })
      }
    })
}

export default initTicker
