import ColorThief from 'colorthief'

export const shuffle = arr => {
  const array = [...arr]
  let currentIndex = array.length
  let temporaryValue
  let randomIndex

  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1
    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }

  return array
}

export const decodeHtmlCharCodes = str =>
  str.replace(/(&#(\d+);)/g, (match, capture, charCode) => String.fromCharCode(charCode))

export class ClassWatcher {
  targetNode
  classToWatch
  onClassChange
  observer
  lastClassState
  paramsOnChange

  constructor(targetNode, classToWatch, onClassChange, paramsOnChange = null) {
    this.targetNode = targetNode
    this.classToWatch = classToWatch
    this.onClassChange = onClassChange
    this.observer = null
    this.lastClassState = targetNode.classList.contains(this.classToWatch)
    this.paramsOnChange = paramsOnChange
    this.init()
  }

  init() {
    this.observer = new MutationObserver(this.mutationCallback)
    this.observe()
  }

  observe() {
    this.observer.observe(this.targetNode, { attributes: true })
  }

  disconnect() {
    this.observer.disconnect()
  }

  mutationCallback = mutationsList => {
    for (const mutation of mutationsList) {
      if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
        const currentClassState = mutation.target.classList.contains(this.classToWatch)
        if (this.lastClassState !== currentClassState) {
          this.lastClassState = currentClassState
          this.onClassChange(this.paramsOnChange)
        }
      }
    }
  }
}

export const getUrlDomain = link => {
  let domain

  try {
    domain = new URL(link).hostname
  } catch (e) {
    domain = link
  }

  return domain
}

export const getMpFeature = (name: string, defaultValue = false) => {
  const featureValue = (window as any)?.mp_globals?.features?.[name]

  if (featureValue !== undefined) {
    return featureValue
  }

  return defaultValue
}

export const getHoursDifference = (prevDate, currentDate) => {
  try {
    return ((currentDate.getTime() - prevDate) / 36e5) as any
  } catch (e) {
    console.error('Error getting time difference ', e)
    return ''
  }
}

export const RGBToHSL = (r, g, b) => {
  r /= 255
  g /= 255
  b /= 255
  const l = Math.max(r, g, b)
  const s = l - Math.min(r, g, b)
  const h = s ? (l === r ? (g - b) / s : l === g ? 2 + (b - r) / s : 4 + (r - g) / s) : 0
  return [
    60 * h < 0 ? 60 * h + 360 : 60 * h,
    100 * (s ? (l <= 0.5 ? s / (2 * l - s) : s / (2 - (2 * l - s))) : 0),
    (100 * (2 * l - s)) / 2
  ]
}

export const setBackgroundColor = async (el, appImage, styleRules?) => {
  const platform = document.documentElement.dataset.mpPlatform
  const mode = document.documentElement.dataset.colorScheme

  if (platform !== 'moment') return

  const colorThief = new ColorThief()

  appImage.crossOrigin = 'Anonymous'

  appImage.addEventListener('load', function () {
    const color = colorThief.getColor(appImage)
    const rgb = `rgb(${color.join()})`
    const styles = `${styleRules}`

    if (mode === 'dark') {
      el.style = `background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), ${rgb}; ${styles}`
    } else {
      el.style = `background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), ${rgb}; ${styles}`
    }
  })
}

export const changeElementsDisplay = (elements: HTMLElement[], displayValue: string) => {
  try {
    elements.forEach(el => {
      if (el && el instanceof HTMLElement) {
        el.style.display = displayValue
      }
    })
  } catch (e) {
    console.warn(`Can't change 'display' property `, e)
  }
}
