import Swiper from 'swiper'
import { Navigation } from 'swiper/modules'

import { initPerBlockType } from '../utilities/DOM_events'

Swiper.use([Navigation])

const initSwiper = block => {
  const swiper = block.querySelector('.swiper')

  new Swiper(swiper, {
    slidesPerView: 1.33,
    spaceBetween: 16,
    effect: 'fade',
    centeredSlides: true,
    centeredSlidesBounds: true,
    grabCursor: true,
    speed: 1000
  })
}

const initInstantPlayGames = () => {
  const blocksOnPage = Array.from(document.querySelectorAll('.block--instant-play-games'))

  if (!blocksOnPage.length) return

  const initLayout = initPerBlockType(blocksOnPage, initSwiper)

  initLayout('fotoscape_block', 'fotoscapesLoad')
}
export default initInstantPlayGames
