import { LS_BRIDGE_ANDROID_AID_INTERNAL, LS_BRIDGE_UUID } from '../bridge_interface/constants'
import * as constants from '../bridge_interface/constants'

const appIds = {
  metropcs: 'com.metropcs.metrozone',
  mobileposse: 'com.mobileposse.client',
  cricket: 'com.cricketwireless.thescoop',
  boost: 'com.mobileposse.client.fastnews',
  blu: 'com.digitalturbine.android.apps.news.blu',
  pwg: 'com.digitalturbine.android.apps.news.pwg',
  dtom: 'com.digitalturbine.android.apps.news',
  uscc: 'com.digitalturbine.android.apps.news.uscellular',
  moment: 'com.mobileposse.client.moment',
  essentials: 'com.digitalturbine.android.apps.news.att'
}

const base = 'https://play.google.com/store/apps/details?id='

const appVersion = () => {
  return localStorage.getItem('int_mp_mpAppVersion')
}

const majorAppVersion = () => {
  const version = appVersion()
  if (!version) return null

  return Number.parseInt(version)
}

const availableAppVersion = () => {
  return (window as any).mp_globals.env.available_version
}

const availableMajorAppVersion = () => {
  const version = availableAppVersion()
  if (!version) return null

  return Number.parseInt(version)
}

export const getAppId = () => {
  const partner = appPartner()
  const pkg = appIds[partner]

  if (pkg) return pkg

  return appBundle()
}

/**
 * This check is a little excessive but should cover situations where invalid location data has
 * been stored alongside a valid cache timestamp (the valid timestamp prevents a lookup
 * even if the values are bad).
 */
export const locationStoredIsValid = () => {
  const latitude = localStorage.getItem(constants.LS_BRIDGE_LAT)
  const longitude = localStorage.getItem(constants.LS_BRIDGE_LNG)

  const isNumber = n => {
    return !isNaN(parseFloat(n)) && isFinite(n)
  }

  return (
    latitude !== 'null' &&
    latitude !== null &&
    isNumber(latitude) &&
    longitude !== 'null' &&
    longitude !== null &&
    isNumber(longitude)
  )
}

export const appPartner = () => {
  return localStorage.getItem('int_mp_partner')
}

const appBundle = () => {
  return localStorage.getItem('int_mp_appBundle')
}

const doNotTrack = () => {
  return (
    localStorage.getItem(constants.LS_BRIDGE_DNSMI_LOCAL) === 'true' ||
    localStorage.getItem(constants.LS_BRIDGE_DNT) === 'true' ||
    localStorage.getItem(constants.LS_BRIDGE_IFADTRK) === 'false'
  )
}

const androidAID = () => {
  if (doNotTrack()) return null

  return localStorage.getItem(LS_BRIDGE_ANDROID_AID_INTERNAL)
}

const playstoreLink = () => {
  const id = getAppId()
  if (!id) return null

  return `${base}${id}`
}

const mpid = () => {
  return localStorage.getItem(LS_BRIDGE_UUID)
}

export {
  androidAID,
  appVersion,
  availableAppVersion,
  availableMajorAppVersion,
  doNotTrack,
  majorAppVersion,
  mpid,
  playstoreLink
}
