import { ClassWatcher } from '../utilities'
let initialHeaderHeight, lastHeaderHeight

const elementInView = (el, headerHeight) => {
  const elementTop = el.getBoundingClientRect().top
  return elementTop <= headerHeight && elementTop > 0
}

const setMargin = (element, marginTopBottom, marginLeftRight) => {
  element.style.margin = `${marginTopBottom}px ${marginLeftRight}px`
}

const handleScrollAnimation = (
  headerContainer,
  mainContainer,
  initialHeaderHeight,
  opacityStep
) => {
  const currentHeaderHeight =
    mainContainer.getBoundingClientRect().top < 0 ? 0 : mainContainer.getBoundingClientRect().top
  // '+' is used as unary operator to convert string to number
  const headerOpacity = headerContainer.style.opacity ? +headerContainer.style.opacity : 1
  const headerPadding = headerContainer.style.padding
    ? Number.parseFloat(headerContainer.style.padding)
    : 0
  const containerMargin = (currentHeaderHeight / initialHeaderHeight) * 4

  if (elementInView(mainContainer, currentHeaderHeight)) {
    if (currentHeaderHeight >= initialHeaderHeight) {
      headerContainer.style.opacity = 1
      headerContainer.style.padding = 0
      setMargin(mainContainer, 0, 4)
    } else if (currentHeaderHeight >= lastHeaderHeight) {
      if (currentHeaderHeight === initialHeaderHeight) {
        headerContainer.style.opacity = 1
        headerContainer.style.padding = 0
        setMargin(mainContainer, 0, 4)
      } else {
        if (headerOpacity < 1) {
          headerContainer.style.opacity = opacityStep * currentHeaderHeight
        }
        if (containerMargin < 4) {
          setMargin(mainContainer, 0, containerMargin)
        }
        if (headerPadding > 0) {
          headerContainer.style.padding = headerPadding - 0.3 + 'px'
        }
      }
    } else {
      if (headerOpacity > 0) {
        headerContainer.style.opacity = opacityStep * currentHeaderHeight
      }
      if (containerMargin > 0 && containerMargin > 0) {
        setMargin(mainContainer, 0, containerMargin)
      }
      if (headerPadding < 8.5) {
        headerContainer.style.padding = headerPadding + 0.3 + 'px'
      }
    }
  } else {
    headerContainer.style.opacity = 0
    setMargin(mainContainer, 0, 0)
    headerContainer.style.padding = '8.5px'
  }
  lastHeaderHeight = currentHeaderHeight
}

const setInitialHeaderheight = headerContainer => {
  initialHeaderHeight = headerContainer.offsetHeight
  lastHeaderHeight = initialHeaderHeight
}

const initAnimationVisionYou = () => {
  try {
    const appTheme = document.documentElement.getAttribute('data-mp-app-theme')
    const product = document.documentElement.getAttribute('data-gtm-product')
    const headerContainer = document.querySelector('.header-container') as HTMLElement
    const mainContainer = document.querySelector('.main-container') as HTMLElement
    const generalContainer = document.querySelector('.general-container') as HTMLElement
    const adUnit = headerContainer.querySelector('.block-item__ad') as HTMLElement

    if (
      !(appTheme === 'vision-you') ||
      !(product === 'portal') ||
      !headerContainer ||
      !mainContainer
    ) {
      return
    }

    setInitialHeaderheight(headerContainer)

    if (adUnit) {
      new ClassWatcher(adUnit, 'loading', setInitialHeaderheight, headerContainer)
    }

    const opacityStep = 1 / initialHeaderHeight

    generalContainer.addEventListener('scroll', () => {
      handleScrollAnimation(headerContainer, mainContainer, initialHeaderHeight, opacityStep)
    })
  } catch (e) {
    console.error('Cannot init vision you animation ', e)
  }
}

export default initAnimationVisionYou
