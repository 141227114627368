import * as constants from './constants'

// Bridge exposed on the window by the Android Client
const bridge = (window as any).MP

// Breaking this out, to allow for more extensive sniffing in the future
const hasBridge = bridge != null

export const getMode = () => {
  const mode = localStorage.getItem('int_mp_mode')

  if (mode) {
    sessionStorage.setItem('mode', mode)
    return mode
  }

  return sessionStorage.getItem('mode')
}

export const isTwaMode = () => {
  const mode = getMode()
  return mode === 'twa'
}

export const getInternalData = () => {
  const keys = [
    'int_mp_uuID',
    'int_mp_androidID',
    'int_mp_androidAID',
    'int_mp_carrier',
    'int_mp_appVersion',
    'int_mp_appVer',
    'int_mp_appBuildVersionRevision',
    'int_mp_locSource'
  ]
  const data = keys.reduce((acc, curr) => {
    acc[curr] = localStorage.getItem(curr)
    return acc
  }, {})
  return data
}

export const isLocationAvailable = () => {
  const lat = localStorage.getItem(constants.LS_BRIDGE_LAT)
  const lng = localStorage.getItem(constants.LS_BRIDGE_LNG)
  if (isNaN(parseFloat(lat)) || isNaN(parseFloat(lng))) {
    return false
  }
  return true
}

export const isThemeConfigured = () => {
  const mode = localStorage.getItem('int_mp_darkMode')

  switch (mode) {
    case null:
      return false
    case 'true':
    case 'false':
      return true
    default:
      console.error(`int_mp_darkMode contains invalid setting '${mode}'`)
      return false
  }
}

export const isWebviewDarkMode = () => {
  return localStorage.getItem('int_mp_darkMode') === 'true'
}

export const isWebview = () => {
  return hasBridge
}

export const isSbEnabled = () => {
  return localStorage.getItem('int_mp_sb') === 'true'
}

// ##### Legacy Bridge functionality ######

// If we're in a client and it's pre-neptune, return true.
export const isLegacyClient = () => {
  const clientVersion = localStorage.getItem('int_mp_appVersionCode')
  return clientVersion && clientVersion.length < 5
}

export const openLegacyCCT = url => {
  // Old versions support this.
  if (bridge.openCustomTab) {
    bridge.openCustomTab(url)
  } else {
    // Very old versions only support this.
    bridge.openNativeBrowser(url)
  }
}

export const openAppInPlayStore = (link = null) => {
  const platform = document.documentElement.dataset.mpPlatform?.toLowerCase()
  const googlePlayStoreBase = 'https://play.google.com/store/apps/details?id='
  const playStoreIdByPlatform = {
    metropcs: localStorage.getItem('int_mp_appBundle') || 'com.mobileposse.client',
    boost: 'com.mobileposse.client.fastnews',
    cricket: 'com.cricketwireless.thescoop',
    blu: 'com.digitalturbine.android.apps.news.blu',
    pwg: 'com.digitalturbine.android.apps.news.pwg',
    dtom: 'com.digitalturbine.android.apps.news',
    uscc: 'com.digitalturbine.android.apps.news.uscellular',
    moment: 'com.mobileposse.client.moment',
    essentials: 'com.digitalturbine.android.apps.news.att'
  }

  const playStoreLink = link ? link : googlePlayStoreBase + playStoreIdByPlatform[platform]

  if (bridge?.launchMarket !== undefined) {
    bridge.launchMarket(playStoreLink)
  } else {
    window.location.href = playStoreLink
  }
}

// ##### SCREEN UNLOCK TASK QUEUE #####
// (As usual, there is a good argument for breaking this out into a module. But keeping it here until that proves necessary continues to discourage excessive
// reliance on magical bridge interface processes.)

// NOTE: The client bridge is only equipped to handle one callback function on unlock. If we want multiple things
// to be delayed, we need to coordinate it with a wrapping function.
// Also, we don't want to queue things if the screen is already unlocked, or if there is no unlock function.
// In the case of the client supporting the screen unlock callback but the actual phones not having an unlock event, both Pistachio
// and Neptune client's are designed to fire the unlock function immediately, so we don't have to handle this edge case (happy news because, spoiler alert, we couldn't).
const unlockTaskQueue = []
let isScreenUnlocked = hasBridge && bridge.hasOwnProperty('setScreenUnLockCallBack') ? false : true

// This function is what other processes will call to push to the queue. To that end it is globally exposed
// by the universal page setup.
export const queueUnlockTask = callback => {
  // We only want to queue functions while the screen is still locked.
  if (!isScreenUnlocked) {
    console.debug(`Queuing ${callback.name} for unlock`, Date.now())
    unlockTaskQueue.push(callback)
  } else {
    console.debug(`Firing ${callback.name} immediately instead of queueing for unlock`, Date.now())
    callback()
  }
}

// This is the callback that is exposed globally and passed to the client bridge by name.
export const screenUnlockCallback = () => {
  if (!isScreenUnlocked) {
    console.debug(`Dequeuing ${unlockTaskQueue.length} screen unlock tasks`, Date.now())
    isScreenUnlocked = true
    unlockTaskQueue.forEach(callback => {
      if (typeof callback === 'function') {
        callback()
      }
    })
  } else {
    console.debug('Prevented duplicate calls to dequeue screen unlock tasks')
  }
}

// This is the init process to register the single unlock callback function with the client bridge.
export const setScreenUnlockCallback = () => {
  if (!isScreenUnlocked && bridge.hasOwnProperty('setScreenUnLockCallBack')) {
    console.debug('Registering screen unlock callback with client', Date.now())
    bridge.setScreenUnLockCallBack('bridgeInterface.screenUnlockCallback()')
  } else {
    console.debug('Calling screen unlock callback immediately')
    screenUnlockCallback()
  }
}

// export const fireUnlockEventToGTM = () => {} // Passed by string reference to bridge using:
// export const dismissFSD = () => {}
// export const getActiveNetworkInfo = () => {}
// export const updateOtherData = () => {}
// export const compareApplicationVersionCode = () => {}
// export const getBrand = () => {}
