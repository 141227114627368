// Translation file

export const English = {
  loading: 'Loading weather data...',
  feels: 'Feels Like',
  more: 'More from',
  degrees: 'degrees',
  high_today: 'high today',
  low_today: 'Low today',
  wind_speed: 'Wind speed',
  humidity: 'Humidity',
  click_to_switch: 'Click to switch to',
  lang: '',
  days: {
    today: 'TODAY',
    ten: '10 DAYS',
    hourly: 'HOURLY'
  },
  error: {
    headLine: 'Oops! We can’t reach weather.',
    subHeadline: 'Please check your network connection',
    link: 'For more information please visit'
  },
  social: {
    share: 'Share',
    feedback: 'Feedback'
  },
  icon_text: {
    0: '', // NA
    1: '', // NA
    2: '', // NA
    3: 'Strong Storms',
    4: 'Thunderstorms',
    5: 'Rain/Snow',
    6: 'Rain/Sleet',
    7: 'Wintry Mix',
    8: 'Freezing Drizzle',
    9: 'Drizzle',
    10: 'Freezing Rain',
    11: 'Showers',
    12: 'Rain',
    13: 'Scattered Flurries',
    14: 'Snow Showers',
    15: '', // NA
    16: 'Snow',
    17: '', // NA
    18: 'Sleet',
    19: '', // NA
    20: 'Fog',
    21: '', // NA
    22: '', // NA
    23: '', // NA
    24: 'Wind',
    25: 'Frigid',
    26: 'Sprinkles',
    27: 'Mostly Cloudy',
    28: 'Mostly Cloudy',
    29: 'Partly Cloudy',
    30: 'Partly Cloudy',
    31: 'Clear',
    32: 'Sunny',
    33: 'Mostly Clear',
    34: 'Mostly Sunny',
    35: '', // NA
    36: '', // NA
    37: 'Isolated Thunderstorms',
    38: 'Scattered Thunderstorms',
    39: 'Scattered Showers',
    40: 'Heavy Rain',
    41: 'Scattered Snow Showers',
    42: 'Heavy Snow',
    43: 'Blizzard',
    44: '', // NA
    45: 'Scattered Showers',
    46: 'Scattered Snow Showers',
    47: 'Isolated Thunderstorms'
  }
}

export const Spanish = {
  loading: 'Cargando datos del clima...',
  feels: 'Parece',
  more: 'Mas de',
  degrees: 'grados',
  high_today: 'Alto hoy',
  low_today: 'Bajo hoy',
  wind_speed: 'Velocidad del viento',
  humidity: 'Humedad',
  click_to_switch: 'Haga clic para cambiar a',
  lang: 'es-US/',
  days: {
    today: 'HOY',
    ten: '10 DÍAS',
    hourly: 'POR HORA'
  },
  error: {
    headLine: 'Ups! No podemos llegar al clima.',
    subHeadline: 'Por favor revise su conexión de red',
    link: 'Para mayor información por favor visite'
  },
  social: {
    share: 'Compartir',
    feedback: 'Realimentación'
  },
  icon_text: {
    0: '', // NA
    1: '', // NA
    2: '', // NA
    3: 'Tormentas Fuertes',
    4: 'Tormentas',
    5: 'Lluvia/Nieve',
    6: 'Lluvia/Aguanieve',
    7: 'Mezcla invernal',
    8: 'Llovizna helada',
    9: 'Llovizna',
    10: 'Lluvia helada',
    11: 'Duchas',
    12: 'Lluvia',
    13: 'Ráfagas dispersas',
    14: 'Chubasco de nieve',
    15: '', // NA
    16: 'Nieve',
    17: '', // NA
    18: 'Aguanieve',
    19: '', // NA
    20: 'Niebla',
    21: '', // NA
    22: '', // NA
    23: '', // NA
    24: 'Viento',
    25: 'Frígido',
    26: 'Chispas',
    27: 'Nublado',
    28: 'Nublado',
    29: 'Parcialmente nublado',
    30: 'Parcialmente nublado',
    31: 'Despejado',
    32: 'Soleado',
    33: 'Cielos despejados la mayor parte del dia',
    34: 'Cielos soleado la mayor parte del dia',
    35: '', // NA
    36: '', // NA
    37: 'Tormentas aisladas',
    38: 'Tormentas dispersas',
    39: 'Chubascos disperso',
    40: 'Lluvia Fuerte',
    41: 'Chubascos de nieve dispersos',
    42: 'Nevada Fuerte',
    43: 'Nevada',
    44: '', // NA
    45: 'Chubascos dispersos',
    46: 'Chubascos de nieve dispersos',
    47: 'Tormentas aisladas'
  }
}

export const Portugese = {
  loading: 'Carregando dados meteorológicos...',
  feels: 'sente',
  more: 'Mais',
  degrees: 'graus',
  high_today: 'máxima hoje',
  low_today: 'Mínima hoje',
  wind_speed: 'Velocidade do vento',
  humidity: 'Humidade',
  click_to_switch: 'Clique para mudar para',
  lang: 'pt-br/',
  days: {
    today: 'HOJE',
    ten: '10 DÍAS',
    hourly: 'POR HORA'
  },
  error: {
    headLine: 'Ops! Não conseguimos aceder ao clima',
    subHeadline: 'Verifique sua conexão de rede',
    link: 'Para mais informações, por favor, visite'
  },
  social: {
    share: 'Partilhar',
    feedback: 'Feedback'
  },
  icon_text: {
    0: '', // NA
    1: '', // NA
    2: '', // NA
    3: 'Tempestades fortes',
    4: 'Trovoadas',
    5: 'Chuva/Neve',
    6: 'Chuva/Granizo',
    7: 'Mistura Invernal',
    8: 'Garoa Gelada',
    9: 'Garoa',
    10: 'Chuva Congelante',
    11: 'Chuviscos',
    12: 'Chuva',
    13: 'Rajadas Dispersas',
    14: 'Pancadas de Neve',
    15: '', // NA
    16: 'Neve',
    17: '', // NA
    18: 'Granizo',
    19: '', // NA
    20: 'Névoa',
    21: '', // NA
    22: '', // NA
    23: '', // NA
    24: 'Vento',
    25: 'Gelado',
    26: 'Granulados',
    27: 'Predominantemente Nublado',
    28: 'Predominantemente Nublado',
    29: 'Parcialmente Nublado',
    30: 'Parcialmente Nublado',
    31: 'Limpo',
    32: 'Ensolarado',
    33: 'Predominantemente Limpo',
    34: 'Predominantemente Ensolarado',
    35: '', // NA
    36: '', // NA
    37: 'Tempestades Isoladas',
    38: 'Tempestades Dispersas',
    39: 'Chuveiros Espalhados',
    40: 'Chuva Fortes',
    41: 'Pancadas de Neve Dispersas',
    42: 'Neve Pesada',
    43: 'Nevasca',
    44: '', // NA
    45: 'Chuveiros Espalhados',
    46: 'Pancadas de Neve Dispersas',
    47: 'Tempestades Isoladas'
  }
}
export const Hindi = {
  loading: 'लोडिंग मौसम...',
  feels: 'Parece',
  more: 'Mas de',
  degrees: 'डिग्री',
  high_today: 'Alto hoy',
  low_today: 'Bajo hoy',
  wind_speed: 'Velocidad del viento',
  humidity: 'Humedad',
  click_to_switch: 'Haga clic para cambiar a',
  lang: 'hi-in/',
  days: {
    today: 'आज',
    ten: 'दस दिन',
    hourly: '्रति घंटा'
  },
  error: {
    headLine: 'Ups! No podemos llegar al clima.',
    subHeadline: 'Por favor revise su conexión de red',
    link: 'Para mayor información por favor visite'
  },
  social: {
    share: 'फीडबैक',
    feedback: 'शेयर करें'
  },
  icon_text: {
    0: '', // NA
    1: '', // NA
    2: '', // NA
    3: 'Tormentas Fuertes',
    4: 'Tormentas',
    5: 'Lluvia/Nieve',
    6: 'Lluvia/Aguanieve',
    7: 'Mezcla invernal',
    8: 'Llovizna helada',
    9: 'Llovizna',
    10: 'Lluvia helada',
    11: 'Duchas',
    12: 'Lluvia',
    13: 'Ráfagas dispersas',
    14: 'Chubasco de nieve',
    15: '', // NA
    16: 'Nieve',
    17: '', // NA
    18: 'Aguanieve',
    19: '', // NA
    20: 'Niebla',
    21: '', // NA
    22: '', // NA
    23: '', // NA
    24: 'Viento',
    25: 'Frígido',
    26: 'Chispas',
    27: 'Nublado',
    28: 'Nublado',
    29: 'Parcialmente nublado',
    30: 'Parcialmente nublado',
    31: 'Despejado',
    32: 'Soleado',
    33: 'Cielos despejados la mayor parte del dia',
    34: 'Cielos soleado la mayor parte del dia',
    35: '', // NA
    36: '', // NA
    37: 'Tormentas aisladas',
    38: 'Tormentas dispersas',
    39: 'Chubascos disperso',
    40: 'Lluvia Fuerte',
    41: 'Chubascos de nieve dispersos',
    42: 'Nevada Fuerte',
    43: 'Nevada',
    44: '', // NA
    45: 'Chubascos dispersos',
    46: 'Chubascos de nieve dispersos',
    47: 'Tormentas aisladas'
  }
}

export const Italian = {
  loading: 'Cargando datos del clima...',
  feels: 'Parece',
  more: 'Mas de',
  degrees: 'grados',
  high_today: 'Alto hoy',
  low_today: 'Bajo hoy',
  wind_speed: 'Velocidad del viento',
  humidity: 'Humedad',
  click_to_switch: 'Haga clic para cambiar a',
  lang: 'it/',
  days: {
    today: 'HOY',
    ten: '10 DÍAS',
    hourly: 'POR HORA'
  },
  error: {
    headLine: 'Ups! No podemos llegar al clima.',
    subHeadline: 'Por favor revise su conexión de red',
    link: 'Para mayor información por favor visite'
  },
  social: {
    share: 'Compartir',
    feedback: 'Realimentación'
  },
  icon_text: {
    0: '', // NA
    1: '', // NA
    2: '', // NA
    3: 'Tormentas Fuertes',
    4: 'Tormentas',
    5: 'Lluvia/Nieve',
    6: 'Lluvia/Aguanieve',
    7: 'Mezcla invernal',
    8: 'Llovizna helada',
    9: 'Llovizna',
    10: 'Lluvia helada',
    11: 'Duchas',
    12: 'Lluvia',
    13: 'Ráfagas dispersas',
    14: 'Chubasco de nieve',
    15: '', // NA
    16: 'Nieve',
    17: '', // NA
    18: 'Aguanieve',
    19: '', // NA
    20: 'Niebla',
    21: '', // NA
    22: '', // NA
    23: '', // NA
    24: 'Viento',
    25: 'Frígido',
    26: 'Chispas',
    27: 'Nublado',
    28: 'Nublado',
    29: 'Parcialmente nublado',
    30: 'Parcialmente nublado',
    31: 'Despejado',
    32: 'Soleado',
    33: 'Cielos despejados la mayor parte del dia',
    34: 'Cielos soleado la mayor parte del dia',
    35: '', // NA
    36: '', // NA
    37: 'Tormentas aisladas',
    38: 'Tormentas dispersas',
    39: 'Chubascos disperso',
    40: 'Lluvia Fuerte',
    41: 'Chubascos de nieve dispersos',
    42: 'Nevada Fuerte',
    43: 'Nevada',
    44: '', // NA
    45: 'Chubascos dispersos',
    46: 'Chubascos de nieve dispersos',
    47: 'Tormentas aisladas'
  }
}
