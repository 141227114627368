import './index.scss'

import { isLegacyClient, openLegacyCCT } from '../common/scripts/bridge_interface/bridge_interface'
import { syncEnvDataByUser } from '../common/scripts/environment_sync/environment_sync'
import { appendInternalTrackingSearchParams } from '../common/scripts/utilities/analytics'
import * as domInitializers from './initializers'
import captureException from '../sentry/captureException'

// Kludges
const demonetizeGoogleSearchOnCricketByDeviceModel = () => {
  const DEVICE_BLACKLIST = ['5044C', 'SM-G960U', 'LM-X210CM', 'LM-X210CMR', 'FTU18A00']
  const DEMONETIZED_FALLBACK_ATTRIBUTION = 'dist-ms-android-cricket'

  const el = document.querySelector('#mp-search-client')
  if (!el) {
    return
  }

  const platform = document.documentElement.dataset.mpPlatform
  const isCricketProperty = ['cricket', 'cricketweb'].includes(platform)
  if (!isCricketProperty) {
    return
  }

  const { userAgent } = navigator
  if (!userAgent) {
    return
  }

  for (const blacklistedDevice of DEVICE_BLACKLIST) {
    if (userAgent.includes(blacklistedDevice)) {
      el.setAttribute('value', DEMONETIZED_FALLBACK_ATTRIBUTION)
    }
  }
}

// We want to find any external links in content items generated statically,
//  marked with a specific attribute and add utm targeting.
// The attribute 'data-mp-external-content-link' has been added to any templates where we want
// the link to receive the utm targeting.
const addUTMParamsToAllExtantLinks = () => {
  const externalLinksToTarget = Array.from(
    document.querySelectorAll('[data-mp-external-content-link]')
  )
  externalLinksToTarget.forEach(anchorEl => {
    const link = (anchorEl as HTMLAnchorElement).href
    const fullLink = appendInternalTrackingSearchParams(link)
    ;(anchorEl as HTMLAnchorElement).href = fullLink

    // There's a second part that's even more obscure.
    // We use an attribute data-mp-share-link to declaratively create share links. But there might not
    // be one there (in the case of ntent for example), in which case it defaults to the page url.
    // So we need to find if there is a parent ancestor with that property and do the same thing on that
    // value. Specifically before initActionButtons runs and makes use of that value.
    const shareLinkElement = anchorEl.closest('[data-mp-share-link]')
    const gtmShareLinkElement = anchorEl.closest('[data-gtm-share-link]')

    if (shareLinkElement) {
      shareLinkElement.setAttribute('data-mp-share-link', fullLink)
    }

    if (gtmShareLinkElement) {
      gtmShareLinkElement.setAttribute('data-gtm-share-link', fullLink)
    }
  })
}

// LEGACY SUPPORT
// Processes to support differing requirements on pre v64 versions of the app.
// (NOTE: Once this expands beyond the single CCT use case, we should break it out.
// Leaving it here until then for simplicity.)
const initLegacySupport = () => {
  // 1. v64 and up open CCTs by default. Older versions require this to be a manual process.
  // Older versions relied on the content to manually handle all the analytics reporting
  // for this before manually opening the CCT. Instead, we want to not prevent any normal
  // reporting, only prevent the navigation by link and instead delegate that to a bridge function
  // after reporting is complete.
  const initLegacyCCTSupport = () => {
    document.addEventListener('click', function (e) {
      // A click on a subelement might have triggered this, in which case we
      // need to find the parent anchor with the requisite attribute.
      // NOTE: (BB 3/2020) We might be able to merge this with data-mp-external-content-link. Have to
      // wait and see where requirements settle.
      const cctTrigger: HTMLAnchorElement = (e.target as HTMLElement).closest('a[data-mp-cct]')
      if (cctTrigger) {
        e.preventDefault()
        console.log('openCCT bridge call made')
        console.log(cctTrigger.href)
        // NOTE: We're no longer using event bubbling order to handle race conditions.
        // Here we are relying on the event loop trickery instead alone. Might not be enough.
        // DCM had to resort to waiting for a callback on the GA call. Need to figure out
        // the necessity of that.
        setTimeout(function () {
          openLegacyCCT(cctTrigger.href)
        }, 0)
      }
    })
  }

  const isLegacy = isLegacyClient()
  if (!isLegacy) {
    return
  }

  initLegacyCCTSupport()
}

// TODO: Do we want to create a second bundle for portals that includes this code to avoid this
// running at all on FSDs? We could, but if everything else is the same, it seems like it would
// lead to issues with divergence over time. For now, a quick sniff and conditional based
// on the existence of the hamburger menu element is enough.
const initMobileMenuDrawerListeners = () => {
  const hamburgerMenuButtons = Array.from(document.querySelectorAll('[data-hamburger-container]'))
  if (hamburgerMenuButtons.length) {
    const mobileMenuItems = Array.from(document.querySelectorAll('.mobile-menu__link'))
    const headerHamburgerToggleButton = document.querySelector('#hamburger-toggle')
    const menuContainer = document.querySelector('#mobile-menu__container')
    const mobileMenuCloseButton = document.querySelector('.hamburger-container--mobile-menu')
    // This let's us dynamically add the tabindex to the close button as well as the links.
    mobileMenuItems.push(mobileMenuCloseButton)

    // completely gross hack for now to prevent animation from firing on page load, need to
    // figure out a better way for doing this
    headerHamburgerToggleButton.addEventListener('click', () => {
      menuContainer.setAttribute('style', '')
    })

    mobileMenuItems[mobileMenuItems.length - 1].addEventListener('keydown', (event: any) => {
      if (event.keyCode === 9 && !event.shiftKey) {
        ;(mobileMenuItems[0] as any).focus()
        event.preventDefault()
        event.stopImmediatePropagation()
      }
    })

    // We want to track the last active element before the mobile menu is opened.
    // This lets us support opening the mobile menu from multiple entry targets across the page and ensures
    // that on closing, the now-hidden menu is not still focused.
    let entrypointElement = null

    const menuToggle = () => {
      const isMenuOpen = document.documentElement.classList.contains('menu-active')
      document.documentElement.classList.toggle('menu-active')
      // OPEN
      if (!isMenuOpen) {
        // Toggling the tabindex to avoid keyboard tabbing through offscreen content.
        mobileMenuItems.forEach((el: any) => {
          el.tabIndex = 0
        })
        headerHamburgerToggleButton.setAttribute('aria-expanded', 'true')
        menuContainer.setAttribute('aria-hidden', 'false')
        entrypointElement = document.activeElement
      }
      // CLOSE
      else {
        mobileMenuItems.forEach((el: any) => {
          el.tabIndex = -1
        })
        headerHamburgerToggleButton.setAttribute('aria-expanded', 'false')
        menuContainer.setAttribute('aria-hidden', 'true')
        entrypointElement.focus()
        entrypointElement = null
      }
    }
    hamburgerMenuButtons.forEach(hamburgerMenuButton => {
      hamburgerMenuButton.addEventListener('click', menuToggle)
      // Typescript is bizarrely freaking out that key is not on the event. Hence
      // the any. It, of course, is.
      hamburgerMenuButton.addEventListener('keyup', (e: any) => {
        if (e.key === 'Enter' || e.key === ' ') {
          menuToggle()
        }
      })
    })
  }
}

const removeTransitionDisablingRule = () => {
  document.documentElement.classList.remove('disable-css-transitions-on-initial-paint')
}

const handleSwipe = () => {
  const navbarEl = document.getElementById('section-navbar-block')

  if (!navbarEl) {
    return
  }

  enum direction {
    left = 'left',
    right = 'right',
    none = 'none'
  }

  const COEFFICIENT = 2
  const SWIPE_OFFSET = 50
  const swipeEl: HTMLElement = document.querySelector('.root-container__swipe')
  let windowWidth = window.innerWidth
  let touchXStart = 0
  let currentDirection = direction.none

  const linkList = Array.from(navbarEl.querySelectorAll('li a')).map(item => {
    return item.getAttribute('href')
  })

  let linkIndex = linkList.findIndex(link => {
    return window.location.href.includes(link.split('#')[0])
  })

  const reset = () => {
    swipeEl.style.setProperty('transform', `translateX(0px)`)
    swipeEl.classList.remove('root-container__swipe--active')
    swipeEl.classList.remove('root-container__swipe--align-right')
    currentDirection = direction.none
  }

  const onResize = () => {
    windowWidth = window.innerWidth
  }

  const onTouchStart = (e: TouchEvent) => {
    touchXStart = e.targetTouches[0].clientX
  }

  const onTouchMove = (e: TouchEvent) => {
    const x = e.targetTouches[0].clientX

    if (currentDirection === direction.none) {
      if (
        (linkIndex <= 0 && e.targetTouches[0].clientX > touchXStart) ||
        (linkIndex === linkList.length - 1 && e.targetTouches[0].clientX < touchXStart)
      ) {
        return
      }

      currentDirection = e.targetTouches[0].clientX > touchXStart ? direction.right : direction.left
    }

    if (swipeEl && Math.abs(x - touchXStart) > SWIPE_OFFSET) {
      if (currentDirection === direction.right) {
        swipeEl.classList.remove('root-container__swipe--align-right')
        swipeEl.style.setProperty('transform', `translateX(${x}px)`)

        if (x > windowWidth / COEFFICIENT) {
          swipeEl.classList.add('root-container__swipe--active')
        } else {
          swipeEl.classList.remove('root-container__swipe--active')
        }
      } else if (currentDirection === direction.left) {
        swipeEl.classList.add('root-container__swipe--align-right')
        swipeEl.style.setProperty('transform', `translateX(${x - windowWidth}px)`)

        if (x < windowWidth / COEFFICIENT) {
          swipeEl.classList.add('root-container__swipe--active')
        } else {
          swipeEl.classList.remove('root-container__swipe--active')
        }
      }
    }
  }

  const onTouchEnd = (e: TouchEvent) => {
    const touchXEnd = e.changedTouches[0].clientX

    if (Math.abs(touchXStart - touchXEnd) > windowWidth / COEFFICIENT) {
      if (currentDirection === direction.right) {
        swipeEl.style.setProperty('transform', `translateX(100%)`)
        linkIndex = linkIndex > 0 ? linkIndex - 1 : linkIndex
      } else if (currentDirection === direction.left) {
        swipeEl.style.setProperty('transform', `translateX(-100%)`)
        linkIndex = linkIndex < linkList.length ? linkIndex + 1 : linkIndex
      }

      if (
        linkList[linkIndex] &&
        !window.location.href.includes(linkList[linkIndex].split('#')[0])
      ) {
        window.location.href = linkList[linkIndex]
      } else {
        reset()
      }

      return
    }

    reset()
  }

  window.addEventListener('touchstart', onTouchStart)
  window.addEventListener('touchmove', onTouchMove)
  window.addEventListener('touchend', onTouchEnd)
  window.addEventListener('resize', onResize)
}

const onDOMContentLoaded = () => {
  const initializers = [
    ...Object.values(domInitializers),
    ...[
      removeTransitionDisablingRule,
      addUTMParamsToAllExtantLinks,
      initMobileMenuDrawerListeners,
      initLegacySupport,
      demonetizeGoogleSearchOnCricketByDeviceModel,
      addMetrowebDnsmiHandler
    ]
  ]

  for (const initFunction of initializers) {
    try {
      initFunction()
    } catch (e) {
      captureException(e, `could not execute init function ${initFunction.name}`)
    }
  }

  handleSwipe()
}

const onLoad = () => {
  syncEnvDataByUser()
}

document.addEventListener('DOMContentLoaded', onDOMContentLoaded)
window.addEventListener('load', onLoad)

const addMetrowebDnsmiHandler = () => {
  const links: HTMLLinkElement[] = Array.from(document.querySelectorAll('.portal-footer__link'))
  const platform = document.documentElement.dataset.mpPlatform

  links.map(link => {
    const tmoProperties = ['metroweb', 'sprintweb', 'awweb', 'magentaweb', 'metropcs']

    if (!tmoProperties.includes(platform)) return
    if (!link.href.includes('do-not-sell')) return

    link.addEventListener('click', e => {
      e.preventDefault()
      const target = e.currentTarget as HTMLLinkElement
      window.open(target.href, '_blank')
    })
  })
}
