import { RGBToHSL, setBackgroundColor, shuffle, getUrlDomain } from '../utilities'
import podcastsEn from './podcasts_en'
import i18n from 'content/src/assets/utilities/translation'
import { initPerBlockType } from '../utilities/DOM_events'
import ColorThief from 'colorthief'
import { appendInternalTrackingSearchParams } from '../utilities/analytics'
import captureException from '../../../sentry/captureException'

const CONTENT_ITEMS = {
  en: podcastsEn
}

const language = document.documentElement.lang
let content = CONTENT_ITEMS[language]

if (!content) {
  content = CONTENT_ITEMS['en']
}

let unseenContent = shuffle(content)

const truncateText = (content, length) => {
  if (!content) return

  if (content.length > length) {
    return content.substring(0, length) + '...'
  }

  return content
}

const refreshPodcast = (block?, pod?) => {
  if (!block || !pod) {
    return
  }

  try {
    const { date, image, title, subtitle = '', play_link, outbound_link } = pod

    const podcastContainer = block

    const truncatedTitle = truncateText(title, 42)
    const truncatedSubTitle = truncateText(subtitle, 58)

    const articleBody = podcastContainer.querySelector('.block-item')
    const blockActions = podcastContainer.querySelector('.block-item__actions')
    const blockActionsBtn = blockActions.querySelector('.button__icon')
    const podBody = podcastContainer.querySelector('.block-item__body')
    const podDate = podcastContainer.querySelector('.block-item__timestamp')
    const podTitle = podcastContainer.querySelector('.block-item__headline')
    const podSubtitle = podcastContainer.querySelector('.block-item__subtitle')
    const podImage = podcastContainer.querySelector('.block-item__thumbnail')
    const podPlayLink = podcastContainer.querySelector('.block-item__playbutton')

    blockActionsBtn.setAttribute(
      'data-mp-share-link',
      `${appendInternalTrackingSearchParams(outbound_link)}`
    )
    blockActionsBtn.setAttribute('data-mp-share-title', title)
    blockActionsBtn.querySelector('.icon-three-dots').setAttribute('data-gtm-event-label', title)
    blockActionsBtn.setAttribute('data-gtm-event-action', getUrlDomain(outbound_link))

    // Currently data is being pulled from a static file
    // in the future data will be returned via api request
    articleBody.setAttribute('data-mp-content-type', 'podcast')
    articleBody.setAttribute(
      'data-gtm-share-link',
      `${appendInternalTrackingSearchParams(outbound_link)}`
    )
    articleBody.setAttribute('data-gtm-share-title', title)
    articleBody.setAttribute('data-mp-share-image', image)
    articleBody.setAttribute('data-mp-share-description', subtitle)
    articleBody.setAttribute('data-mp-publisher-logo', '')
    articleBody.setAttribute('data-mp-publisher-logo-dark', '')
    articleBody.setAttribute('data-mp-content', 'podcast')
    articleBody.setAttribute('data-mp-content-text', 'podcast')
    articleBody.setAttribute('data-mp-publisher-name', title)
    articleBody.setAttribute('data-mp-pub-time', date)
    articleBody.setAttribute('data-mp-is-sponsored', '')
    articleBody.setAttribute('data-gtm-event-type', 'click')
    articleBody.setAttribute(
      'data-gtm-share-link',
      `${appendInternalTrackingSearchParams(outbound_link)}`
    )
    articleBody.setAttribute('data-gtm-article-type', '')
    articleBody.setAttribute('data-gtm-title', title)

    articleBody.setAttribute('data-gtm-share-description', title)
    articleBody.setAttribute('data-gtm-share-text', title)
    articleBody.setAttribute('data-gtm-thumbnail', image)
    articleBody.setAttribute(
      'data-mp-share-link',
      `${appendInternalTrackingSearchParams(outbound_link)}`
    )
    articleBody.setAttribute('data-mp-share-text', title)
    articleBody.setAttribute('data-mp-share-title', title)
    articleBody.setAttribute('data-mp-thumbnail', image)

    podBody.setAttribute('href', `${appendInternalTrackingSearchParams(outbound_link)}`)
    podBody.setAttribute('data-href', `${appendInternalTrackingSearchParams(outbound_link)}`)
    podBody.setAttribute('data-mp-share-description', title)
    podBody.setAttribute(
      'data-mp-share-link',
      `${appendInternalTrackingSearchParams(outbound_link)}`
    )

    podBody.setAttribute(
      'data-gtm-share-link',
      `${appendInternalTrackingSearchParams(outbound_link)}`
    )
    podBody.setAttribute(
      'data-mp-share-link',
      `${appendInternalTrackingSearchParams(outbound_link)}`
    )
    podBody.setAttribute('data-mp-share-text', title)
    podBody.setAttribute('data-mp-share-image', image)
    podBody.setAttribute('data-mp-share-title', title)
    podBody.setAttribute('data-mp-thumbnail', image)
    podBody.setAttribute('data-mp-publisher-logo', '')
    podBody.setAttribute('data-mp-publisher-logo-dark', '')
    podTitle.innerHTML = truncatedTitle
    podSubtitle.innerHTML = subtitle === '' ? (podSubtitle.innerHTML = ``) : truncatedSubTitle
    podImage.setAttribute('src', image)
    podPlayLink.setAttribute('target', '_blank')
    podPlayLink.setAttribute('href', play_link)

    if (podDate) {
      podDate.innerHTML = date
    }

    if (block.getAttribute('class').includes('small')) {
      podTitle.innerHTML = truncateText(title, 32)
      podSubtitle.innerHTML = subtitle !== '' ? truncateText(subtitle, 45) : ''
    }

    const fragment = document.createDocumentFragment()
    const a = fragment.appendChild(document.createElement('a'))
    a.setAttribute('title', i18n('Play', language))
    a.classList.add('block-item__playbutton-insert')
    a.setAttribute('target', '_blank')
    a.setAttribute('href', play_link)
    a.innerHTML = i18n('Play', language)
    podcastContainer.querySelector('.block-item__title').appendChild(a)

    if (block.getAttribute('class').includes('large')) {
      returnHSL(podBody, podImage, true)
    }

    if (block.className.includes('rss')) {
      try {
        // attempting removal because links are being overwritten with incorrect data
        articleBody.removeAttribute('data-gtm-share-link')
        articleBody.removeAttribute('data-mp-share-link')
      } catch (error) {}
    }
  } catch (e) {
    console.error(e)
    captureException(e, 'refreshPodcast failed')
  }
}

const returnHSL = async (el, appImage, checkLightness?) => {
  const mode = document.documentElement.dataset.colorScheme
  const colorThief = new ColorThief()

  appImage.crossOrigin = 'Anonymous'

  appImage.addEventListener(
    'load',
    await function () {
      const color = colorThief.getColor(appImage)
      const rgb = `rgb(${color.join()})`
      const hsl = RGBToHSL(color[0], color[1], color[2])

      if (checkLightness) {
        if (hsl[2] > 80) {
          if (mode === 'dark') {
            el.style = ` 
          z-index: -1;
        height: 100%;
        width: 100%;
        display: flex;
        background: 
        linear-gradient(0deg, rgba(69,69,69,1.000), rgba(69,69,69,1.000)); 
        background-size: contain;
        background-repeat: no-repeat;`
            return
          }
        }

        if (hsl[2] < 20) {
          if (mode === 'dark') {
            el.style = `
          z-index: -1;
        height: 100%;
        width: 100%;
        display: flex;
        background: 
        linear-gradient(0deg, rgba(69,69,69,1.000), rgba(69,69,69,1.000));
        background-size: contain;
        background-repeat: no-repeat;`
            return
          }

          el.style = ` 
        z-index: -1;
      height: 100%;
      width: 100%;
      display: flex;
      background: 
      linear-gradient(0deg, rgba(237,237,237,1.000), rgba(237,237,237,1.000));
      background-size: contain;
      background-repeat: no-repeat;`
          return
        }

        if (mode === 'dark') {
          el.style = `background: linear-gradient(0deg, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.38)), ${rgb};`
        } else {
          el.style = `background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), ${rgb};`
        }
      }
    }
  )
}

const initPodBlocks = block => {
  const mode = document.documentElement.dataset.colorScheme

  if (!unseenContent.length) {
    unseenContent = shuffle(content)
  }

  const activeSmallPod = unseenContent.pop()
  if (block.className.includes('rss')) {
    if (block.getAttribute('data-mp-block-layout').includes('podcast')) {
      return refreshPodcast(block, activeSmallPod)
    }
    return
  }

  if (block.getAttribute('id') === 'podcast_block') {
    const podBody = block.querySelector('.block-item__body')
    const podImage = block.querySelector('.block-item__thumbnail')
    const activePodBlocks = unseenContent.pop()

    if (block.getAttribute('data-mp-block-layout').includes('podcast')) {
      if (block.getAttribute('data-mp-block-layout').includes('small')) {
        const fragment = document.createDocumentFragment()
        const a = fragment.appendChild(document.createElement('a'))
        const aBackground = fragment.appendChild(document.createElement('span'))
        const aContainer = fragment.appendChild(document.createElement('div'))
        a.setAttribute('title', i18n('Play', language))
        a.classList.add('block-item__playbutton')
        aBackground.classList.add('block-item__playbutton-background')
        aContainer.classList.add('block-item__playbutton-container')

        try {
          setBackgroundColor(
            aBackground,
            podImage,
            `
            display: flex;
            height: 3rem;
            width: 3rem;
            background-size: contain;
            background-repeat: no-repeat;
            position: absolute;
            background-image:
              ${
                mode === 'dark'
                  ? `url("data:image/svg+xml,%3Csvg width='32' height='33' viewBox='0 0 32 33' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16 0.5C7.1664 0.5 0 7.668 0 16.5C0 25.332 7.1664 32.5 16 32.5C24.832 32.5 32 25.332 32 16.5C32 7.668 24.832 0.5 16 0.5Z' fill=''/%3E%3C/svg%3E%0A");`
                  : `url("data:image/svg+xml,%3Csvg width='32' height='33' viewBox='0 0 32 33' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16 0.5C7.1664 0.5 0 7.668 0 16.5C0 25.332 7.1664 32.5 16 32.5C24.832 32.5 32 25.332 32 16.5C32 7.668 24.832 0.5 16 0.5Z' fill=''/%3E%3C/svg%3E%0A");`
              }
            border-radius: 50%;
            `
          )
        } catch (e) {
          console.debug('error getting podImage color: ', e)
        }

        aContainer.appendChild(a)
        aContainer.appendChild(aBackground)
        podBody.appendChild(aContainer)
      }
      return refreshPodcast(block, activePodBlocks)
    }
  }
}

const initPodcast = async () => {
  const smallPodLayouts: HTMLElement[] = Array.from(
    document.querySelectorAll('.block--podcast-small')
  )
  const largePodLayouts: HTMLElement[] = Array.from(
    document.querySelectorAll('.block--podcast-large')
  )

  const podcastBlocks: HTMLElement[] = Array.from(
    document.querySelectorAll('[data-mp-podcast-block]')
  )

  const podBlocks = []
  const mergedBlocks = podBlocks.concat(smallPodLayouts, largePodLayouts, podcastBlocks)

  const blocksOnPage = mergedBlocks

  if (!blocksOnPage) return

  const initPods = initPerBlockType(blocksOnPage, initPodBlocks)
  initPods('block--rss')
  initPods('podcast_block')
}

export default initPodcast
