import { LS_BRIDGE_APP_VERSION_CODE_INTERNAL } from '../bridge_interface/constants'
import * as device from '../device'

export const getPartner = () => {
  const pagePartner = document.documentElement.dataset.mpPlatform

  if (pagePartner !== 'metropcs') return pagePartner

  const appId = device.getAppId()

  if (!appId) return pagePartner
  if (appId.includes('metrozone')) return 'metropcs'

  return 'mobileposse'
}

/**
 * The following is a way to determine the platform
 * We found a pattern in the version code followed as such:
 * code < 1000 = tobago
 * code < 10000 = pistachio
 * code > 10000 = neptune
 * Exceptions:
 * code === 5004 = tobago
 */
export const getPlatform = () => {
  const versionCode = parseInt(localStorage.getItem(LS_BRIDGE_APP_VERSION_CODE_INTERNAL), 10)

  if (versionCode === 5004 || versionCode < 1000) {
    return 'tobago'
  } else if (versionCode < 10_000) {
    return 'pistachio'
  } else {
    return 'neptune'
  }
}
