import { appendInternalTrackingSearchParams } from '../../../utilities/analytics'
import { createAttributes, removeSpecialCharacters } from '../utils'
import { FeedItemContext } from 'lib/feed/interfaces'

const language = document.documentElement.lang;

const isFirstArticle = index => index <= 1

const primaryItem = (feedItem: FeedItemContext) => {
  const {
    title,
    publisherLogo,
    thumbnailUrl,
    description,
    publisherDarkLogo,
  } = feedItem;

  return `
    <div class='daily-top-minimal'>
      <img class='daily-top-minimal__image' src='${thumbnailUrl}' alt='story'>
      <div class='daily-top-minimal__container'>
        ${publisherLogo ? `<img class='daily-top-minimal__logo' src='${publisherLogo}' alt='publisher logo'>` : ''}
        ${publisherLogo ? `<img class='daily-top-minimal__logo daily-top-minimal__logo--dark' src='${publisherDarkLogo}' alt='publisher logo'>` : ''}
        <h1 class='daily-top-minimal__title ${language}'>${title}</h1>
        ${description ? ` <div class='daily-top-minimal__description ${language}'>${removeSpecialCharacters(description)}</div>` : ''}
    </div>
</div>`

}

const secondaryItem = (feedItem: FeedItemContext) => {
  const {
    title,
    publisherLogo,
    publisherDarkLogo,
  } = feedItem;

  return `
    <div class="daily-top-minimal daily-top-minimal--small daily-top-minimal__container">
      <div class='daily-top-minimal__data'>
        ${publisherLogo ? `<img class='daily-top-minimal__logo' src='${publisherLogo}' alt='publisher logo'>` : ''}
        ${publisherLogo ? `<img class='daily-top-minimal__logo daily-top-minimal__logo--dark' src='${publisherDarkLogo}' alt='publisher logo'>` : ''}
        ${title ? `<h1 class="daily-top-minimal__title ${language}">${title}</h1>` : ''}
      </div>
    </div>
  `
}

const feedItemTemplate = feedItem => {
  const { link, index } = feedItem

  return `
    <article
      ${createAttributes(feedItem, isFirstArticle(index) ? `block-item--primary ${language}` : '')}
      data-gtm-scroll-count='${index}'>

      <a class='block-item__body' href='${appendInternalTrackingSearchParams(link)}' data-mp-cct >
        ${isFirstArticle(index) ? primaryItem(feedItem) : secondaryItem(feedItem)}
      </a>
  </article>`
}

export default feedItemTemplate
