import { IPlatform } from '../../../types-generator/shared/interfaces'

// @todo This should be moved to a platform boolean property. --hrivera
export function getGdprPlatforms(): IPlatform[] {
  return [
    IPlatform.orangeweb,
    IPlatform.pwgbar,
    IPlatform.aonebar,
    IPlatform.aoneweb,
    IPlatform.timbar,
    IPlatform.nokialatambar
  ]
}
