import { GA4Ticker } from './GA4Ticker'

export class GA4TickerFotoscape extends GA4Ticker {
  static contentType = 'sponsored'
  static elementType = 'fotoscapes'
  static publisher = 'fotoscapes'

  public static getTitle(linkElement) {
    return linkElement.querySelector('[data-mp-fotoscape-ticker-text]').innerHTML
  }
}
