import { FeedItemContext } from '../../../../../../lib/feed/interfaces'
import { appendInternalTrackingSearchParams } from '../../../utilities/analytics'
import { header, footer, language, media } from '../feed_item'
import { createAttributes } from '../utils'

const carouselTemplate = (feedItem: FeedItemContext) => {
  const {
    link,
    title,
    description,
    publisherLogo,
    publisherDarkLogo,
    publisherName,
    isVideoLink,
    thumbnailUrl,
    isSponsored,
    contentType,
    contentTypeText,
    ms_news,
    contentApiId,
    pub_time,
    layout,
    duration,
    index
  } = feedItem

  return `
  <article
  ${createAttributes(feedItem)}
  data-gtm-scroll-count=${index}>
  <a class="block-item__body"
     href="${appendInternalTrackingSearchParams(link)}"
     data-mp-cct>
    ${media(thumbnailUrl, isVideoLink, duration, layout)}

    ${header(
      isSponsored,
      publisherDarkLogo,
      publisherLogo,
      publisherName,
      isVideoLink,
      ms_news,
      layout
    )}

    <h1 class="block-item__title ${language}">
        <span class="block-item__headline">${title}</span>
        <span class="block-item__subtitle ${language}"></span>
    </h1>
    
    <div class="block-item__description">${description}</div>
   </a>

    ${footer(
      title,
      link,
      isSponsored,
      contentTypeText,
      contentType,
      language,
      layout,
      publisherDarkLogo,
      publisherLogo,
      publisherName,
      isVideoLink,
      ms_news,
      contentApiId,
      pub_time
    )}
    </article>
  `
}

export default carouselTemplate
