import dateSetup, { formatDate } from '../portal_header/utilities'

const platformsNoLogo = ['firstly', 'moment']

const initDailyTopMinimalHeader = () => {
  try {
  // there should be just 1 daily top block on a page
    const blockDailyMinimalTop = document.querySelector('.block--daily-top-minimal') as HTMLElement;

    if(blockDailyMinimalTop) {
      const logoContainer = blockDailyMinimalTop.querySelector('.subheader__logo')

      const language = document.documentElement.lang
      if (!language) return

      const localLocale = dateSetup(language)
      let displayFormat = formatDate(language, localLocale)
      if (displayFormat) {
        logoContainer.innerHTML = displayFormat
        logoContainer.classList.add('text-logo')
      }
    }
  } catch (e) {
    console.error('Daily top minimal header init error: ', e)
  }
}

export default initDailyTopMinimalHeader
