import { neptuneWarn } from '../../../utilities'
import { NeptuneAttribute, NeptuneAttributeKey } from '../../lib/NeptuneAttribute'
import { findClosestAncestorNode } from '../utilities/DOM_manipulation'
import { BlockType } from './interfaces'

export class GA4ElementValues {
  public article: HTMLElement
  public section: HTMLElement
  public blockIndex: number
  public blockLayout: string
  public blockType: BlockType
  public publisher: string
  public title: string
  public experimentID: string
  public experimentType: string

  constructor(target: HTMLElement) {
    // Block elements.
    this.section = this.getSection(target)
    this.article = this.getArticle(target)

    // Section level values, always.
    this.blockIndex = parseInt(NeptuneAttribute.get(this.section, NeptuneAttributeKey.blockIndex))
    this.blockType = NeptuneAttribute.get(this.section, NeptuneAttributeKey.blockType) as BlockType

    // Section level values, optional.
    this.blockLayout = NeptuneAttribute.get(this.section, NeptuneAttributeKey.blockLayout) || null
    this.experimentID = NeptuneAttribute.get(this.section, NeptuneAttributeKey.gtmExperimentId) || null
    this.experimentType = NeptuneAttribute.get(this.section, NeptuneAttributeKey.gtmExperimentType) || null

    // Item level values.
    if (this.article) {
      this.publisher = NeptuneAttribute.get(this.article, NeptuneAttributeKey.publisherName)
      this.title = NeptuneAttribute.get(this.article, NeptuneAttributeKey.shareTitle)
    } else {
      neptuneWarn(`article not found for ${this.blockType}`)
    }
  }

  getArticle(target: HTMLElement) {
    switch (target.tagName) {
      case 'A':
        return findClosestAncestorNode(target, 'article')
      default:
        return target
    }
  }

  getSection(target: HTMLElement) {
    return findClosestAncestorNode(target, `[${NeptuneAttribute.toAttributeName(NeptuneAttributeKey.blockType)}]`)
  }
}
