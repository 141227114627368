import { FeedItemContext } from '../../../../../../lib/feed/interfaces'
import { createAttributes, removeSpecialCharacters } from '../utils'
import { appendInternalTrackingSearchParams } from '../../../utilities/analytics'
import i18n from '../../../../../utilities/translation'
import { footer, header, language, media } from '../feed_item'

const slideShowTemplate = (feedItem: FeedItemContext) => {
  const {
    link,
    title,
    duration,
    publisherLogo,
    publisherDarkLogo,
    publisherName,
    isVideoLink,
    thumbnailUrl,
    isSponsored,
    contentType,
    contentTypeText,
    description,
    ms_news,
    contentApiId,
    pub_time,
    layout
  } = feedItem

  return `
    <article ${createAttributes(feedItem)}>
      <a class="block-item__body"
        href="${appendInternalTrackingSearchParams(link)}"
        data-gtm-event-category="content_reveal_external_click" data-gtm-event-type="click-element"
        data-mp-cct>
   
        <h1 class="block-item__slideshow-title {{@root.language}}">
          <span class="block-item__slideshow-icon">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M21.75 5.96986C21.75 4.74387 20.7845 3.75 19.5935 3.75H7.21154C6.03315 3.76817 5.08718 4.75674 5.08731 5.96986V7.42483H2.90654C1.71552 7.42483 0.75 8.41869 0.75 9.64469V18.7902C0.75 20.4248 2.03735 21.75 3.62538 21.75H18.3254C20.2136 21.75 21.7455 20.1768 21.75 18.2331V5.96986ZM5.08769 18.8069C5.12831 19.3666 4.8612 19.9027 4.39587 20.1954C3.93053 20.488 3.34523 20.488 2.8799 20.1954C2.41457 19.9027 2.14746 19.3666 2.18808 18.8069V9.66137C2.18808 9.25271 2.50991 8.92142 2.90692 8.92142H5.08769V18.8069ZM9.33576 13.8931H17.5096C17.9066 13.8931 18.2285 14.2244 18.2285 14.6331C18.2285 15.0417 17.9066 15.373 17.5096 15.373H9.33576C9.07894 15.373 8.84163 15.232 8.71322 15.0031C8.58481 14.7741 8.58481 14.492 8.71322 14.2631C8.84163 14.0342 9.07894 13.8931 9.33576 13.8931ZM17.5096 16.853H9.33576C9.07894 16.853 8.84163 16.994 8.71322 17.223C8.58481 17.4519 8.58481 17.734 8.71322 17.9629C8.84163 18.1918 9.07894 18.3329 9.33576 18.3329H17.5096C17.9066 18.3329 18.2285 18.0016 18.2285 17.5929C18.2285 17.1843 17.9066 16.853 17.5096 16.853ZM17.5096 7.07556H9.33576C8.93875 7.07556 8.61691 7.40685 8.61691 7.81552V11.64C8.61691 12.0487 8.93875 12.3799 9.33576 12.3799H17.5096C17.9066 12.3799 18.2285 12.0487 18.2285 11.64V7.84877C18.2373 7.64683 18.1655 7.44996 18.0297 7.30391C17.8939 7.15787 17.706 7.07536 17.5096 7.07556ZM16.7906 10.9251H10.0544V8.5888H16.7906V10.9251Z" />
            </svg>
          </span>
          <span class="block-item__slideshow-title-text">${i18n('Today’s news', language)}</span>
        </h1>
  
        ${media(thumbnailUrl, isVideoLink, duration, layout)}
  
        ${header(
          isSponsored,
          publisherDarkLogo,
          publisherLogo,
          publisherName,
          isVideoLink,
          ms_news,
          layout
        )}
  
        <div class="block-item__text-container">
          <h1 class="block-item__title ${language}">
            <span class="block-item__headline">${title}</span>
            <span class="block-item__subtitle ${language}"></span>
          </h1>
          <p class="block-item__description ${language}">${removeSpecialCharacters(description)}</p>
        </div>
      </a>
  
      ${footer(
        title,
        link,
        isSponsored,
        contentTypeText,
        contentType,
        language,
        layout,
        publisherDarkLogo,
        publisherLogo,
        publisherName,
        isVideoLink,
        ms_news,
        contentApiId,
        pub_time
      )}
    </article>
  `
}

export default slideShowTemplate
