import dailyTopPartial from './layouts/daily_top'
import dailyTopMinimalPartial from './layouts/daily_top_minimal'
import gamesPhotocard from './layouts/games_photocard'
import instantPlayGamesItem from './layouts/instant_play_games/instant_play_games_item'
import stubTemplate from './layouts/stub'
import trendingCarouselItem from './layouts/trending_carousel/trending_carousel_item'
import largePhotocard from './layouts/large_photocard'
import smallPhotocard from './layouts/small_photocard'
import smallPhotocardFlat from './layouts/small_photocard_flat'
import carouselPhotocard from './layouts/carousel_photocard'
import carouselTemplate from './layouts/carousel'
import edgeTileTemplate from './layouts/edge_tile'
import edgeListTemplate from './layouts/edge_list'
import defaultTemplate from './layouts/default'
import { appendInternalTrackingSearchParams } from '../../utilities/analytics'
import { language } from './feed_item'
import gamesCarouselTemplate from './layouts/games_carousel'
import defaultDescriptionTemplate from './layouts/default_description'
import gamesQuizTileTemplate from './layouts/games_quiz_tile'
import quizCarouselTemplate from './layouts/quiz_carousel'
import edgeDescriptionTemplate from './layouts/edge_description'
import dealsListTemplate from './layouts/deals-list'
import podcastLargeTemplate from './layouts/podcast_large'
import podcastSmallTemplate from './layouts/podcast_small'
import slideShowTemplate from './layouts/slideshow'
import edgeTemplate from './layouts/edge'

export const isVisionYouTheme = app_theme => app_theme === 'vision-you'
export const isMomentTheme = app_theme => app_theme === 'moment'
export const isPersonalizationEnabled = (window as any).mp_globals?.features?.personalize

export const isThreeDotEnabled = layout => {
  const types = [
    'carousel',
    'carousel-photocard',
    'daily-top',
    'daily-top-minimal',
    'deals-list',
    'edge',
    'edge-description',
    'edge-list',
    'edge-tile',
    'games-carousel',
    'games-hero',
    'games-tile',
    'large-photocard',
    'list-medium',
    'list-small-sponsored',
    'list-small',
    'podcast-large',
    'podcast-small',
    'quiz-carousel',
    'quiz-hero',
    'quiz-tile',
    'slideshow',
    'small-photocard',
    'small-photocard-flat',
    'stub',
    'tile',
    'instant-play-games',
    'horoscope_carousel_block'
  ]

  return types.includes(layout)
}

export const removeSpecialCharacters = description => description?.replace(/[^a-z0-9,. ]/gi, '')

export const formatCategory = category => {
  const format = category.replace(/referral/gi, '')
  return format.replace('_', ' ')
}

export const getJavaScriptTemplateForLayout = layout => {
  switch (layout) {
    case 'daily-top':
      return dailyTopPartial
    case 'daily-top-minimal':
      return dailyTopMinimalPartial
    case 'deals-list':
      return dealsListTemplate
    case 'trending-carousel':
      return trendingCarouselItem
    case 'instant-play-games':
      return instantPlayGamesItem
    case 'stub':
      return stubTemplate
    case 'large-photocard':
      return largePhotocard
    case 'small-photocard-flat':
      return smallPhotocardFlat
    case 'slideshow':
      return slideShowTemplate
    case 'carousel':
      return carouselTemplate
    case 'carousel-photocard':
      return carouselPhotocard
    case 'small-photocard':
      return smallPhotocard
    case 'edge-description':
      return edgeDescriptionTemplate
    case 'games-carousel':
      return gamesCarouselTemplate
    case 'games-hero':
      return defaultDescriptionTemplate
    case 'games-photocard':
      return gamesPhotocard
    case 'edge-tile':
      return edgeTileTemplate
    case 'edge-list':
      return edgeListTemplate
    case 'quiz-carousel':
      return quizCarouselTemplate
    case 'games-tile':
    case 'quiz-tile':
      return gamesQuizTileTemplate
    case 'podcast-large':
      return podcastLargeTemplate
    case 'podcast-small':
      return podcastSmallTemplate
    case 'edge':
      return edgeTemplate
    case 'tile':
    case 'list-medium':
    case 'list-small':
    case 'list-small-sponsored':
    case 'quiz-hero':
      return defaultTemplate
    default:
      return null
  }
}

export const createAttributes = (feedItem, classList = '') => {
  const {
    link,
    title,
    publisherLogo,
    publisherDarkLogo,
    publisherName,
    thumbnailUrl,
    isSponsored,
    contentType,
    contentTypeText,
    description,
    pub_time,
    uid
  } = feedItem
  return `
    data-mp-content-type="feed_item_link"
    data-gtm-event-type="click"
    data-mp-share-title="${title}"
    data-mp-share-image="${thumbnailUrl}"
    data-mp-publisher-logo="${publisherLogo}"
    data-mp-publisher-logo-dark="${publisherDarkLogo}"
    data-mp-content="${contentType}"
    data-mp-content-text="${contentTypeText}"
    data-mp-publisher-name="${publisherName}"
    data-mp-pub-time="${pub_time}"
    data-mp-is-sponsored="${isSponsored ? isSponsored : ''}"
    data-gtm-title="${title}"
    class="block-item ${language} ${classList}"
    data-gtm-article-type="${isSponsored ? 'sponsor' : 'editorial'}"
    data-mp-uid="${uid ? uid : ''}"
    data-gtm-share-link="${appendInternalTrackingSearchParams(link)}"
    data-mp-share-link="${appendInternalTrackingSearchParams(link)}"
    data-mp-share-description ="${description ? removeSpecialCharacters(description) : ''}"
  `
}
