import { lazyload } from '../../utilities/DOM_events'
import GA4Handler from '../GA4Handler'

const ga4GenericLinkImpressionHandler = (target?: HTMLElement, optional = {}) => {
  lazyload(
    target,
    () => {
      const dataLayerObject = GA4Handler.createImpressionObject(target, {
        ...optional
      })

      GA4Handler.pushObject('ga4GenericLinkImpressionHandler', dataLayerObject)
    },
    {
      threshold: 0.7 // This might need to be tweaked for carousel.
    }
  )
}

export default ga4GenericLinkImpressionHandler
