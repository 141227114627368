import { media, header } from '../feed_item'
import { appendInternalTrackingSearchParams } from '../../../utilities/analytics'
import { createAttributes, removeSpecialCharacters } from '../utils'
const language = document.documentElement.lang

const isFirstArticle = index => index <= 1

const primaryArticlePartial = ({
  title,
  publisherLogo,
  publisherDarkLogo,
  publisherName,
  isVideoLink,
  thumbnailUrl,
  duration,
  isSponsored,
  layout,
  description
}) => {
  return `
      ${media(thumbnailUrl, isVideoLink, duration, layout)}      
      <div class="block-item__body-content">
      ${title ? `<h1 class="block-item__title ${language}">${title}</h1>` : ''}
      ${header(isSponsored, publisherDarkLogo, publisherLogo, publisherName, isVideoLink, false)}
      ${
        description
          ? `<h1 class="block-item__description ${language}">${removeSpecialCharacters(
              description
            )}</h1>`
          : ''
      }
      </div>`
}

const secondaryArticlePartial = ({
  title,
  publisherLogo,
  publisherDarkLogo,
  publisherName,
  isVideoLink,
  isSponsored,
  index
}) => {
  return `
        <div class="block-item__body-list">${index}.</div>
        <div class="block-item__body-content">
        ${header(isSponsored, publisherDarkLogo, publisherLogo, publisherName, isVideoLink, false)}
        ${title ? `<h1 class="block-item__title ${language}">${title}</h1>` : ''}
        </div>`
}

const feedItemTemplate = feedItem => {
  const { link, index } = feedItem

  return `
    <article 
    ${createAttributes(feedItem, isFirstArticle(index) ? `block-item--primary ${language}` : '')}
    data-gtm-scroll-count=${index}>
      <a class="block-item__body" href="${appendInternalTrackingSearchParams(link)}" data-mp-cct >
        ${
          isFirstArticle(index)
            ? primaryArticlePartial(feedItem)
            : secondaryArticlePartial(feedItem)
        }
      </a>
  </article>`
}

export default feedItemTemplate
