import { appendInternalTrackingSearchParams } from '../../../../utilities/analytics'
import i18n from '../../../../../../utilities/translation'

const language = document.documentElement.lang

const feedTemplate = ({ publisherLogo, publisherDarkLogo, publisherName, url, items, title }) => `
    <article class="fotoscape-gallery">
        <div class="fotoscape-gallery__swiper swiper autoplay-enabled">
          <div class="swiper-wrapper"></div>
        </div>


    <div class="fotoscape-gallery__publisher-logo">
      <div class="block-item__body-header-light">
          ${
            publisherLogo && publisherName
              ? `<img loading="lazy" class="block-item__publisher-logo" src="${publisherLogo}" alt="${publisherName} logo" />`
              : `<small class="block-item__publisher-name ${language}">${publisherName}</small>`
          }
        </div>
      <div class="block-item__body-header-dark">
        ${
          publisherDarkLogo && publisherName
            ? `<img loading="lazy" class="block-item__publisher-logo" src="${publisherDarkLogo}" alt="${publisherName} logo" />`
            : `<small class="block-item__publisher-name ${language}">${publisherName}</small>`
        }
      </div>
    </div>
        

        ${title ? `<div class="fotoscape-gallery__title">${title}</div>` : ''}
        <div class="fotoscape-gallery__link-container">
          <a class="fotoscape-gallery__link" href="${appendInternalTrackingSearchParams(url)}">
            <div class="fotoscape-gallery__link-content">${i18n(
              'View All',
              language
            )} <span>${items}</span></div>
          </a>
        </div>
    </article>`

export default feedTemplate
