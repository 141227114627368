import { GA4Ticker } from './GA4Ticker'

export class GA4TickerWeather extends GA4Ticker {
  static contentType = 'sponsored'
  static elementType = 'weather'
  static publisher = 'weatherbug'

  // noinspection JSUnusedLocalSymbols
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public static getTitle(linkElement) {
    return this.publisher
  }
}
