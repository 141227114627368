import { lazyload } from '../utilities/DOM_events'

const initPlaybuzzVideo = () => {
  const template: HTMLTemplateElement = document.querySelector(
    'template[data-playbuzz-video-template]'
  )

  const container: HTMLElement = document.querySelector('[data-playbuzz-video-container]')

  if (!template) return

  console.debug('Playbuzz Video: Page loaded', new Date())

  const renderPlaybuzz = () => {
    console.debug('Playbuzz Video: Video Loaded', new Date())

    const content = template.content.cloneNode(true)
    template.parentNode.insertBefore(content, template)
  }

  if (container.dataset.mpPlaybuzzVideoLazyload === 'false') {
    console.debug('Playbuzz Video: Video Not Lazy-Loaded', new Date())
    renderPlaybuzz()
    return
  }

  lazyload(container, renderPlaybuzz, { rootMargin: '100px', threshold: 0 })
}

export default initPlaybuzzVideo
