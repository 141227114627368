export class CropImageInvalidBaseUrlError extends Error {
  baseUrl: string
  domainPrefix: string

  constructor(message: string, baseUrl: string, domainPrefix: string) {
    super(message)

    this.baseUrl = baseUrl
    this.domainPrefix = domainPrefix

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, CropImageInvalidBaseUrlError)
    }

    this.toString = (): string => {
      return (
        `baseUrl: ${this.baseUrl}, domainPrefix: ${this.domainPrefix}` + '\n' + super.toString()
      )
    }
  }
}
