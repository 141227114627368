import { isTwaMode } from '../bridge_interface/bridge_interface'

export type DataLayerArticleContentType = 'sponsored' | 'editorial' | 'unknown'

export enum DataLayerNonLinkClickElementAction {
  // For three dot menu share / feedback / like / dislike
  'click_three_dots' = 'click_three_dots',
  'impression_click' = 'impression_click',
  'feedback_show' = 'feedback_show',
  'feedback_submit' = 'feedback_submit',
  'refresh' = 'refresh',
  'share' = 'share',
  'show' = 'show'
}

export enum DataLayerInteractionType {
  'external' = 'external',
  'internal' = 'internal'
}

export enum Publishers {
  'fotoscapes' = 'fotoscapes'
}

/**
 * @todo Should we track language here?
 * @todo The joke element name could be more descriptive.
 */
export interface DataLayerNonLinkClick {
  block_layout?: string
  block_position: number
  block_type: BlockType
  content_type: DataLayerArticleContentType
  domain?: string
  element: BlockType
  element_action: DataLayerNonLinkClickElementAction
  element_detail: string
  event: DataLayerNonLinkClickElementAction
  interaction_type: DataLayerInteractionType
  path?: string | null
  title?: string | null
}

export interface DataLayerVideoClick {
  event: 'video'
  element: 'youtube_video'
  element_action: 'play'

  block_type: BlockType

  domain: string
  path: string
}

export interface DataLayerRevealWidgetClickShow {
  block_type: BlockType
  content_type: 'editorial' | 'sponsor'
  domain?: string
  element: BlockType
  element_action: 'show' | 'refresh'
  element_detail: string
  element_display_count?: number
  element_teaser?: string
  event: 'impression_click'
  path?: string
}

export interface DataLayerRevealWidgetClickRefresh {
  event: 'impression_click'
  content_type: 'editorial'
  element: BlockType
  element_action: 'refresh'

  block_type: BlockType
}

/**
 * @todo-types Is there a type for block_layout? --hrivera
 */
export interface DataLayerBaseEvent {
  block_layout: string
  block_position: number
  block_type: BlockType
  content_type: DataLayerContentSourceType
  domain?: string
  element: string
  event: DataLayerEventType
  element_action?: string
  element_detail?: string
  experiment_group?: string
  experiment_id?: string
  experiment_type?: string
  interaction_type: DataLayerInteractionType
  path?: string
  publisher?: string
  title?: string
}

export interface DataLayerAppAdGridEvent extends DataLayerBaseEvent {
  neptune_platform: string
  neptune_product: string
  dt_app_media_partner: string
  dt_app_media_site_id: string
  mode: 'twa' | 'webview'
}

export interface DataLayerExternalClickEvent extends DataLayerBaseEvent {
  domain: string
  event: DataLayerEventType.impression_click
  path: string
  title: string
}

export interface DataLayerImpressionEvent extends DataLayerBaseEvent {
  carousel_position?: number
  title?: string
}

export interface DataLayerInternalClickEvent extends DataLayerBaseEvent {
  event: DataLayerEventType.impression_click
}

export type DataLayerOptionalEvent = {
  [key in keyof DataLayerBaseEvent]?
}

// export type DataLayerExternalClickEvent = 'impression_click' | 'impression_view'
export enum DataLayerEventType {
  impression_view = 'impression_view',
  impression_click = 'impression_click'
}

export enum DataLayerContentSourceType {
  editorial = 'editorial',
  sponsored = 'sponsored',
  unknown = 'unknown'
}

export enum BlockType {
  ad_sticky = 'ad_sticky',
  ad_unit = 'ad_unit',
  advice_of_day_block = 'advice_of_day_block',
  affiliate_ad_grid_block = 'affiliate_ad_grid_block',
  app_ad_grid_block = 'app_ad_grid_block',
  app_download_block = 'app_download_block',
  app_rating = 'app_rating',
  banner = 'banner',
  bookmark = 'bookmark',
  button_nav = 'button_nav',
  discover_bar = 'discover_bar',
  emoji_feedback_block = 'emoji_feedback_block',
  feedback_form_block = 'feedback_form_block',
  firework_video_block = 'firework_video_block',
  fortune_cookie_block = 'fortune_cookie_block',
  fotoscape_block = 'fotoscape_block',
  fotoscape_gallery_block = 'fotoscape_gallery_block',
  full_article_block = 'full_article_block',
  google_search_bar = 'google_search_bar',
  header_block = 'header_block',
  header_block_alert = 'header_block_alert',
  horoscope_block = 'horoscope_block',
  horoscope_carousel_block = 'horoscope_carousel_block',
  horoscope_details_block = 'horoscope_details_block',
  horoscope_details_group_block = 'horoscope_details_group_block',
  horoscope_discover_bar = 'horoscope_discover_bar',
  horoscope_group_block = 'horoscope_group_block',
  horoscope_top_block = 'horoscope_top_block',
  house_deal_block = 'house_deal_block',
  iframe_block = 'iframe_block',
  image_only_block = 'image_only_block',
  jokes_widget_block = 'jokes_widget_block',
  list_small_block = 'list_small_block',
  memes_widget_block = 'memes_widget_block',
  mobile_menu = 'mobile_menu', // This is a partial template.
  ms_news_block = 'ms_news_block',
  nativo_block = 'nativo_block',
  outbrain_block = 'outbrain_block',
  outbrain_widget_block = 'outbrain_widget_block',
  playbuzz_video_block = 'playbuzz_video_block',
  portal_footer_block = 'portal_footer_block',
  portal_header_block = 'portal_header_block',
  portal_navigation_bar = 'portal_navigation_bar',
  promo_card_block = 'promo_card_block',
  quotes_widget_block = 'quotes_widget_block',
  recommendation = 'recommendation',
  section_navigation_bar = 'section_navigation_bar',
  share_button = 'share_button',
  stub = 'stub',
  ticker_block = 'ticker_block',
  upgrade_prompt = 'upgrade_prompt',
  visual_brief_block = 'visual_brief_block',
  voting_block = 'voting_block',
  weather_widget_block = 'weather_widget_block',
  words_widget_block = 'words_widget_block',
  youtube_video = 'youtube_video',
  youtube_video_block = 'youtube_video_block'
}

export enum BlockNeptuneAttribute {
  articleType = 'data-gtm-article-type',
  blockIndex = 'data-mp-block-index',
  blockType = 'data-mp-block-type',
  contentText = 'data-mp-content-text',
  contentType = 'data-mp-content-type',
  description = 'data-mp-share-description',
  horoscopeSign = 'data-mp-horoscope-sign',
  horoscopeType = 'data-mp-horoscope-type',
  image = 'data-mp-share-image',
  pubTime = 'data-mp-pub-time',
  publisherLogo = 'data-mp-publisher-logo',
  publisherLogoDark = 'data-mp-publisher-logo-dark',
  publisherName = 'data-mp-publisher-name',
  shareLinkGTM = 'data-gtm-share-link',
  shareLinkMP = 'data-mp-share-link',
  shareHoroscopeText = 'data-mp-share-horoscope-text',
  title = 'data-mp-share-title'
}

export class BlockNeptune {
  articleType: BlockNeptuneAttribute.articleType
  blockType: BlockNeptuneAttribute.blockType
  contentText: BlockNeptuneAttribute.contentText
  contentType: BlockNeptuneAttribute.contentType
  description: BlockNeptuneAttribute.description
  horoscopeSign: BlockNeptuneAttribute.horoscopeSign
  horoscopeType: BlockNeptuneAttribute.horoscopeType
  image: BlockNeptuneAttribute.image
  pubTime: BlockNeptuneAttribute.pubTime
  publisherLogo: BlockNeptuneAttribute.publisherLogo
  publisherLogoDark: BlockNeptuneAttribute.publisherLogoDark
  publisherName: BlockNeptuneAttribute.publisherName
  shareHoroscopeText: BlockNeptuneAttribute.shareHoroscopeText
  shareLinkGTM: BlockNeptuneAttribute.shareLinkGTM
  shareLinkMP: BlockNeptuneAttribute.shareLinkMP
  title: BlockNeptuneAttribute.title

  constructor(element) {
    this.articleType = element.getAttribute(BlockNeptuneAttribute.articleType)
    this.blockType = element.getAttribute(BlockNeptuneAttribute.blockType)
    this.contentText = element.getAttribute(BlockNeptuneAttribute.contentText)
    this.contentType = element.getAttribute(BlockNeptuneAttribute.contentType)
    this.description = element.getAttribute(BlockNeptuneAttribute.description)
    this.horoscopeSign = element.getAttribute(BlockNeptuneAttribute.horoscopeSign)
    this.horoscopeType = element.getAttribute(BlockNeptuneAttribute.horoscopeType)
    this.image = element.getAttribute(BlockNeptuneAttribute.image)
    this.pubTime = element.getAttribute(BlockNeptuneAttribute.pubTime)
    this.publisherLogo = element.getAttribute(BlockNeptuneAttribute.publisherLogo)
    this.publisherLogoDark = element.getAttribute(BlockNeptuneAttribute.publisherLogoDark)
    this.publisherName = element.getAttribute(BlockNeptuneAttribute.publisherName)
    this.shareHoroscopeText = element.getAttribute(BlockNeptuneAttribute.shareHoroscopeText)
    this.shareLinkGTM = element.getAttribute(BlockNeptuneAttribute.shareLinkGTM)
    this.shareLinkMP = element.getAttribute(BlockNeptuneAttribute.shareLinkMP)
    this.title = element.getAttribute(BlockNeptuneAttribute.title)
  }

  copyAttributesToElement(target) {
    target.setAttribute(BlockNeptuneAttribute.articleType, this.articleType)
    target.setAttribute(BlockNeptuneAttribute.blockType, this.blockType)
    target.setAttribute(BlockNeptuneAttribute.contentText, this.contentText)
    target.setAttribute(BlockNeptuneAttribute.contentType, this.contentType)
    target.setAttribute(BlockNeptuneAttribute.description, this.description)
    target.setAttribute(BlockNeptuneAttribute.horoscopeSign, this.horoscopeSign)
    target.setAttribute(BlockNeptuneAttribute.horoscopeType, this.horoscopeType)
    target.setAttribute(BlockNeptuneAttribute.image, this.image)
    target.setAttribute(BlockNeptuneAttribute.pubTime, this.pubTime)
    target.setAttribute(BlockNeptuneAttribute.publisherLogo, this.publisherLogo)
    target.setAttribute(BlockNeptuneAttribute.publisherLogoDark, this.publisherLogoDark)
    target.setAttribute(BlockNeptuneAttribute.publisherName, this.publisherName)
    target.setAttribute(BlockNeptuneAttribute.shareHoroscopeText, this.shareHoroscopeText)
    target.setAttribute(BlockNeptuneAttribute.shareLinkGTM, this.shareLinkGTM)
    target.setAttribute(BlockNeptuneAttribute.shareLinkMP, this.shareLinkMP)
    target.setAttribute(BlockNeptuneAttribute.title, this.title)
  }
}

export const isSponsored = blockType => {
  switch (blockType) {
    case BlockType.ad_sticky:
    case BlockType.ad_unit:
    case BlockType.advice_of_day_block:
    case BlockType.affiliate_ad_grid_block:
    case BlockType.app_ad_grid_block:
    case BlockType.app_download_block:
    case BlockType.banner:
    case BlockType.button_nav:
    case BlockType.discover_bar:
    case BlockType.emoji_feedback_block:
    case BlockType.feedback_form_block:
    case BlockType.fortune_cookie_block:
    case BlockType.fotoscape_block:
    case BlockType.fotoscape_gallery_block:
    case BlockType.full_article_block:
    case BlockType.header_block:
    case BlockType.header_block_alert:
    case BlockType.horoscope_block:
    case BlockType.horoscope_carousel_block:
    case BlockType.horoscope_details_block:
    case BlockType.horoscope_details_group_block:
    case BlockType.horoscope_discover_bar:
    case BlockType.horoscope_group_block:
    case BlockType.horoscope_top_block:
    case BlockType.image_only_block:
    case BlockType.jokes_widget_block:
    case BlockType.memes_widget_block:
    case BlockType.mobile_menu:
    case BlockType.portal_footer_block:
    case BlockType.portal_header_block:
    case BlockType.portal_navigation_bar:
    case BlockType.promo_card_block:
    case BlockType.quotes_widget_block:
    case BlockType.recommendation:
    case BlockType.section_navigation_bar:
    case BlockType.ticker_block:
    case BlockType.visual_brief_block:
    case BlockType.voting_block:
    case BlockType.words_widget_block:
    case BlockType.youtube_video:
      return false

    case BlockType.firework_video_block:
    case BlockType.google_search_bar:
    case BlockType.house_deal_block:
    case BlockType.ms_news_block:
    case BlockType.nativo_block:
    case BlockType.outbrain_block:
    case BlockType.outbrain_widget_block:
    case BlockType.playbuzz_video_block:
    case BlockType.weather_widget_block:
      return true

    default:
      throw new Error(`GA4 dataLayer: isSponsored: blockType ${blockType} not supported`)
  }
}

export const getSponsoredType = blockType => {
  if (isSponsored(blockType)) {
    return DataLayerContentSourceType.sponsored
  }

  return DataLayerContentSourceType.editorial
}

export const getBlockInteractionType = blockType => {
  switch (blockType) {
    case BlockType.ad_sticky:
    case BlockType.ad_unit:
    case BlockType.affiliate_ad_grid_block:
    case BlockType.app_ad_grid_block:
    case BlockType.firework_video_block:
    case BlockType.fotoscape_block:
    case BlockType.fotoscape_gallery_block:
    case BlockType.full_article_block:
    case BlockType.google_search_bar:
    case BlockType.header_block_alert:
    case BlockType.house_deal_block:
    case BlockType.image_only_block:
    case BlockType.ms_news_block:
    case BlockType.nativo_block:
    case BlockType.outbrain_block:
    case BlockType.outbrain_widget_block:
    case BlockType.playbuzz_video_block:
    case BlockType.promo_card_block:
    case BlockType.ticker_block:
    case BlockType.visual_brief_block:
    case BlockType.weather_widget_block:
    case BlockType.youtube_video:
      return DataLayerInteractionType.external

    case BlockType.advice_of_day_block:
    case BlockType.app_download_block:
    case BlockType.button_nav:
    case BlockType.discover_bar:
    case BlockType.emoji_feedback_block:
    case BlockType.feedback_form_block:
    case BlockType.fortune_cookie_block:
    case BlockType.header_block:
    case BlockType.horoscope_block:
    case BlockType.horoscope_carousel_block:
    case BlockType.horoscope_details_block:
    case BlockType.horoscope_details_group_block:
    case BlockType.horoscope_discover_bar:
    case BlockType.horoscope_group_block:
    case BlockType.horoscope_top_block:
    case BlockType.jokes_widget_block:
    case BlockType.memes_widget_block:
    case BlockType.mobile_menu:
    case BlockType.portal_footer_block:
    case BlockType.portal_header_block:
    case BlockType.portal_navigation_bar:
    case BlockType.quotes_widget_block:
    case BlockType.recommendation:
    case BlockType.section_navigation_bar:
    case BlockType.voting_block:
    case BlockType.words_widget_block:
      return DataLayerInteractionType.internal

    default:
      throw new Error(`GA4 getBlockInteractionType: blockType ${blockType} not supported`)
  }
}
