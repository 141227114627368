const app_theme = document.documentElement.getAttribute('data-mp-app-theme')

const forbidBorder = event => {
  event.target.style.border = 'none'
}

const allowBorder = event => {
  event.target.style.removeProperty('border')
}

const addMultipleEventListener = (element, events, handler) => {
  events.forEach(e => element.addEventListener(e, handler))
}

const initGoogleSearchBar = () => {
  try {
    const searchBarForm: HTMLFormElement = document.querySelector('.google-search__form')

    if (!searchBarForm) return

    const searchBarInput: HTMLInputElement = searchBarForm.querySelector(
      '.google-search__text-input'
    )
    const searchButton: HTMLButtonElement = document.querySelector(
      'button[data-google-search-button]'
    )
    if (searchButton) {
      searchButton.addEventListener('click', function () {
        const isInvalid = searchBarInput.validationMessage.length > 0
        searchBarInput.setAttribute('aria-invalid', isInvalid.toString())
      })
    }

    addMultipleEventListener(searchBarInput, ['mousedown', 'touchstart'], forbidBorder)
    addMultipleEventListener(searchBarInput, ['blur', 'touchend'], allowBorder)

    if (app_theme === 'moment') {
      const searchBarContainer: HTMLElement = document.querySelector('.block--google-search-bar')

      const observer = new IntersectionObserver(
        ([e]) => e.target.classList.toggle('sticky', e.intersectionRatio < 1),
        { threshold: [1] }
      )
      observer.observe(searchBarContainer)
    }
  } catch (e) {
    console.error('Cannot init google search bar ', e)
  }
}

export default initGoogleSearchBar
