export default {
  0: { description: { en: 'Clear', es: 'Despejado' }, weatherChannelCode: 32 },
  1: { description: { en: 'Cloudy', es: 'Nublado' }, weatherChannelCode: 26 },
  2: {
    description: { en: 'Partly Cloudy', es: 'Parcialmente nublado' },
    weatherChannelCode: 29
  },
  3: {
    description: { en: 'Partly Cloudy', es: 'Parcialmente nublado' },
    weatherChannelCode: 30
  },
  4: {
    description: { en: 'Partly Sunny', es: 'Parcialmente soleado' },
    weatherChannelCode: 34
  },
  5: { description: { en: 'Rain', es: 'Lluvia' }, weatherChannelCode: 12 },
  6: {
    description: { en: 'Thunderstorms', es: 'Tormentas eléctricas' },
    weatherChannelCode: 3
  },
  7: { description: { en: 'Sunny', es: 'Soleado' }, weatherChannelCode: 36 },
  8: { description: { en: 'Snow', es: 'Nieve' }, weatherChannelCode: 16 },
  9: { description: { en: 'Flurries', es: 'Ráfagas' }, weatherChannelCode: 13 },
  10: { description: { en: 'Unknown', es: 'Desconocido' }, weatherChannelCode: '"na"' },
  11: {
    description: { en: 'Chance of Snow', es: 'Probabilidad de nieve' },
    weatherChannelCode: 41
  },
  12: { description: { en: 'Snow', es: 'Nieve' }, weatherChannelCode: 46 },
  13: { description: { en: 'Cloudy', es: 'Nublado' }, weatherChannelCode: 26 },
  14: { description: { en: 'Rain', es: 'Lluvia' }, weatherChannelCode: 45 },
  15: {
    description: { en: 'Chance of Rain', es: 'Probabilidad de lluvia' },
    weatherChannelCode: 39
  },
  16: {
    description: { en: 'Partly Cloudy', es: 'Parcialmente nublado' },
    weatherChannelCode: 30
  },
  17: { description: { en: 'Clear', es: 'Despejado' }, weatherChannelCode: 32 },
  18: {
    description: { en: 'Thunderstorms', es: 'Tormentas eléctricas' },
    weatherChannelCode: 47
  },
  19: {
    description: { en: 'Chance of Flurries', es: 'Probabilidad de ráfagas' },
    weatherChannelCode: 41
  },
  20: {
    description: { en: 'Chance of Rain', es: 'Probabilidad de lluvia' },
    weatherChannelCode: 45
  },
  21: {
    description: { en: 'Chance of Sleet', es: 'Probabilidad de aguanieve' },
    weatherChannelCode: 35
  },
  22: {
    description: { en: 'Chance of Storms', es: 'Probabilidad de tormentas' },
    weatherChannelCode: 38
  },
  23: { description: { en: 'Hazy', es: 'Brumoso' }, weatherChannelCode: 20 },
  24: {
    description: { en: 'Mostly Cloudy', es: 'Mayormente nublado' },
    weatherChannelCode: 28
  },
  25: { description: { en: 'Sleet', es: 'Aguanieve' }, weatherChannelCode: 35 },
  26: {
    description: { en: 'Mostly Sunny', es: 'Mayormente soleado' },
    weatherChannelCode: 32
  },
  27: {
    description: { en: 'Chance of Flurries', es: 'Probabilidad de ráfagas' },
    weatherChannelCode: 46
  },
  28: {
    description: { en: 'Chance of Sleet', es: 'Probabilidad de aguanieve' },
    weatherChannelCode: 35
  },
  29: {
    description: { en: 'Chance of Snow', es: 'Probabilidad de nieve' },
    weatherChannelCode: 46
  },
  30: {
    description: { en: 'Chance of Storms (Night)', es: 'Probabilidad de Tormentas (Noche)' },
    weatherChannelCode: 47
  },
  31: {
    description: { en: 'Clear (Night)', es: 'Despejado (Noche)' },
    weatherChannelCode: 32
  },
  32: { description: { en: 'Flurries', es: 'Ráfagas' }, weatherChannelCode: 13 },
  33: { description: { en: 'Hazy (Night)', es: 'Brumoso (Noche)' }, weatherChannelCode: 20 },
  34: {
    description: { en: 'Mostly Cloudy (Night)', es: 'Mayormente nublado (noche)' },
    weatherChannelCode: 28
  },
  35: {
    description: { en: 'Mostly Sunny', es: 'Mayormente soleado' },
    weatherChannelCode: 32
  },
  36: { description: { en: 'Sleet', es: 'Aguanieve' }, weatherChannelCode: 6 },
  37: {
    description: { en: 'Unknown (Night)', es: 'Desconocido (Noche)' },
    weatherChannelCode: '"na"'
  },
  38: {
    description: { en: 'Chance of Rain Showers', es: 'Probabilidad de lluvias' },
    weatherChannelCode: 45
  },
  39: {
    description: { en: 'Chance of Snow Showers', es: 'Probabilidad de chubascos de nieve' },
    weatherChannelCode: 46
  },
  40: { description: { en: 'Snow Showers', es: 'Duchas de nieve' }, weatherChannelCode: 42 },
  41: { description: { en: 'Rain Showers', es: 'Lluvias' }, weatherChannelCode: 40 },
  42: {
    description: { en: 'Chance of Rain Showers', es: 'Probabilidad de lluvias' },
    weatherChannelCode: 45
  },
  43: {
    description: { en: 'Chance of Snow Showers', es: 'Probabilidad de chubascos de nieve' },
    weatherChannelCode: 46
  },
  44: { description: { en: 'Snow Showers', es: 'Duchas de nieve' }, weatherChannelCode: 46 },
  45: { description: { en: 'Rain Showers', es: 'Lluvias' }, weatherChannelCode: 45 },
  46: { description: { en: 'Freezing Rain', es: 'Lluvia helada' }, weatherChannelCode: 35 },
  47: { description: { en: 'Freezing Rain', es: 'Lluvia helada' }, weatherChannelCode: 35 },
  48: {
    description: { en: 'Chance of Freezing Rain', es: 'Probabilidad de lluvia helada' },
    weatherChannelCode: 35
  },
  49: {
    description: { en: 'Chance of Freezing Rain', es: 'Probabilidad de lluvia helada' },
    weatherChannelCode: 35
  },
  50: { description: { en: 'Windy', es: 'Ventoso' }, weatherChannelCode: 24 },
  51: { description: { en: 'Foggy', es: 'Neblinoso' }, weatherChannelCode: 20 },
  52: {
    description: { en: 'Scattered Showers', es: 'Chubascos dispersos' },
    weatherChannelCode: 12
  },
  53: {
    description: { en: 'Scattered Thunderstorms', es: 'Tormentas eléctricas dispersas' },
    weatherChannelCode: 38
  },
  54: { description: { en: 'Snow', es: 'Nieve' }, weatherChannelCode: 41 },
  55: {
    description: { en: 'Chance of Snow', es: 'Probabilidad de nieve' },
    weatherChannelCode: 41
  },
  56: { description: { en: 'Frozen Mix', es: 'Mezcla Congelada' }, weatherChannelCode: 10 },
  57: {
    description: { en: 'Chance of Frozen Mix', es: 'Posibilidad de mezcla congelada' },
    weatherChannelCode: 10
  },
  58: { description: { en: 'Drizzle', es: 'Llovizna' }, weatherChannelCode: 11 },
  59: {
    description: { en: 'Chance of Drizzle', es: 'Probabilidad de llovizna' },
    weatherChannelCode: 11
  },
  60: {
    description: { en: 'Freezing Drizzle', es: 'Llovizna helada' },
    weatherChannelCode: 8
  },
  61: {
    description: { en: 'Chance of Freezing Drizzle', es: 'Probabilidad de llovizna helada' },
    weatherChannelCode: 8
  },
  62: { description: { en: 'Heavy Snow', es: 'Fuertes nevadas' }, weatherChannelCode: 42 },
  63: { description: { en: 'Heavy Rain', es: 'Lluvia Pesada' }, weatherChannelCode: 40 },
  64: {
    description: { en: 'Hot and Humid', es: 'Caliente y húmedo' },
    weatherChannelCode: 32
  },
  65: { description: { en: 'Very Hot', es: 'Muy caliente' }, weatherChannelCode: 36 },
  66: {
    description: { en: 'Increasing Clouds', es: 'nubes crecientes' },
    weatherChannelCode: 28
  },
  67: { description: { en: 'Clearing', es: 'Despejando' }, weatherChannelCode: 32 },
  68: {
    description: { en: 'Mostly Cloudy', es: 'Mayormente nublado' },
    weatherChannelCode: 28
  },
  69: { description: { en: 'Very Cold', es: 'Muy frío' }, weatherChannelCode: 23 },
  70: {
    description: { en: 'Mostly Clear', es: 'Mayormente Despejado' },
    weatherChannelCode: 34
  },
  71: {
    description: { en: 'Increasing Clouds', es: 'nubes crecientes' },
    weatherChannelCode: 28
  },
  72: { description: { en: 'Clearing', es: 'Despejando' }, weatherChannelCode: 29 },
  73: {
    description: { en: 'Mostly Cloudy', es: 'Mayormente nublado' },
    weatherChannelCode: 27
  },
  74: { description: { en: 'Very Cold', es: 'Muy frío' }, weatherChannelCode: 23 },
  75: { description: { en: 'Warm and Humid', es: 'cálido y húmedo' }, weatherChannelCode: 31 },
  76: { description: { en: 'Now', es: 'Ahora' }, weatherChannelCode: 'na' },
  77: { description: { en: 'Exclamation', es: 'Exclamación' }, weatherChannelCode: 'na' },
  78: {
    description: { en: '30% Chance of Snow', es: '30% de probabilidad de nieve' },
    weatherChannelCode: 41
  },
  79: {
    description: { en: '40% Chance of Snow', es: '40% de probabilidad de nieve' },
    weatherChannelCode: 41
  },
  80: {
    description: { en: '50% Chance of Snow', es: '50% de probabilidad de nieve' },
    weatherChannelCode: 41
  },
  81: {
    description: { en: '30% Chance of Rain', es: '30% de probabilidad de lluvia' },
    weatherChannelCode: 45
  },
  82: {
    description: { en: '40% Chance of Rain', es: '40% de probabilidad de lluvia' },
    weatherChannelCode: 45
  },
  83: {
    description: { en: '50% Chance of Rain', es: '50% de probabilidad de lluvia' },
    weatherChannelCode: 45
  },
  84: {
    description: { en: '30% Chance of Flurries', es: '30% de probabilidad de ráfagas' },
    weatherChannelCode: 41
  },
  85: {
    description: { en: '40% Chance of Flurries', es: '40% de probabilidad de ráfagas' },
    weatherChannelCode: 41
  },
  86: {
    description: { en: '50% Chance of Flurries', es: '50% de probabilidad de ráfagas' },
    weatherChannelCode: 41
  },
  87: {
    description: { en: '30% Chance of Rain', es: '30% de probabilidad de lluvia' },
    weatherChannelCode: 39
  },
  88: {
    description: { en: '40% Chance of Rain', es: '40% de probabilidad de lluvia' },
    weatherChannelCode: 39
  },
  89: {
    description: { en: '50% Chance of Rain', es: '50% de probabilidad de lluvia' },
    weatherChannelCode: 39
  },
  90: {
    description: { en: '30% Chance of Sleet', es: '30% de probabilidad de aguanieve' },
    weatherChannelCode: 6
  },
  91: {
    description: { en: '40% Chance of Sleet', es: '40% de probabilidad de aguanieve' },
    weatherChannelCode: 6
  },
  92: {
    description: { en: '50% Chance of Sleet', es: '50% de probabilidad de aguanieve' },
    weatherChannelCode: 6
  },
  93: {
    description: { en: '30% Chance of Storms', es: '30% de probabilidad de tormentas' },
    weatherChannelCode: 38
  },
  94: {
    description: { en: '40% Chance of Storms', es: '40% de probabilidad de tormentas' },
    weatherChannelCode: 38
  },
  95: {
    description: { en: '50% Chance of Storms', es: '50% de probabilidad de tormentas' },
    weatherChannelCode: 38
  },
  96: {
    description: { en: '30% Chance of Flurries', es: '30% de probabilidad de ráfagas' },
    weatherChannelCode: 46
  },
  97: {
    description: { en: '40% Chance of Flurries', es: '40% de probabilidad de ráfagas' },
    weatherChannelCode: 46
  },
  98: {
    description: { en: '50% Chance of Flurries', es: '50% de probabilidad de ráfagas' },
    weatherChannelCode: 46
  },
  99: {
    description: { en: '30% Chance of Sleet', es: '30% de probabilidad de aguanieve' },
    weatherChannelCode: 6
  },
  100: {
    description: { en: '40% Chance of Sleet', es: '40% de probabilidad de aguanieve' },
    weatherChannelCode: 6
  },
  101: {
    description: { en: '50% Chance of Sleet', es: '50% de probabilidad de aguanieve' },
    weatherChannelCode: 6
  },
  102: {
    description: { en: '30% Chance of Snow', es: '30% de probabilidad de nieve' },
    weatherChannelCode: 46
  },
  103: {
    description: { en: '40% Chance of Snow', es: '40% de probabilidad de nieve' },
    weatherChannelCode: 46
  },
  104: {
    description: { en: '50% Chance of Snow', es: '50% de probabilidad de nieve' },
    weatherChannelCode: 46
  },
  105: {
    description: { en: '30% Chance of Storms', es: '30% de probabilidad de tormentas' },
    weatherChannelCode: 47
  },
  106: {
    description: { en: '40% Chance of Storms', es: '40% de probabilidad de tormentas' },
    weatherChannelCode: 47
  },
  107: {
    description: { en: '50% Chance of Storms', es: '50% de probabilidad de tormentas' },
    weatherChannelCode: 47
  },
  108: {
    description: { en: '30% Chance of Rain Showers', es: '30% de probabilidad de lluvias' },
    weatherChannelCode: 39
  },
  109: {
    description: { en: '40% Chance of Rain Showers', es: '40% de probabilidad de lluvias' },
    weatherChannelCode: 39
  },
  110: {
    description: { en: '50% Chance of Rain Showers', es: '50% de probabilidad de lluvias' },
    weatherChannelCode: 39
  },
  111: {
    description: {
      en: '30% Chance of Snow Showers',
      es: '30% de probabilidad de chubascos de nieve'
    },
    weatherChannelCode: 41
  },
  112: {
    description: {
      en: '40% Chance of Snow Showers',
      es: '40% Probabilidad de Lluvias de Nieve'
    },
    weatherChannelCode: 41
  },
  113: {
    description: {
      en: '50% Chance of Snow Showers',
      es: '50% de probabilidad de chubascos de nieve'
    },
    weatherChannelCode: 41
  },
  114: {
    description: { en: '30% Chance of Rain Showers', es: '30% de probabilidad de lluvias' },
    weatherChannelCode: 45
  },
  115: {
    description: { en: '40% Chance of Rain Showers', es: '40% de probabilidad de lluvias' },
    weatherChannelCode: 45
  },
  116: {
    description: { en: '50% Chance of Rain Showers', es: '50% de probabilidad de lluvias' },
    weatherChannelCode: 45
  },
  117: {
    description: {
      en: '30% Chance of Snow Showers',
      es: '30% de probabilidad de chubascos de nieve'
    },
    weatherChannelCode: 46
  },
  118: {
    description: {
      en: '40% Chance of Snow Showers',
      es: '40% Probabilidad de Lluvias de Nieve'
    },
    weatherChannelCode: 46
  },
  119: {
    description: {
      en: '50% Chance of Snow Showers',
      es: '50% de probabilidad de chubascos de nieve'
    },
    weatherChannelCode: 46
  },
  120: {
    description: {
      en: '30% Chance of Freezing Rain',
      es: '30% de probabilidad de lluvia helada'
    },
    weatherChannelCode: 35
  },
  121: {
    description: {
      en: '40% Chance of Freezing Rain',
      es: '40% de probabilidad de lluvia helada'
    },
    weatherChannelCode: 35
  },
  122: {
    description: {
      en: '50% Chance of Freezing Rain',
      es: '50% de probabilidad de lluvia helada'
    },
    weatherChannelCode: 35
  },
  123: {
    description: {
      en: '30% Chance of Freezing Rain',
      es: '30% de probabilidad de lluvia helada'
    },
    weatherChannelCode: 35
  },
  124: {
    description: {
      en: '40% Chance of Freezing Rain',
      es: '40% de probabilidad de lluvia helada'
    },
    weatherChannelCode: 35
  },
  125: {
    description: {
      en: '50% Chance of Freezing Rain',
      es: '50% de probabilidad de lluvia helada'
    },
    weatherChannelCode: 35
  },
  126: {
    description: { en: '30% Chance of Snow', es: '30% de probabilidad de nieve' },
    weatherChannelCode: 41
  },
  127: {
    description: { en: '40% Chance of Snow', es: '40% de probabilidad de nieve' },
    weatherChannelCode: 41
  },
  128: {
    description: { en: '50% Chance of Snow', es: '50% de probabilidad de nieve' },
    weatherChannelCode: 41
  },
  129: {
    description: {
      en: '30% Chance of Frozen Mix',
      es: '30% de probabilidad de mezcla congelada'
    },
    weatherChannelCode: 10
  },
  130: {
    description: {
      en: '40% Chance of Frozen Mix',
      es: '40% de probabilidad de mezcla congelada'
    },
    weatherChannelCode: 10
  },
  131: {
    description: {
      en: '50% Chance of Frozen Mix',
      es: '50% de probabilidad de mezcla congelada'
    },
    weatherChannelCode: 10
  },
  132: {
    description: { en: '30% Chance of Drizzle', es: '30% de probabilidad de llovizna' },
    weatherChannelCode: 11
  },
  133: {
    description: { en: '40% Chance of Drizzle', es: '40% de probabilidad de llovizna' },
    weatherChannelCode: 11
  },
  134: {
    description: { en: '50% Chance of Drizzle', es: '50% de probabilidad de llovizna' },
    weatherChannelCode: 11
  },
  135: {
    description: {
      en: '30% Chance of Freezing Drizzle',
      es: '30% de probabilidad de llovizna helada'
    },
    weatherChannelCode: 8
  },
  136: {
    description: {
      en: '40% Chance of Freezing Drizzle',
      es: '40% de probabilidad de llovizna helada'
    },
    weatherChannelCode: 8
  },
  137: {
    description: {
      en: '50% Chance of Freezing Drizzle',
      es: '50% de probabilidad de llovizna helada'
    },
    weatherChannelCode: 8
  },
  138: {
    description: { en: 'Chance of Snow', es: 'Probabilidad de nieve' },
    weatherChannelCode: 41
  },
  139: {
    description: { en: 'Chance of Rain', es: 'Probabilidad de lluvia' },
    weatherChannelCode: 39
  },
  140: {
    description: { en: 'Chance of Flurries', es: 'Probabilidad de ráfagas' },
    weatherChannelCode: 41
  },
  141: {
    description: { en: 'Chance of Rain', es: 'Probabilidad de lluvia' },
    weatherChannelCode: 39
  },
  142: {
    description: { en: 'Chance of Sleet', es: 'Probabilidad de aguanieve' },
    weatherChannelCode: 6
  },
  143: {
    description: { en: 'Chance of Storms', es: 'Probabilidad de tormentas' },
    weatherChannelCode: 38
  },
  144: {
    description: { en: 'Chance of Flurries', es: 'Probabilidad de ráfagas' },
    weatherChannelCode: 41
  },
  145: {
    description: { en: 'Chance of Sleet', es: 'Probabilidad de aguanieve' },
    weatherChannelCode: 6
  },
  146: {
    description: { en: 'Chance of Snow', es: 'Probabilidad de nieve' },
    weatherChannelCode: 41
  },
  147: {
    description: { en: 'Chance of Storms', es: 'Probabilidad de tormentas' },
    weatherChannelCode: 38
  },
  148: {
    description: { en: 'Chance of Rain Showers', es: 'Probabilidad de lluvias' },
    weatherChannelCode: 39
  },
  149: {
    description: { en: 'Chance of Snow Showers', es: 'Probabilidad de chubascos de nieve' },
    weatherChannelCode: 41
  },
  150: {
    description: { en: 'Chance of Rain Showers', es: 'Probabilidad de lluvias' },
    weatherChannelCode: 39
  },
  151: {
    description: { en: 'Chance of Snow Showers', es: 'Probabilidad de chubascos de nieve' },
    weatherChannelCode: 41
  },
  152: {
    description: { en: 'Chance of Freezing Rain', es: 'Probabilidad de lluvia helada' },
    weatherChannelCode: 35
  },
  153: {
    description: { en: 'Chance of Freezing Rain', es: 'Probabilidad de lluvia helada' },
    weatherChannelCode: 35
  },
  154: {
    description: { en: 'Chance of Snow', es: 'Probabilidad de nieve' },
    weatherChannelCode: 41
  },
  155: {
    description: { en: 'Chance of Frozen Mix', es: 'Posibilidad de mezcla congelada' },
    weatherChannelCode: 10
  },
  156: {
    description: { en: 'Chance of Drizzle', es: 'Probabilidad de llovizna' },
    weatherChannelCode: 11
  },
  157: {
    description: { en: 'Chance of Freezing Drizzle', es: 'Probabilidad de llovizna helada' },
    weatherChannelCode: 8
  },
  158: { description: { en: 'Windy', es: 'Ventoso' }, weatherChannelCode: 24 },
  159: { description: { en: 'Foggy', es: 'Neblinoso' }, weatherChannelCode: 20 },
  160: { description: { en: 'Snow', es: 'Nieve' }, weatherChannelCode: 46 },
  161: { description: { en: 'Frozen Mix', es: 'Mezcla Congelada' }, weatherChannelCode: 10 },
  162: { description: { en: 'Drizzle', es: 'Llovizna' }, weatherChannelCode: 11 },
  163: { description: { en: 'Heavy Rain', es: 'Lluvia Pesada' }, weatherChannelCode: 40 },
  164: {
    description: { en: 'Chance of Frozen Mix', es: 'Posibilidad de mezcla congelada' },
    weatherChannelCode: 10
  },
  165: {
    description: { en: 'Chance of Drizzle', es: 'Probabilidad de llovizna' },
    weatherChannelCode: 11
  },
  166: {
    description: { en: 'Chance of Frozen Drizzle', es: 'Probabilidad de llovizna helada' },
    weatherChannelCode: 8
  },
  167: {
    description: { en: '30% Chance of Drizzle', es: '30% de probabilidad de llovizna' },
    weatherChannelCode: 11
  },
  168: {
    description: {
      en: '30% Chance Frozen Drizzle',
      es: '30% de probabilidad de llovizna congelada'
    },
    weatherChannelCode: 8
  },
  169: {
    description: {
      en: '30% Chance of Frozen Mix',
      es: '30% de probabilidad de mezcla congelada'
    },
    weatherChannelCode: 10
  },
  170: {
    description: { en: '40% Chance of Drizzle', es: '40% de probabilidad de llovizna' },
    weatherChannelCode: 11
  },
  171: {
    description: {
      en: '40% Chance Frozen Drizzle',
      es: '40% de probabilidad de llovizna congelada'
    },
    weatherChannelCode: 8
  },
  172: {
    description: { en: '40% Chance of FrozenMix', es: '40% de probabilidad de FrozenMix' },
    weatherChannelCode: 10
  },
  173: {
    description: { en: '50% Chance of Drizzle', es: '50% de probabilidad de llovizna' },
    weatherChannelCode: 11
  },
  174: {
    description: {
      en: '50% Chance Frozen Drizzle',
      es: '50% de probabilidad de llovizna congelada'
    },
    weatherChannelCode: 8
  },
  175: {
    description: { en: '50% Chance of FrozenMix', es: '50% de probabilidad de FrozenMix' },
    weatherChannelCode: 10
  },
  176: {
    description: { en: 'Chance of Snow', es: 'Probabilidad de nieve' },
    weatherChannelCode: 46
  },
  177: {
    description: { en: '30% Chance of Snow', es: '30% de probabilidad de nieve' },
    weatherChannelCode: 46
  },
  178: {
    description: { en: '40% Chance of Snow', es: '40% de probabilidad de nieve' },
    weatherChannelCode: 46
  },
  179: {
    description: { en: '50% Chance of Snow', es: '50% de probabilidad de nieve' },
    weatherChannelCode: 46
  },
  180: {
    description: { en: 'Scattered Thunderstorms', es: 'Tormentas eléctricas dispersas' },
    weatherChannelCode: 38
  },
  181: {
    description: { en: 'Freezing Drizzle', es: 'Llovizna helada' },
    weatherChannelCode: 8
  },
  182: {
    description: { en: 'Scattered Showers', es: 'Chubascos dispersos' },
    weatherChannelCode: 39
  },
  183: {
    description: { en: 'Scattered Thunderstorms', es: 'Tormentas eléctricas dispersas' },
    weatherChannelCode: 47
  },
  184: {
    description: { en: 'Warm and Humid', es: 'cálido y húmedo' },
    weatherChannelCode: 32
  },
  185: {
    description: { en: '60% Chance of Snow', es: '60% de probabilidad de nieve' },
    weatherChannelCode: 41
  },
  186: {
    description: { en: '70% Chance of Snow', es: '70% de probabilidad de nieve' },
    weatherChannelCode: 41
  },
  187: {
    description: { en: '80% Chance of Snow', es: '80% de probabilidad de nieve' },
    weatherChannelCode: 41
  },
  188: {
    description: { en: '60% Chance of Rain', es: '60% de probabilidad de lluvia' },
    weatherChannelCode: 45
  },
  189: {
    description: { en: '70% Chance of Rain', es: '70% de probabilidad de lluvia' },
    weatherChannelCode: 45
  },
  190: {
    description: { en: '80% Chance of Rain', es: '80% de probabilidad de lluvia' },
    weatherChannelCode: 45
  },
  191: {
    description: { en: '60% Chance of Flurries', es: '60% de probabilidad de ráfagas' },
    weatherChannelCode: 41
  },
  192: {
    description: { en: '70% Chance of Flurries', es: '70% de probabilidad de ráfagas' },
    weatherChannelCode: 41
  },
  193: {
    description: { en: '80% Chance of Flurries', es: '80% de probabilidad de ráfagas' },
    weatherChannelCode: 41
  },
  194: {
    description: { en: '60% Chance of Rain', es: '60% de probabilidad de lluvia' },
    weatherChannelCode: 39
  },
  195: {
    description: { en: '70% Chance of Rain', es: '70% de probabilidad de lluvia' },
    weatherChannelCode: 39
  },
  196: {
    description: { en: '80% Chance of Rain', es: '80% de probabilidad de lluvia' },
    weatherChannelCode: 39
  },
  197: {
    description: { en: '60% Chance of Sleet', es: '60% de probabilidad de aguanieve' },
    weatherChannelCode: 6
  },
  198: {
    description: { en: '70% Chance of Sleet', es: '70% de probabilidad de aguanieve' },
    weatherChannelCode: 6
  },
  199: {
    description: { en: '80% Chance of Sleet', es: '80% de probabilidad de aguanieve' },
    weatherChannelCode: 6
  },
  200: {
    description: { en: '60% Chance of Storms', es: '60% de probabilidad de tormentas' },
    weatherChannelCode: 38
  },
  201: {
    description: { en: '70% Chance of Storms', es: '70% de probabilidad de tormentas' },
    weatherChannelCode: 38
  },
  202: {
    description: { en: '80% Chance of Storms', es: '80% de probabilidad de tormentas' },
    weatherChannelCode: 38
  },
  203: {
    description: { en: '60% Chance of Flurries', es: '60% de probabilidad de ráfagas' },
    weatherChannelCode: 46
  },
  204: {
    description: { en: '70% Chance of Flurries', es: '70% de probabilidad de ráfagas' },
    weatherChannelCode: 46
  },
  205: {
    description: { en: '80% Chance of Flurries', es: '80% de probabilidad de ráfagas' },
    weatherChannelCode: 46
  },
  206: {
    description: { en: '60% Chance of Sleet', es: '60% de probabilidad de aguanieve' },
    weatherChannelCode: 6
  },
  207: {
    description: { en: '70% Chance of Sleet', es: '70% de probabilidad de aguanieve' },
    weatherChannelCode: 6
  },
  208: {
    description: { en: '80% Chance of Sleet', es: '80% de probabilidad de aguanieve' },
    weatherChannelCode: 6
  },
  209: {
    description: { en: '60% Chance of Snow', es: '60% de probabilidad de nieve' },
    weatherChannelCode: 46
  },
  210: {
    description: { en: '70% Chance of Snow', es: '70% de probabilidad de nieve' },
    weatherChannelCode: 46
  },
  211: {
    description: { en: '80% Chance of Snow', es: '80% de probabilidad de nieve' },
    weatherChannelCode: 46
  },
  212: {
    description: { en: '60% Chance of Storms', es: '60% de probabilidad de tormentas' },
    weatherChannelCode: 47
  },
  213: {
    description: { en: '70% Chance of Storms', es: '70% de probabilidad de tormentas' },
    weatherChannelCode: 47
  },
  214: {
    description: { en: '80% Chance of Storms', es: '80% de probabilidad de tormentas' },
    weatherChannelCode: 47
  },
  215: {
    description: { en: '60% Chance of Rain Showers', es: '60% de probabilidad de lluvias' },
    weatherChannelCode: 39
  },
  216: {
    description: { en: '70% Chance of Rain Showers', es: '70% de probabilidad de lluvias' },
    weatherChannelCode: 39
  },
  217: {
    description: { en: '80% Chance of Rain Showers', es: '80% de probabilidad de lluvias' },
    weatherChannelCode: 39
  },
  218: {
    description: {
      en: '60% Chance of Snow Showers',
      es: '60% Probabilidad de Lluvias de Nieve'
    },
    weatherChannelCode: 41
  },
  219: {
    description: {
      en: '70% Chance of Snow Showers',
      es: '70% Probabilidad de Lluvias de Nieve'
    },
    weatherChannelCode: 41
  },
  220: {
    description: {
      en: '80% Chance of Snow Showers',
      es: '80% Probabilidad de chubascos de nieve'
    },
    weatherChannelCode: 41
  },
  221: {
    description: { en: '60% Chance of Rain Showers', es: '60% de probabilidad de lluvias' },
    weatherChannelCode: 45
  },
  222: {
    description: { en: '70% Chance of Rain Showers', es: '70% de probabilidad de lluvias' },
    weatherChannelCode: 45
  },
  223: {
    description: { en: '80% Chance of Rain Showers', es: '80% de probabilidad de lluvias' },
    weatherChannelCode: 45
  },
  224: {
    description: {
      en: '60% Chance of Snow Showers',
      es: '60% Probabilidad de Lluvias de Nieve'
    },
    weatherChannelCode: 46
  },
  225: {
    description: {
      en: '70% Chance of Snow Showers',
      es: '70% Probabilidad de Lluvias de Nieve'
    },
    weatherChannelCode: 46
  },
  226: {
    description: {
      en: '80% Chance of Snow Showers',
      es: '80% Probabilidad de chubascos de nieve'
    },
    weatherChannelCode: 46
  },
  227: {
    description: {
      en: '60% Chance of Freezing Rain',
      es: '60% de probabilidad de lluvia helada'
    },
    weatherChannelCode: 35
  },
  228: {
    description: {
      en: '70% Chance of Freezing Rain',
      es: '70% de probabilidad de lluvia helada'
    },
    weatherChannelCode: 35
  },
  229: {
    description: {
      en: '80% Chance of Freezing Rain',
      es: '80% de probabilidad de lluvia helada'
    },
    weatherChannelCode: 35
  },
  230: {
    description: {
      en: '60% Chance of Freezing Rain',
      es: '60% de probabilidad de lluvia helada'
    },
    weatherChannelCode: 35
  },
  231: {
    description: {
      en: '70% Chance of Freezing Rain',
      es: '70% de probabilidad de lluvia helada'
    },
    weatherChannelCode: 35
  },
  232: {
    description: {
      en: '80% Chance of Freezing Rain',
      es: '80% de probabilidad de lluvia helada'
    },
    weatherChannelCode: 35
  },
  233: {
    description: { en: '60% Chance of Snow', es: '60% de probabilidad de nieve' },
    weatherChannelCode: 41
  },
  234: {
    description: { en: '70% Chance of Snow', es: '70% de probabilidad de nieve' },
    weatherChannelCode: 41
  },
  235: {
    description: { en: '80% Chance of Snow', es: '80% de probabilidad de nieve' },
    weatherChannelCode: 41
  },
  236: {
    description: {
      en: '60% Chance of Frozen Mix',
      es: '60% de probabilidad de mezcla congelada'
    },
    weatherChannelCode: 10
  },
  237: {
    description: {
      en: '70% Chance of Frozen Mix',
      es: '70% de probabilidad de mezcla congelada'
    },
    weatherChannelCode: 10
  },
  238: {
    description: {
      en: '80% Chance of Frozen Mix',
      es: '80% de probabilidad de mezcla congelada'
    },
    weatherChannelCode: 10
  },
  239: {
    description: { en: '60% Chance of Drizzle', es: '60% de probabilidad de llovizna' },
    weatherChannelCode: 11
  },
  240: {
    description: { en: '70% Chance of Drizzle', es: '70% de probabilidad de llovizna' },
    weatherChannelCode: 11
  },
  241: {
    description: { en: '80% Chance of Drizzle', es: '80% de probabilidad de llovizna' },
    weatherChannelCode: 11
  },
  242: {
    description: {
      en: '60% Chance of Freezing Drizzle',
      es: '60% de probabilidad de llovizna helada'
    },
    weatherChannelCode: 8
  },
  243: {
    description: {
      en: '70% Chance of Freezing Drizzle',
      es: '70% de probabilidad de llovizna helada'
    },
    weatherChannelCode: 8
  },
  244: {
    description: {
      en: '80% Chance of Freezing Drizzle',
      es: '80% de probabilidad de llovizna helada'
    },
    weatherChannelCode: 8
  },
  245: {
    description: { en: '60% Chance of Snow', es: '60% de probabilidad de nieve' },
    weatherChannelCode: 46
  },
  246: {
    description: { en: '70% Chance of Snow', es: '70% de probabilidad de nieve' },
    weatherChannelCode: 46
  },
  247: {
    description: { en: '80% Chance of Snow', es: '80% de probabilidad de nieve' },
    weatherChannelCode: 46
  },
  248: {
    description: {
      en: '60% Chance of Frozen Mix',
      es: '60% de probabilidad de mezcla congelada'
    },
    weatherChannelCode: 10
  },
  249: {
    description: {
      en: '70% Chance of Frozen Mix',
      es: '70% de probabilidad de mezcla congelada'
    },
    weatherChannelCode: 10
  },
  250: {
    description: {
      en: '80% Chance of Frozen Mix',
      es: '80% de probabilidad de mezcla congelada'
    },
    weatherChannelCode: 10
  },
  251: {
    description: { en: 'Chance of Rain', es: 'Probabilidad de lluvia' },
    weatherChannelCode: 45
  },
  252: {
    description: { en: '30% Chance of Rain', es: '30% de probabilidad de lluvia' },
    weatherChannelCode: 45
  },
  253: {
    description: { en: '40% Chance of Rain', es: '40% de probabilidad de lluvia' },
    weatherChannelCode: 45
  },
  254: {
    description: { en: '50% Chance of Rain', es: '50% de probabilidad de lluvia' },
    weatherChannelCode: 45
  },
  255: {
    description: { en: '60% Chance of Rain', es: '60% de probabilidad de lluvia' },
    weatherChannelCode: 45
  },
  256: {
    description: { en: '70% Chance of Rain', es: '70% de probabilidad de lluvia' },
    weatherChannelCode: 45
  },
  257: {
    description: { en: '80% Chance of Rain', es: '80% de probabilidad de lluvia' },
    weatherChannelCode: 45
  },
  258: { description: { en: 'Rain', es: 'Lluvia' }, weatherChannelCode: 45 },
  259: {
    description: { en: 'Chance of Rain', es: 'Probabilidad de lluvia' },
    weatherChannelCode: 11
  },
  260: {
    description: { en: '30% Chance of Rain', es: '30% de probabilidad de lluvia' },
    weatherChannelCode: 11
  },
  261: {
    description: { en: '40% Chance of Rain', es: '40% de probabilidad de lluvia' },
    weatherChannelCode: 11
  },
  262: {
    description: { en: '50% Chance of Rain', es: '50% de probabilidad de lluvia' },
    weatherChannelCode: 11
  },
  263: {
    description: { en: '60% Chance of Rain', es: '60% de probabilidad de lluvia' },
    weatherChannelCode: 11
  },
  264: {
    description: { en: '70% Chance of Rain', es: '70% de probabilidad de lluvia' },
    weatherChannelCode: 11
  },
  265: {
    description: { en: '80% Chance of Rain', es: '80% de probabilidad de lluvia' },
    weatherChannelCode: 11
  },
  266: { description: { en: 'Rain', es: 'Lluvia' }, weatherChannelCode: 11 },
  267: { description: { en: 'Heavy Snow', es: 'Fuertes nevadas' }, weatherChannelCode: 42 },
  268: {
    description: { en: 'Chance of Heavy Snow', es: 'Probabilidad de nevadas intensas' },
    weatherChannelCode: 42
  },
  269: {
    description: {
      en: '30 % Chance of Heavy Snow',
      es: '30 % de probabilidad de nevadas intensas'
    },
    weatherChannelCode: 42
  },
  270: {
    description: {
      en: '40% Chance of Heavy Snow',
      es: '40% de probabilidad de nevadas intensas'
    },
    weatherChannelCode: 42
  },
  271: {
    description: {
      en: '50% Chance of Heavy Snow',
      es: '50% de probabilidad de nevadas intensas'
    },
    weatherChannelCode: 42
  },
  272: {
    description: {
      en: '60% Chance of Heavy Snow',
      es: '60% de probabilidad de nevadas intensas'
    },
    weatherChannelCode: 42
  },
  273: {
    description: {
      en: '70% Chance of Heavy Snow',
      es: '70% de probabilidad de nevadas intensas'
    },
    weatherChannelCode: 42
  },
  274: {
    description: {
      en: '80% Chance of Heavy Snow',
      es: '80% de probabilidad de nevadas intensas'
    },
    weatherChannelCode: 42
  },
  275: { description: { en: 'Heavy Snow', es: 'Fuertes nevadas' }, weatherChannelCode: 42 },
  276: {
    description: { en: 'Chance of Heavy Snow', es: 'Probabilidad de nevadas intensas' },
    weatherChannelCode: 42
  },
  277: {
    description: {
      en: '30% Chance of Heavy Snow',
      es: '30% de probabilidad de nevadas intensas'
    },
    weatherChannelCode: 42
  },
  278: {
    description: {
      en: '40% Chance of Heavy Snow',
      es: '40% de probabilidad de nevadas intensas'
    },
    weatherChannelCode: 42
  },
  279: {
    description: {
      en: '50% Chance of Heavy Snow',
      es: '50% de probabilidad de nevadas intensas'
    },
    weatherChannelCode: 42
  },
  280: {
    description: {
      en: '60% Chance of Heavy Snow',
      es: '60% de probabilidad de nevadas intensas'
    },
    weatherChannelCode: 42
  },
  281: {
    description: {
      en: '70% Chance of Heavy Snow',
      es: '70% de probabilidad de nevadas intensas'
    },
    weatherChannelCode: 42
  },
  282: {
    description: {
      en: '80% Chance of Heavy Snow',
      es: '80% de probabilidad de nevadas intensas'
    },
    weatherChannelCode: 42
  }
}
