import { appendInternalTrackingSearchParams } from '../../../utilities/analytics'
import { FeedItemContext } from 'content/src/lib/feed/interfaces'
import { removeSpecialCharacters, createAttributes } from '../utils'
import { header, footer, language, media } from '../feed_item'

const dealsListTemplate = (feedItem: FeedItemContext) => {
  const {
    link,
    title,
    duration,
    publisherLogo,
    publisherDarkLogo,
    publisherName,
    isVideoLink,
    thumbnailUrl,
    isSponsored,
    contentType,
    contentTypeText,
    description,
    ms_news,
    contentApiId,
    pub_time,
    layout
  } = feedItem

  return `
    <article ${createAttributes(feedItem)}>
      <a data-mp-cct
        class='block-item__body'
        href='${appendInternalTrackingSearchParams(link)}'>
      
        ${media(thumbnailUrl, isVideoLink, duration, layout)}
  
        ${header(
          isSponsored,
          publisherDarkLogo,
          publisherLogo,
          publisherName,
          isVideoLink,
          ms_news,
          layout
        )}
        <h1 class="block-item__title ${language}">
          <span class="block-item__headline">${title}</span>
          <span class="block-item__subtitle ${language}"></span>
        </h1>
        
        <p class="block-item__description ${language}">${removeSpecialCharacters(description)}</p>
      </a>
 
       ${footer(
         title,
         link,
         isSponsored,
         contentTypeText,
         contentType,
         language,
         layout,
         publisherDarkLogo,
         publisherLogo,
         publisherName,
         isVideoLink,
         ms_news,
         contentApiId,
         pub_time
       )}
    </article>
  `
}

export default dealsListTemplate
