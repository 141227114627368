import GA4Handler from '../GA4Handler'
import { BlockType, DataLayerEventType, getBlockInteractionType, isSponsored } from '../interfaces'
import { lazyload } from '../../utilities/DOM_events'
import { ga4DebugCancelLinkNavigation } from '../index'
import { findClosestAncestorNode } from '../../utilities/DOM_manipulation'

export class GA4Ticker {
  static blockType = BlockType.ticker_block
  static blockLayout = 'ticker_block'
  static contentType = 'editorial'
  static elementType = 'ticker_block'
  static publisher = 'ticker_block'

  public static wireImpressionBlock(blockElement: HTMLElement) {
    lazyload(
      blockElement,
      () => {
        // @todo Need to reference an interface type here.
        const e = {
          block_layout: this.blockLayout,
          block_position: blockElement.getAttribute('data-mp-block-index'),
          block_type: this.blockType,
          content_type: isSponsored(this.blockType) ? 'sponsored' : 'editorial',
          element: this.blockType,
          event: DataLayerEventType.impression_view,
          interaction_type: getBlockInteractionType(this.blockType)
        }

        GA4Handler.pushObject('GA4Ticker.wireImpressionBlock', e)
      },
      {
        threshold: 0.7
      }
    )
  }

  public static wireImpressionImage(imageElement: HTMLElement, index: number) {
    const section = findClosestAncestorNode(imageElement, '.block--ticker')

    lazyload(
      imageElement,
      () => {
        const d = {
          block_layout: this.blockLayout,
          block_position: section.getAttribute('data-mp-block-index'),
          block_type: this.blockType,
          carousel_position: index,
          content_type: this.contentType,
          element: this.blockType,
          element_type: this.elementType,
          event: `impression_view`,
          interaction_type: getBlockInteractionType(this.blockType)
        }

        GA4Handler.pushObject('GA4Ticker.wireImpressionImage', d)
      },
      {
        threshold: 0.7
      }
    )
  }

  public static wireLinkClick(linkElement) {
    const w = window as any
    const url = new URL(
      linkElement.getAttribute('href').replace(/^\/\//, `${w.location.protocol}//`)
    )

    linkElement.addEventListener('click', (e: Event) => {
      ga4DebugCancelLinkNavigation(e)

      const data = {
        block_layout: this.blockLayout,
        block_type: this.blockType,
        content_type: this.contentType,
        domain: url.hostname,
        element: 'ticker_block_item',
        element_type: this.elementType,
        event: 'impression_click',
        interaction_type: getBlockInteractionType(this.blockType),
        path: url.pathname,
        publisher: this.publisher,
        title: this.getTitle(linkElement)
      }

      GA4Handler.pushObject('GA4Ticker.wireLinkClick', data)
    })
  }

  // noinspection JSUnusedLocalSymbols
  public static getTitle(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    linkElement
  ) {
    return this.publisher
  }
}
