import { NeptuneAttribute, NeptuneAttributeKey } from '../../../lib/NeptuneAttribute'
import GA4Handler from '../GA4Handler'
import { BlockType, DataLayerEventType, DataLayerInteractionType, DataLayerInternalClickEvent } from '../interfaces'
import { findClosestAncestorNode } from '../../utilities/DOM_manipulation'

/**
 * Note that the actual block type is feedback_form (this needs to be changed).
 * Note that ga4DebugCancelLinkNavigation does not work here as the play store link is opened by openAppInPlayStore
 */
const ga4AppRatingClickHandler = (e, type, action, detail = null) => {
  const section = findClosestAncestorNode(e.currentTarget, '[data-mp-block-type]')
  const blockIndex = NeptuneAttribute.get(section, NeptuneAttributeKey.blockIndex)

  const data: DataLayerInternalClickEvent = {
    block_layout: BlockType.app_rating,
    block_position: parseInt(blockIndex),
    block_type: BlockType.app_rating,
    content_type: type,
    element: BlockType.app_rating,
    element_action: action,
    element_detail: detail,
    event: DataLayerEventType.impression_click,
    interaction_type: DataLayerInteractionType.internal
  }

  GA4Handler.pushObject('ga4AppRatingClickHandler', data)
}

export default ga4AppRatingClickHandler
