import { FeedItemContext } from '../../../../../../lib/feed/interfaces'
import { appendInternalTrackingSearchParams } from '../../../utilities/analytics'
import i18n from '../../../../../utilities/translation'
import { style, header, footer, language, app_theme } from '../feed_item'
import {
  isMomentTheme,
  removeSpecialCharacters,
  isVisionYouTheme,
  createAttributes
} from '../utils'

const largePhotocardTemplate = (feedItem: FeedItemContext) => {
  const {
    link,
    title,
    publisherLogo,
    publisherDarkLogo,
    publisherName,
    isVideoLink,
    thumbnailUrl,
    isSponsored,
    contentType,
    contentTypeText,
    description,
    ms_news,
    contentApiId,
    pub_time,
    layout
  } = feedItem

  return `
    <article
      ${createAttributes(feedItem)}>
      <a class="block-item__body"
         href="${appendInternalTrackingSearchParams(link)}"
         style="${style(layout, thumbnailUrl)}"
         data-mp-cct>

  
        ${
          isVisionYouTheme(app_theme) || isMomentTheme(app_theme)
            ? `<div class="block-item__media">
            <img loading="lazy" class="block-item__thumbnail" src="${thumbnailUrl}" alt="" />
            </div>
            <div class="block-item__blur"></div>
            ${isMomentTheme(app_theme) ? `<div class="flex-container">` : ''}
            `
            : ''
        }

        ${header(
          isSponsored,
          publisherDarkLogo,
          publisherLogo,
          publisherName,
          isVideoLink,
          ms_news,
          layout
        )}

        <h1 class="block-item__title ${language}">
            <span class="block-item__headline">${title}</span>
            <span class="block-item__subtitle ${language}"></span>
        </h1>
  
        ${
          description
            ? `<p class="block-item__description ${language}">${removeSpecialCharacters(
                description
              )}</p>`
            : ''
        }
  
        ${isMomentTheme(app_theme) ? '</div>' : ''}
    </a>
  
      ${footer(
        title,
        link,
        isSponsored,
        contentTypeText,
        contentType,
        language,
        layout,
        publisherDarkLogo,
        publisherLogo,
        publisherName,
        isVideoLink,
        ms_news,
        contentApiId,
        pub_time
      )}
  
      ${
        isMomentTheme(app_theme)
          ? `<a href="${appendInternalTrackingSearchParams(link)}" class="cr-button">${i18n(
              'Continue Reading',
              language
            )}</a>`
          : ''
      }
  
    </article>
    `
}

export default largePhotocardTemplate
