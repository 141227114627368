import { getAssetDomainByMPGlobals } from 'lib/deployment'
import { English, Hindi, Italian, Portugese, Spanish } from './Text'

// Using https://github.com/mobileposse/locationapi
export const settings = {
  blockConfig: {
    api: {
      location: {
        sandbox: 'https://location-api.sandbox.mobileposse.com/api/2.0/location?',
        live: 'https://location-api.mobileposse.com/api/2.0/location?'
      },
      weatherCurrent: {
        sandbox: 'https://location-api.sandbox.mobileposse.com/api/2.0/weather/current?',
        live: 'https://location-api.mobileposse.com/api/2.0/weather/current?'
      },
      weatherDaily: {
        sandbox: 'https://location-api.sandbox.mobileposse.com/api/1.0/weather/daily?',
        live: 'https://location-api.mobileposse.com/api/1.0/weather/daily?'
      }
    },
    chrome_tab: 1,
    tracking_string: '?sourceProviderId=dt'
  },
  icon_path: `https://${getAssetDomainByMPGlobals()}/image/upload/app/weather_widget`
}

export const textLangMap = lang => {
  switch (lang) {
    case 'es':
      return Spanish
    case 'pt':
      return Portugese
    case 'hi':
      return Hindi
    case 'es-mx':
      return Spanish
    case 'it':
      return Italian
    default:
      return English
  }
}

export const langMap = lang => {
  switch (lang) {
    case 'es':
    case 'es-mx':
      return 'es'
    default:
      return 'en'
  }
}
