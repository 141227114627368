import CaptureException from '../../../../sentry/captureException'

const starSvgCreate = (
  id: string,
  gradientPercentage: string,
  gradientFirstColor: string,
  gradientSecColor: string
) => `<svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
  <linearGradient id="${id}" x1="0" y1="1" x2="1" y2="1">
      <stop offset="0%" stop-opacity="1" stop-color="${gradientFirstColor}" />
      <stop offset="${gradientPercentage}" stop-opacity="1" stop-color="${gradientFirstColor}" />
      <stop offset="${gradientPercentage}" stop-opacity="1" stop-color="${gradientSecColor}" />
      <stop offset="100%" stop-opacity="1" stop-color="${gradientSecColor}" />
  </linearGradient>
  <path
    d="M4 5.68L2.00568 6.99991C1.88227 7.08159 1.72365 6.96634 1.76319 6.82373L2.40223 4.51915L0.530638 3.03031C0.414824 2.93818 0.475412 2.75171 0.62326 2.7425L3.01252 2.64085L3.85013 0.400791C3.90197 0.262176 4.09803 0.262176 4.14987 0.400791L4.98748 2.64085L7.37674 2.74525C7.52459 2.75171 7.58518 2.93818 7.46936 3.03031L5.59777 4.51915L6.23681 6.82373C6.27635 6.96634 6.11773 7.08159 5.99432 6.99991L4 5.68Z"
    fill="url(#${id})" />
  </svg>`

export const drawStars = (starCount: number) => {
  let svg = ``

  try {
    // calculations
    const fullPositiveStarsNumber = Math.floor(starCount) //number of full yellow stars
    const restStarsNumber = 5 - starCount // number of full grey stars + split one
    let fullNegativeStarsNumber = restStarsNumber >= 1 ? Math.floor(restStarsNumber) : 0 // number of full grey stars
    let splitStar = (restStarsNumber - fullNegativeStarsNumber) * 100 // split star percentage

    for (let i = 1; i <= 5; i++) {
      if (i <= fullPositiveStarsNumber) {
        const starSvgCreated = starSvgCreate(`${i}pos`, '50%', '#FDB615', '#FDB615')
        svg += starSvgCreated
        continue
      }

      if (splitStar > 0) {
        const splitStarResult = starSvgCreate(
          `${splitStar}split`,
          `${splitStar}%`,
          '#FDB615',
          '#BABABA'
        )
        svg += splitStarResult
        splitStar = 0
      }

      if (fullNegativeStarsNumber > 0) {
        const starSvgCreated = starSvgCreate(`${i}neg`, '50%', '#BABABA', '#BABABA')
        svg += starSvgCreated
        fullNegativeStarsNumber -= 1
      }
    }
  } catch (e) {
    CaptureException(e, 'drawStars: error drawing stars')
    return ''
  }

  return svg
}
