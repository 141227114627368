import Swiper from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'

import { initPerBlockType } from '../utilities/DOM_events'

Swiper.use([Navigation, Pagination])

const initSwiper = block => {
  const swiper = block.querySelector('.swiper--trending-carousel')
  const pagination = block.querySelector('.swiper-pagination')

  const swiperInstance = new Swiper(swiper, {
    slidesPerView: 0.8845,
    spaceBetween: 0,
    loop: true,
    pagination: {
      el: pagination,
      clickable: true
    },
    a11y: {
      enabled: true
    }
  })

  block.addEventListener('keydown', e => {
    const targetElement = e.target as HTMLElement
    const isSlideElement = targetElement.classList.contains('block-item__body')
    if (!isSlideElement) {
      if (!swiper.getAttribute('tab-clicked')) {
        swiper.setAttribute('tab-clicked', true)

        swiperInstance.destroy()
        new Swiper(swiper, {
          spaceBetween: 20,
          loop: false,
          pagination: {
            el: pagination,
            clickable: true
          },
          a11y: {
            enabled: true
          }
        })
      }
    }
  })
}

const initTrendingCarousel = () => {
  const blocksOnPage = Array.from(document.querySelectorAll('.block--trending-carousel'))

  if (!blocksOnPage.length) return

  const initCarousel = initPerBlockType(blocksOnPage, initSwiper)

  initCarousel('block--rss')
  initCarousel('fotoscape_block', 'fotoscapesLoad')
  initCarousel('ms_news', 'msNewsLoad')
}
export default initTrendingCarousel
