import { lazyload } from '../utilities/DOM_events'
import * as device from '../device'
import { neptuneDebug } from 'content/src/assets/utilities'
import captureException from 'content/src/assets/sentry/captureException'
import GA4Handler from '../ga4_support/GA4Handler'

const OBR = (window as any).OBR

const addUserId = (widget: HTMLElement) => {
  const product = document.documentElement.dataset.gtmProduct
  const id = device.androidAID()

  if (product === 'portal') return
  if (!id) return

  widget.setAttribute('data-ob-user-id', id)
}

/**
 * @todo Why is this being executed on pages with no outbrain widget?
 */
if (OBR && OBR.extern) {
  try {
    OBR.extern.registerOnWidgetRendered((o: any) => {
      /*
      Loop through all properties of the object to find which returns the HTML element for the widget.
      for (const k of Object.keys(o)) {
        try {
          if (typeof o[k] === 'function') {
            console.log(`o.${k}: `, o[k]())
          } else {
            console.log(`o.${k}: `, o[k])
          }
        } catch (e) {
          console.error(`Could not test key ${k}`)
        }
      }
      */

      // Yes, this is nasty, but we need to get at the element for GA tracking.
      try {
        if (!o.B || typeof o.B !== 'function') {
          return
        }

        const container: HTMLElement = o.B()
        for (const link of Array.from(container.querySelectorAll('a'))) {
          link.addEventListener('click', e => {
            GA4Handler.clickHandleEvent(e)
          })
          GA4Handler.impressionHandleEvent(link as HTMLElement)
        }
      } catch (e) {
        const m = 'Could not attach GA4 handlers to outbrain widget: '
        console.error(m, e)
        captureException(e, m)
      }
    })
  } catch (e) {
    console.error(e)
  }
}

const addLazy = (container: Element) => {
  const template: HTMLTemplateElement = container.querySelector(
    'template[data-outbrain-widget-template]'
  )

  const outbrainWidget: HTMLElement = template.content.querySelector('.OUTBRAIN')
  const widgetId = outbrainWidget.dataset.widgetId
  addUserId(outbrainWidget)

  neptuneDebug(`Outbrain Widget ${widgetId}: Page loaded`, new Date())

  const renderOutbrain = () => {
    neptuneDebug(`Outbrain Widget ${widgetId}: Widget Loaded`, new Date())

    const content = template.content.cloneNode(true)
    container.querySelector('.block__main').innerHTML = ''
    template.parentNode.insertBefore(content, template)

    OBR.extern.researchWidget()
  }

  lazyload(container, renderOutbrain, { rootMargin: '900px', threshold: 0 })
}

const initOutbrainWidget = () => {
  const containers: NodeListOf<Element> = document.querySelectorAll(
    '[data-outbrain-widget-container]'
  )

  Array.from(containers).map(container => addLazy(container))
}

export default initOutbrainWidget
