/**
 * @file Action Buttons
 *
 * The behavior of our action buttons is tied to bridge methods on the client. This means we can't deviate too
 * far from the core of how Cashew/Pistachio approached the issue.
 *
 * We have, however, massively simplified their process in several ways. Primarily by restructuring the action buttons
 * placement in the DOM to avoid needing to mess with natural event propagation. In turn, we don't need to manually fire off GTM/GA
 * events.
 *
 * One feature we have currently removed is the delay for finger taps (we also have not added in support for block item highlighting
 * either yet). This is done by introducing artificial delays in UI behavior long enough for CSS animations to play. Again, forcing
 * the code to manually propagate events.
 *
 * Both the share and feedback buttons create mailto links (except in a webview where share uses a bridge method). In some cases,
 * they have custom text, in others just page defaults. Until we have a stronger picture of the text content desired, we'll keep it
 * as close to the code as possible here.
 *
 * NOTE: There is a bridge method for sharing a URL that cashew uses in webviews. We mimic this. However, its behavior is
 * less than ideal and should be improved (if it hasn't already) in Neptune.
 */

import { findClosestAncestorNode } from '../utilities/DOM_manipulation'
import i18n from '../../../utilities/translation'
import * as MobilePosse from '../bridge_interface/bridge_interface'
import * as modal from '../utilities/modal/modal'
import axios from 'axios'
import parseFeedItem from './parseFeedItem'
import saveFeedItem from './saveFeedItem'
import { initBookmark } from './bookmark'
import { neptuneDebug } from '../../../utilities'
import { initPersonalizationButtons } from './personalize'
import ga4ShareButtonClickHandler from 'content/src/assets/common/scripts/ga4_support/blocks/share_button'
import ga4FeedbackModalSubmitHandler from 'content/src/assets/common/scripts/ga4_support/blocks/feedback_form'
import captureException from 'content/src/assets/sentry/captureException'
import { BlockType, DataLayerInteractionType, DataLayerNonLinkClickElementAction } from '../ga4_support/interfaces'
import { isMomentTheme } from '../shared/templates/utils'
import { getContentType } from '../ga4_support'
import ContentBlockDataset from '../../lib/ContentBlockDataset'

// Content Types
const ADVICE_OF_DAY_CONTENT_TYPE = 'advice_of_day_widget'
const FEED_ITEM_LINK_CONTENT_TYPE = 'feed_item_link'
const EVENT_LINK_CONTENT_TYPE = 'event_link'
const DEAL_LINK_CONTENT_TYPE = 'deal_link'
const HOUSE_DEAL_LINK_CONTENT_TYPE = 'house_deal_link'
const WEATHER_WIDGET_CONTENT_TYPE = 'weather_widget'
const FORTUNE_COOKIE_WIDGET_CONTENT_TYPE = 'fortune_cookie_widget'
const QUOTES_WIDGET_CONTENT_TYPE = 'quotes_widget'
const JOKES_WIDGET_CONTENT_TYPE = 'jokes_widget'
const MEMES_WIDGET_CONTENT_TYPE = 'memes_widget'
const HOROSCOPE_LINK_ITEM_CONTENT_TYPE = 'horoscope_link_item'
const HOROSCOPE_DETAIL_ITEM_CONTENT_TYPE = 'horoscope_detail_item'
const HOROSCOPE_CAROUSEL_ITEM_CONTENT_TYPE = 'horoscope_carousel_item'
const WORD_OF_DAY_WIDGET_CONTENT_TYPE = 'word_of_day_widget'
const VOTING_BLOCK_CONTENT_TYPE = 'voting_block'

// Selectors
export const CONTENT_TYPE_SELECTOR = '[data-mp-content-type]'
const SHARE_BUTTONS_SELECTOR = '[data-mp-button-share]'
// Attribute Names
const FEEDBACK_EMAIL_ATTRIBUTE_NAME = 'data-mp-feedback-email'
const BRAND_ATTRIBUTE_NAME = 'data-mp-brand'
export const SHARE_LINK_ATTRIBUTE_NAME = 'data-mp-share-link'
export const SHARE_TITLE_ATTRIBUTE_NAME = 'data-mp-share-title'
export const SHARE_DESCRIPTION_ATTRIBUTE_NAME = 'data-mp-share-description'
const SHARE_IMAGE_ATTRIBUTE_NAME = 'data-mp-share-image'
const SHARE_FORTUNE_TEXT_ATTRIBUTE_NAME = 'data-mp-share-fortune-text'
const SHARE_QUOTES_TEXT_ATTRIBUTE_NAME = 'data-mp-share-quotes-text'
const SHARE_JOKES_TEXT_ATTRIBUTE_NAME = 'data-mp-share-jokes-text'
const SHARE_MEMES_TEXT_ATTRIBUTE_NAME = 'data-mp-share-memes-text'
const SHARE_ADVICE_TEXT_ATTRIBUTE_NAME = 'data-mp-share-advice-text'
const SHARE_WORDS_TEXT_ATTRIBUTE_NAME = 'data-mp-share-words-text'
const SHARE_VOTING_TEXT_ATTRIBUTE_NAME = 'data-mp-share-voting-text'
const SHARE_HOROSCOPE_TEXT_ATTRIBUTE_NAME = 'data-mp-share-horoscope-text'
const SHARE_HOROSCOPE_SIGN_ATTRIBUTE_NAME = 'data-mp-share-horoscope-sign'
const SHARE_HOROSCOPE_TYPE_ATTRIBUTE_NAME = 'data-mp-share-horoscope-type'

const isWebview = (window as any).MP != undefined
const pageLanguage = document.documentElement.getAttribute('lang')
const platformBrand = document.documentElement.getAttribute(BRAND_ATTRIBUTE_NAME)
const feedbackEmailAddress = document.documentElement.getAttribute(FEEDBACK_EMAIL_ATTRIBUTE_NAME)

export const app_theme = document.documentElement.getAttribute('data-mp-app-theme')

const shareEmailTemplate = ({ title, body }: { title?: string; body: string }): string => {
  return `mailto:?${title ? `subject=${title}` : ''}&body=${encodeURIComponent(body)}`
}

export const getShareableContent = button => {
  // Share buttons on content/elements that are not wrapped in a shareable container will share the page
  // information by default.
  let url = (window as any).location.href
  let title = document.title
  let body = `${encodeURI(url)}`
  let text
  let description
  let mpContentType

  const contentContainer = findClosestAncestorNode(button, CONTENT_TYPE_SELECTOR)

  if (contentContainer) {
    mpContentType = contentContainer.dataset.mpContentType

    switch (mpContentType) {
      case FEED_ITEM_LINK_CONTENT_TYPE:
        const mpShareLink = contentContainer.getAttribute(SHARE_LINK_ATTRIBUTE_NAME)
        const mpShareTitle = contentContainer.getAttribute(SHARE_TITLE_ATTRIBUTE_NAME)
        const mpShareDescription = contentContainer.getAttribute(SHARE_DESCRIPTION_ATTRIBUTE_NAME)

        if (!mpShareLink) {
          throw new Error('Missing required property <mpShareLink> for feed_item_link content type')
        }
        if (!mpShareTitle) {
          throw new Error('Missing required property <mpShareTitle> for feed_item_link content type')
        }
        url = mpShareLink
        title = mpShareTitle
        body = `${title}: `
        description = mpShareDescription
        break

      case FORTUNE_COOKIE_WIDGET_CONTENT_TYPE:
        text = contentContainer.getAttribute(SHARE_FORTUNE_TEXT_ATTRIBUTE_NAME)
        title = `${i18n('Check out my fortune on', pageLanguage)} ${platformBrand}`
        // TODO: Add translations for new text
        body = `${i18n('My fortune was', pageLanguage)}:\n\n\t"${text}"\n\n${i18n(
          'You can get your own fortune told at',
          pageLanguage
        )} `
        break

      case QUOTES_WIDGET_CONTENT_TYPE:
        text = contentContainer.getAttribute(SHARE_QUOTES_TEXT_ATTRIBUTE_NAME)
        title = `${i18n('Check out this quote I saw on', pageLanguage)} ${platformBrand}`
        // TODO: Add translations for new text
        body = `"${text}"\n\n${i18n('You can see more quotes at', pageLanguage)} `
        break

      case JOKES_WIDGET_CONTENT_TYPE:
        text = contentContainer.getAttribute(SHARE_JOKES_TEXT_ATTRIBUTE_NAME)
        title = `${i18n('Check out this joke I heard on', pageLanguage)} ${platformBrand}`
        // TODO: Add translations for new text
        body = `"${text}"\n\n${i18n('You can see more jokes at', pageLanguage)} `
        break

      case MEMES_WIDGET_CONTENT_TYPE:
        text = contentContainer.getAttribute(SHARE_MEMES_TEXT_ATTRIBUTE_NAME)
        title = `${i18n('Check out this meme I saw on', pageLanguage)} ${platformBrand}`
        // TODO: Add translations for new text
        body = `"${text}"\n\n${i18n('You can see more memes at', pageLanguage)} `
        break

      case ADVICE_OF_DAY_CONTENT_TYPE:
        text = contentContainer.getAttribute(SHARE_ADVICE_TEXT_ATTRIBUTE_NAME)
        title = `${i18n('Check out this advice I saw on', pageLanguage)} ${platformBrand}`
        // TODO: Add translations for new text
        body = `"${text}"\n\n${i18n('You can see more advices at', pageLanguage)} `
        break

      case WORD_OF_DAY_WIDGET_CONTENT_TYPE:
        text = contentContainer.getAttribute(SHARE_WORDS_TEXT_ATTRIBUTE_NAME)
        title = `${i18n('Check out this word I saw on', pageLanguage)} ${platformBrand}`
        // TODO: Add translations for new text
        body = `"${text}"\n\n${i18n('You can see the word of the day at', pageLanguage)} `
        break

      case VOTING_BLOCK_CONTENT_TYPE:
        text = contentContainer.getAttribute(SHARE_VOTING_TEXT_ATTRIBUTE_NAME)
        body = `"${text}"\n\n${i18n('You can vote at', pageLanguage)} `
        break

      case HOROSCOPE_LINK_ITEM_CONTENT_TYPE:
      case HOROSCOPE_DETAIL_ITEM_CONTENT_TYPE:
      case HOROSCOPE_CAROUSEL_ITEM_CONTENT_TYPE:
      case BlockType.horoscope_carousel_block:
        let signName

        if (mpContentType === HOROSCOPE_LINK_ITEM_CONTENT_TYPE) {
          const prefix = window.location.href.substring(0, window.location.href.lastIndexOf('/'))
          const filename = contentContainer.querySelector('a').getAttribute('href')
          url = `${prefix}/${filename}`
        }

        if (mpContentType === BlockType.horoscope_carousel_block) {
          const article = contentContainer.querySelector('.swiper-slide-active')
          text = article.getAttribute(SHARE_HOROSCOPE_TEXT_ATTRIBUTE_NAME)
          signName = article.getAttribute(SHARE_HOROSCOPE_SIGN_ATTRIBUTE_NAME)
        } else {
          signName = contentContainer.getAttribute(SHARE_HOROSCOPE_SIGN_ATTRIBUTE_NAME)
          text = contentContainer.getAttribute(SHARE_HOROSCOPE_TEXT_ATTRIBUTE_NAME)
        }

        const titleCaseSignName = signName.slice(0, 1).toUpperCase() + signName.slice(1)
        title = `${i18n('Check out my horoscope on', pageLanguage)} ${platformBrand}`
        body = `${i18n('My', pageLanguage)} ${i18n(titleCaseSignName, pageLanguage)} ${i18n(
          'horoscope was',
          pageLanguage
        )}:\n\n\t"${text}"\n\n${i18n('You can see your own horoscope at', pageLanguage)} `
        break

      case HOUSE_DEAL_LINK_CONTENT_TYPE:
        url = contentContainer.getAttribute(SHARE_LINK_ATTRIBUTE_NAME)
        title = contentContainer.getAttribute(SHARE_TITLE_ATTRIBUTE_NAME)
        body = `${i18n('Check out this deal I found on', pageLanguage)} ${platformBrand} ${i18n(
          'for',
          pageLanguage
        )} ${title} ${i18n('at', pageLanguage)} `
        break

      case WEATHER_WIDGET_CONTENT_TYPE:
        const mpShareTitleWeather = contentContainer.getAttribute(SHARE_TITLE_ATTRIBUTE_NAME)
        const mpShareDescriptionWeather = contentContainer.getAttribute(SHARE_DESCRIPTION_ATTRIBUTE_NAME)

        title = `${i18n('Current Weather', pageLanguage)}`
        body = `${i18n('The current weather in', pageLanguage)} ${mpShareDescriptionWeather} ${i18n(
          'is',
          pageLanguage
        )} ${mpShareTitleWeather}\n\n${i18n('You can check your own forecast at', pageLanguage)} `
        break
    }
  }

  return {
    url,
    title,
    body,
    description,
    mpContentType
  }
}

const createUrl = (url, language, page, param, value) => {
  url = document.location.href.split(`/${language}/`)[0]
  url += `/${language}/shared.html?${param}=${value}`
  url += `&utm_source=sharedlink`
  url += `&utm_medium=onsite_share`
  url += `&utm_campaign=${page}`

  return url
}

const shareButtonHandler = button => async () => {
  const w = window as any

  // Skip Share API for these content types.
  const excludeApi = [
    // Horoscopes.
    HOROSCOPE_LINK_ITEM_CONTENT_TYPE,
    HOROSCOPE_DETAIL_ITEM_CONTENT_TYPE,
    HOROSCOPE_CAROUSEL_ITEM_CONTENT_TYPE,

    // Reveal widgets.
    // @todo I'm not sure that this list is complete.
    ADVICE_OF_DAY_CONTENT_TYPE,
    FORTUNE_COOKIE_WIDGET_CONTENT_TYPE,
    JOKES_WIDGET_CONTENT_TYPE,
    MEMES_WIDGET_CONTENT_TYPE,
    QUOTES_WIDGET_CONTENT_TYPE,
    WEATHER_WIDGET_CONTENT_TYPE,
    WORD_OF_DAY_WIDGET_CONTENT_TYPE,
    VOTING_BLOCK_CONTENT_TYPE,
    BlockType.horoscope_carousel_block
  ]

  // Moved outside mpContentType check for GA4 support.
  const shareElement = w?.LAST_SHARE_ELEMENT ?? button
  const contentContainer = findClosestAncestorNode(shareElement, '[data-mp-block-type]')
  const article = findClosestAncestorNode(shareElement, 'article')

  const language = document.documentElement.lang
  const page = document.documentElement.dataset.mpPage
  const blockIndex = contentContainer?.getAttribute('data-mp-block-index')
  const blockType = contentContainer?.getAttribute('data-mp-block-type')
  const fotoscapeUid = article?.getAttribute('data-mp-uid')
  const shareableContent = getShareableContent(shareElement)
  let { url, body } = shareableContent
  const { title, mpContentType } = shareableContent

  // @todo Can this be handled generically?
  // Push share event to dataLayer.
  ga4ShareButtonClickHandler({
    block_position: parseInt(blockIndex),
    block_type: BlockType[blockType],
    content_type: getContentType(BlockType[blockType]),
    element: BlockType[blockType],
    element_action: DataLayerNonLinkClickElementAction.share,
    element_detail: url,
    event: DataLayerNonLinkClickElementAction.impression_click,
    interaction_type: DataLayerInteractionType.internal,
    path: null,
    title: null
  })

  // Reset element in case the next share doesn't use the popup.
  w.LAST_SHARE_ELEMENT = null

  if (!excludeApi.includes(mpContentType)) {
    try {
      if (fotoscapeUid) {
        url = createUrl(url, language, page, 'fotoscape_id', fotoscapeUid)
      } else {
        const item = parseFeedItem(article)
        const itemID = await saveFeedItem(item)

        if (itemID) {
          url = createUrl(url, language, page, 'feed_item', itemID)
        }
      }
    } catch (e) {
      console.error('could not save share: %o', e)
      w.Sentry.captureMessage(`could not save share: `, e)
    }
  }

  closePersonalizeModal()

  if (isWebview) {
    w.MP.shareUrl(url, title)
  } else if (navigator.share) {
    await navigator.share({ title: title, text: body, url: url })
  } else {
    w.location = shareEmailTemplate({ title, body: body + encodeURI(url) })
  }
}

const getFeedbackMetadata = button => {
  const currentTime = new Date()
  // Metadata includes:
  // - Basic page metadata
  // - Device information in a webview
  // - Content information if the feedback was on an external link
  // - Module information if the feedback was on an internal feature (horoscope/fortune cookie/...)
  const metadata: any = {
    url: (window as any).location.href,
    path: (window as any).location.pathname,
    local_time: currentTime.toString(),
    UTC_time: currentTime.toUTCString(),
    // Get all necessary metadata for the device
    ...MobilePosse.getInternalData()
  }

  // TODO: It would make sense to include a reference to the content's hash so that it can
  // be directly referenced from our table?
  const contentContainer = findClosestAncestorNode(button, CONTENT_TYPE_SELECTOR)
  if (contentContainer) {
    const { mpContentType } = contentContainer.dataset
    metadata.content_type = mpContentType
    switch (mpContentType) {
      case FEED_ITEM_LINK_CONTENT_TYPE:
      case EVENT_LINK_CONTENT_TYPE:
      case DEAL_LINK_CONTENT_TYPE:
      case HOUSE_DEAL_LINK_CONTENT_TYPE:
        const link = contentContainer.getAttribute(SHARE_LINK_ATTRIBUTE_NAME)
        const title = contentContainer.getAttribute(SHARE_TITLE_ATTRIBUTE_NAME)
        const image = contentContainer.getAttribute(SHARE_IMAGE_ATTRIBUTE_NAME)
        const description = contentContainer.getAttribute(SHARE_DESCRIPTION_ATTRIBUTE_NAME)
        metadata.content_link = link
        metadata.content_title = title
        metadata.content_image = image
        metadata.content_description = description
        break
      case WEATHER_WIDGET_CONTENT_TYPE:
        // Unfortunately, because they are not using a global store, there isn't a great way to record
        // state values (such as location). We could read them from localstorage, but no, yuck.
        // I'm leaving this comment here to indicate why no other metadata is currently available for weather
        // feedback.
        break
      case FORTUNE_COOKIE_WIDGET_CONTENT_TYPE:
        const fortuneBody = contentContainer.getAttribute(SHARE_FORTUNE_TEXT_ATTRIBUTE_NAME)
        metadata.content_body = fortuneBody
        break
      case QUOTES_WIDGET_CONTENT_TYPE:
        const quoteBody = contentContainer.getAttribute(SHARE_QUOTES_TEXT_ATTRIBUTE_NAME)
        metadata.content_body = quoteBody
        break
      case JOKES_WIDGET_CONTENT_TYPE:
        const jokeBody = contentContainer.getAttribute(SHARE_JOKES_TEXT_ATTRIBUTE_NAME)
        metadata.content_body = jokeBody
        break
      case MEMES_WIDGET_CONTENT_TYPE:
        const memeBody = contentContainer.getAttribute(SHARE_MEMES_TEXT_ATTRIBUTE_NAME)
        metadata.content_body = memeBody
        break
      case HOROSCOPE_LINK_ITEM_CONTENT_TYPE:
        // NOTE: No break because, the link is the only unique aspect of an overview horoscope item. Everything else
        // is the same and can share logic.
        metadata.content_link = contentContainer.getAttribute(SHARE_LINK_ATTRIBUTE_NAME)
        break
      case HOROSCOPE_DETAIL_ITEM_CONTENT_TYPE:
        metadata.content_horoscope_sign = contentContainer.getAttribute(SHARE_HOROSCOPE_SIGN_ATTRIBUTE_NAME)
        metadata.content_horoscope_type = contentContainer.getAttribute(SHARE_HOROSCOPE_TYPE_ATTRIBUTE_NAME)
        metadata.content_horoscope_text = contentContainer.getAttribute(SHARE_HOROSCOPE_TEXT_ATTRIBUTE_NAME)
        break
      case HOROSCOPE_CAROUSEL_ITEM_CONTENT_TYPE:
        metadata.content_horoscope_sign = contentContainer.getAttribute(SHARE_HOROSCOPE_SIGN_ATTRIBUTE_NAME)
        metadata.content_horoscope_type = contentContainer.getAttribute(SHARE_HOROSCOPE_TYPE_ATTRIBUTE_NAME)
        metadata.content_horoscope_text = contentContainer.getAttribute(SHARE_HOROSCOPE_TEXT_ATTRIBUTE_NAME)
        break
      default:
        break
    }
  } else {
    metadata.content_type = 'Unknown'
  }

  return metadata
}

// Patch code to support v55-58
const initMailtoFeedbackFormFallback = containerSelector => {
  const feedbackEmailTemplate = metadata => {
    const prepend = '\n\n\n\n\n\n\n\n'
    const divider = '~~~ ------------------------ ~~~\n'
    const metadataBody = Object.entries(metadata)
      .map(([key, value]) => `~~~ ${key}: ${value} ~~~\n`)
      .join('')
    const body = prepend + divider + metadataBody + divider
    const link = `mailto:${feedbackEmailAddress}?subject=${`${platformBrand} ${i18n(
      'Feedback',
      pageLanguage
    )}`}&body=${encodeURIComponent(body)}`
    return link
  }

  const feedbackButtonHandler = button => event => {
    event.stopPropagation()

    const feedbackMetadata = getFeedbackMetadata(button)
    ;(window as any).location = feedbackEmailTemplate(feedbackMetadata)
  }

  const feedbackButtons = Array.from(
    document.querySelectorAll(`${containerSelector} [data-modal-custom-trigger-target-feedback="modal--feedback"]`)
  )
  feedbackButtons.forEach(button => {
    button.addEventListener('click', feedbackButtonHandler(button))
    button.addEventListener('keyup', e => {
      // Buttons will simulate click events and we don't want duplication
      if (e.type !== 'keyup') {
        return
      }
      if (['Enter', ' '].includes((e as any).key)) {
        feedbackButtonHandler(button)
      }
    })
  })
}

const closePersonalizeModal = () => {
  if (document.querySelector('div#modal--personalize').classList.contains('active')) {
    document.querySelector('div#modal--personalize').classList.remove('active')
    document.body.style.overflow = 'unset'
  }
}

const validatePersonalisationModal = () => {
  const form = window['feedback-modal']
  const emailEl = window['feedback-input-email']
  const feedbackEl = window['feedback-input-body']
  const feedbackSubmitButton = window['feedback-submit-button']
  const feedbackErrorBlock = window['feedback-input-error']
  const feedbackSuccessBlock = window['feedback-input-success']
  const emailErrorBlock = window['email-input-error']
  const emailSuccessBlock = window['email-input-success']

  setSubmitButtonState()

  feedbackEl.addEventListener('blur', onFeedbackBlur)
  feedbackEl.addEventListener('input', onFeedbackInput)
  emailEl.addEventListener('blur', onEmailBlur)
  emailEl.addEventListener('input', onEmailInput)
  form.addEventListener('reset', onFormReset)

  function validate(field, successBlock, errorBlock) {
    setSubmitButtonState()

    if (!field.touched) {
      return
    }

    if (field.validity.valid) {
      errorBlock.style.display = 'none'
      successBlock.style.display = 'block'
      return
    }

    successBlock.style.display = 'none'
    errorBlock.style.display = 'block'
    errorBlock.querySelector('.mp-modal__validation-message').textContent = field.validationMessage
  }

  function setSubmitButtonState() {
    if (emailEl.validity.valid && feedbackEl.validity.valid) {
      feedbackSubmitButton.removeAttribute('disabled')
      return
    }

    feedbackSubmitButton.setAttribute('disabled', 'true')
  }

  function onFormReset() {
    feedbackEl.removeEventListener('blur', onFeedbackBlur)
    emailEl.removeEventListener('blur', onEmailBlur)
    form.removeEventListener('reset', onFormReset)
    resetValidateMessage({
      emailEl,
      feedbackEl,
      emailSuccessBlock,
      emailErrorBlock,
      feedbackSuccessBlock,
      feedbackErrorBlock
    })
  }

  function onFeedbackBlur() {
    feedbackEl.touched = true
    validate(feedbackEl, feedbackSuccessBlock, feedbackErrorBlock)
  }

  function onFeedbackInput() {
    validate(feedbackEl, feedbackSuccessBlock, feedbackErrorBlock)
  }

  function onEmailBlur() {
    emailEl.touched = true
    validate(emailEl, emailSuccessBlock, emailErrorBlock)
  }

  function onEmailInput() {
    validate(emailEl, emailSuccessBlock, emailErrorBlock)
  }
}

const resetValidateMessage = ({
  emailEl,
  feedbackEl,
  emailSuccessBlock,
  emailErrorBlock,
  feedbackSuccessBlock,
  feedbackErrorBlock
}) => {
  feedbackErrorBlock.style.display = 'none'
  feedbackSuccessBlock.style.display = 'none'
  emailErrorBlock.style.display = 'none'
  emailSuccessBlock.style.display = 'none'
  emailEl.touched = false
  feedbackEl.touched = false
}

const initFeedbackButtons = containerSelector => {
  // Everything in the first path of the following conditional is a patch to support several versions of Pistachio that
  // somehow don't support input fields. Leaving it in one place for easy refactor/removal later.
  const clientVersion = localStorage.getItem('int_mp_appVersionCode')

  // If there is no client version we aren't in a client.
  // And we only care about pistachio version 55 - 58.
  // Amusingly, tobago version codes are 3 digits, pistachio (what we care about) are 4, and neptune are 6.
  // So we can just check the length!
  const isLegacyClientRequiringFallbackMailTo = clientVersion && clientVersion.length === 4

  if (isLegacyClientRequiringFallbackMailTo) {
    initMailtoFeedbackFormFallback(containerSelector)
  } else {
    // NOTE: The current Modal setup return a map of Modals by property name.
    // The only one here, and the one we want, is "modal--feedback"
    const modals = modal.init({
      containerSelector,
      triggerSelector: 'data-modal-custom-trigger-target-feedback'
    })

    // Can't run if no triggers were found and hence no modals were instantiated.
    if (modals) {
      const feedbackModal = modals['modal--feedback']
      if (feedbackModal) {
        const w = window as any

        const onShow = (triggerActivateEvent, modalContainer): any => {
          closePersonalizeModal()

          const trigger = triggerActivateEvent.target
          const metadata = getFeedbackMetadata(trigger)

          if (isMomentTheme(app_theme)) {
            validatePersonalisationModal()
          }

          try {
            const section = w.LAST_SHARE_ELEMENT.closest('section[data-mp-block-type]')
            const blockIndex = ContentBlockDataset.getMpBlockIndex(section)
            const blockType = section?.getAttribute('data-mp-block-type')
            const detail = getDetail(blockType, metadata)

            ga4FeedbackModalSubmitHandler({
              block_position: parseInt(blockIndex),
              block_type: BlockType[blockType],
              content_type: getContentType(BlockType[blockType]),
              element: BlockType[blockType],
              element_action: DataLayerNonLinkClickElementAction.feedback_show,
              element_detail: detail,
              event: DataLayerNonLinkClickElementAction.impression_click,
              interaction_type: DataLayerInteractionType.internal,
              domain: null,
              path: null,
              title: null
            })
          } catch (e) {
            captureException(e, 'Feedback Modal: could not push GA4 feedback_show to dataLayer: ')
          }

          const form = modalContainer.querySelector('form')
          const button = form.querySelector('button[type="submit"]')
          const feedback = form['feedback-input-body']
          const email = form['feedback-input-email']

          const onClick = () => {
            feedback.setAttribute('aria-invalid', feedback.validationMessage.length > 0)
            email.setAttribute('aria-invalid', feedback.validationMessage.length > 0)
          }

          button.addEventListener('click', onClick)

          const onSubmit = event => {
            event.preventDefault()
            const userText = form['feedback-input-body'].value
            const userEmail = form['feedback-input-email'].value.toLowerCase()
            const platform = document.documentElement.dataset.mpPlatform
            const { send_email_url, environment } = (window as any).mp_globals.env

            try {
              const section = w.LAST_SHARE_ELEMENT.closest('section[data-mp-block-type]')
              const blockIndex = ContentBlockDataset.getMpBlockIndex(section)
              const blockType = section?.getAttribute('data-mp-block-type')
              const detail = getDetail(blockType, metadata)

              ga4FeedbackModalSubmitHandler({
                block_position: parseInt(blockIndex),
                block_type: BlockType[blockType],
                content_type: getContentType(BlockType[blockType]),
                element: BlockType[blockType],
                element_action: DataLayerNonLinkClickElementAction.feedback_submit,
                element_detail: detail,
                event: DataLayerNonLinkClickElementAction.impression_click,
                interaction_type: DataLayerInteractionType.internal,
                domain: null,
                path: null,
                title: null
              })
            } catch (e) {
              captureException(e, 'Feedback Modal: could not push GA4 feedback_submit to dataLayer: ')
            }

            // We can handle awaiting later, when the reqs change to include visual feedback in the
            // modal following a submission (errors, thank yous, etc.)
            //
            // The local dev server has a mocked email handler.
            if (
              !send_email_url &&
              environment === 'sandbox' &&
              window.location.host.startsWith('neptune') &&
              window.location.host.endsWith('amazonaws.com')
            ) {
              neptuneDebug('FEEDBACK: not sending email on branch stack')
            } else {
              try {
                axios.post(send_email_url, {
                  serviceTarget: 'feedback',
                  plainTextContent: userText,
                  jsonAttachmentContent: JSON.stringify(metadata),
                  replyToEmail: userEmail,
                  environment,
                  platform
                })
              } catch (e) {
                captureException(e, 'Could not send feedback email to endpoint')
              }
            }

            form.removeEventListener('submit', onSubmit)
            feedbackModal.close()
          }
          form.addEventListener('submit', onSubmit)

          feedbackModal.onClose = () => {
            form.reset()
            form.removeEventListener('submit', onSubmit)
            feedback.setAttribute('aria-invalid', 'false')
            email.setAttribute('aria-invalid', 'false')
            button.removeEventListener('click', onClick)
          }
        }

        feedbackModal.onShow = onShow
      }
    }
  }
}

// Some blocks don't have a link.
const getDetail = (blockType, metadata) => {
  switch (blockType) {
    case 'horoscope_carousel':
    case 'horoscope_details':
      return `${window.location.href}#${metadata.content_horoscope_sign}`
    case 'advice_of_day':
    case 'fortune_cookie':
    case 'jokes_widget':
    case 'memes_widget':
    case 'quotes_widget':
    case 'voting':
    case 'words_widget':
      return window.location.href
    default:
      return metadata.content_link
  }
}

// add action listeners to static blocks when page is first loaded
export const initActionButtonsPageLoad = () => {
  initActionButtons('body')
  modal.init()
}

const initActionButtons = containerSelector => {
  const containers = Array.from(document.querySelectorAll(containerSelector))
  if (!containers.length) {
    return
  }
  for (const container of containers) {
    const shareButtons: HTMLElement[] = Array.from(container.querySelectorAll(SHARE_BUTTONS_SELECTOR))

    initBookmark(container)

    shareButtons.forEach(button => {
      button.addEventListener('click', shareButtonHandler(button))
      button.addEventListener('keyup', e => {
        // Buttons will simulate click events and we don't want duplication
        if (e.type !== 'keyup') {
          return
        }
        if (e.key !== 'Enter') {
          return
        }
        shareButtonHandler(button)
      })
    })
  }
  initFeedbackButtons(containerSelector)
  initPersonalizationButtons(containerSelector)
}

export default initActionButtons
