import { isWebview } from '../bridge_interface/bridge_interface'
import axios from 'axios'

const LS_KEY = 'local_mp_envSyncTimestamp'

const { env_sync_api_url } = (window as any).mp_globals.env

const setCookie = (key, value, numDays = 9125) => {
  const cookieExpiry = new Date(Date.now() + numDays * 24 * 60 * 60 * 1000)
  document.cookie = key + '=' + value + '; path=/; expires=' + cookieExpiry
}

const onFetchError = error => {
  console.group()
  if (error.response) {
    // Request made and server responded
    console.log('HTTP response: ', error.response.status)
    console.log(error.response.data)
    console.log(error.response.headers)
  } else if (error.request) {
    // The request was made but no response was received
    console.log('Request Error')
    console.log(error.request)
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message)
  }
  console.groupEnd()
}

// Handlers for all values based on the keys used to store them in the sync DB
const keys = {
  get dnsmi() {
    return localStorage.getItem('local_mp_dnsmi')
  },
  set dnsmi(value: string) {
    // NOTE: We don't set LS values (those will be updated on next page load)
    // This is to avoid confusion in behavior since public values will not get purged in the process.
    // It's just one more load delayed.
    const cookieValue = value === 'true' ? '1' : '0'
    setCookie('dnt', cookieValue)
    setCookie('mp_dnsmi', cookieValue)
  },

  // Advertising ID sync reference: http://prebid.org/dev-docs/modules/userId.html#adapters-supporting-the-user-id-sub-modules
  get pubcid() {
    if (typeof (window as any)?.pbjs?.getUserIds !== 'function') {
      return
    }
    if (localStorage.getItem('local_mp_dnsmi') === 'true') {
      return
    }
    return (window as any).pbjs.getUserIds().pubcid
  },
  set pubcid(value) {
    setCookie('pbjs-pubCommonId', value, 30)
  },

  get tdid() {
    if (typeof (window as any)?.pbjs?.getUserIds !== 'function') {
      return
    }
    if (localStorage.getItem('local_mp_dnsmi') === 'true') {
      return
    }
    return (window as any).pbjs.getUserIds().tdid
  },
  set tdid(value) {
    const cookiePackage = {
      TDID: value,
      TDID_LOOKUP: 'TRUE',
      TDID_CREATED_AT: new Date().toISOString().slice(0, 19)
    }
    const encodedPackage = encodeURIComponent(JSON.stringify(cookiePackage))
    setCookie('pbjs-unifiedid', encodedPackage, 30)
  },

  get idl_env() {
    if (typeof (window as any)?.pbjs?.getUserIds !== 'function') {
      return
    }
    if (localStorage.getItem('local_mp_dnsmi') === 'true') {
      return
    }
    return (window as any).pbjs.getUserIds().idl_env
  },
  set idl_env(value) {
    setCookie('idl_env', value, 30)
  }
}

// Create mappings for which properties should be synced from which env.
// Sure, we can expand this and support multisource later, but for now we have a situation
// where each property has one source and one dest.
// Read = read from sync DB
// Write = write to sync DB.
const syncRules = {
  webview: {
    read: ['pubcid', 'tdid', 'idl_env'],
    write: ['dnsmi']
  },
  cct: {
    read: ['dnsmi'],
    write: ['pubcid', 'tdid', 'idl_env']
  }
}

export const syncEnvDataByUser = async () => {
  // Syncing is done by mpid. Can't do anything without it.
  const mpId = localStorage.getItem('int_mp_uuID')
  if (!mpId || !env_sync_api_url) {
    console.debug('SYNC: Missing some necessary parameters to perform env syncing')
    if (!mpId) {
      console.debug('SYNC: Missing mpId')
    }
    if (!env_sync_api_url) {
      console.debug('SYNC: Missing sync api endpoint. A backend stack may not be available.')
    }
    return
  }

  // If this isn't the first time a user has synced, we want to throttle
  // sync events to cap them at once a day.
  const timeOfLastAttemptedRetrieval = parseInt(localStorage.getItem(LS_KEY))
  if (timeOfLastAttemptedRetrieval) {
    const timeSinceLastRetrieval = Date.now() - timeOfLastAttemptedRetrieval
    const hasBeenASufficientAmountOfTime = timeSinceLastRetrieval >= 86_400_000
    if (!hasBeenASufficientAmountOfTime) {
      return
    }
  }

  // 1. Fetch current user data from sync DB
  let remoteValues = {}
  try {
    const res = await axios.get(`${env_sync_api_url}/${mpId}`)
    const { data } = res
    remoteValues = data
    // Update timestamp to prevent excessive syncs.
    localStorage.setItem(LS_KEY, `${Date.now()}`)
  } catch (error) {
    onFetchError(error)
  }

  // The environment is the deciding factor in which keys are read/written.
  const env = isWebview() ? 'webview' : 'cct'

  // 2. Values marked as read for this environment should be stored.
  // Remote values take priority in a read operation.
  const readKeys = syncRules[env].read
  for (const readKey of readKeys) {
    const localValue = keys[readKey]
    const remoteValue = remoteValues[readKey]
    if (remoteValue !== undefined && localValue !== remoteValue) {
      console.info(`SYNC: Reading ${readKey} from DB`)
      keys[readKey] = remoteValue
    }
  }

  // 3. Values marked as write for this environment should be written to the sync DB.
  // Local values take priority in a write operation.
  const valuesToWrite = {}
  const writeKeys = syncRules[env].write
  for (const writeKey of writeKeys) {
    const localValue = keys[writeKey]
    const remoteValue = remoteValues[writeKey]
    if (localValue !== undefined && localValue !== remoteValue) {
      valuesToWrite[writeKey] = localValue
    }
  }

  // No sense writing if there's nothing new to say.
  if (Object.keys(valuesToWrite).length) {
    const payload = {
      mpid: mpId,
      ...valuesToWrite
    }

    axios
      .post(env_sync_api_url, payload)
      .then(function () {
        console.debug(`SYNC: Writing ${Object.keys(valuesToWrite)} to DB`)
      })
      .catch(onFetchError)
  }
}
