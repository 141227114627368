import { FeedItemContext } from 'content/src/lib/feed/interfaces'
import defaultTemplate from './default'
import { app_theme } from '../feed_item'
import edgeDescriptionTemplate from './edge_description'

const edgeListTemplate = (feedItem: FeedItemContext) => {
  const isTheFirstArticle = feedItem.index <= 1
  const template = isTheFirstArticle ? edgeDescriptionTemplate(feedItem) : defaultTemplate(feedItem)
  const layoutName = isTheFirstArticle
    ? 'block--edge-description'
    : app_theme === 'moment'
    ? 'block--list-small'
    : 'block--list-medium'

  return `
    <section class="block ${layoutName}">
    ${template}
    </section>
    `
}

export default edgeListTemplate
