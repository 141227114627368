import GA4Handler from '../GA4Handler'
import {
  BlockType,
  DataLayerEventType,
  DataLayerExternalClickEvent,
  DataLayerInteractionType,
  getBlockInteractionType,
  getSponsoredType,
  isSponsored,
  Publishers
} from '../interfaces'
import { lazyload } from '../../utilities/DOM_events'
import { ga4DebugCancelLinkNavigation, getContentType } from '../index'

export class GA4FotoscapeGallery {
  static blockType = BlockType.fotoscape_gallery_block
  static blockLayout = 'fotoscape_gallery'

  static getTitle(element: HTMLElement) {
    return element.querySelector('.fotoscape-gallery__title')?.textContent || ''
  }

  public static wireImpressionBlock(blockElement: HTMLElement) {
    lazyload(
      blockElement,
      () => {
        // @todo Need to reference an interface type here.
        const e = {
          block_layout: this.blockLayout,
          block_position: blockElement.getAttribute('data-mp-block-index'),
          block_type: this.blockType,
          content_type: getSponsoredType(this.blockType),
          element: this.blockType,
          element_action: DataLayerEventType.impression_view,
          element_detail: this.getTitle(blockElement),
          event: DataLayerEventType.impression_view,
          interaction_type: getBlockInteractionType(this.blockType),
          publisher: Publishers.fotoscapes,
          title: this.getTitle(blockElement)
        }

        GA4Handler.pushObject('GA4FotoscapeGallery.wireImpressionBlock', e)
      },
      {
        threshold: 0.7
      }
    )
  }

  public static wireImpressionImage(
    blockElement: HTMLElement,
    imageElement: HTMLElement,
    index: number
  ) {
    lazyload(
      imageElement,
      () => {
        const d = {
          block_layout: this.blockLayout,
          block_position: blockElement.getAttribute('data-mp-block-index'),
          block_type: this.blockType,
          carousel_position: index,
          content_type: isSponsored(this.blockType) ? 'sponsored' : 'editorial',
          element: this.blockType,
          element_action: DataLayerEventType.impression_view,
          element_detail: this.getTitle(blockElement),
          event: DataLayerEventType.impression_view,
          interaction_type: getBlockInteractionType(this.blockType),
          publisher: Publishers.fotoscapes,
          title: this.getTitle(blockElement)
        }

        GA4Handler.pushObject('GA4FotoscapeGallery.wireImpressionImage', d)
      },
      {
        threshold: 0.7
      }
    )
  }

  public static wireLinkClick(blockContainerElement: any) {
    const w = window as any
    const articleElement = blockContainerElement.querySelector('article')
    const linkElement = articleElement.querySelector('.fotoscape-gallery__link')
    const url = new URL(
      articleElement
        .querySelector('a')
        .getAttribute('href')
        .replace(/^\/\//, `${w.location.protocol}//`)
    )

    linkElement.addEventListener('click', (e: Event) => {
      ga4DebugCancelLinkNavigation(e)

      const data: DataLayerExternalClickEvent = {
        block_layout: this.blockLayout,
        block_position: blockContainerElement.parentElement.getAttribute('data-mp-block-index'),
        block_type: this.blockType,
        content_type: getContentType(this.blockType),
        domain: url.hostname,
        element: this.blockType,
        element_action: 'click',
        element_detail: this.getTitle(blockContainerElement.parentElement),
        event: DataLayerEventType.impression_click,
        interaction_type: DataLayerInteractionType.internal,
        path: url.pathname,
        publisher: Publishers.fotoscapes,
        title: this.getTitle(blockContainerElement.parentElement)
      }

      GA4Handler.pushObject('GA4FotoscapeGallery.wireLinkClick', data)
    })
  }
}
