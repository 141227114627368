import axios from 'axios'
import captureException from '../../../sentry/captureException'
import { neptuneDebug } from '../../../utilities'
import { NeptuneAttribute, NeptuneAttributeKey } from '../../lib/NeptuneAttribute'
import { fetchFotoscapesDaily } from '../fotoscape/fotoscape'
import FotoscapesURL from '../fotoscape/FotoscapesURL'
import { NewPersonalize } from '../fotoscape/helpers/personalize'
import GA4Handler from '../ga4_support/GA4Handler'
import dateSetup from '../portal_header/utilities'
import { refreshVisibleAds } from '../utilities/ad_behaviors'
import { appendInternalTrackingSearchParams } from '../utilities/analytics'
import { lazyload } from '../utilities/DOM_events'

const fsData = {}
const AD_DEFAULT_REFRESH_RATE_SECONDS = 30
const RECIRC_DELAY_SECONDS = 8

const w = window as any

w.LAST_AD_REFRESH_TIME = Date.now()

const setUI = (template, data) => {
  const language = document.documentElement.lang
  const instance = NewPersonalize()

  // Note: brandLogoDark does not match the color scheme.
  const title = instance.chooseText(data.title, language)
  const summary = instance.chooseText(data.summary, language)
  const owner = instance.chooseText(data.owner)
  const brandLogoLight = instance.chooseText(data.brandLogo)
  const brandLogoDark = instance.chooseText(data.brandLogo)
  const imageUrl = data.previews[0].link

  const content = template.content.firstElementChild.cloneNode(true)
  const destination = template.dataset.mpFotoscapeTickerLinkDestination
  const inner = content.querySelector('.inner-container') as HTMLElement
  const imageElement = content.querySelector('.fotoscape-ticker-image-container') as HTMLElement
  const textElement = content.querySelector('.fotoscape-ticker-text-container') as HTMLElement

  const link = destination === 'referral' && data.sourceLink ? data.sourceLink : data.link

  content.dataset.mpShareTitle = title
  content.querySelector('[data-mp-fotoscape-ticker-text]').textContent = title

  inner.style.setProperty('--bg-image', `url('${imageUrl}')`)
  imageElement.style.setProperty('--bg-image', `url('${imageUrl}')`)
  imageElement.style.setProperty('--bg-x', `${data.kb.endX * 100}%`)
  imageElement.style.setProperty('--bg-y', `${data.kb.endY * 100}%`)
  textElement.style.setProperty('--bg-image', `url('${imageUrl}')`)

  content.querySelector('[data-mp-fotoscape-ticker-link]').href = appendInternalTrackingSearchParams(link)

  if (document.querySelector('[data-mp-block-type="visual_brief_block"]')) {
    NeptuneAttribute.set(content, NeptuneAttributeKey.publisherName, data.owner)
    content.querySelector('[data-mp-fotoscape-ticker-summary]').textContent = summary
    content.querySelector('[data-mp-fotoscape-brand-logo]').setAttribute('src', brandLogoLight)
    content.querySelector('[data-mp-fotoscape-brand-logo-dark]').setAttribute('src', brandLogoDark)
    content.querySelector('[data-mp-fotoscape-brand-logo]').setAttribute('alt', `${owner} logo`)
  }

  return content
}

const displayArticleSlide = async (skeleton, template, category, blockIndex, itemIndex, slideCount) => {
  try {
    const data = fsData[category].shift()
    const content = setUI(template, data)

    // Create the slide.
    const child = skeleton.parentNode.insertBefore(content, skeleton)
    NeptuneAttribute.set(child, NeptuneAttributeKey.gtmScrollCount, itemIndex + 1)

    // Remove the skeleton.
    template.parentElement.querySelector('.skeleton').remove()

    // GA4 setup.
    const link = child.querySelector('a')
    const optional = {
      carousel_count: slideCount,
      carousel_position: child.getAttribute('data-gtm-scroll-count')
    }

    // Setup click handling.
    link.addEventListener('click', (browserEvent: Event) => {
      GA4Handler.clickHandleEvent(browserEvent, optional)
    })

    GA4Handler.impressionHandleEvent(child, optional, 0.7, link)
  } catch (e) {
    neptuneDebug(e)
    captureException(e)
  }
}

const touchHandlerAdRefresh = refreshRate => {
  refreshRate = refreshRate || AD_DEFAULT_REFRESH_RATE_SECONDS

  w.addEventListener('touchstart', () => {
    const now = Date.now()
    const diff = (now - w.LAST_AD_REFRESH_TIME) / 1000

    neptuneDebug(`TOUCH STARTED: seconds since last refresh: ${diff}`)

    if (diff >= refreshRate) {
      w.LAST_AD_REFRESH_TIME = now
      refreshVisibleAds()
    }
  })
}

const getVisualBriefBlock = (): HTMLElement => {
  return document.querySelector('[data-mp-block-type="visual_brief_block"]')
}

const getTemplateForBlock = (block): HTMLElement => {
  return block.querySelector('template[data-mp-visual-brief-fotoscape-template]')
}

/**
 * The default ad skeleton is a square and causes a flash while the slides load, so hide it.
 */
const removeAdSkeleton = () => {
  const skeleton = document.querySelector('#mp_ad_unit_TOP > .skeleton')
  skeleton.remove()
}

const initVisualBriefFotoscape = async () => {
  const block = getVisualBriefBlock()

  // No blocks found.
  if (!block) {
    return
  }

  removeAdSkeleton()
  touchHandlerAdRefresh(block.dataset.mpVisualBriefAdvertisementRefreshRate)

  const blockIndex = block.dataset.mpBlockIndex
  const headerElement = block.querySelector('header')
  const headerElementText = headerElement.querySelector('h1')
  const template = getTemplateForBlock(block)
  const category = block.dataset.mpVisualBriefCategory

  if (!template) {
    throw new Error('initVisualBriefFotoscape: template not found')
  }

  if (block.dataset.mpVisualBriefPageHeaderMode === 'dynamic') {
    const language = document.documentElement.lang
    const date = dateSetup(language)
    const text = `${date.format('ddd')}, ${date.format('MMMM')} ${date.format('D')}`
    headerElementText.textContent = text
  }

  if (!fsData[category]) {
    const data = await fetchFotoscapesDaily(category, '1:1')
    fsData[category] = data.items
  }

  const searchParams = new URLSearchParams(location.search)
  const fotoscapeId = searchParams.get('fotoscape_id')

  // If a fotoscape ID is passed in the querystring, use it for the first slide.
  // The response returned by the API is different then the standard lookbook query so a compatible
  // object needs to be created and prepended to the dataset.
  if (fotoscapeId) {
    const response = await axios.get(
      `${FotoscapesURL.getBaseURL()}/wp/v1/lookbook/${fotoscapeId}?ckey=${FotoscapesURL.getKey()}&previewAspect=1:1`
    )
    const json = response.data
    const lookbook = json.lookbook
    const article = response.data.lookbook.looks[0]

    fsData[category].unshift({
      brandLogo: lookbook.ownedBy.logo,
      brandLogoDark: lookbook.ownedBy.logoDark,
      images: article.images,
      interests: lookbook.interests,
      lbtype: lookbook.lbtype,
      link: `${FotoscapesURL.getBaseURL()}/lookbook/${fotoscapeId}/`,
      summary: lookbook.summary,
      title: lookbook.title,
      uid: lookbook.uid,
      kb: article.kenBurnsParams['0:0'],
      previews: [
        {
          width: article.images[0].width,
          height: article.images[0].height,
          link: article.images[0].url
        }
      ]
    })
  }

  const skeletons = Array.from(block.querySelectorAll('.skeleton'))
  const slideCount = skeletons.length

  await Promise.all(
    skeletons.map(async (s: any, itemIndex) => {
      await displayArticleSlide(s, template, category, blockIndex, itemIndex, slideCount)
    })
  )

  const cycle = (element, fn) => {
    const index = Math.floor(Math.random() * fsData[category].length)
    const item = fsData[category][index]

    const element2 = document.querySelector('.image-cycle > .upper') as HTMLElement
    element2.style['background-image'] = `url('${item.images[0].link}')`
    element2.style['background-position-x'] = `${item.kb.endX * 100}%`
    element2.style['background-position-y'] = `${item.kb.endY * 100}%`

    const elementContainer = document.querySelector('.image-cycle') as HTMLElement
    elementContainer.style.backgroundImage = `url('${item.images[0].link}')`

    window.setTimeout(() => {
      fn(element, fn)
    }, RECIRC_DELAY_SECONDS * 1000)
  }

  const imageCycle = block.querySelector('.image-cycle')
  lazyload(imageCycle, () => {
    cycle(imageCycle, cycle)
  })
}

export default initVisualBriefFotoscape
