/**
 * @file
 *
 * Outbrain requires the use of an external library to facilitate making recommendations calls.
 * This is at https://widgets.outbrain.com/outbrain.js.
 * (TODO: Do we want to include the library here instead? Can we even?)
 *
 * This first implementation is as-is. That means client-side rendering. Ideally, we'd be able to
 * move this process server-side, but that will remain for now as a TODO (docs available here:
 * http://developer.outbrain.com/apis/outbrain-endpoint-api-guide-server-server/)
 *
 * The bulk of the following is in accordance with the API guide
 * (http://developer.outbrain.com/apis/outbrain-js-api-guide/) and from existing requirements.
 *
 */

import 'intersection-observer'
import { getAssetDomainByMPGlobals } from 'lib/deployment' // Polyfill for v50 and below
import userAgentMatch from '../../../utilities/userAgentMatch'
import captureException from '../../../sentry/captureException'
import { neptuneDebug, neptuneWarn } from '../../../utilities'
import initActionButtons from '../action_buttons/action_buttons'
import GA4Handler from '../ga4_support/GA4Handler'
import ga4GenericLinkImpressionHandler from '../ga4_support/generic/ga4GenericLinkImpressionHandler'
import feedItemTemplate from '../shared/templates/feed_item'
import { setTimeDifference } from '../timestamp/utils'
import { lazyload } from '../utilities/DOM_events'
import { cropImage } from '../utilities/imageManipulation'
import * as modal from '../utilities/modal/modal'
import { OutbrainResponse } from './interfaces'
import { setBlockAnimation } from '../../../utilities/animation'

/**
 * Receive outbrain response, extract the required properties, and call renderPost
 * with the relevant calculated values.
 *
 * There are always caveats.
 * 1) Outbrain does not respect any configured item count (according to Lawter). To that
 * end, we have to slice only the number of elements a content editor has indicated should be in the
 * block. This value will be stored in the global config.
 *
 * TODO: Attaching event callbacks for view
 *
 * @param res Raw Outbrain container response
 */

const language = document.documentElement.lang

const generateItemHTML = (rawPost, layout, index, appTheme) => {
  const { content: title, thumbnail, url: link, source_name: publisherName, pc_id, logo } = rawPost

  // Use cloudinary to reduce image size
  const baseThumbnailUrl = thumbnail.url
  const thumbnailUrl = cropImage(baseThumbnailUrl, getAssetDomainByMPGlobals(), {
    size: appTheme === 'moment' ? 'photocard' : 'large'
  })

  const publisherLogo = logo?.url

  // The presence of any pc_id tells us it is sponsored.
  const isSponsored = pc_id != undefined
  const postContext: any = {
    title,
    // The API says that by using this url, we avoid having to also manually emit click events to outbrain.
    link,
    thumbnailUrl,
    isSponsored,
    publisherName,
    layout,
    index,
    pub_time: setTimeDifference(rawPost.publish_date, language)
  }

  if (!isSponsored && publisherLogo) {
    postContext.publisherLogo = publisherLogo
  }
  return feedItemTemplate(postContext)
}

const addViewability = (doc, container) => {
  Array.from(container.children).map((child, index) => {
    const rawPost = doc[index]
    const blockItem: any = child
    const onViewed = rawPost['on-viewed'][0]

    const recordView = () => {
      neptuneDebug(`Outbrain View: reported item viewed ${rawPost.content} ${onViewed}`)
      window.OBR.extern.callViewed(onViewed)
    }

    lazyload(blockItem, recordView, {
      threshold: 0.5
    })
  })
}

const onResponse = (blockContainerId: string, response: OutbrainResponse) => {
  const blockContainerSelector = `#${blockContainerId} .block__main`
  const blockContainerElement = document.querySelector(blockContainerSelector)
  const layout = blockContainerElement?.parentElement.getAttribute('data-mp-block-layout')
  const appTheme = document.documentElement.getAttribute('data-mp-app-theme')

  if (blockContainerElement instanceof HTMLElement) {
    /*
     * @todo-outbrain reportViewed used to be sent, but is apparently not anymore.  Outbrain appears
     *                to have added error handling since the last outage.  Need to confirm with Outbrain before removing. --hrivera
     */
    if (response.reportViewed) {
      neptuneDebug(`Outbrain View: reported section viewed ${blockContainerId} ${response.reportViewed}`)
      try {
        window.OBR.extern.callViewed(response.reportViewed)
      } catch (e) {
        captureException(e, 'outbrain onResponse: call to OBR.extern.callViewed failed')
      }
    } else {
      neptuneWarn(`Outbrain View: reportViewed is missing in response`)
    }

    if (response.total_count == undefined || response.total_count === 0) {
      return
    }

    let innerHTML = ''

    for (let i = 0; i < response.total_count; i++) {
      const rawPost = response.doc[i]
      innerHTML += generateItemHTML(rawPost, layout, i + 1, appTheme)
    }

    blockContainerElement.innerHTML = innerHTML
    addViewability(response.doc, blockContainerElement)

    // GA4 click handling.
    for (const link of Array.from(blockContainerElement.querySelectorAll('article a'))) {
      link.addEventListener('click', e => {
        GA4Handler.clickHandleEvent(e)
      })
      ga4GenericLinkImpressionHandler(link as HTMLElement)
    }
  }

  modal.init({ containerSelector: blockContainerSelector })
  initActionButtons(blockContainerSelector)
}

const initOutbrain = async () => {
  // If a config doesn't exist, we can assume there are no outbrain elements on a page.
  const config = window.mp_globals.outbrain_config

  if (!config) {
    return
  }

  if (config.override) {
    neptuneDebug('OUTBRAIN: FOUND OVERRIDE')
    if (userAgentMatch(navigator.userAgent, config.override.matchList)) {
      config.commonRequestParams.permalink = config.commonRequestParams.permalink.replace(
        config.outbrain_id,
        config.override.platform_id
      )
      neptuneDebug(`OUTBRAIN: REPLACED PLATFORM ID ${config.outbrain_id} ${config.override.platform_id}`)
    }
  }

  // Executing Outbrain requests is done via a 3rd party library. If it's not available there's no need to do anything
  // else.
  const hasRequiredOutbrainDependency = window.hasOwnProperty('OBR') && window.OBR.hasOwnProperty('extern')
  if (!hasRequiredOutbrainDependency) {
    throw new Error('External Outbrain library not available prior to executing MP Outbrain module.')
  }

  const baseParams = {
    ...config.commonRequestParams
  }

  // We don't want to override the outbrain user tracking except in a webview (where they have none) or when
  // DNT is enabled in both webview and Chrome.
  const advertisingId = localStorage.getItem('mp_androidAID')
  if (advertisingId) {
    baseParams.userId = advertisingId
  }
  const shouldNotTrack =
    localStorage.getItem('local_mp_dnsmi') === 'true' ||
    localStorage.getItem('mp_dnt') === 'true' ||
    localStorage.getItem('mp_ifadtrk') === 'false'
  if (shouldNotTrack) {
    baseParams.userId = 'null'
  }

  // We need to execute a request on a per container basis.
  const { containers } = config
  const containerConfigs: any = Object.values(containers)

  for (const [idx, containerConfig] of containerConfigs.entries()) {
    const { widgetId, block_id } = containerConfig
    const params = {
      ...baseParams,
      widgetId
    }

    // The current preference is not to delay the loading of the first Outbrain block, even if it
    // is outside the viewport. This was a business decision, not load optimization.
    const outbrainBlockContainer = document.querySelector(`#${block_id}`)

    // This keeps from calling the outbrain API when the block is part of a A/B test that is not supposed to show
    if (!outbrainBlockContainer) continue

    /**
     * Wrap the call to outbrain in a function so it can be lazy loaded.
     *
     * Note that the Outbrain library wraps this in a try/catch which eats errors. --hrivera
     */
    const requestOutbrainContent = () => {
      window.OBR.extern.callRecs(params, (response: OutbrainResponse) => {
        onResponse(block_id, response)

        const itemList = outbrainBlockContainer.querySelectorAll('.block-item')
        itemList.forEach(item => {
          setBlockAnimation(item)
        })
      })
    }

    if (idx === 0) {
      requestOutbrainContent()
    } else {
      lazyload(outbrainBlockContainer, requestOutbrainContent, {
        rootMargin: '0px',
        threshold: 0
      })
    }
  }
}

export default initOutbrain
