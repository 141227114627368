import i18n from '../../../utilities/translation'
const language = document.documentElement.lang

export default `
<div class="ticker-block-item">
    <div class="weather-container weather-container--no-location">
      <div class="weather-container__text-content">
        <p class="weather-container__title">${i18n('Get local weather now!', language)}</p>
        <a href="https://smart.link/fbfp423n2q84v?sourceProviderId=dt" class="weather-container__link">weatherbug.com</a>
      </div>
      <div class="weather-container__button-container">
        <a href="https://dailymoments.page.link/Moment-Location" class="weather-container__button">
          <div>${i18n('Allow location', language)}</div>
        </a>
      </div>
    </div>
</div>`
