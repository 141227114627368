import axios from 'axios'
import { FotoscapeLicensedResponseImage } from 'content/src/assets/common/scripts/fotoscape/interfaces/FotoscapeLicensedResponse'
import feedItemTemplate from 'content/src/assets/common/scripts/shared/templates/layouts/stub'
import { cropImage } from 'content/src/assets/common/scripts/utilities/imageManipulation'
import i18n from 'content/src/assets/utilities/translation'
import { FeedItemContext } from 'content/src/lib/feed/interfaces'
import { getAssetDomainByMPGlobals } from 'lib/deployment'
import { pubwiseBiddingSetup } from '../../../pubwise_integration'
import FotoscapesURL from './FotoscapesURL'
import { LicensedFotoscapeArticle } from './licensed_fotoscape_article'

const CONFIG_CKEY = '733053ec7939379e'

export const generateLicensedFotoscapeContext = (post): FeedItemContext => {
  // @todo Links in the article body are breaking the template, I've not been able to figure out why.
  const div = document.createElement('div')
  div.innerHTML = post.body

  for (const link of div.querySelectorAll('a')) {
    link.replaceWith(link.text)
  }

  return {
    credit: post.credit,
    thumbnailUrl: post.image,
    publisherName: post.publisher,
    publisherLogo: post.logo
      ? cropImage(post.logo, getAssetDomainByMPGlobals(), { size: 'clamped', protocol: 'https:' })
      : '',
    publisherDarkLogo: post.darkLogo
      ? cropImage(post.darkLogo, getAssetDomainByMPGlobals(), {
          size: 'clamped',
          protocol: 'https:'
        })
      : '',
    title: post.title,
    contentType: 'gallery',
    contentTypeText: 'browse',
    link: post.url,
    layout: post.layout,
    description: post.summary,
    index: post.index,
    pub_time: post.pub_time, // setTimeDifference(pub_time),
    category: post.category,
    body: div.innerHTML,

    // Should probably make these optional.
    contentApiId: null,
    duration: null,
    isSponsored: false,
    isVideoLink: false,
    ms_news: false,
    uid: post.uid
  }
}

/**
 * Returns the body for the first pane that contains an article body.
 */
export const getBody = (language, panes = []) => {
  for (const p of panes) {
    if (p.translations?.[language]) {
      return p.translations?.[language]
    }
  }
}

export const generateLicensedFotoscapeArticleHtml = async (language, json) => {
  const article = await LicensedFotoscapeArticle.generateLicensedFotoscapeArticleLook(
    language,
    json
  )
  const context = generateLicensedFotoscapeContext(article)
  return feedItemTemplate(context, { show_back_button: true, force_moment_template: true })
}

export const fetchLicensedFotoscapeArticleJson = async (id = '7mfEedlCR') => {
  // Test articles.
  // id = 'x6fqBxlIe'
  // id = 'ZofBYEwc0'
  // id = '26fRbBeiE'
  // id = 'aYf0Oo1fO'
  // id = '7mfln3NcR'
  // id = 'w6f3POpTV'
  // id = 'lAfYnkwiQ'
  // id = '01fP2gASJ'
  // id = 'BBfOmjOuA'
  // id = 'e1fj1RVU7'
  // id = 'Gvf3WR3u4'
  // id = 'PYfkNPVSo'
  // id = 'v4fyR06To' // Has a link.

  const url = `${FotoscapesURL.getBaseURL()}/wp/v1/lookbook/${id}?ckey=${FotoscapesURL.getKey()}&tf=html`

  const response = await axios.get(url)

  return response.data
}

/**
 * Return the smallest image that fits.  Returns the first image if no match is found.
 */
export const findImage = (images: FotoscapeLicensedResponseImage[], w: number, h: number) => {
  let image = null
  let smallest2 = Number.MAX_VALUE
  const len = images.length

  for (let i = 0; i < len; i++) {
    const el = images[i]
    if (el.width >= w && el.height >= h) {
      const size2 = el.width * el.width + el.height * el.height
      if (size2 < smallest2) {
        image = el
        smallest2 = size2
      }
    }
  }

  if (!image) {
    image = images[0]
  }
  return image
}

export const splitUnitPath = path => {
  const list = path.split('/')

  return {
    dfp_account_id: list[1],
    ad_unit_path: list[2]
  }
}

export const getFotoscapeIDFromFeedItem = async () => {
  const { feed_item_api } = (window as any).mp_globals.env
  const searchParams = new URLSearchParams(location.search)
  const feedItemID = searchParams.get('feed_item')

  if (feedItemID) {
    const response = await axios.get(`${feed_item_api}/${feedItemID}`)
    const link = response.data.link
    const fotoscapeId = link.match(/lookbook\/([^\/]+)/)[1]

    if (fotoscapeId) {
      return fotoscapeId
    }
  }

  return null
}

export const getFotoscapeIdFromFotoscape = () => {
  const searchParams = new URLSearchParams(location.search)
  const fotoscapeId = searchParams.get('fotoscape_id')

  if (fotoscapeId) {
    return fotoscapeId
  }

  return null
}

/**
 * Fetchs an ad configuration from fotoscapes for a specific utm_source.
 *
 * @param id Aticle ID.  Contains a default to help with testing pages.
 */
export const fetchAdTag = async (id = 'OnfysO') => {
  const searchParams = new URLSearchParams(location.search)
  const baseUrl = `${FotoscapesURL.getBaseURL()}/wp/v1/brandgroup/${id}/adtags`
  const qs = [`ckey=${CONFIG_CKEY}`, `param=utm_source`, `value=${searchParams.get('utm_source')}`]
  const url = `${baseUrl}?${qs.join('&')}`

  const response = await axios.get(url)

  if (!response.data) {
    throw new Error('fetchAdTag failed: response is empty')
  } else if (!response.data.adTag) {
    return null
  }

  return response.data.adTag
}

export const generateAdBlock = () => {
  const language = document.documentElement.getAttribute('lang')
  const title = i18n('Advertisement', language)
  const ad_unit_id = 'mp_ad_unit_TOP'

  return `
    <section
      class="block block--ad"
      data-mp-block-type="ad"
      data-mp-block-index="{{ @index }}"
      data-mp-block-layout="{{ layout }}"
      data-mp-block-type="{{ @root.blockTypes.ad_unit }}">

      <div class="block-item" data-block-plugin-id="mp_prebid_js">
        <div
          id="${ad_unit_id}"
          class="block-item__ad prebid prebid_ad_unit ${language} loading"
          title="${title}">

          <div class="skeleton"></div>
        </div>
      </div>
    </section>
  `
}

const initFotoscapesLicensedArticle = () => {
  pubwiseBiddingSetup()
  const language = document.documentElement.getAttribute('lang')
  LicensedFotoscapeArticle.renderLicensedFotoscapeArticle(language)
}

export default initFotoscapesLicensedArticle
