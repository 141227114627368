import i18n from '../../../utilities/translation'
import ga4EmojiFeedbackClickHandler from '../ga4_support/blocks/emoji_feedback'
import { BlockType } from '../ga4_support/interfaces'
import GA4Handler from '../ga4_support/GA4Handler'

const KEY = 'emoji_feedback_form'

const hasSubmittedEmojiForm = () => {
  const forms = getForms()
  const hasSubmitted = forms[formKey()]

  return hasSubmitted === true
}

const close = block => {
  block.classList.add('fadeOut')

  ga4EmojiFeedbackClickHandler(block, 'editorial', 'close', 'close')

  setTimeout(() => {
    block.remove()
  }, 400)
}

const getForms = () => {
  return localStorage.getItem(KEY) ? JSON.parse(localStorage.getItem(KEY)) : {}
}

const formKey = () => {
  const title: HTMLElement = document.querySelector('[data-emoji-feedback-title]')
  return title.innerText.toLowerCase().replace(/ /g, '')
}

const transitionScreen = block => {
  const title: HTMLElement = block.querySelector('[data-emoji-feedback-title]')
  const body: HTMLElement = block.querySelector('[data-emoji-feedback-body]')
  const language = document.documentElement.lang

  title.innerText = i18n('Thank you for your feedback!', language)
  title.classList.add('fadeIn')
  body.innerHTML = ''
  body.style.height = '48px'

  setTimeout(() => {
    close(block)
  }, 1000)
}

const emojiClickHandler = (e, block) => {
  const active = e.currentTarget.querySelector('.active')
  const inactive = e.currentTarget.querySelector('.inactive')
  const question = e.currentTarget.getAttribute('data-gtm-element-detail')
  const action = e.currentTarget.getAttribute('data-gtm-event-action')

  active.style.display = 'inline-block'
  inactive.style.display = 'none'

  const form = getForms()
  form[formKey()] = true

  localStorage.setItem(KEY, JSON.stringify(form))

  ga4EmojiFeedbackClickHandler(block, 'editorial', action, question)

  setTimeout(() => {
    transitionScreen(block)
  }, 1000)
}

const initEmojiFeedback = () => {
  const block: HTMLTemplateElement = document.querySelector('[data-emoji-feedback-content]')

  if (!block) return
  if (hasSubmittedEmojiForm()) {
    block.remove()
    return
  }

  GA4Handler.impressionHandleEvent(block as HTMLElement, {
    block_layout: BlockType.emoji_feedback_block
  })

  const closeButton: HTMLElement = block.querySelector('[data-prompt-close]')
  const emojiButtons: HTMLElement[] = Array.from(block.querySelectorAll('[data-emoji-button]'))

  closeButton.onclick = () => {
    close(block)
  }

  emojiButtons.forEach(btn => {
    btn.onclick = e => emojiClickHandler(e, block)
  })
}

export default initEmojiFeedback
