import screenTemplate from './templates/screen'
import { openAppInPlayStore } from '../bridge_interface/bridge_interface'
import i18n from '../../../utilities/translation'
import { constrainTabFocusToModal, setFocusToFirstFocusableElement } from '../utilities/modal'
import ga4AppRatingClickHandler from 'content/src/assets/common/scripts/ga4_support/blocks/app_rating'
import GA4Handler from '../ga4_support/GA4Handler'
import { setUpTimeWillBeAllowedIn, updateFormWasShownTimeArray } from './utils'

// NEW NOTES FROM BB
// This is going to be a half-baked, half-cocked quick pass. There's intent to ASAP replace this with a new design.
// One thing to note is that we have to purge the input and replicate the inaccessible contenteditable pattern they used
// or this won't work on pistachio, because no one noticed pistachio broke text inputs.

// OLD NOTES FROM INITIAL SCRAPS IN A CAVE PASS
// Do we want to use several templates, or do we want to update elements?
// It affects how we change listeners. Might be easier to treat each screen as a new app instance.
// Let's start wth four templates and figure out if that breaks analytics massively later. (probably will need some
// sort of GA intermediary (which is likely why they inlined everything and used display rules to toggle screens. We could also
// do this quickly if need be by moving all the client templates into the handlebars partial and initially hiding the screens))
// Will also need to make sure cursor focus is reoriented correctly for WCAG.
// In addition, we may want to create elements and reuse them rather than using innerHTML and having to relocate elements
// every time.

// TODO: translation

// The document brand name doesn't match the APK name. Quick hack is creating a mapping here instead of
// in the platform configurations to avoid touching anything until we have confirmation of the change.
const platformBrandingMap = {
  blu: 'Moment',
  boost: 'FastNEWS',
  cricket: 'theSCOOP',
  firstly: 'Moment',
  metropcs: 'metroZONE',
  pwg: 'Moment',
  moment: 'Moment',
  essentials: 'Essentials',
  uscc: 'NewsPop',
  hmd: 'Moment'
}

const language = document.documentElement.lang
const platform = document.documentElement.dataset.mpPlatform
const theme = document.documentElement.dataset.mpAppTheme
const brand = platformBrandingMap[platform]
const mpId = localStorage.getItem('int_mp_uuID')
const appVersionCode = localStorage.getItem('int_mp_appVersionCode')

// NOTE: Cashew did a lovely thing and coded the drupal page/node id into the cookie name. So we can't just
// keep using the same cookie to ensure a user doesn't see the prompt after migration.
// One could argue that we'd have future use cases for showing the prompt to the same user after a completed
// exchange, but I don't know. They can't rate the app twice. Maybe it's good as a filter of bad reviews, but its very
// unlikely that the timing will be so serendipitous on the 2nd, 3rd, or 4th displays to capture the exact moment when a user
// was about to go to the play store to bitch. It will just antagonize them further if anything.
// So, to that end, we are going to look for the cookie's existence generically, not based on node id.
const LEGACY_COOKIE_NAME_BASE = 'hideFeedbackPrompt'
const hasAlreadyInteractedWithFeedbackForm = document.cookie.includes(LEGACY_COOKIE_NAME_BASE)

// There seems to be a new series of requirements around the logic of when to display the prompt and
// when to render it permanently invisible rather than just snooze it for a period of time.
// To that end, we will use a more complex local storage object.
// TODO: Migrate the legacy cookies to a completed state in local storage? This might not be desired. Seems there is some
// desire to show newer feedback modules based on client version, which would change the calculus.
// We need to use a separate prepend for now because the bridge blows away int_mp values on refresh believing they are all bridge.
const LOCAL_STORAGE_KEY = 'local_mp_feedbackFormStore_v1'
const initialState = {
  hasRated: false,
  hasDeclinedToRate: false,
  hasSubmittedFeedback: false,
  hasDeclinedToSubmitFeedback: false,
  initialReaction: null,
  hasCompleted: false,
  hasCompletedLegacy: hasAlreadyInteractedWithFeedbackForm,
  hasInteracted: false,
  timeOfLastInteraction: null,
  appVersionCodeAtTimeOfLastInteraction: null,
  timeWillBeAllowedIn: null,
  formWasShownTimeArray: []
}
const existingStoreState = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY))
let activeState = existingStoreState || initialState

for (const key of Object.keys(initialState)) {
  if (!activeState.hasOwnProperty(key)) {
    activeState[key] = initialState[key]
  }
}

// Breaking the store update into a function to not have to repeat the JSON serializing logic.
export const updateState = newState => {
  // NOTE: Not throwing on invalid keys. Just absorbing. Play nice.
  activeState = {
    ...activeState,
    ...newState,
    timeOfLastInteraction: Date.now(),
    appVersionCodeAtTimeOfLastInteraction: appVersionCode
  }
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(activeState))
}

// NOTE: We should use freshdesk instead.
// neptune one. not firing. so reusing old one for now
// const googleFormBase = `https://docs.google.com/forms/d/e/1CE4WCNRFpye6GDJjhCKfUkske-p1cPVZtSvACxXqZ50/formResponse?entry.1672660817=${brand}&entry.1600970032=${mpId}&&submit=Submit&entry.553747833=`
const googleFormBase = `https://docs.google.com/forms/d/e/1FAIpQLSerCEEkLaEJM3y6T1sp9t_KAxnDBkClPXWCNDFdzh4sxjweBQ/formResponse?entry.1672660817=${brand}&entry.1600970032=${mpId}&&submit=Submit&entry.553747833=`

const sendFeedback = userText => {
  const feedbackEndpoint = googleFormBase + encodeURIComponent(userText)
  const iframe = document.createElement('IFRAME')
  iframe.setAttribute('id', 'hidden-iframe')
  iframe.setAttribute('src', feedbackEndpoint)
  iframe.style.display = 'none'
  document.body.appendChild(iframe)
}

const close = () => {
  const feedbackFormContainer = document.getElementById('mp_feedback_form')
  feedbackFormContainer.style.display = 'none'
}

// 5. Screen 5: No problem.
//    No problem. If you ever change your mind, you can find us on the Play Store. Enjoy!
const renderScreen5 = container => {
  updateState({ hasInteracted: true, initialReaction: 'positive' })
  const title = i18n(
    'No problem. If you ever change your mind, you can find us on the Play Store. Enjoy!',
    language
  )

  container.innerHTML = screenTemplate({
    text: title
  })
}

// Define Actions for: THANK YOU TAG
// 4. Screen 4:
//    a) Close (EXIT)
const renderScreen4 = container => {
  const title = i18n('Thank you for your feedback!', language)
  container.innerHTML = screenTemplate({
    text: title
  })
  const element = container.querySelector('.feedback-form__icon') as HTMLElement
  GA4Handler.impressionHandleEvent(element as HTMLElement, {
    title: title,
    element_detail: 'screen_4'
  })

  setTimeout(close, 3000)
  addClose(container)
  setFocus(container)
}

// 3. Screen 3: APP RATING
//    a) No thanks (EXIT)
//    b) Rate the app (Open App in play store) -> (Screen 4)
const renderScreen3 = container => {
  updateState({ hasInteracted: true, initialReaction: 'positive' })

  const title = i18n('Great! Would you mind rating the app?', language)

  container.innerHTML = screenTemplate({
    text: i18n('Great! Would you mind rating the app?', language),
    button_one_text:
      theme === 'moment' ? i18n('Not right now', language) : i18n('NO THANKS', language),
    button_one_action: 'No, Rate the App',
    button_two_text: theme === 'moment' ? i18n('Sure', language) : i18n('Rate the App', language),
    button_two_action: 'Sure, Rate the App'
  })
  addClose(container)

  const button1 = container.querySelector('#mp_feedback_form__button_1')
  const button2 = container.querySelector('#mp_feedback_form__button_2')

  GA4Handler.impressionHandleEvent(button1 as HTMLElement, {
    title: title,
    element_detail: 'Step 2: Display'
  })

  button1.addEventListener('click', e => {
    renderScreen5(container)
    updateState({
      hasDeclinedToRate: true,
      hasCompleted: true,
      timeOfCompletion: Date.now()
    })
    ga4AppRatingClickHandler(e, 'editorial', 'no', 'Step 2')
    setTimeout(close, 3000)
  })
  button2.addEventListener('click', e => {
    updateState({ hasRated: true, hasCompleted: true })
    ga4AppRatingClickHandler(e, 'editorial', 'yes', 'Step 2')
    close()
    openAppInPlayStore()
  })

  setFocus(container)
}

// 2. Screen 2: PROVIDING NEGATIVE FEEDBACK
//    a) Submit input (https://docs.google.com/spreadsheets/d/1ZfL1uCzevnccNXU0PS6OD0NIimvngircQ431zgjPpbE/edit?usp=sharing) -> (Screen 4)
//    b) cancel (EXIT)
const renderScreen2 = container => {
  updateState({ hasInteracted: true, initialReaction: 'negative' })
  const title = `${i18n("What's the top reason you're not enjoying", language)} ${brand}?`

  container.innerHTML = screenTemplate({
    text: title,
    feedback_title: 'form',
    button_two_text: i18n('Submit Feedback', language),
    button_two_action: 'Submit Feedback',
    screen: 'screen-2'
  })
  addClose(container)

  const onSubmit = e => {
    // Validate user has entered comments when trying to send feedback.
    const radios = form.elements['user_selection']
    const selection = radios.value
    if (selection) {
      ga4AppRatingClickHandler(e, 'editorial', 'no', `Step 3: ${radios.value}`)
      sendFeedback(selection)
      updateState({ hasSubmittedFeedback: true, hasCompleted: true })
      renderScreen4(container)
    }
  }
  const form = container.querySelector('#feedback-form__radio-buttons')
  const button2 = container.querySelector('#mp_feedback_form__button_2')

  button2.setAttribute('disabled', true)
  button2.addEventListener('click', onSubmit)

  GA4Handler.impressionHandleEvent(button2 as HTMLElement, {
    title: title,
    element_detail: 'screen_2'
  })

  form.addEventListener('submit', e => {
    e.preventDefault()
    onSubmit(e)
  })

  // Handle coordinating the state of the submit button based
  // on a selection being made or not.
  form.addEventListener('change', () => {
    const radios = form.elements['user_selection']
    const selection = radios.value
    if (!selection) {
      button2.setAttribute('disabled', true)
    } else {
      button2.setAttribute('data-gtm-event-label', selection)
      button2.removeAttribute('disabled')
    }
  })

  setFocus(container)
}

const setFocus = container => {
  setFocusToFirstFocusableElement(container)
}
// 1. Screen 1: CALL TO ACTION
//    a) Yes (screen 3)
//    b) Not Really (screen 2)
const renderScreen1 = container => {
  const title = `${i18n('Do you enjoy news from', language)} ${brand}?`

  container.innerHTML = screenTemplate({
    text: title,
    button_one_text: i18n("NO, I DON'T", language),
    button_one_action: 'Not Enjoying the App',
    button_two_text: i18n('YES, I LIKE IT!', language),
    button_two_action: 'Yes Enjoying the App'
  })
  addClose(container)

  const button1 = container.querySelector('#mp_feedback_form__button_1')
  const button2 = container.querySelector('#mp_feedback_form__button_2')

  GA4Handler.impressionHandleEvent(button1 as HTMLElement, {
    title: title,
    element_detail: 'screen_1'
  })

  button1.addEventListener('click', e => {
    e.preventDefault()
    ga4AppRatingClickHandler(e, 'editorial', 'no', 'Step 1')
    renderScreen2(container)
  })
  button2.addEventListener('click', e => {
    e.preventDefault()
    ga4AppRatingClickHandler(e, 'editorial', 'yes', 'Step 1')
    renderScreen3(container)
  })

  setFocus(container)
}

const addClose = container => {
  const closeButton: HTMLElement = container.querySelector('button[data-prompt-close]')
  closeButton.onclick = e => {
    ga4AppRatingClickHandler(e, 'editorial', 'close', 'close')
    close()
  }
}

const loadForm = feedbackFormContainer => {
  setUpTimeWillBeAllowedIn(activeState, updateState)

  // Lots of assumptions. We'll start with the simplest. Don't show it if they have completed, while
  // distinguishing between legacy and new.
  const shouldShow =
    !activeState.hasCompleted && !activeState.hasCompletedLegacy && !activeState.timeWillBeAllowedIn
  if (!shouldShow) {
    close()
    return
  }

  // To keep track of every time the form is shown to the user
  updateFormWasShownTimeArray(activeState, updateState)

  // Initialize screen 1
  const feedbackFormInnerContainer: HTMLElement = feedbackFormContainer.querySelector(
    '#mp_feedback_form__inner-container'
  )

  feedbackFormInnerContainer.onkeydown = event =>
    constrainTabFocusToModal(feedbackFormInnerContainer, event)

  renderScreen1(feedbackFormInnerContainer)
}

const placeAndLoadForm = feedbackFormContainer => {
  const fixedAdContainer = document.getElementById('pw-adh-dyn-container')
  if (fixedAdContainer) {
    fixedAdContainer.insertBefore(feedbackFormContainer.parentElement, fixedAdContainer.firstChild)
  }
  loadForm(feedbackFormContainer)
}

const initFeedbackFormPubwise = feedbackFormContainer => {
  if ((window as any).pubwise?.dyn_adunit_config?.[0]?.active == 1 && theme === 'moment') {
    if ((window as any).pubwise?.status?.PUBWISE_ADHESION_READY) {
      placeAndLoadForm(feedbackFormContainer)
    } else {
      document.body.addEventListener('PUBWISE_ADHESION_READY', () => {
        placeAndLoadForm(feedbackFormContainer)
      })
    }
  } else {
    loadForm(feedbackFormContainer)
  }
}

const initFeedbackForm = () => {
  const feedbackFormContainer = document.getElementById('mp_feedback_form')
  if (!feedbackFormContainer) {
    return
  }
  try {
    if ((window as any).mp_globals.flags.allow_pubwise && (window as any).setup?.pubwise?.scripts) {
      if ((window as any).pubwise?.loaded) {
        initFeedbackFormPubwise(feedbackFormContainer)
      } else {
        document.addEventListener('PubwiseLoadingSucceed', () => {
          initFeedbackFormPubwise(feedbackFormContainer)
        })
      }
    } else {
      loadForm(feedbackFormContainer)
    }
  } catch (e) {
    console.error('Error initializing feedback form ', e)
  }
}

export default initFeedbackForm
