export default [
  {
    title: 'Candy Crush Friends Saga',
    image:
      'https://play-lh.googleusercontent.com/hBqiEjyW5hlc_34fIvM8MjF4dTo4aGKi4yEVoimYQ9FB_xiZSfgx-aUb9gHEiu0KYas=s360-rw',
    url: 'https://play.google.com/store/apps/details?id=com.king.candycrush4&hl=en_US&gl=US',
    description:
      '¡El juego más nuevo de la franquicia Candy Crush! Con gráficos actualizados, diversión nueva...',
    starCount: 3.5,
    starVoteNumber: '1M+',
    downloadNumber: '1.5m',
    carouselImages: [
      'https://play-lh.googleusercontent.com/DkVQr9o4xjtFYphRUW_yDQi87hgWrNo6LBBWPlaTvdj10IaBHTm09SgB3338z4mDb2Q=w1440-h620-rw',
      'https://play-lh.googleusercontent.com/g0CX0a_brrm8gG3r0aXeorccsYYtz3OSTEhCgOVNHx0hI6_Eokhip1TTXKLcWNQ5OiA=w1440-h620-rw',
      'https://play-lh.googleusercontent.com/x2bXNrkygGOhE1srBjlz5hsUjiR0a7OnvXVbkujqFbZBavuknC25Aq7HEFOh8gErKA=w1440-h620-rw',
      'https://play-lh.googleusercontent.com/vk9pZ8BxjjrtOi4ts3Lw99IPWTKjvKzfT_6YpNr0RIxVy_H4waxlSlqf6KK8adyidLM=w1440-h620-rw'
    ]
  },
  {
    title: 'Johnny Trigger',
    image:
      'https://play-lh.googleusercontent.com/BWXMOiyHGAq5iWuCzfTfWOH7-9lHpTIfbyKtVmXbxcksfXrKOwztjz1KWoUJpccoaw=s360-rw',
    url: 'https://play.google.com/store/apps/details?id=com.time.trigger',
    description: '¿Tienes lo que se necesita para acabar con el mundo subterráneo de la mafia?',
    starCount: 3.5,
    starVoteNumber: '1M+',
    downloadNumber: '1.5m',
    carouselImages: [
      'https://play-lh.googleusercontent.com/R7_vETUmN7RwKf4v0eaE_46lvMVkl9xGci83_9jrRdoDdvRNZGjT-FpCQfh7VxJZ_fvk=w1440-h620-rw',
      'https://play-lh.googleusercontent.com/v38ORtyUKEITBbMFn4AJfQW4R0V-LFVFzyhqv78GNXI5bTSb2BCHTK4L96Ogq9VSABE=w1440-h620-rw',
      'https://play-lh.googleusercontent.com/81Y86AXAxPZ2CtsrTRado2hRMDaqu5NICT0Kif-tEUHDe1mGXBecyB4HCQ9OqjVt8g=w1440-h620-rw',
      'https://play-lh.googleusercontent.com/Of0jlWAgMw1LVp52YOqfYOoc39eZ4VN8hSbPl5kwnoDmu8XSZFdofP_cX5S6uRR0vJs=w1440-h620-rw'
    ]
  },
  {
    title: 'Bingo Blitz™️ - Bingo Games',
    image:
      'https://play-lh.googleusercontent.com/CncWZE2jjGJkdoJUsvKtNAw2LuHyNvQLTSuijuFlppKEjo-ma87knP7LYzEXjAZKJQ=s360-rw',
    url: 'https://play.google.com/store/apps/details?id=air.com.buffalo_studios.newflashbingo',
    description:
      'Experimente su juego de bingo en línea como nunca antes, mientras se embarca en una aventura de juegos de bingo, en Bin...',
    starCount: 3.5,
    starVoteNumber: '1.4M+',
    downloadNumber: '10M+',
    carouselImages: [
      'https://play-lh.googleusercontent.com/obR7Yt1omnUedQtHBK7JHOgje5k22hAh7cBiOLxSWnVfIEobXpn8Xgz-TdHrB3lDp0aX=w1440-h620-rw',
      'https://play-lh.googleusercontent.com/HCQXSLnjmn_IST4ODNqL6UA89iqg1fSk3qHTL8DmBH8sCZcV_qX5th2dvUPnR3-FhTGT=w1440-h620-rw',
      'https://play-lh.googleusercontent.com/elHOIgXYKNBpC5Y5bObuhOfiKJARf8XVo5YdWZY3PIAhNN_N-Ddrl8MwcrHD3xlIFhLR=w1440-h620-rw',
      'https://play-lh.googleusercontent.com/w59m0HITS1uDhZiKS-mI5hpld8ac1OEPncM2c6wOAxRjDt2AChX2XQPeoRgqnAd2SG0=w1440-h620-rw'
    ]
  },
  {
    title: 'Solitaire',
    image:
      'https://play-lh.googleusercontent.com/4eYZx0FAKK0woQPFm9LEaDBLccqfP6FJLzR_pDjOUIsnxqR4v8FS5LBgTbX9XZohzAA=s360-rw',
    url:
      'https://play.google.com/store/apps/details?id=solitaire.patience.card.games.klondike.free',
    description:
      '¡Solitario de MobilityWare es el juego de solitario original para dispositivos Android!',
    starCount: 3.5,
    starVoteNumber: '1.6M',
    downloadNumber: '100M+',
    carouselImages: [
      'https://play-lh.googleusercontent.com/M7V6xMvJDXxY5AY6YgwiV9UzOSh1wf9RqPpbPUayroiZ4SEGLk5SOOvc_b998iAqhw=w1440-h620-rw',
      'https://play-lh.googleusercontent.com/toa04bgZmqdZGMZVYAJrz1WMvpPmyBFoiKruGy63AT5ZHj4LNhk61UaPeVdJMy1v4M0=w1440-h620-rw',
      'https://play-lh.googleusercontent.com/AGG2jv9S9RWTFgO8BLxhQkzQRwriwff_00nHVx7qvf7_F1tQ1imgswvGBv8ydKuI4Fg=w1440-h620-rw',
      'https://play-lh.googleusercontent.com/l6Vp5rWhLUlJuDK3JoDuSn07a9V2WSfLfuillc20vvcmIWdigDLK9nSZFkcqi5av89k=w1440-h620-rw'
    ]
  },
  {
    title: 'WORDS CRUSH: WordsMania',
    image:
      'https://play-lh.googleusercontent.com/zKOQTcPeDs_LPjDtJqhBIIfjHXK-W_RP-gxHlbCz9GtgLxcoA_nPGA0wyIuLifAaSh_-=s360-rw',
    url: 'https://play.google.com/store/apps/details?id=com.zytoona.wordscrusheng',
    description: 'WORDS CRUSH es un nuevo y divertido juego de palabras para dispositivos Android.',
    starCount: 3.5,
    starVoteNumber: '1.4K',
    downloadNumber: '10M+',
    carouselImages: [
      'https://play-lh.googleusercontent.com/GuHKBt23R0CE8OtTm0HtJXHr6HEX-MGeY6_sZ8NmlZ-ERC-Y3aplJ9EsmM4cRLbQ1GoU=w1440-h620-rw',
      'https://play-lh.googleusercontent.com/rjmILv3QnMSzENnpjAxj4jUzFGSAzFJSwBIxVFsW-jeWoMVbMS2GUSBZElfoEFES9SI=w1440-h620-rw',
      'https://play-lh.googleusercontent.com/z07O-lEa2JlbKJcI8FEpJsiXY5En9GySmtsxKNeV5LbbRmjClA3VKKCI2Nd94Xzddg1B=w1440-h620-rw',
      'https://play-lh.googleusercontent.com/n8_Bd0L51JdhKvrjhEvGF9PjMkLQXVWcBBPoUF-lpMDdF7vIaShETUh3GL9bGXFy9ow=w1440-h620-rw'
    ]
  },
  {
    title: 'The House of Da Vinci',
    image:
      'https://play-lh.googleusercontent.com/nGmwV-5qJxd0eeXlIPAX2mqlzD_OI61-VQ3mjNWVd6sbKhuoYxGcBkRQ88MGsM05tFY=s360-rw',
    url: 'https://play.google.com/store/apps/details?id=com.bluebraingames.thehouseofdavinci',
    description:
      'Ingrese a The House of Da Vinci, un nuevo juego de aventuras de rompecabezas en 3D que debe probar. Resuelve acertijos mecánicos, descubr...',
    starCount: 3.5,
    starVoteNumber: '1.4K',
    downloadNumber: '30K+',
    carouselImages: [
      'https://play-lh.googleusercontent.com/5phJjHk5pwlPq64C8w5NAhHb6-6rSyDRmmm2eHWLu_AMcD2QKdqPu-zRPMp2tjHH6RY=w1440-h620-rw',
      'https://play-lh.googleusercontent.com/BPRyPaZF4_DUNmx7QYkajXLqptW2ohQsVBxLI0WO4lrtGA4hJ3i3-pmyPfgc_sHe7gk=w1440-h620-rw',
      'https://play-lh.googleusercontent.com/gAbSVZSvxoh80Tb5dIrcHZqJ0Xu01QxUTrW1f9GFzkeXxN2XWtPurdJY0nqdjj1E4w=w1440-h620-rw',
      'https://play-lh.googleusercontent.com/W-VseB-C2qWYJes8-TY3paIM7Iq8v_iRu7dO0-kY7kei-hUx7K9efF_at1T8_RQZnw=w1440-h620-rw'
    ]
  },
  {
    title: 'Royal Match',
    image:
      'https://play-lh.googleusercontent.com/qBdVfwRCsI4KM7qewhJ0AKZKQjyD-DdxPDcdDbsRMhNO9zrwbefggn1vGqRIDZA3fg=s360-rw',
    url: 'https://play.google.com/store/apps/details?id=com.dreamgames.royalmatch',
    description: 'DESLIZA COLORES, RESUELVE ROMPECABEZAS, DECORA EL CASTILLO Y GANA MONEDAS',
    starCount: 3.5,
    starVoteNumber: '100K+',
    downloadNumber: '145K+',
    carouselImages: [
      'https://play-lh.googleusercontent.com/v6UeTt6D-ShKxY2VqGH3BnyrUZJ77srLiBz5hqTa5kmGYHtumAghNTxcGbLMDqyrm50=w1440-h620-rw',
      'https://play-lh.googleusercontent.com/wFoLENLmHfvoAS6RRapC3dznZCBMcS4h07ElEnlClHVfxDh8GlEBfPm6VVB3SUx695o=w1440-h620-rw',
      'https://play-lh.googleusercontent.com/A78MYRBlJiOk3eGtVCLHLp6bhTrqrhKrFQn6vgvH3w-Zecu0HBZjqxtfpvnYBXdj61E=w1440-h620-rw',
      'https://play-lh.googleusercontent.com/xs_Wj8nDu5nyDCjJIR_yV5f19KPexYYFNxKGmmikB5NX8JuMKV6JdhypGqR35rN2mZuY=w1440-h620-rw'
    ]
  },
  {
    title: 'HBO Max',
    image:
      'https://play-lh.googleusercontent.com/GmIOtlRHzTffK3WSyNrz4NNrWFh_yUuhQb9UHXztk0ZxeeFzAUD52b9YVTGh7nsdJ8c=w480-h960-rw',
    url: 'https://delivers.dtignite.com/v2/clickAd.jsp?siteId=11942&campaignId=30086',
    description:
      'Mire programas de TV imperdibles, películas taquilleras y Max Originals exclusivos',
    starCount: 3.7,
    starVoteNumber: '374k',
    downloadNumber: '50M+',
    carouselImages: [
      'https://play-lh.googleusercontent.com/beqZefycxo9aRp3PzP9b4HpkC6OOHSAx345eVO9u20P_UmKYVBVllVXaZ5bXUArTbNE=w1052-h592-rw',
      'https://play-lh.googleusercontent.com/5sgzj_E3-FLo-Idt6-BMGVMeb3xyWJ1ByZzou1uWB2jW0IBsrzj3baNnFgv-RQulPg=w1052-h592-rw',
      'https://play-lh.googleusercontent.com/IABEsDPnh2CRSmwqSnRaXKMR8sDDtFVW_1nJlu0ErSqzoaju0cpQBbW3U8nQpWizwsOj=w1052-h592-rw',
      'https://play-lh.googleusercontent.com/RHU97Wst6-MllFsac6SVN0BEuEH7m1crLqxcpGgy-Xw4toZK8XCeg_jXSwX6H4VuRw=w1052-h592-rw'
    ]
  },
  {
    title: 'Rooster Teeth',
    image:
      'https://play-lh.googleusercontent.com/qBdVfwRCsI4KM7qewhJ0AKZKQjyD-DdxPDcdDbsRMhNO9zrwbefggn1vGqRIDZA3fg=s360-rw',
    url: 'https://delivers.dtignite.com/v2/clickAd.jsp?siteId=11942&campaignId=30087',
    description:
      'Obtenga su dosis diaria de videos y contenido, incluidos Red vs. Blue, RWBY, podcasts galardonados, videos de juegos, cortometrajes de acción en vivo, series con guión e incluso películas.',
    starCount: 3,
    starVoteNumber: '7.27K',
    downloadNumber: '500K+',
    carouselImages: [
      'https://play-lh.googleusercontent.com/7H66cABSU4fn9n1kO7ncjFRw8NdH9L8BohlVl2CjRnOMkp8gvwcQ2hVUiLQuASP-pnc=w1052-h592-rw',
      'https://play-lh.googleusercontent.com/1icHBuVAmtgMe2P7xLkb2T2rbw4jqla9sdM0vOXYK-q07Gp04xnJXHr7PhPVXo8RA7Q5=w1052-h592-rw',
      'https://play-lh.googleusercontent.com/RqucTiHUFivCWBzLJg1P8AErIUy1WhAUqO_vZOQ9gYBzQ64-rU5Cri1TI4p4b8V3JfM=w1052-h592-rw',
      'https://play-lh.googleusercontent.com/jfZ0gSOHRtuz0mwcDHeRsfveZxlV2ldQiEJabDBMNPiq_QLIjT-SSNCsF37NQhYTnFZF=w1052-h592-rw'
    ]
  },
  {
    title: 'Spotify',
    image:
      'https://play-lh.googleusercontent.com/UrY7BAZ-XfXGpfkeWg0zCCeo-7ras4DCoRalC_WXXWTK9q5b0Iw7B0YQMsVxZaNB7DM=w480-h960-rw',
    url: 'https://delivers.dtignite.com/v2/clickAd.jsp?siteId=11942&campaignId=30088',
    description: 'Música y podcasts',
    starCount: 4.4,
    starVoteNumber: '26.5M',
    downloadNumber: '1B+',
    carouselImages: [
      'https://play-lh.googleusercontent.com/fpGI7aCfk4xWIkEuRkLaOZBdGkoWpzKq-927vzx28xlBdcKq8mQ2oL8XKlg32NALvA=w1052-h592-rw',
      'https://play-lh.googleusercontent.com/DWcx1WvpqCnlhp_KSkF9ozBCr-vhhRMaoLaTTRRAnqwRbk27SBeNFMD0RgFNnfPs88U=w1052-h592-rw',
      'https://play-lh.googleusercontent.com/TGEmPmsFcewcJCpOzxtT23NpBv8AjEjvW3AHRviJ9zJTRTX5DnMdXTCBqTtyCaYzqhg=w1052-h592-rw',
      'https://play-lh.googleusercontent.com/7EX6KcvFsihyhU4F0tQ2Cxl9MkMEfptb_urm4x4Vw8kKVWBJMugA0nF6wju4n2vcdQ=w1052-h592-rw'
    ]
  },
  {
    title: 'Snapchat',
    image:
      'https://play-lh.googleusercontent.com/KxeSAjPTKliCErbivNiXrd6cTwfbqUJcbSRPe_IBVK_YmwckfMRS1VIHz-5cgT09yMo=w480-h960-rw',
    url: 'https://delivers.dtignite.com/v2/clickAd.jsp?siteId=11942&campaignId=30089',
    description:
      'Snapchat es una forma rápida y divertida de compartir el momento con tus amigos y familiares.',
    starCount: 4.2,
    starVoteNumber: '30.6M',
    downloadNumber: '1B+',
    carouselImages: [
      'https://play-lh.googleusercontent.com/xKCYMMuIshGmxLVckXnGYsdorvBxF0oI58Yt82Vkj_cn3Dby52gdrt4Lmr7BTYiVww=w1052-h592-rw',
      'https://play-lh.googleusercontent.com/yoN8h1j4M0Axz1UK2-iyPOmlQmIHqZ1tO8p7PjRicfmyPxj3-rztyB3YImG58zeMvOI=w1052-h592-rw',
      'https://play-lh.googleusercontent.com/3KtSEofVcRIQUWQnq814EYI7xo61uD6Cg1tvWdYTPUXweFMjv7HkA9b9FVmt3zYDkSau=w1052-h592-rw',
      'https://play-lh.googleusercontent.com/CHV1Yg5KonaNU_HHXG_Iol6zn7bAQ0b0o868LTPyh9bCLoZDu0GM8EJxBQ4zTcMfy90=w1052-h592-rw'
    ]
  },
  {
    title: 'Starbucks',
    image:
      'https://play-lh.googleusercontent.com/qrmolFrTzWjD7o_vVZ8bcQj0WM8-AI4fhJzg-L3ecLCLUJc9vNWOQ1MmkKQA2RN69C0u=w480-h960-rw',
    url: 'https://delivers.dtignite.com/v2/clickAd.jsp?siteId=11942&campaignId=30090',
    description:
      'Una forma conveniente de ordenar con anticipación para recoger, escanear y pagar en la tienda y personalizar sus favoritos.',
    starCount: 4.6,
    starVoteNumber: '692k',
    downloadNumber: '10M+',
    carouselImages: [
      'https://play-lh.googleusercontent.com/6Yo7K_KnK05-SJtr2MCuM52WiHgmHpUMCITlALA88hRl60Pf9e1mr4xdMoL-CL_Awg=w1052-h592-rw',
      'https://play-lh.googleusercontent.com/coX-ZXm2DrkgwvRdlB0465nF26Cg7X2Eio_QhCf6lsK8z4Dqww2kYCecovpKPrmBaQ=w1052-h592-rw',
      'https://play-lh.googleusercontent.com/5mFRTjJNS3H6OImPm7ZQFJ3kWLba-2D5cVKIxJVSg0e2TZjthG3H8OTsz0Ee9LXZEg=w1052-h592-rw',
      'https://play-lh.googleusercontent.com/h0SKIxXnwfCaaPyOvTPGq0Q3Tq6NNCg6VnHO_gslp0TrgAQEmS4mZjpKNlzHODLjDQ=w1052-h592-rw'
    ]
  },
  {
    title: 'Uber',
    image:
      'https://play-lh.googleusercontent.com/qy_wZ92sFQccojEtscg52vtdAQmCIeQ4jsybMPmuML9Or7_SEOyrt0Jn0wyG-l2Fyw=w480-h960-rw',
    url: 'https://delivers.dtignite.com/v2/clickAd.jsp?siteId=11942&campaignId=30093',
    description: 'ENCUENTRE UN VIAJE DESDE CASI CUALQUIER LUGAR',
    starCount: 4.4,
    starVoteNumber: '10.1M',
    downloadNumber: '500M+',
    carouselImages: [
      'https://play-lh.googleusercontent.com/BxAW6Q2C2GW2tahJBypTBlQC5ccZIfivm36vW1GxRJA7QJdgQytHe6aDAtoGzqyxKrRr=w1052-h592-rw',
      'https://play-lh.googleusercontent.com/ArREas37jPdfuXpU5s_UCidAH4ZVL_8iA-b3h-R2pszVRoAWfjuRAS0kiWlD82YhGA=w1052-h592-rw',
      'https://play-lh.googleusercontent.com/HVikLxQJze5vSayzxds1WFWY6BD38oXBngz1xjx2GCmldKy7WXVHFHyLRA6fai7oxWI=w1052-h592-rw',
      'https://play-lh.googleusercontent.com/SnzGog3LeEQ8Kw0AcF-KbvIH6Y4iS6GdtkZAkb4fqaCarrMf9cHlw8jRAXM35NsBtA=w1052-h592-rw'
    ]
  }
]
