import { IPlatform } from 'types-generator/shared/interfaces'
import {
  generateAdBlock,
  getFotoscapeIdFromFotoscape,
  getFotoscapeIDFromFeedItem,
  splitUnitPath,
  fetchAdTag,
  fetchLicensedFotoscapeArticleJson,
  findImage,
  generateLicensedFotoscapeArticleHtml,
  getBody
} from './full_page_article'
import initActionButtons from '../action_buttons/action_buttons'
import * as modal from '../utilities/modal/modal'
import { adBlockConfigure, initPubwise } from 'content/src/assets/common/scripts/pubwise_config'
import { IPageSettingAdCampaigns } from 'types-generator/page-configuration/IPageConfiguration'
import { IPlatformSettingHeaderBidding } from 'types-generator/platform-definition/IPlatformConfiguration'
import { FotoscapeLicensedResponse } from './interfaces/FotoscapeLicensedResponse'
import { FotoscapeLicensedArticle } from './interfaces/FotoscapeLicensedArticle'

export class LicensedFotoscapeArticle {
  /**
   * Note:
   * The fetchAdTag is used to pull an ad by the brand group instead of the fotoscape ID as
   * including all the available ad tags would result in a huge payload.  The brand group ID
   * is NOT the same as the fotoscape ID.
   */
  static async renderLicensedFotoscapeArticle(language, id = 'v4fyR06To') {
    const w = window as any

    const fotoscapeFotoscapeId = getFotoscapeIdFromFotoscape()
    const feedItemFotoscapeId = await getFotoscapeIDFromFeedItem()

    if (fotoscapeFotoscapeId) {
      id = fotoscapeFotoscapeId
    } else if (feedItemFotoscapeId) {
      id = await getFotoscapeIDFromFeedItem()
    }

    const block = document.querySelector('[data-mp-block-type="full_article_block"]')

    if (!block) {
      return
    }

    const json: FotoscapeLicensedResponse = await fetchLicensedFotoscapeArticleJson(id)
    const html = await generateLicensedFotoscapeArticleHtml(language, json)
    const main = block.querySelector(`.block__main`)
    const brandGroup = json.lookbook.ownedBy.brandGroup

    main.innerHTML = html

    main.querySelector('button.back').addEventListener('click', () => {
      history.back()
    })

    modal.init({ containerSelector: '[data-mp-block-type="full_article_block"]' })
    initActionButtons('[data-mp-block-type="full_article_block"]')

    LicensedFotoscapeArticle.removeCaption(main)

    const paragraphs = Array.from(main.querySelectorAll('p'))

    for (const index in paragraphs) {
      if (index === '2') {
        // Third paragraph.
        const p = paragraphs[index]
        const section = document.createElement('section')
        section.innerHTML = generateAdBlock()
        p.parentNode.insertBefore(section, p)
        break
      }
    }

    const ad = (await fetchAdTag(brandGroup)) || json.lookbook.defaultAdTag
    const adConfig = splitUnitPath(ad.unitPath)
    const headerBidding: IPlatformSettingHeaderBidding = {
      default: {
        en: {
          scripts: [
            {
              src: ad.pubwiseScript
            }
          ],
          dfp_account_id: adConfig.dfp_account_id,
          ad_unit_paths: {
            top: adConfig.ad_unit_path,
            bottom: adConfig.ad_unit_path // Not currently used.
          }
        }
      }
    }

    const campaign: IPageSettingAdCampaigns = {
      default: {
        config: 'default',
        type: 'single'
      }
    }

    try {
      await initPubwise(
        document.documentElement.dataset.mpPlatform as IPlatform,
        headerBidding,
        campaign,
        language,
        w.mp_globals.env.content_asset_url
      )

      adBlockConfigure(headerBidding.default.en, 0, 'mp_ad_unit_TOP', '', [])
    } catch (e) {
      console.error(e, 'initPubwise failed')
    }
  }

  /**
   * Remove caption.  We assume everything before a horizontal rule is the caption.
   */
  static removeCaption(main) {
    const horizontalRule = main.querySelector('hr') as HTMLElement

    if (!horizontalRule) {
      return
    }

    const startNode = horizontalRule as HTMLElement

    let count = 0
    let currentNode = startNode

    while (currentNode) {
      const deleteNode = currentNode

      // Look for previous element, or stop loop if not found.  Reassign before delete as the node will be gone.
      currentNode = currentNode.previousSibling as HTMLElement

      deleteNode.parentNode.removeChild(deleteNode)

      // Crash insurance.
      if (count++ > 50) {
        break
      }
    }
  }

  static async generateLicensedFotoscapeArticleLook(
    language: string,
    json: FotoscapeLicensedResponse
  ): Promise<FotoscapeLicensedArticle> {
    const context = {
      uid: json.lookbook.uid,
      credit: json.lookbook?.credit?.[language],
      url: '#',
      layout: 'licensed',
      summary: json.lookbook.summary[language] || '',
      index: 'N/A',
      pub_time: json.lookbook.publishOn || null, // @todo Find (or add) this in API response.
      category: null // @todo Find (or add) this in API response.
    }

    const look = json.lookbook.looks[0]
    if (!look) {
      throw new Error('generateLicensedFotoscapeArticleHtml: could not find first look')
    }

    const body = getBody(language, json.lookbook.plan.views[0]?.panes) || ''
    if (!body) {
      throw new Error(
        `generateLicensedFotoscapeArticleHtml: body not found for language ${language}`
      )
    }

    return {
      ...context,
      image: findImage(look.images, 450, 200).url,
      publisher: look.ownedBy.name,
      logo: look.ownedBy.logoLarge ?? '',
      darkLogo: look.ownedBy.logoLarge ?? '', // Yeah, this is wrong.
      title: json.lookbook.title[language],
      lbtype: json.lookbook.lbtype,
      body: body
    }
  }

  static async generateLicensedFotoscapeArticleLookbook(
    language: string,
    json: FotoscapeLicensedResponse
  ): Promise<FotoscapeLicensedArticle> {
    const context = {
      uid: json.lookbook.uid,
      credit: json.lookbook?.credit?.en,
      url: '#',
      layout: 'licensed',
      summary: json.lookbook.summary[language] || '',
      index: 'N/A',
      pub_time: json.lookbook.publishOn || null, // @todo Find (or add) this in API response.
      category: null // @todo Find (or add) this in API response.
    }

    const lookbook = json.lookbook

    return {
      ...context,
      image: findImage(lookbook.looks[0].images, 450, 200).url,
      publisher: lookbook.ownedBy.name,
      logo: lookbook.ownedBy.logo ?? '',
      darkLogo: lookbook.ownedBy.logoLarge ?? '', // Yeah, this is wrong.
      title: json.lookbook.title[language] || '',
      body: json.lookbook.summary[language] || '',
      url: lookbook.link,
      sourceLink: lookbook.sourceLink || '',
      lbtype: lookbook.lbtype
    }
  }
}
