/**
 * @file
 *
 * Basic lookup of translation mappings. Currently no support for templating, case agnosticism, etc.
 */

// Note: Keeping old file until we are sure this works: ./translations/index
const map = require('../../../lib/translation/translations.json')

const i18n = (key, language = 'en') => {
  const text = map?.[key]?.[language]

  if (!text) {
    // Disabling this as it generates too many warnings.  Will run locally once more missing translations have been fixed.
    if (typeof process !== 'undefined' && process.env.TRANSLATION_WARNINGS) {
      console.warn(`TRANSLATION MISSING: ${language} for '${key}'`)
    }
    return key
  }

  return text
}

export default i18n
