import { getCurrentWeather } from './apis/location'
import iconMap from '../weather_widget/settings/iconMap'
import { settings } from '../weather_widget/settings/config'
import placeholder from './placeholder'
import { appendInternalTrackingSearchParams } from '../utilities/analytics'
import { English, Spanish } from '../weather_widget/settings/Text'
import { GA4TickerWeather } from '../ga4_support/blocks/GA4TickerWeather'

const icon = '/icons/moment/'

const { icon_path } = settings

const setUI = (content, data) => {
  const lang = document.documentElement.lang
  let texts = English
  if (lang === 'es') texts = Spanish

  const iconData = iconMap[data.weatherData.iconCode]
  const temp = `${Math.round(data.weatherData.temperature)}°`
  const { city, state } = data.locationData
  const link = data.source.currentLink
  const max = Math.round(data.weatherData.temperatureMax)
  const min = Math.round(data.weatherData.temperatureMin)
  const feelsLike = Math.round(data.weatherData.temperatureFeelsLike)

  const iconImg = `${icon_path}${icon}${iconData.weatherChannelCode}_widget_light.svg`

  content.querySelector('[data-mp-ticker-temp]').textContent = temp
  content.querySelector('[data-mp-ticker-link]').href = appendInternalTrackingSearchParams(link)
  content.querySelector('[data-mp-ticker-location]').textContent = `${city}${
    state ? `, ${state}` : ''
  }`
  content.querySelector(
    '[data-mp-ticker-high-low]'
  ).textContent = `${max}° / ${min}° ${texts.feels} ${feelsLike}°`

  content.querySelector('[data-mp-ticker-weather-icon]').src = iconImg
}

const displayWidget = async template => {
  try {
    const content = template.content.firstElementChild.cloneNode(true)

    const data: any = await getCurrentWeather()

    setUI(content, data)

    const child = template.parentNode.insertBefore(content, template)

    GA4TickerWeather.wireImpressionImage(child, 0)
    GA4TickerWeather.wireLinkClick(child.querySelector('a'))
  } catch (e) {
    displayPlaceholder(template)
  }
}

const displayPlaceholder = async template => {
  template.parentElement.innerHTML = placeholder
}

const initWeatherTicker = async () => {
  const template: HTMLTemplateElement = document.querySelector(
    'template[data-ticker-weather-template]'
  )

  if (!template) return

  await displayWidget(template)
}

export default initWeatherTicker
