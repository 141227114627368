const feedTemplate = itemsNumber => {
  let dots = ''

  for (let i = 0; i < itemsNumber; i++) {
    dots += '<span class="dot"></span>'
  }

  return `
    <div class="slider-container">
        <div class="slides">            
        </div>

        <a class="prev"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M16 8C16 7.44771 15.5523 7 15 7L3.83 7L8.71 2.12C9.10172 1.72828 9.1006 1.09284 8.70749 0.702507C8.31635 0.314121 7.68476 0.315237 7.295 0.705003L0.0792093 7.92079C0.0354639 7.96454 0.0354641 8.03546 0.0792095 8.07921L7.29469 15.2947C7.68423 15.6842 8.31579 15.6842 8.70532 15.2947C9.09461 14.9054 9.09489 14.2743 8.70595 13.8847L3.83 9L15 9C15.5523 9 16 8.55228 16 8Z"
                    fill="white" fill-opacity="0.8" />
            </svg>
        </a>
        <a class="next"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M-6.93001e-06 8C-6.97829e-06 8.55228 0.447708 9 0.999993 9L12.17 9L7.29 13.88C6.89828 14.2717 6.8994 14.9072 7.29251 15.2975C7.68365 15.6859 8.31524 15.6848 8.705 15.295L15.9208 8.07921C15.9645 8.03546 15.9645 7.96454 15.9208 7.92079L8.70531 0.705314C8.31578 0.315779 7.68422 0.31578 7.29468 0.705315C6.90539 1.0946 6.90511 1.72568 7.29405 2.11531L12.17 7L0.999992 7C0.447708 7 -6.88173e-06 7.44771 -6.93001e-06 8Z"
                    fill="white" fill-opacity="0.8" />
            </svg>
        </a>

        <div class="slider-dots-container">
        ${dots}
        </div>
    </div>

    `
}

export default feedTemplate
