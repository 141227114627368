/**
 * @file
 *
 * Keep functionality for modifying images on the fly here.
 * (Primarily using Cloudinary or a similar service)
 *
 * We'll likely exapnd the options in the future for more granular
 * manipulation. Starting with a one size fits all approach largely though.
 * Later, break the fidgety bits into separate processes so we're not tied too
 * strongly to one Cloudinary account's set of transformations.
 */

import { IPlatform } from 'types-generator/shared/interfaces'
import { getGdprPlatforms } from '../../../../lib/process'
import { DomainPrefix } from '../../../../types'
import { CropImageInvalidBaseUrlError } from '../../../../types/errors'
import captureException from '../../../sentry/captureException'
import { neptuneDebugCloudinary } from '../../../utilities'

const getCloudinaryBaseUrl = (domainPrefix: string) => {
  return `//${domainPrefix}/image/fetch/`
}
const getCloudinaryLargeUrl = (domainPrefix: string) => {
  return getCloudinaryBaseUrl(domainPrefix) + 'g_auto,w_450,h_200,c_fill/'
}
const getCloudinaryPhotocardUrl = (domainPrefix: string) => {
  return getCloudinaryBaseUrl(domainPrefix) + 'g_auto,w_370,h_390,c_fill/'
}
const getCloudinaryThumbnailSmallUrl = (domainPrefix: string) => {
  return getCloudinaryBaseUrl(domainPrefix) + 'g_auto,w_72,h_72,c_fill/'
}
const getCloudinaryPublisherLogoUrl = (domainPrefix: string) => {
  return getCloudinaryBaseUrl(domainPrefix) + 'f_png/if_h_gt_132/h_132/if_end/'
}
const getCloudinaryPublisherLogoNoWhitespaceUrl = (domainPrefix: string) => {
  return getCloudinaryBaseUrl(domainPrefix) + 'f_png/e_trim/if_h_gt_132/h_132/if_end/'
}

interface ImageOptions {
  size?: 'large' | 'photocard' | 'small' | 'clamped' | 'no-whitespace'
  protocol?: 'https:' | 'http:'
  platform?: string
}

// TODO(rrm): Make this use API in long-image cases?
// https://support.cloudinary.com/hc/en-us/articles/207746165-Why-do-I-get-a-public-ID-too-long-error-when-trying-to-fetch-from-a-remote-URL-
export const isTooLong = (url: string) => {
  return url.length > 255
}

export const escapeUrl = (unescapedUrl: string) => {
  // Sometimes content providers give us urls with 'http' or '//'.
  if (unescapedUrl && unescapedUrl.startsWith('//')) {
    unescapedUrl = `https:${unescapedUrl}`
  }

  return encodeURIComponent(unescapedUrl)
}

export const getCloudinaryFormatString = (options: ImageOptions, domainPrefix: string) => {
  switch (options.size) {
    case 'large':
      return getCloudinaryLargeUrl(domainPrefix)
    case 'photocard':
      return getCloudinaryPhotocardUrl(domainPrefix)
    case 'small':
      return getCloudinaryThumbnailSmallUrl(domainPrefix)
    case 'clamped':
      return getCloudinaryPublisherLogoUrl(domainPrefix)
    case 'no-whitespace':
      return getCloudinaryPublisherLogoNoWhitespaceUrl(domainPrefix)
    default:
      throw new Error(`getCloudinaryFormatString: invalid size option '${options.size}'`)
  }
}

/**
 * Creates a Cloudinary transformation URL for a given image URL.
 *
 * Note: code is being left commented out for now, as this is kind of an experiment.
 */
export const cropImage = (
  baseUrl: string,
  domainPrefix: DomainPrefix | string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _options: ImageOptions = { protocol: 'https:' }
) => {
  if (!baseUrl.match(/(https:)?\/\//)) {
    const e = new CropImageInvalidBaseUrlError('cropImage called with invalid baseUrl', baseUrl, domainPrefix)
    captureException(e)
  }

  // const platform =
  //   typeof document === 'undefined' ? options.platform : document.documentElement.dataset.mpPlatform

  neptuneDebugCloudinary(`cropImage disabled: returning ${baseUrl}`)
  return baseUrl

  /*
  return createFormatImageURL(
    baseUrl,
    getCloudinaryFormatString(options, domainPrefix),
    options.protocol,
    platform as IPlatform,
    domainPrefix
  )
   */
}

export const createFormatImageURL = (
  baseUrl: string,
  format: string,
  protocol: string,
  platform: IPlatform,
  domainPrefix: string
) => {
  const escapedUrl = escapeUrl(baseUrl)

  // There is a cloudinary limit of 255 characters for the url.
  if (isTooLong(escapedUrl)) {
    return baseUrl
  }

  const img = `${protocol ? protocol : ''}${format}${escapedUrl}`

  // @todo-cdn I'm not sure if this should be removed (or our CDN config updated). --hrivera
  if (getGdprPlatforms().includes(platform)) {
    switch (domainPrefix) {
      case 'neptune.mobileposse.com':
        return img.replace(domainPrefix, `${platform}.myfirstly.com`)
      case 'sandbox.mobileposse.com':
        return img.replace(domainPrefix, `${platform}.${domainPrefix}`)
    }
  }

  return img
}
