import { Slider } from '../../../utilities'

class SlideShowSlider extends Slider {
  timeOutIdClick
  gradientBackground
  constructor(slider) {
    super(slider)
  }

  cleanContent(title, description, gradientBackground) {
    title.classList.remove('hideTitleToLeft', 'titleAppearNext', 'titleAppearPrev')
    description.classList.remove('hideDescriptionToLeft', 'descriptionAppearNext', 'descriptionAppearPrev')
    gradientBackground?.classList.remove('gradientDisappear', 'gradientDisappearFast')
  }

  setStyleProperties(title, description) {
    title.style.setProperty('--titleClipPath', title.innerText.length > 87 ? '0' : '28px')
    const heightDefference = title.innerText.length > 87 ? 56 : 28
    const newHeight = 149 - heightDefference

    description.style.setProperty('--descHeight', `${newHeight}px`)
    description.style.setProperty('--descHeightTop', `${newHeight + 7}px`)
    description.style.setProperty('--usualDescHeight', `${newHeight - 105 < 0 ? 0 : newHeight - 105}px`)
  }

  appearAllContent(state = 'next') {
    const title = this.activeSlide.querySelector('.block-item__title') as HTMLElement
    const description = this.activeSlide.querySelector('.block-item__description') as HTMLElement
    const gradientBackground = this.activeSlide.querySelector('.block-item__footer-gradient') as HTMLElement

    if (gradientBackground) {
      if (description.innerText.length <= 140 && title.innerText.length > 55) {
        if (description.innerText.length <= 70 && title.innerText.length > 55) {
          if (title.innerText.length > 122) {
            title.style['-webkit-line-clamp'] = '5'
          } else if (title.innerText.length > 87) {
            title.style['-webkit-line-clamp'] = '4'
          } else {
            title.style['-webkit-line-clamp'] = '3'
          }
          this.setStyleProperties(title, description)
        } else {
          if (title.innerText.length > 87) {
            title.style['-webkit-line-clamp'] = '4'
          } else {
            title.style['-webkit-line-clamp'] = '3'
          }
          this.setStyleProperties(title, description)
        }
      } else if (title.innerText.length < 28) {
        title.style.setProperty('--titleClipPath', `56px`)
        description.style.setProperty('--descHeight', '177px')
        description.style.setProperty('--descHeightTop', '188px')
        description.style.setProperty('--usualDescHeight', `72px`)
      } else {
        title.style.setProperty('--titleClipPath', `56px`)
        description.style.setProperty('--descHeight', '149px')
        description.style.setProperty('--descHeightTop', '160px')
        description.style.setProperty('--usualDescHeight', `44px`)
      }
    }
    this.appearTitleAndDescription(title, description, gradientBackground, state)
    this.currentImageAppear()
    this.setHiddenImages()
  }

  appearTitleAndDescription(title, description, gradientBackground = null, state) {
    title.style.animationPlayState = 'running'
    description.style.animationPlayState = 'running'
    if (gradientBackground) {
      gradientBackground.style.animationPlayState = 'running'
    }

    this.cleanContent(title, description, gradientBackground)

    if (state === 'next') {
      title.classList.add('titleAppearNext')
      description.classList.add('descriptionAppearNext')
    } else {
      title.classList.add('titleAppearPrev')
      description.classList.add('descriptionAppearPrev')
    }

    if (gradientBackground) {
      gradientBackground?.classList.add('gradientAppear')
    }

    title.addEventListener('animationend', event => {
      if (event.animationName.includes('titleAppearPrev') || event.animationName.includes('titleAppearNext')) {
        this.onNextClick(false)
      }
    })
  }

  hideContentBounced(title, description, gradientBackground) {
    title.style.removeProperty('animation-delay')
    description.style.removeProperty('animation-delay')
    gradientBackground?.style.removeProperty('animation-delay')

    this.cleanContent(title, description, gradientBackground)

    title.classList.add('hideTitleToLeft')
    description.classList.add('hideDescriptionToLeft')
    gradientBackground?.classList.add('gradientDisappear')
  }

  hideContentFast(container, gradientBackground) {
    if (gradientBackground) {
      gradientBackground.style.removeProperty('--top')
      gradientBackground.style.removeProperty('--height')

      gradientBackground.style.setProperty('--top', `${gradientBackground.offsetTop}px`)
      gradientBackground.style.setProperty('--height', `${gradientBackground.offsetHeight}px`)
      gradientBackground.classList.remove('gradientAppear')
      gradientBackground.classList.add('gradientDisappearFast')
      gradientBackground.style.animationPlayState = 'running'
    }
    container.classList.add('hideContainer')
  }

  currentImageAppear() {
    const currentImage = this.activeSlide.querySelector('.block-item__thumbnail') as HTMLElement
    currentImage.classList.remove('imageDisappear')
    currentImage.classList.add('imageAppear')
  }

  currentImageDisappear() {
    const currentImage = this.activeSlide.querySelector('.block-item__thumbnail') as HTMLElement
    currentImage.classList.remove('imageAppear')
    currentImage.classList.add('imageDisappear')
  }

  arrowsDisappear() {
    this.prev.classList.remove('arrowAppear')
    this.next.classList.remove('arrowAppear')
  }

  arrowAppear() {
    this.prev.classList.add('arrowAppear')
    this.next.classList.add('arrowAppear')
  }

  returnTextContainer(container) {
    container.classList.remove('hideContainer')
  }

  setHiddenImages() {
    let nextSlideImage
    const nextImageContainer = this.activeSlide.querySelector('.block-item__thumbnail-hidden') as HTMLElement

    if (this.slideIndex < this.slides.length) {
      nextSlideImage = this.slides[this.slideIndex].querySelector('.block-item__thumbnail') as HTMLElement
    } else {
      nextSlideImage = this.slides[0].querySelector('.block-item__thumbnail') as HTMLElement
    }

    nextImageContainer.setAttribute('src', nextSlideImage.getAttribute('src'))

    let previousSlideImage
    const previousImageContainer = this.activeSlide.querySelector(
      '.block-item__thumbnail-hidden-previous'
    ) as HTMLElement

    if (this.slideIndex != 1) {
      previousSlideImage = this.slides[this.slideIndex - 2].querySelector('.block-item__thumbnail') as HTMLElement
    } else {
      previousSlideImage = this.slides[this.slides.length - 1].querySelector('.block-item__thumbnail') as HTMLElement
    }

    previousImageContainer.setAttribute('src', previousSlideImage.getAttribute('src'))
    nextImageContainer.style.opacity = '0'
    previousImageContainer.style.opacity = '0'
  }

  onNextClick(clicked) {
    clearTimeout(this.timeOutIdClick)
    this.currentImageDisappear()

    const nextImageContainer = this.activeSlide.querySelector('.block-item__thumbnail-hidden') as HTMLElement
    nextImageContainer.style.opacity = '0.6'

    this.arrowsDisappear()

    {
      const title = this.activeSlide.querySelector('.block-item__title') as HTMLElement
      const description = this.activeSlide.querySelector('.block-item__description') as HTMLElement
      const gradientBackground = this.activeSlide.querySelector('.block-item__footer-gradient') as HTMLElement
      if (clicked) {
        title.style.animationPlayState = 'paused'
        description.style.animationPlayState = 'paused'
        if (gradientBackground) {
          gradientBackground.style.animationPlayState = 'paused'
        }

        const textContainer = this.activeSlide.querySelector('.block-item__text-container') as HTMLElement
        this.hideContentFast(textContainer, gradientBackground)
      } else this.hideContentBounced(title, description, gradientBackground)
    }

    this.timeOutIdClick = setTimeout(
      () => {
        this.plusSlides()

        const textContainer = this.activeSlide.querySelector('.block-item__text-container') as HTMLElement
        this.returnTextContainer(textContainer)

        this.appearAllContent()
        this.arrowAppear()
      },
      clicked ? 600 : 1000
    )
  }

  onPrevClick() {
    clearTimeout(this.timeOutIdClick)

    const previousImageContainer = this.activeSlide.querySelector(
      '.block-item__thumbnail-hidden-previous'
    ) as HTMLElement
    previousImageContainer.style.opacity = '1'

    this.arrowsDisappear()
    this.currentImageDisappear()

    {
      this.minusSlides()

      const textContainer = this.activeSlide.querySelector('.block-item__text-container') as HTMLElement
      this.returnTextContainer(textContainer)

      this.appearAllContent('prev')
      this.arrowAppear()
    }
  }

  init() {
    this.showSlides(this.slideIndex)

    this.appearAllContent()
    this.next.classList.add('arrowAppear')

    this.next.addEventListener('click', this.onNextClick.bind(this, true))
    this.prev.addEventListener('click', this.onPrevClick.bind(this))
  }
}

const initSlider = (slideshowBlocks): void => {
  slideshowBlocks.forEach(slider => {
    const container = slider.querySelector('.slider-container')

    if (!container) return

    const slideshowSlider = new SlideShowSlider(container)
    slideshowSlider.init()
  })
}

const initSlideshow = (): void => {
  try {
    const slideshowBlocks = Array.from(document.querySelectorAll('.block--slideshow'))

    if (!slideshowBlocks) {
      return
    }

    const msNewsSlideshow = slideshowBlocks.filter(block => block.id.includes('ms_news'))

    if (msNewsSlideshow) {
      msNewsSlideshow.forEach(block => {
        block.addEventListener('msNewsLoad', () => {
          initSlider([block])
        })
      })
    }

    const fotoscapeSlideshow = slideshowBlocks.filter(block => block.id.includes('fotoscape_block'))
    if (fotoscapeSlideshow) {
      fotoscapeSlideshow.forEach(block => {
        block.addEventListener('fotoscapesLoad', () => {
          initSlider([block])
        })
      })
    }
  } catch (e) {
    console.error('Content slideshow block error: ' + e)
  }
}

export default initSlideshow
