/**
 * Starting at a given DOM node, crawl upwards through the tree until a parent element containing
 * a provided selector is found. Returns true if classname is found on any DOM node higher in the tree than
 * the provided node.
 * A DNA test for DOM Nodes if you will.
 *
 * There is a native method (Element.prototype.closest()) but only has support for Chrome 41+
 *
 * @param node
 * @param selector - classname only, do not use '.' selector
 */
export const findClosestAncestorNode = (node: any, selector: string): HTMLElement => {
  while (node && node.parentNode) {
    if (node.matches(selector)) {
      return node
    }
    node = node.parentNode
  }

  return null
}

/**
 * Dynamically add script to page.
 */
export const injectScript = (src: string, attributes: any) => {
  const script = document.createElement('script')
  if (attributes && typeof attributes === 'object') {
    Object.entries(attributes).forEach(([key, value]) => {
      script.setAttribute(key, value as any)
    })
  }
  script.src = src
  const head = document.getElementsByTagName('head')[0]
  head.insertAdjacentElement('beforeend', script)
  return script
}
