import dateSetup, { formatDate } from './utilities'

const initPortalHeader = () => {
  try {
    const app_theme = document.documentElement.getAttribute('data-mp-app-theme')

    if (app_theme !== 'moment' && app_theme !== 'vision-you') return

    const lightImage = document.querySelector('.portal-header__logo--light') as HTMLElement
    const darkImage = document.querySelector('.portal-header__logo--dark') as HTMLElement

    if (!lightImage || !darkImage) return

    const language = document.documentElement.lang
    if (!language) return

    const localLocale = dateSetup(language)

    const backgroundImageCheck = window
      .getComputedStyle(darkImage || lightImage)
      .getPropertyValue('background-image')

    const displayFormat = formatDate(language, localLocale)

    if (backgroundImageCheck === 'none') {
      darkImage.innerHTML = displayFormat
      darkImage.classList.add('text-logo')
      lightImage.innerHTML = displayFormat
      lightImage.classList.add('text-logo')
    }
  } catch (e) {
    console.error('Portal header init error: ', e)
  }
}

export default initPortalHeader
