/**
 * List of product types.
 */
export enum IProduct {
  toolbar = 'toolbar',
  firstscreen = 'firstscreen',
  minusone = 'minusone',
  portal = 'portal'
}

/**
 * Which platforms will this page be created for? Part of the URL structure.
 */
// noinspection SpellCheckingInspection
export enum IPlatform {
  aonebar = 'aonebar',
  aoneweb = 'aoneweb',
  attweb = 'attweb',
  awweb = 'awweb',
  blu = 'blu',
  blubar = 'blubar',
  bluweb = 'bluweb',
  boost = 'boost',
  cricket = 'cricket',
  cricketbar = 'cricketbar',
  cricketminus1 = 'cricketminus1',
  cricketweb = 'cricketweb',
  dtom = 'dtom',
  essentials = 'essentials',
  firstly = 'firstly',
  firstlybar = 'firstlybar',
  firstlyweb = 'firstlyweb',
  gioneebar = 'gioneebar',
  gioneeweb = 'gioneeweb',
  hmd = 'hmd',
  magentaweb = 'magentaweb',
  metropcs = 'metropcs',
  metroweb = 'metroweb',
  moment = 'moment',
  myphonebar = 'myphonebar',
  nokiainbar = 'nokiainbar',
  nokialatambar = 'nokialatambar',
  nokiamenabar = 'nokiamenabar',
  orangeweb = 'orangeweb',
  pwg = 'pwg',
  pwgbar = 'pwgbar',
  pwgweb = 'pwgweb',
  sprintweb = 'sprintweb',
  timbar = 'timbar',
  tracfone = 'tracfone',
  tracfonebar = 'tracfonebar',
  uscc = 'uscc',
  usccweb = 'usccweb',
  uscellbar = 'uscellbar',
  verizonbar = 'verizonbar',
  verizonweb = 'verizonweb'
}
